import apiClient from '../../helpers/apiClient';
import { clearStoredUserData, setStoredItem, getStoredObject } from '../../helpers/storageHelper';
import { StorageKeys } from '../../constants/storageKeys';
import { startSubmit } from 'redux-form';
import { clearCart } from './network/ShoppingCart';

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_ERROR = 'LOGIN_ERROR';

export const REFRESH_TOKEN_REQUEST = 'REFRESH_TOKEN_REQUEST';
export const REFRESH_TOKEN_SUCCESS = 'REFRESH_TOKEN_SUCCESS';
export const REFRESH_TOKEN_ERROR = 'REFRESH_TOKEN_ERROR';

export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';

export const REGISTER_REQUEST = 'REGISTER_REQUEST';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_ERROR = 'REGISTER_ERROR';

export const REGISTER_DISTRIBUTOR_REQUEST = 'REGISTER_DISTRIBUTOR_REQUEST';
export const REGISTER_DISTRIBUTOR_SUCCESS = 'REGISTER_DISTRIBUTOR_SUCCESS';
export const REGISTER_DISTRIBUTOR_ERROR = 'REGISTER_DISTRIBUTOR_ERROR';

export const REGISTER_CUSTOMER_REQUEST = 'REGISTER_CUSTOMER_REQUEST';
export const REGISTER_CUSTOMER_SUCCESS = 'REGISTER_CUSTOMER_SUCCESS';
export const REGISTER_CUSTOMER_ERROR = 'REGISTER_CUSTOMER_ERROR';

export const REGISTER_AGENT_REQUEST = 'REGISTER_AGENT_REQUEST';
export const REGISTER_AGENT_SUCCESS = 'REGISTER_AGENT_SUCCESS';
export const REGISTER_AGENT_ERROR = 'REGISTER_AGENT_ERROR';

export const CONFIRM_EMAIL_REQUEST = 'CONFIRM_EMAIL_REQUEST';
export const CONFIRM_EMAIL_SUCCESS = 'CONFIRM_EMAIL_SUCCESS';
export const CONFIRM_EMAIL_ERROR = 'CONFIRM_EMAIL_ERROR';

export const GET_PROFILE_REQUEST = 'GET_PROFILE_REQUEST';
export const GET_PROFILE_SUCCESS = 'GET_PROFILE_SUCCESS';
export const GET_PROFILE_ERROR = 'GET_PROFILE_ERROR';

export const GET_CUSTOMER_PROFILE_REQUEST = 'GET_CUSTOMER_PROFILE_REQUEST';
export const GET_CUSTOMER_PROFILE_SUCCESS = 'GET_CUSTOMER_PROFILE_SUCCESS';
export const GET_CUSTOMER_PROFILE_ERROR = 'GET_CUSTOMER_PROFILE_ERROR';

export const UPDATE_PROFILE_REQUEST = 'UPDATE_PROFILE_REQUEST';
export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS';
export const UPDATE_PROFILE_ERROR = 'UPDATE_PROFILE_ERROR';

export const FORGOT_PASSWORD_REQUEST = 'FORGOT_PASSWORD_REQUEST';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_ERROR = 'FORGOT_PASSWORD_ERROR';

export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR';

export const CHANGE_PASSWORD_REQUEST = 'CHANGE_PASSWORD_REQUEST';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_ERROR = 'CHANGE_PASSWORD_ERROR';

export const login =
  ({ email, password }, formId) =>
  dispatch => {
    return Promise.resolve(dispatch(startSubmit(formId)))
      .then(() => {
        return dispatch({
          types: [LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_ERROR],
          callAPI: () => {
            const params = new URLSearchParams();
            params.append('grant_type', 'password');
            params.append('username', email);
            params.append('password', password);
            params.append(
              'scope',
              'openid profile email offline_access admin network customer product agent service payment',
            );
            params.append('client_id', 'dms-web-app');
            params.append('client_secret', 'dms-web-app');

            const config = {
              headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
              },
            };
            return apiClient.post('token', params, config);
          },
          payload: {
            data: {
              username: email,
              password,
            },
            formId,
            waitSubmit: true,
          },
        });
      })
      .then(
        resp => {
          setStoredItem(StorageKeys.AuthData, JSON.stringify(resp.response.data));
          return Promise.resolve(resp);
        },
        error => {
          return Promise.reject(error);
        },
      );
  };

export const refreshToken = refresh_token => dispatch => {
  return Promise.resolve()
    .then(() => {
      return dispatch({
        types: [REFRESH_TOKEN_REQUEST, REFRESH_TOKEN_SUCCESS, REFRESH_TOKEN_ERROR],
        callAPI: () => {
          const params = new URLSearchParams();
          params.append('grant_type', 'refresh_token');
          params.append('refresh_token', refresh_token);
          params.append(
            'scope',
            'openid profile email offline_access admin network customer product agent service payment',
          );
          params.append('client_id', 'dms-web-app');
          params.append('client_secret', 'dms-web-app');

          const config = {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
          };
          return apiClient.post('token', params, config);
        },
        payload: {
          data: {
            refresh_token,
          },
        },
      });
    })
    .then(
      resp => {
        setStoredItem(StorageKeys.AuthData, JSON.stringify(resp.response.data));
        return Promise.resolve(resp);
      },
      error => {
        return Promise.reject(error);
      },
    );
};

export const register = (data, formId) => dispatch => {
  return Promise.resolve(dispatch(startSubmit(formId))).then(() =>
    dispatch({
      types: [REGISTER_REQUEST, REGISTER_SUCCESS, REGISTER_ERROR],
      callAPI: () => apiClient.post('register', data),
      payload: { data, formId },
    }),
  );
};

export const registerDistributor = (data, formId) => dispatch => {
  return Promise.resolve(dispatch(startSubmit(formId))).then(() =>
    dispatch({
      types: [REGISTER_DISTRIBUTOR_REQUEST, REGISTER_DISTRIBUTOR_SUCCESS, REGISTER_DISTRIBUTOR_ERROR],
      callAPI: () => apiClient.post('registerDistributor', data),
      payload: { data, formId },
    }),
  );
};

export const registerBusinessCustomer = (data, formId) => dispatch => {
  return Promise.resolve(dispatch(startSubmit(formId))).then(() =>
    dispatch({
      types: [REGISTER_CUSTOMER_REQUEST, REGISTER_CUSTOMER_SUCCESS, REGISTER_CUSTOMER_ERROR],
      callAPI: () => apiClient.post('identity/Account/RegisterBusinessCustomer', data),
      payload: { data, formId },
    }),
  );
};

export const registerAgent = (data, formId) => dispatch => {
  return Promise.resolve(dispatch(startSubmit(formId))).then(() =>
    dispatch({
      types: [REGISTER_AGENT_REQUEST, REGISTER_AGENT_SUCCESS, REGISTER_AGENT_ERROR],
      callAPI: () => apiClient.post('registerAgent', data),
      payload: { data, formId },
    }),
  );
};

export const confirmEmail = data => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [CONFIRM_EMAIL_REQUEST, CONFIRM_EMAIL_SUCCESS, CONFIRM_EMAIL_ERROR],
      callAPI: () => apiClient.patch('confirm', data),
      payload: { data },
    }),
  );
};

export const getProfile = () => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [GET_PROFILE_REQUEST, GET_PROFILE_SUCCESS, GET_PROFILE_ERROR],
      callAPI: () => apiClient.get('admin/Profile'),
      payload: {},
    }),
  );
};

export const getCustomerProfile = () => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [GET_CUSTOMER_PROFILE_REQUEST, GET_CUSTOMER_PROFILE_SUCCESS, GET_CUSTOMER_PROFILE_ERROR],
      callAPI: () => apiClient.get('customer/customers/GetByUserId'),
      payload: {},
    }),
  );
};

export const updateProfile = (data, formId) => dispatch => {
  return Promise.resolve(dispatch(startSubmit(formId))).then(() =>
    dispatch({
      types: [UPDATE_PROFILE_REQUEST, UPDATE_PROFILE_SUCCESS, UPDATE_PROFILE_ERROR],
      callAPI: () => apiClient.put('admin/Profile', data),
      payload: { data, formId },
    }),
  );
};

export const forgotPassword = (data, formId) => dispatch => {
  return Promise.resolve(dispatch(startSubmit(formId))).then(() =>
    dispatch({
      types: [FORGOT_PASSWORD_REQUEST, FORGOT_PASSWORD_SUCCESS, FORGOT_PASSWORD_ERROR],
      callAPI: () => apiClient.post('admin/Password/ForgotByEmail', data),
      payload: { data, formId },
    }),
  );
};

export const resetPassword = (data, formId) => dispatch => {
  return Promise.resolve(dispatch(startSubmit(formId))).then(() =>
    dispatch({
      types: [RESET_PASSWORD_REQUEST, RESET_PASSWORD_SUCCESS, RESET_PASSWORD_ERROR],
      callAPI: () => apiClient.post('admin/Password/ResetByEmail', data),
      payload: { data, formId },
    }),
  );
};

export const changePassword = (data, formId) => dispatch => {
  return Promise.resolve(dispatch(startSubmit(formId))).then(() =>
    dispatch({
      types: [CHANGE_PASSWORD_REQUEST, CHANGE_PASSWORD_SUCCESS, CHANGE_PASSWORD_ERROR],
      callAPI: () => apiClient.post('admin/Password/ChangePassword', data),
      payload: { data, formId },
    }),
  );
};

const requestLogout = () => dispatch => {
  dispatch({
    type: LOGOUT_REQUEST,
    payload: {},
  });

  return Promise.resolve();
};

const receiveLogout = () => (dispatch, getState) => {
  dispatch({
    type: LOGOUT_SUCCESS,
    payload: {},
  });
  return Promise.resolve();
};

export const logout = () => (dispatch, getState) => {
  return Promise.resolve()
    .then(() => dispatch(requestLogout()))
    .then(() => {
      clearStoredUserData();
      return Promise.resolve();
    })
    .then(() => dispatch(receiveLogout()))
    .then(() => dispatch(clearCart()));
};

// -----------------
const profile = getStoredObject(StorageKeys.Profile);
const customerProfile = getStoredObject(StorageKeys.CustomerProfile);

const initialState = {
  payload: {},
  response: {},
  error: {},
  isAuthenticated: false,
  profile: profile ? profile : {},
  customerProfile: customerProfile ? customerProfile : {},
  errorMessage: '',
  successMessage: '',
};

export default function reducer(state = initialState, action) {
  const { payload, response, error, successMessage, errorMessage } = action;
  switch (action.type) {
    case LOGIN_REQUEST:
    case REGISTER_REQUEST:
    case REGISTER_DISTRIBUTOR_REQUEST:
    case REGISTER_CUSTOMER_REQUEST:
    case CONFIRM_EMAIL_REQUEST:
    case GET_PROFILE_REQUEST:
    case GET_CUSTOMER_PROFILE_REQUEST:
    case UPDATE_PROFILE_REQUEST:
    case FORGOT_PASSWORD_REQUEST:
    case RESET_PASSWORD_REQUEST:
    case CHANGE_PASSWORD_REQUEST:
    case REGISTER_AGENT_REQUEST:
      return {
        ...state,
        payload,
        successMessage: '',
        errorMessage: '',
      };

    case LOGIN_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        payload,
        response,
        successMessage,
      };

    case REGISTER_SUCCESS:
    case REGISTER_DISTRIBUTOR_SUCCESS:
    case REGISTER_CUSTOMER_SUCCESS:
    case CONFIRM_EMAIL_SUCCESS:
    case FORGOT_PASSWORD_SUCCESS:
    case RESET_PASSWORD_SUCCESS:
    case CHANGE_PASSWORD_SUCCESS:
    case REGISTER_AGENT_SUCCESS:
      return {
        ...state,
        payload,
        response,
        successMessage,
      };

    case GET_PROFILE_SUCCESS:
    case UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        profile: response.data.data,
        payload,
        response,
        successMessage,
      };

      case GET_CUSTOMER_PROFILE_SUCCESS:
      return {
        ...state,
        customerProfile: response.data.data,
        payload,
        response,
        successMessage,
      };

    case LOGIN_ERROR:
    case REGISTER_ERROR:
    case REGISTER_DISTRIBUTOR_ERROR:
    case REGISTER_CUSTOMER_ERROR:
    case GET_PROFILE_ERROR:
    case GET_CUSTOMER_PROFILE_ERROR:
    case UPDATE_PROFILE_ERROR:
    case CONFIRM_EMAIL_ERROR:
    case FORGOT_PASSWORD_ERROR:
    case RESET_PASSWORD_ERROR:
    case CHANGE_PASSWORD_ERROR:
    case REGISTER_AGENT_ERROR:
      return {
        ...state,
        payload,
        error,
        errorMessage,
      };

    case LOGOUT_SUCCESS:
      return {
        ...state,
        isAuthenticated: false,
        profile: {},
      };
    default:
      return state;
  }
}
