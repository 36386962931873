import React from 'react';
import cx from 'classnames';
import { formatAmount } from 'helpers/numberHelper';

const DiscountInfo = ({ discounts, className, currency, uomCode, ...props }) => {
  return discounts ? (
    <div className={cx('discount-info', className)} {...props}>
      <div className="row mb-1">
        <div className="col">
          <b>Promotion:</b>
        </div>
      </div>
      <div className="row">
        {discounts.map((d, idx) => {
          return (
            <div className="col-6" key={idx}>
              {d.description}:{' '}
              <b>
                {currency} {formatAmount(d.price)}
              </b>{' '}
              / <i>{uomCode}</i>
            </div>
          );
        })}
      </div>
    </div>
  ) : (
    ''
  );
};

export default DiscountInfo;
