import ApiClient from '../../../helpers/apiClient';
import { startSubmit } from 'redux-form';

export const FETCH_SALE_ORDERS_REQUEST = 'FETCH_SALE_ORDERS_REQUEST';
export const FETCH_SALE_ORDERS_SUCCESS = 'FETCH_SALE_ORDERS_SUCCESS';
export const FETCH_SALE_ORDERS_ERROR = 'FETCH_SALE_ORDERS_ERROR';

export const GET_SALE_ORDER_DETAIL_REQUEST = 'GET_SALE_ORDER_DETAIL_REQUEST';
export const GET_SALE_ORDER_DETAIL_SUCCESS = 'GET_SALE_ORDER_DETAIL_SUCCESS';
export const GET_SALE_ORDER_DETAIL_ERROR = 'GET_SALE_ORDER_DETAIL_ERROR';

export const CREATE_SALE_ORDER_REQUEST = 'CREATE_SALE_ORDER_REQUEST';
export const CREATE_SALE_ORDER_SUCCESS = 'CREATE_SALE_ORDER_SUCCESS';
export const CREATE_SALE_ORDER_ERROR = 'CREATE_SALE_ORDER_ERROR';

export const UPDATE_SALE_ORDER_REQUEST = 'UPDATE_SALE_ORDER_REQUEST';
export const UPDATE_SALE_ORDER_SUCCESS = 'UPDATE_SALE_ORDER_SUCCESS';
export const UPDATE_SALE_ORDER_ERROR = 'UPDATE_SALE_ORDER_ERROR';

export const DELETE_SALE_ORDER_REQUEST = 'DELETE_SALE_ORDER_REQUEST';
export const DELETE_SALE_ORDER_SUCCESS = 'DELETE_SALE_ORDER_SUCCESS';
export const DELETE_SALE_ORDER_ERROR = 'DELETE_SALE_ORDER_ERROR';

export const CONFIRM_SALE_ORDER_REQUEST = 'CONFIRM_SALE_ORDER_REQUEST';
export const CONFIRM_SALE_ORDER_SUCCESS = 'CONFIRM_SALE_ORDER_SUCCESS';
export const CONFIRM_SALE_ORDER_ERROR = 'CONFIRM_SALE_ORDER_ERROR';

export const ADD_OR_UPDATE_ORDER_DETAIL_REQUEST = 'ADD_OR_UPDATE_ORDER_DETAIL_REQUEST';
export const ADD_OR_UPDATE_ORDER_DETAIL_SUCCESS = 'ADD_OR_UPDATE_ORDER_DETAIL_SUCCESS';
export const ADD_OR_UPDATE_ORDER_DETAIL_ERROR = 'ADD_OR_UPDATE_ORDER_DETAIL_ERROR';

export const fetchSaleOrders = data => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [FETCH_SALE_ORDERS_REQUEST, FETCH_SALE_ORDERS_SUCCESS, FETCH_SALE_ORDERS_ERROR],
      callAPI: () => ApiClient.post('network/SaleOrders/Search', data),
      payload: { data },
    }),
  );
};

export const getSaleOrderDetail = id => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [GET_SALE_ORDER_DETAIL_REQUEST, GET_SALE_ORDER_DETAIL_SUCCESS, GET_SALE_ORDER_DETAIL_ERROR],
      callAPI: () => ApiClient.get(`network/SaleOrders/GetDetail/${id}`),
      payload: { data: { id } },
    }),
  );
};

export const createSaleOrder = (data, formId) => dispatch => {
  return Promise.resolve(dispatch(startSubmit(formId))).then(() =>
    dispatch({
      types: [CREATE_SALE_ORDER_REQUEST, CREATE_SALE_ORDER_SUCCESS, CREATE_SALE_ORDER_ERROR],
      callAPI: () => ApiClient.post('network/SaleOrders', data),
      payload: { data, formId },
    }),
  );
};

export const updateSaleOrder = (id, data, formId) => dispatch => {
  return Promise.resolve(dispatch(startSubmit(formId))).then(() =>
    dispatch({
      types: [UPDATE_SALE_ORDER_REQUEST, UPDATE_SALE_ORDER_SUCCESS, UPDATE_SALE_ORDER_ERROR],
      callAPI: () => ApiClient.put(`network/SaleOrders/${id}`, data),
      payload: { data: { ...data, id }, formId },
    }),
  );
};

export const deleteSaleOrder = id => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [DELETE_SALE_ORDER_REQUEST, DELETE_SALE_ORDER_SUCCESS, DELETE_SALE_ORDER_ERROR],
      callAPI: () => ApiClient.delete(`network/SaleOrders/${id}`),
      payload: { data: { id } },
    }),
  );
};

export const addOrUpdateOrderDetail = data => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [ADD_OR_UPDATE_ORDER_DETAIL_REQUEST, ADD_OR_UPDATE_ORDER_DETAIL_SUCCESS, ADD_OR_UPDATE_ORDER_DETAIL_ERROR],
      callAPI: () => ApiClient.post(`network/SaleOrders/AddOrUpdateDetail`, data),
      payload: { data },
    }),
  );
};

export const confirmSaleOrder = id => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [CONFIRM_SALE_ORDER_REQUEST, CONFIRM_SALE_ORDER_SUCCESS, CONFIRM_SALE_ORDER_ERROR],
      callAPI: () => ApiClient.put(`network/SaleOrders/Confirm/${id}`),
      payload: { id },
    }),
  );
};

const initialState = {
  payload: {},
  data: {},
  response: {},
  error: {},
  errorMessage: '',
  successMessage: '',
};

export default function reducer(state = initialState, action) {
  const { payload, response, error, successMessage, errorMessage } = action;
  switch (action.type) {
    case FETCH_SALE_ORDERS_REQUEST:
    case GET_SALE_ORDER_DETAIL_REQUEST:
    case CREATE_SALE_ORDER_REQUEST:
    case UPDATE_SALE_ORDER_REQUEST:
    case DELETE_SALE_ORDER_REQUEST:
    case ADD_OR_UPDATE_ORDER_DETAIL_REQUEST:
    case CONFIRM_SALE_ORDER_REQUEST:
      return {
        ...state,
        payload,
        response: {},
        error: {},
        successMessage: '',
        errorMessage: '',
      };

    case FETCH_SALE_ORDERS_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          items: response.data.data.items,
          pages: response.data.data.pageCount,
        },
        response,
        successMessage,
      };
    case GET_SALE_ORDER_DETAIL_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          item: response.data.data,
        },
        response,
        successMessage,
      };

    case ADD_OR_UPDATE_ORDER_DETAIL_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          details: response.data.data,
        },
        response,
        successMessage,
      };

    case CREATE_SALE_ORDER_SUCCESS:
    case UPDATE_SALE_ORDER_SUCCESS:
    case DELETE_SALE_ORDER_SUCCESS:
    case CONFIRM_SALE_ORDER_SUCCESS:
      return {
        ...state,
        response,
        successMessage,
      };

    case FETCH_SALE_ORDERS_ERROR:
    case GET_SALE_ORDER_DETAIL_ERROR:
    case CREATE_SALE_ORDER_ERROR:
    case UPDATE_SALE_ORDER_ERROR:
    case DELETE_SALE_ORDER_ERROR:
    case ADD_OR_UPDATE_ORDER_DETAIL_ERROR:
    case CONFIRM_SALE_ORDER_ERROR:
      return {
        ...state,
        payload,
        error,
        errorMessage,
      };
    default:
      return state;
  }
}
