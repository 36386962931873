import React from 'react';
import { Input, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import ValidationMessage from '../Controls/ValidationMessage';
import classnames from 'classnames';
import 'styles/css/IconTextField.css';

const IconTextField = ({
  input,
  className,
  showLabel,
  required,
  horizontal,
  label,
  icon,
  meta: { touched, error },
  description,
  ...props
}) => {
  const classes = classnames('icon-input-group', className);
  return (
    <InputGroup className={classes}>
      <InputGroupAddon addonType="prepend">
        <InputGroupText className="icon-input-icon">
          <img className="icon" src={icon} alt={label} />
        </InputGroupText>
      </InputGroupAddon>
      <Input className="icon-text-input" placeholder={label} style={{ border: 0 }} {...input} {...props} />
    </InputGroup>
  );
};

export default IconTextField;
