import ApiClient from '../../../helpers/apiClient';
import { startSubmit } from 'redux-form';

export const GET_ROLE_PERMISSION_REQUEST = 'GET_ROLE_PERMISSION_REQUEST';
export const GET_ROLE_PERMISSION_SUCCESS = 'GET_ROLE_PERMISSION_SUCCESS';
export const GET_ROLE_PERMISSION_ERROR = 'GET_ROLE_PERMISSION_ERROR';

export const UPDATE_ROLE_PERMISSION_REQUEST = 'UPDATE_ROLE_PERMISSION_REQUEST';
export const UPDATE_ROLE_PERMISSION_SUCCESS = 'UPDATE_ROLE_PERMISSION_SUCCESS';
export const UPDATE_ROLE_PERMISSION_ERROR = 'UPDATE_ROLE_PERMISSION_ERROR';

export const GET_CUSTOMER_GROUP_PERMISSION_REQUEST = 'GET_CUSTOMER_GROUP_PERMISSION_REQUEST';
export const GET_CUSTOMER_GROUP_PERMISSION_SUCCESS = 'GET_CUSTOMER_GROUP_PERMISSION_SUCCESS';
export const GET_CUSTOMER_GROUP_PERMISSION_ERROR = 'GET_CUSTOMER_GROUP_PERMISSION_ERROR';

export const UPDATE_CUSTOMER_GROUP_PERMISSION_REQUEST = 'UPDATE_CUSTOMER_GROUP_PERMISSION_REQUEST';
export const UPDATE_CUSTOMER_GROUP_PERMISSION_SUCCESS = 'UPDATE_CUSTOMER_GROUP_PERMISSION_SUCCESS';
export const UPDATE_CUSTOMER_GROUP_PERMISSION_ERROR = 'UPDATE_CUSTOMER_GROUP_PERMISSION_ERROR';

export const getRolePermissions = () => dispatch => {
    return Promise.resolve().then(() =>
        dispatch({
            types: [
                GET_ROLE_PERMISSION_REQUEST,
                GET_ROLE_PERMISSION_SUCCESS,
                GET_ROLE_PERMISSION_ERROR,
            ],
            callAPI: () => ApiClient.get('admin/Permissions'),
            payload: {},
        }),
    );
};

export const updateRolePermissions = (data) => dispatch => {
    return Promise.resolve().then(() =>
        dispatch({
            types: [
                UPDATE_ROLE_PERMISSION_REQUEST,
                UPDATE_ROLE_PERMISSION_SUCCESS,
                UPDATE_ROLE_PERMISSION_ERROR,
            ],
            callAPI: () => ApiClient.put('admin/Permissions', data),
            payload: { data },
        }),
    );
};

export const getCustomerGroupPermissions = () => dispatch => {
    return Promise.resolve().then(() =>
        dispatch({
            types: [
                GET_CUSTOMER_GROUP_PERMISSION_REQUEST,
                GET_CUSTOMER_GROUP_PERMISSION_SUCCESS,
                GET_CUSTOMER_GROUP_PERMISSION_ERROR,
            ],
            callAPI: () => ApiClient.get('admin/Permissions/GetCustomerGroupPermissions'),
            payload: {},
        }),
    );
};

export const updateCustomerGroupPermissions = (data) => dispatch => {
    return Promise.resolve().then(() =>
        dispatch({
            types: [
                UPDATE_CUSTOMER_GROUP_PERMISSION_REQUEST,
                UPDATE_CUSTOMER_GROUP_PERMISSION_SUCCESS,
                UPDATE_CUSTOMER_GROUP_PERMISSION_ERROR,
            ],
            callAPI: () => ApiClient.put('admin/Permissions/UpdateCustomerGroupPermissions', data),
            payload: { data },
        }),
    );
};

const initialState = {
    payload: {},
    data: {},
    response: {},
    error: {},
    errorMessage: '',
    successMessage: '',
};

export default function reducer(state = initialState, action) {
    const { payload, response, error, successMessage, errorMessage } = action;
    switch (action.type) {
        case UPDATE_ROLE_PERMISSION_REQUEST:
        case GET_ROLE_PERMISSION_REQUEST:
        case GET_CUSTOMER_GROUP_PERMISSION_REQUEST:
        case UPDATE_CUSTOMER_GROUP_PERMISSION_REQUEST:
            return {
                ...state,
                payload,
                response: {},
                error: {},
                successMessage: '',
                errorMessage: '',
            };

        case GET_ROLE_PERMISSION_SUCCESS:
            return {
                ...state,
                data: {
                    ...state.data,
                    rolePermissions: response.data.data.rolePermissions,
                    resources: response.data.data.resources
                },
                response,
                successMessage,
            };

        case GET_CUSTOMER_GROUP_PERMISSION_SUCCESS:
            return {
                ...state,
                data: {
                    ...state.data,
                    customerGroupPermissions: response.data.data.customerGroupPermissions,
                    permissions: response.data.data.permissions
                },
                response,
                successMessage,
            };

        case UPDATE_ROLE_PERMISSION_SUCCESS:
        case UPDATE_CUSTOMER_GROUP_PERMISSION_SUCCESS:
            return {
                ...state,
                response,
                successMessage,
            };

        case UPDATE_ROLE_PERMISSION_ERROR:
        case GET_ROLE_PERMISSION_ERROR:
        case GET_CUSTOMER_GROUP_PERMISSION_ERROR:
        case UPDATE_CUSTOMER_GROUP_PERMISSION_ERROR:
            return {
                ...state,
                payload,
                error,
                errorMessage,
            };
        default:
            return state;
    }
}
