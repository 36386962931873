import ApiClient from '../../../helpers/apiClient';
import { startSubmit } from 'redux-form';

export const FETCH_SETTING_OPTIONS_REQUEST = 'FETCH_SETTING_OPTIONS_REQUEST';
export const FETCH_SETTING_OPTIONS_SUCCESS = 'FETCH_SETTING_OPTIONS_SUCCESS';
export const FETCH_SETTING_OPTIONS_ERROR = 'FETCH_SETTING_OPTIONS_ERROR';

export const FETCH_FREWIE_PROVIDER_OPTION_REQUEST = 'FETCH_FREWIE_PROVIDER_OPTION_REQUEST';
export const FETCH_FREWIE_PROVIDER_OPTION_SUCCESS = 'FETCH_FREWIE_PROVIDER_OPTION_SUCCESS';
export const FETCH_FREWIE_PROVIDER_OPTION_ERROR = 'FETCH_FREWIE_PROVIDER_OPTION_ERROR';

export const FETCH_VALUE_OPTIONS_REQUEST = 'FETCH_VALUE_OPTIONS_REQUEST';
export const FETCH_VALUE_OPTIONS_SUCCESS = 'FETCH_VALUE_OPTIONS_SUCCESS';
export const FETCH_VALUE_OPTIONS_ERROR = 'FETCH_VALUE_OPTIONS_ERROR';

export const FETCH_SETTINGS_REQUEST = 'FETCH_SETTINGS_REQUEST';
export const FETCH_SETTINGS_SUCCESS = 'FETCH_SETTINGS_SUCCESS';
export const FETCH_SETTINGS_ERROR = 'FETCH_SETTINGS_ERROR';

export const GET_SETTING_DETAIL_REQUEST = 'GET_SETTING_DETAIL_REQUEST';
export const GET_SETTING_DETAIL_SUCCESS = 'GET_SETTING_DETAIL_SUCCESS';
export const GET_SETTING_DETAIL_ERROR = 'GET_SETTING_DETAIL_ERROR';

export const CREATE_SETTING_REQUEST = 'CREATE_SETTING_REQUEST';
export const CREATE_SETTING_SUCCESS = 'CREATE_SETTING_SUCCESS';
export const CREATE_SETTING_ERROR = 'CREATE_SETTING_ERROR';

export const UPDATE_SETTING_REQUEST = 'UPDATE_SETTING_REQUEST';
export const UPDATE_SETTING_SUCCESS = 'UPDATE_SETTING_SUCCESS';
export const UPDATE_SETTING_ERROR = 'UPDATE_SETTING_ERROR';

export const DELETE_SETTING_REQUEST = 'DELETE_SETTING_REQUEST';
export const DELETE_SETTING_SUCCESS = 'DELETE_SETTING_SUCCESS';
export const DELETE_SETTING_ERROR = 'DELETE_SETTING_ERROR';

export const fetchFrewieProviderOptions = () => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [FETCH_FREWIE_PROVIDER_OPTION_REQUEST, FETCH_FREWIE_PROVIDER_OPTION_SUCCESS, FETCH_FREWIE_PROVIDER_OPTION_ERROR],
      callAPI: () => ApiClient.get('network/freewie/GetSelectList'),
      payload: {},
    }),
  );
};

export const fetchSettings = data => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [
        FETCH_SETTINGS_REQUEST,
        FETCH_SETTINGS_SUCCESS,
        FETCH_SETTINGS_ERROR,
      ],
      callAPI: () => ApiClient.post('network/Settings/Search', data),
      payload: { data },
    }),
  );
};

export const fetchSettingOptions = settingId => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [
        FETCH_SETTING_OPTIONS_REQUEST,
        FETCH_SETTING_OPTIONS_SUCCESS,
        FETCH_SETTING_OPTIONS_ERROR,
      ],
      callAPI: () => {
        if (settingId)
          return ApiClient.get(
            'network/Settings/GetSelectList?settingId=' + settingId,
          );
        else return ApiClient.get('network/Settings/GetSelectList');
      },
      payload: { settingId },
    }),
  );
};

export const fetchValueOptions = settingName => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [
        FETCH_VALUE_OPTIONS_REQUEST,
        FETCH_VALUE_OPTIONS_SUCCESS,
        FETCH_VALUE_OPTIONS_ERROR,
      ],
      callAPI: () =>
        ApiClient.get(
          'network/Settings/GetValueOptions?settingName=' + settingName,
        ),
      payload: { settingName },
    }),
  );
};

export const getSettingDetail = id => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [
        GET_SETTING_DETAIL_REQUEST,
        GET_SETTING_DETAIL_SUCCESS,
        GET_SETTING_DETAIL_ERROR,
      ],
      callAPI: () => ApiClient.get(`network/Settings/GetDetail/${id}`),
      payload: { data: { id } },
    }),
  );
};

export const createSetting = (data, formId) => dispatch => {
  return Promise.resolve(dispatch(startSubmit(formId))).then(() =>
    dispatch({
      types: [
        CREATE_SETTING_REQUEST,
        CREATE_SETTING_SUCCESS,
        CREATE_SETTING_ERROR,
      ],
      callAPI: () => ApiClient.post('network/Settings', data),
      payload: { data, formId },
    }),
  );
};

export const updateSetting = (id, data, formId) => dispatch => {
  return Promise.resolve(dispatch(startSubmit(formId))).then(() =>
    dispatch({
      types: [
        UPDATE_SETTING_REQUEST,
        UPDATE_SETTING_SUCCESS,
        UPDATE_SETTING_ERROR,
      ],
      callAPI: () => ApiClient.put(`network/Settings/${id}`, data),
      payload: { data: { ...data, id }, formId },
    }),
  );
};

export const deleteSetting = id => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [
        DELETE_SETTING_REQUEST,
        DELETE_SETTING_SUCCESS,
        DELETE_SETTING_ERROR,
      ],
      callAPI: () => ApiClient.delete(`network/Settings/${id}`),
      payload: { data: { id } },
    }),
  );
};

const initialState = {
  payload: {},
  data: {},
  response: {},
  error: {},
  errorMessage: '',
  successMessage: '',
};

export default function reducer(state = initialState, action) {
  const { payload, response, error, successMessage, errorMessage } = action;
  switch (action.type) {
    case FETCH_SETTING_OPTIONS_REQUEST:
    case FETCH_VALUE_OPTIONS_REQUEST:
    case FETCH_SETTINGS_REQUEST:
    case GET_SETTING_DETAIL_REQUEST:
    case CREATE_SETTING_REQUEST:
    case UPDATE_SETTING_REQUEST:
    case DELETE_SETTING_REQUEST:
    case FETCH_FREWIE_PROVIDER_OPTION_REQUEST:
      return {
        ...state,
        payload,
        response: {},
        error: {},
        successMessage: '',
        errorMessage: '',
      };

    case FETCH_FREWIE_PROVIDER_OPTION_SUCCESS:
    case FETCH_SETTING_OPTIONS_SUCCESS:
      return {
        ...state,
        data: { ...state.data, frewieProviderOptions: response.data.data },
        response,
        successMessage,
      };

    case FETCH_VALUE_OPTIONS_SUCCESS:
      return {
        ...state,
        data: { ...state.data, valueOptions: response.data.data },
        response,
        successMessage,
      };

    case FETCH_SETTINGS_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          items: response.data.data.items,
          pages: response.data.data.pageCount,
        },
        response,
        successMessage,
      };
    case GET_SETTING_DETAIL_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          item: response.data.data,
        },
        response,
        successMessage,
      };

    case CREATE_SETTING_SUCCESS:
    case UPDATE_SETTING_SUCCESS:
    case DELETE_SETTING_SUCCESS:
      return {
        ...state,
        response,
        successMessage,
      };

    case FETCH_SETTING_OPTIONS_ERROR:
    case FETCH_VALUE_OPTIONS_ERROR:
    case FETCH_SETTINGS_ERROR:
    case GET_SETTING_DETAIL_ERROR:
    case CREATE_SETTING_ERROR:
    case UPDATE_SETTING_ERROR:
    case FETCH_FREWIE_PROVIDER_OPTION_ERROR:
    case DELETE_SETTING_ERROR:
      return {
        ...state,
        payload,
        error,
        errorMessage,
      };
    default:
      return state;
  }
}
