import ApiClient from '../../../helpers/apiClient';
import { startSubmit } from 'redux-form';
export const FETCH_COUNTRY_REQUEST = 'FETCH_COUNTRY_REQUEST';
export const FETCH_COUNTRY_SUCCESS = 'FETCH_COUNTRY_SUCCESS';
export const FETCH_COUNTRY_ERROR = 'FETCH_COUNTRY_ERROR';

export const FETCH_COUNTRY_OPTIONS_REQUEST =
  'FETCH_COUNTRY_OPTIONS_REQUEST';
export const FETCH_COUNTRY_OPTIONS_SUCCESS =
  'FETCH_COUNTRY_OPTIONS_SUCCESS';
export const FETCH_COUNTRY_OPTIONS_ERROR =
  'FETCH_COUNTRY_OPTIONS_ERROR';

export const GET_COUNTRY_DETAIL_REQUEST =
  'GET_COUNTRY_DETAIL_REQUEST';
export const GET_COUNTRY_DETAIL_SUCCESS =
  'GET_COUNTRY_DETAIL_SUCCESS';
export const GET_COUNTRY_DETAIL_ERROR =
  'GET_COUNTRY_DETAIL_ERROR';


export const UPDATE_COUNTRY_REQUEST = 'UPDATE_COUNTRY_REQUEST';
export const UPDATE_COUNTRY_SUCCESS = 'UPDATE_COUNTRY_SUCCESS';
export const UPDATE_COUNTRY_ERROR = 'UPDATE_COUNTRY_ERROR';

export const FETCH_COUNTRY_PHONE_CODE_OPTION_REQUEST = 'FETCH_COUNTRY_PHONE_CODE_OPTION_REQUEST';
export const FETCH_COUNTRY_PHONE_CODE_OPTION_SUCCESS = 'FETCH_COUNTRY_PHONE_CODE_OPTION_SUCCESS';
export const FETCH_COUNTRY_PHONE_CODE_OPTION_ERROR = 'FETCH_COUNTRY_PHONE_CODE_OPTION_ERROR';

export const fetchCountryOptions = () => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [
        FETCH_COUNTRY_OPTIONS_REQUEST,
        FETCH_COUNTRY_OPTIONS_SUCCESS,
        FETCH_COUNTRY_OPTIONS_ERROR,
      ],
      callAPI: () => ApiClient.get(`product/Country/GetSelectList/`),
      payload: { data: {} },
    }),
  );
};

export const updateCountry = (id, data, formId) => dispatch => {
  return Promise.resolve(dispatch(startSubmit(formId))).then(() =>
    dispatch({
      types: [UPDATE_COUNTRY_REQUEST, UPDATE_COUNTRY_SUCCESS, UPDATE_COUNTRY_ERROR],
      callAPI: () => ApiClient.put(`product/Country/${id}`, data),
      payload: { data: { ...data, id }, formId },
    }),
  );
};

export const fetchCountry = data => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [FETCH_COUNTRY_REQUEST, FETCH_COUNTRY_SUCCESS, FETCH_COUNTRY_ERROR],
      callAPI: () => ApiClient.post('product/Country/Search', data),
      payload: { data },
    }),
  );
};

export const getCountryDetail = id => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [GET_COUNTRY_DETAIL_REQUEST, GET_COUNTRY_DETAIL_SUCCESS, GET_COUNTRY_DETAIL_ERROR],
      callAPI: () => ApiClient.get(`product/Country/GetDetail/${id}`),
      payload: { data: { id } },
    }),
  );
};

export const fetchCountryPhoneCodeOptions = () => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [FETCH_COUNTRY_PHONE_CODE_OPTION_REQUEST, FETCH_COUNTRY_PHONE_CODE_OPTION_SUCCESS, FETCH_COUNTRY_PHONE_CODE_OPTION_ERROR],
      callAPI: () => ApiClient.get('product/Country/GetPhoneCodeSelectList'),
      payload: {},
    }),
  );
};

const initialState = {
  payload: {},
  data: {},
  response: {},
  error: {},
  errorMessage: '',
  successMessage: '',
};

export default function reducer(state = initialState, action) {
  const { payload, response, error, successMessage, errorMessage } = action;
  switch (action.type) {
    case FETCH_COUNTRY_REQUEST:
    case FETCH_COUNTRY_OPTIONS_REQUEST:
    case FETCH_COUNTRY_PHONE_CODE_OPTION_REQUEST:
    case GET_COUNTRY_DETAIL_REQUEST:
    case UPDATE_COUNTRY_REQUEST:
      return {
        ...state,
        payload,
        response: {},
        error: {},
        successMessage: '',
        errorMessage: '',
      };

    case FETCH_COUNTRY_OPTIONS_SUCCESS:
    case FETCH_COUNTRY_PHONE_CODE_OPTION_SUCCESS:
      return {
        ...state,
        data: { ...state.data, options: response.data.data },
        response,
        successMessage,
      };

    case UPDATE_COUNTRY_SUCCESS:
      return {
        ...state,
        response,
        successMessage,
      };

    case FETCH_COUNTRY_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          items: response.data.data.items,
          pages: response.data.data.pageCount,
        },
        response,
        successMessage,
      };

    case GET_COUNTRY_DETAIL_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          item: response.data.data,
        },
        response,
        successMessage,
      };

    case FETCH_COUNTRY_ERROR:
    case FETCH_COUNTRY_OPTIONS_ERROR:
    case UPDATE_COUNTRY_ERROR:
    case GET_COUNTRY_DETAIL_ERROR:
    case FETCH_COUNTRY_PHONE_CODE_OPTION_ERROR:
      return {
        ...state,
        payload,
        error,
        errorMessage,
      };
    default:
      return state;
  }
}
