import ApiClient from '../../../helpers/apiClient';
import { startSubmit } from 'redux-form';

export const FETCH_WALLETS_REQUEST = 'FETCH_WALLETS_REQUEST';
export const FETCH_WALLETS_SUCCESS = 'FETCH_WALLETS_SUCCESS';
export const FETCH_WALLETS_ERROR = 'FETCH_WALLETS_ERROR';

export const GET_WALLET_DETAIL_REQUEST = 'GET_WALLET_DETAIL_REQUEST';
export const GET_WALLET_DETAIL_SUCCESS = 'GET_WALLET_DETAIL_SUCCESS';
export const GET_WALLET_DETAIL_ERROR = 'GET_WALLET_DETAIL_ERROR';

export const GET_CUSTOMER_WALLET_DETAIL_SUCCESS = 'GET_CUSTOMER_WALLET_DETAIL_SUCCESS';
export const CHECK_WALLET_BALANCE_SUCCESS = 'CHECK_WALLET_BALANCE_SUCCESS';

export const FETCH_STRIPE_REQUEST = 'FETCH_STRIPE_REQUEST';
export const FETCH_STRIPE_SUCCESS = 'FETCH_STRIPE_SUCCESS';
export const FETCH_STRIPE_ERROR = 'FETCH_STRIPE_ERROR';

export const RELOAD_REQUEST = 'RELOAD_REQUEST';
export const RELOAD_SUCCESS = 'RELOAD_SUCCESS';
export const RELOAD_ERROR = 'RELOAD_ERROR';

export const GENERATE_SUMMARY_WALLET_PDF_REQUEST = 'GENERATE_SUMMARY_WALLET_PDF_REQUEST';
export const GENERATE_SUMMARY_WALLET_PDF_SUCCESS = 'GENERATE_SUMMARY_WALLET_PDF_SUCCESS';
export const GENERATE_PO_SUMMARY_WALLET_ERROR = 'GENERATE_PO_SUMMARY_WALLET_ERROR';

export const fetchWallets = data => dispatch => {
    return Promise.resolve().then(() =>
        dispatch({
            types: [FETCH_WALLETS_REQUEST, FETCH_WALLETS_SUCCESS, FETCH_WALLETS_ERROR],
            callAPI: () => ApiClient.post('payment/Wallet/Search', data),
            payload: { data },
        }),
    );
};

export const fetchWalletTransaction = data => dispatch => {
    return Promise.resolve().then(() =>
        dispatch({
            types: [FETCH_WALLETS_REQUEST, FETCH_WALLETS_SUCCESS, FETCH_WALLETS_ERROR],
            callAPI: () => ApiClient.post('payment/WalletTransaction/Search', data),
            payload: { data },
        }),
    );
};

export const generateSummaryPdf = (startDate, endDate) => dispatch => {
    return Promise.resolve().then(() =>
      dispatch({
        types: [
          GENERATE_SUMMARY_WALLET_PDF_REQUEST,
          GENERATE_SUMMARY_WALLET_PDF_SUCCESS,
          GENERATE_PO_SUMMARY_WALLET_ERROR
        ],
        callAPI: () => ApiClient.post(`payment/WalletTransaction/GenerateWalletSummaryPDF/${startDate}/${endDate}`),
        payload: { data: { startDate, endDate } },
      }),
    );
  };


export const getWalletDetail = id => dispatch => {
    return Promise.resolve().then(() =>
        dispatch({
            types: [GET_WALLET_DETAIL_REQUEST, GET_WALLET_DETAIL_SUCCESS, GET_WALLET_DETAIL_ERROR],
            callAPI: () => ApiClient.get(`payment/Wallet/${id}`),
            payload: { data: { id } },
        }),
    );
};

export const getCustomerWalletDetail = data => dispatch => {
    return Promise.resolve().then(() =>
        dispatch({
            types: [GET_WALLET_DETAIL_REQUEST, GET_CUSTOMER_WALLET_DETAIL_SUCCESS, GET_WALLET_DETAIL_ERROR],
            callAPI: () => ApiClient.get(`payment/Wallet/GetByCustomer`),
            payload: {},
        }),
    );
};

export const getWalletBalance = id => dispatch => {
    return Promise.resolve().then(() =>
        dispatch({
            types: [GET_WALLET_DETAIL_REQUEST, GET_WALLET_DETAIL_SUCCESS, GET_WALLET_DETAIL_ERROR],
            callAPI: () => ApiClient.get(`payment/Wallet/WalletBalance`),
            payload: {},
        }),
    );
};

export const createStripeWalletPayment =  ({ data, amount })  => dispatch => {
    return Promise.resolve().then(() =>
        dispatch({
            types: [FETCH_STRIPE_REQUEST, FETCH_STRIPE_SUCCESS, FETCH_STRIPE_ERROR],
            callAPI: () => ApiClient.post('payment/Payment/GetStripeReloadUrl', { data: data, amount }),
            payload: { data, amount },
        }),
    );
};

export const createPayDollarWalletPayment = ({ data, amount })  => dispatch => {
    console.log(data);
    console.log(amount);
    return Promise.resolve().then(() =>
        dispatch({
            types: [FETCH_STRIPE_REQUEST, FETCH_STRIPE_SUCCESS, FETCH_STRIPE_ERROR],
            callAPI: () => ApiClient.post('payment/Payment/GetPayDollarUrl', { data: data, amount }),
            payload: { data, amount },
        }),
    );
};

export const reloadWalletByPassword = ({ data }) => dispatch => {
    return Promise.resolve().then(() =>
        dispatch({
            types: [RELOAD_REQUEST, RELOAD_SUCCESS, RELOAD_ERROR],
            callAPI: () => ApiClient.post('identity/account/Reload', data),
            payload: { data },
        }),
    );
};

export const checkWalletBalance = amount => dispatch => {
    console.log(amount);
    return Promise.resolve().then(() =>
        dispatch({
            types: [RELOAD_REQUEST, CHECK_WALLET_BALANCE_SUCCESS, RELOAD_ERROR],
            callAPI: () => ApiClient.get(`payment/Wallet/CheckSufficientWalletBalance/${amount}`),
            payload: { amount },
        }),
    );
};


const initialState = {
    payload: {},
    data: {},
    response: {},
    error: {},
    errorMessage: '',
    successMessage: '',
};

export default function reducer(state = initialState, action) {
    const { payload, response, error, successMessage, errorMessage } = action;
    switch (action.type) {
        case FETCH_WALLETS_REQUEST:
        case GET_WALLET_DETAIL_REQUEST:
        case RELOAD_REQUEST:
            return {
                ...state,
                payload,
                response: {},
                error: {},
                successMessage: '',
                errorMessage: '',
            };

        case FETCH_WALLETS_SUCCESS:
            return {
                ...state,
                data: {
                    ...state.data,
                    items: response.data.data.items,
                    pages: response.data.data.pageCount,
                },
                response,
                successMessage,
            };

        case GET_WALLET_DETAIL_SUCCESS:
        case RELOAD_SUCCESS:
            return {
                ...state,
                data: {
                    ...state.data,
                    item: response.data.data,
                },
                response,
                successMessage,
            };

        case CHECK_WALLET_BALANCE_SUCCESS:
            return {
                ...state,
                data: {
                    ...state.data,
                    walletBalance: response.data.data,
                },
                response,
                successMessage,
            };

        case GET_CUSTOMER_WALLET_DETAIL_SUCCESS:
            return {
                ...state,
                data: {
                    ...state.data,
                    customerWallet: response.data.data,
                },
                response,
                successMessage,
            };


        case GET_WALLET_DETAIL_ERROR:
        case FETCH_WALLETS_ERROR:
        case RELOAD_ERROR:
            return {
                ...state,
                payload,
                error,
                errorMessage,
            };
        default:
            return state;
    }
}