import React from 'react';
import ReactTable from 'react-table-6';
import 'react-table-6/react-table.css';
import { connect } from 'react-redux';
import { fetchFrewiePlan } from '../../../../redux/modules/frewie/FrewiePlan';
import { Button } from 'reactstrap';
import { bindActionCreators } from 'redux';
import Page from 'views/components/Page';

class FrewiePlanList extends React.Component {
  state = {
    loading: false,
  };

  search = (pageIndex, pageSize, filtered, sorted) => {
    this.setState({ loading: true });
    this.props
      .fetchFrewiePlan({
        pageIndex: pageIndex,
        pageSize: pageSize,
        filterColumns: filtered,
        sortColumns: sorted,
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  fetchData = (state, instance) => {
    this.search(state.page + 1, state.pageSize, state.filtered, state.sorted);
  };

  render() {
    const { items, pages } = this.props;
    return (
      <Page title="Frewie Plan List" className="PackagePage" breadcrumbs={[{ name: 'Frewie Plan', active: true }]}>
        <Button
          outline
          color="primary"
          style={{ marginBottom: '1rem' }}
          onClick={() => this.props.history.push('/setup/frewie-plan-new')}
        >
          New
        </Button>

        <ReactTable
          columns={[
            {
              id: 'planName',
              Header: 'Plan Name',
              accessor: 'planName',
            },
            {
              id: 'description',
              Header: 'Description',
              accessor: 'description',
            },
          ]}
          defaultPageSize={10}
          className="-striped -highlight custom-highlight"
          loading={this.state.loading}
          pageSizeOption={[5, 10, 20, 25, 50, 100]}
          manual
          filterable
          data={items}
          pages={pages}
          onFetchData={this.fetchData}
          noDataText={'No Data...'}
          getTdProps={(state, rowInfo, column, instance) => {
            return {
              onClick: () => {
                if (rowInfo && rowInfo.original) {
                  this.props.history.push('/setup/frewie-plan-edit/' + rowInfo.original.id);
                }
              },
            };
          }}
        />
      </Page>
    );
  }
}

const mapStateToProps = ({ frewiePlan }) => ({
  items: frewiePlan.data.items,
  pages: frewiePlan.data.pages,
});

const mapDispatchToProps = dispatch => ({
  fetchFrewiePlan: bindActionCreators(fetchFrewiePlan, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(FrewiePlanList);
