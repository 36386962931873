export const Roles = {
  OperatorAdmin: 'OperatorAdmin',
  DmsAdmin: 'DmsAdmin',
  CompanyAdmin: 'CompanyAdmin',
  CompanyOperator: 'CompanyOperator',
  Agent: 'Agent',
  Customer: 'Customer',
  OperatorStaff: 'OperatorStaff',
  CustomerService: 'CustomerService'
};

export const RoleNames = {
  OperatorStaff: 'Operator Staff',
  OperatorAdmin: 'Operator Admin',
  DmsAdmin: 'Dms Admin',
  CompanyAdmin: 'Company Admin',
  CompanyOperator: 'Company Operator',
  Agent: 'Agent',
  Customer: 'Customer',
  CustomerService: 'Customer Service',
};
