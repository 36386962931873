import React from 'react';
import ValidationMessage from '../Controls/ValidationMessage';
import classnames from 'classnames';

const SelectField = ({
  input,
  options,
  label,
  showLabel,
  required,
  horizontal,
  parentId,
  parentName,
  valueField,
  meta: { touched, error },
  ...props
}) => {
  if (!valueField) {
    valueField = 'value';
  }

  if (options) options.sort((a, b) => a.label.localeCompare(b.label));

  return (
    <div className="col-12 form-group">
      <div className="row">
        {showLabel && horizontal && (
          <div className="col-sm-4 col-form-label">
            <label>
              {label}
              {required && !props.disabled && (
                <span className="mark-required">*</span>
              )}
            </label>
          </div>
        )}
        {showLabel && !horizontal && (
          <label>
            {label}{' '}
            {required && !props.disabled && (
              <span className="mark-required">*</span>
            )}
          </label>
        )}
        {horizontal && (
          <div className="col-sm-8">
            <select
              className={classnames('form-control', {
                'select-error': error && touched,
              })}
              {...input}
              {...props}
            >
              <option value=""></option>
              {options &&
                options.map(
                  (option, idx) =>
                    (!parentId ||
                      !parentName ||
                      option[parentName] === parentId) && (
                      <option key={idx} value={option[valueField]}>
                        {option.label}
                      </option>
                    ),
                )}
            </select>
            <ValidationMessage label={label} touched={touched} error={error} />
          </div>
        )}
        {!horizontal && (
          <select
            className={classnames('form-control', {
              'select-error': error && touched,
            })}
            {...input}
            {...props}
          >
            <option value=""></option>
            {options &&
              options.map(
                (option, idx) =>
                  (!parentId ||
                    !parentName ||
                    option[parentName] === parentId) && (
                    <option key={idx} value={option[valueField]}>
                      {option.label}
                    </option>
                  ),
              )}
          </select>
        )}
        {!horizontal && (
          <ValidationMessage label={label} touched={touched} error={error} />
        )}
      </div>
    </div>
  );
};

export default SelectField;
