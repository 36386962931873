import ApiClient from '../../../helpers/apiClient';
import { startSubmit } from 'redux-form';

export const FETCH_INVITATION_REQUEST = 'FETCH_INVITATION_REQUEST';
export const FETCH_INVITATION_SUCCESS = 'FETCH_INVITATION_SUCCESS';
export const FETCH_INVITATION_ERROR = 'FETCH_INVITATION_ERROR';

export const GET_INVITATION_DETAIL_REQUEST = 'GET_INVITATION_DETAIL_REQUEST';
export const GET_INVITATION_DETAIL_SUCCESS = 'GET_INVITATION_DETAIL_SUCCESS';
export const GET_INVITATION_DETAIL_ERROR = 'GET_INVITATION_DETAIL_ERROR';

export const GET_DISTRIBUTOR_OPTIONS_REQUEST =
  'GET_DISTRIBUTOR_OPTIONS_REQUEST';
export const GET_DISTRIBUTOR_OPTIONS_SUCCESS =
  'GET_DISTRIBUTOR_OPTIONS_SUCCESS';
export const GET_DISTRIBUTOR_OPTIONS_ERROR = 'GET_DISTRIBUTOR_OPTIONS_ERROR';

export const INVITE_CUSTOMER_REQUEST = 'INVITE_GUEST_REQUEST';
export const INVITE_CUSTOMER_SUCCESS = 'INVITE_GUEST_SUCCESS';
export const INVITE_CUSTOMER_ERROR = 'INVITE_GUEST_ERROR';

export const ACCEPT_INVITATION_REQUEST = 'ACCEPT_INVITATION_REQUEST';
export const ACCEPT_INVITATION_SUCCESS = 'ACCEPT_INVITATION_SUCCESS';
export const ACCEPT_INVITATION_ERROR = 'ACCEPT_INVITATION_ERROR';

export const DENY_INVITATION_REQUEST = 'DENY_INVITATION_REQUEST';
export const DENY_INVITATION_SUCCESS = 'DENY_INVITATION_SUCCESS';
export const DENY_INVITATION_ERROR = 'DENY_INVITATION_ERROR';

export const DELETE_INVITATION_REQUEST = 'DELETE_INVITATION_REQUEST';
export const DELETE_INVITATION_SUCCESS = 'DELETE_INVITATION_SUCCESS';
export const DELETE_INVITATION_ERROR = 'DELETE_INVITATION_ERROR';

export const fetchInvitations = data => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [
        FETCH_INVITATION_REQUEST,
        FETCH_INVITATION_SUCCESS,
        FETCH_INVITATION_ERROR,
      ],
      callAPI: () => ApiClient.post('network/Invitations/Search', data),
      payload: { data },
    }),
  );
};
export const getInvitationDetail = id => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [
        GET_INVITATION_DETAIL_REQUEST,
        GET_INVITATION_DETAIL_SUCCESS,
        GET_INVITATION_DETAIL_ERROR,
      ],
      callAPI: () => ApiClient.get(`network/Invitations/GetDetail/${id}`),
      payload: { data: { id } },
    }),
  );
};

export const getDistributorOptions = () => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [
        GET_DISTRIBUTOR_OPTIONS_REQUEST,
        GET_DISTRIBUTOR_OPTIONS_SUCCESS,
        GET_DISTRIBUTOR_OPTIONS_ERROR,
      ],
      callAPI: () => ApiClient.get(`network/Invitations/GetDistributorOptions`),
      payload: {},
    }),
  );
};

export const inviteCustomer = (data, formId) => dispatch => {
  return Promise.resolve(dispatch(startSubmit(formId))).then(() =>
    dispatch({
      types: [
        INVITE_CUSTOMER_REQUEST,
        INVITE_CUSTOMER_SUCCESS,
        INVITE_CUSTOMER_ERROR,
      ],
      callAPI: () => ApiClient.post('network/Invitations/InviteCustomer', data),
      payload: { data, formId },
    }),
  );
};

export const acceptInvitation = (id, formId) => dispatch => {
  return Promise.resolve(dispatch(startSubmit(formId))).then(() =>
    dispatch({
      types: [
        ACCEPT_INVITATION_REQUEST,
        ACCEPT_INVITATION_SUCCESS,
        ACCEPT_INVITATION_ERROR,
      ],
      callAPI: () => ApiClient.put(`network/Invitations/Accept/${id}`),
      payload: { id, formId },
    }),
  );
};

export const denyInvitation = id => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [
        DENY_INVITATION_REQUEST,
        DENY_INVITATION_SUCCESS,
        DENY_INVITATION_ERROR,
      ],
      callAPI: () => ApiClient.put(`network/Invitations/Deny/${id}`),
      payload: { data: { id } },
    }),
  );
};

export const deleteInvitation = id => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [
        DELETE_INVITATION_REQUEST,
        DELETE_INVITATION_SUCCESS,
        DELETE_INVITATION_ERROR,
      ],
      callAPI: () => ApiClient.delete(`network/Invitations/${id}`),
      payload: { data: { id } },
    }),
  );
};

const initialState = {
  payload: {},
  data: {},
  response: {},
  error: {},
  errorMessage: '',
  successMessage: '',
};

export default function reducer(state = initialState, action) {
  const { payload, response, error, successMessage, errorMessage } = action;
  switch (action.type) {
    case FETCH_INVITATION_REQUEST:
    case GET_INVITATION_DETAIL_REQUEST:
    case INVITE_CUSTOMER_REQUEST:
    case ACCEPT_INVITATION_REQUEST:
    case DENY_INVITATION_REQUEST:
    case GET_DISTRIBUTOR_OPTIONS_REQUEST:
    case DELETE_INVITATION_REQUEST:
      return {
        ...state,
        payload,
        response: {},
        error: {},
        successMessage: '',
        errorMessage: '',
      };

    case FETCH_INVITATION_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          items: response.data.data.items,
          pages: response.data.data.pageCount,
        },
        response,
        successMessage,
      };

    case GET_INVITATION_DETAIL_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          item: response.data.data,
        },
        response,
        successMessage,
      };

    case GET_DISTRIBUTOR_OPTIONS_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          distributorOptions: response.data.data,
        },
        response,
        successMessage,
      };

    case INVITE_CUSTOMER_SUCCESS:
    case ACCEPT_INVITATION_SUCCESS:
    case DENY_INVITATION_SUCCESS:
    case DELETE_INVITATION_SUCCESS:
      return {
        ...state,
        response,
        successMessage,
      };

    case FETCH_INVITATION_ERROR:
    case GET_INVITATION_DETAIL_ERROR:
    case INVITE_CUSTOMER_ERROR:
    case ACCEPT_INVITATION_ERROR:
    case DENY_INVITATION_ERROR:
    case GET_DISTRIBUTOR_OPTIONS_ERROR:
    case DELETE_INVITATION_ERROR:
      return {
        ...state,
        payload,
        error,
        errorMessage,
      };
    default:
      return state;
  }
}
