import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { fillError } from '../../../helpers/validator';

const ValidationMessage = ({ label, displayName, touched, error }) => {
  return touched && error ? (
    <span className={classnames('form-error')}>
      {fillError(error, { label, displayName })}
    </span>
  ) : null;
};

ValidationMessage.propTypes = {
  label: PropTypes.string,
  touched: PropTypes.bool,
  error: PropTypes.string,
};

export default ValidationMessage;
