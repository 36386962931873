import ApiClient from '../../../helpers/apiClient';
import { startSubmit } from 'redux-form';

export const FETCH_SERVICE_MOVING_JOURNALS_REQUEST =
  'FETCH_SERVICE_MOVING_JOURNALS_REQUEST';
export const FETCH_SERVICE_MOVING_JOURNALS_SUCCESS =
  'FETCH_SERVICE_MOVING_JOURNALS_SUCCESS';
export const FETCH_SERVICE_MOVING_JOURNALS_ERROR =
  'FETCH_SERVICE_MOVING_JOURNALS_ERROR';

export const GET_SERVICE_MOVING_JOURNAL_DETAIL_REQUEST =
  'GET_SERVICE_MOVING_JOURNAL_DETAIL_REQUEST';
export const GET_SERVICE_MOVING_JOURNAL_DETAIL_SUCCESS =
  'GET_SERVICE_MOVING_JOURNAL_DETAIL_SUCCESS';
export const GET_SERVICE_MOVING_JOURNAL_DETAIL_ERROR =
  'GET_SERVICE_MOVING_JOURNAL_DETAIL_ERROR';

export const CREATE_SERVICE_MOVING_JOURNAL_REQUEST =
  'CREATE_SERVICE_MOVING_JOURNAL_REQUEST';
export const CREATE_SERVICE_MOVING_JOURNAL_SUCCESS =
  'CREATE_SERVICE_MOVING_JOURNAL_SUCCESS';
export const CREATE_SERVICE_MOVING_JOURNAL_ERROR =
  'CREATE_SERVICE_MOVING_JOURNAL_ERROR';

export const UPDATE_SERVICE_MOVING_JOURNAL_REQUEST =
  'UPDATE_SERVICE_MOVING_JOURNAL_REQUEST';
export const UPDATE_SERVICE_MOVING_JOURNAL_SUCCESS =
  'UPDATE_SERVICE_MOVING_JOURNAL_SUCCESS';
export const UPDATE_SERVICE_MOVING_JOURNAL_ERROR =
  'UPDATE_SERVICE_MOVING_JOURNAL_ERROR';

export const DELETE_SERVICE_MOVING_JOURNAL_REQUEST =
  'DELETE_SERVICE_MOVING_JOURNAL_REQUEST';
export const DELETE_SERVICE_MOVING_JOURNAL_SUCCESS =
  'DELETE_SERVICE_MOVING_JOURNAL_SUCCESS';
export const DELETE_SERVICE_MOVING_JOURNAL_ERROR =
  'DELETE_SERVICE_MOVING_JOURNAL_ERROR';

export const fetchServiceMovingJournals = data => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [
        FETCH_SERVICE_MOVING_JOURNALS_REQUEST,
        FETCH_SERVICE_MOVING_JOURNALS_SUCCESS,
        FETCH_SERVICE_MOVING_JOURNALS_ERROR,
      ],
      callAPI: () =>
        ApiClient.post('service/ServiceMovingJournals/Search', data),
      payload: { data },
    }),
  );
};

export const getServiceMovingJournalDetail = id => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [
        GET_SERVICE_MOVING_JOURNAL_DETAIL_REQUEST,
        GET_SERVICE_MOVING_JOURNAL_DETAIL_SUCCESS,
        GET_SERVICE_MOVING_JOURNAL_DETAIL_ERROR,
      ],
      callAPI: () =>
        ApiClient.get(`service/ServiceMovingJournals/GetDetail/${id}`),
      payload: { data: { id } },
    }),
  );
};

export const createServiceMovingJournal = (data, formId) => dispatch => {
  return Promise.resolve(dispatch(startSubmit(formId))).then(() =>
    dispatch({
      types: [
        CREATE_SERVICE_MOVING_JOURNAL_REQUEST,
        CREATE_SERVICE_MOVING_JOURNAL_SUCCESS,
        CREATE_SERVICE_MOVING_JOURNAL_ERROR,
      ],
      callAPI: () => ApiClient.post('service/ServiceMovingJournals', data),
      payload: { data, formId },
    }),
  );
};

export const updateServiceMovingJournal = (id, data, formId) => dispatch => {
  return Promise.resolve(dispatch(startSubmit(formId))).then(() =>
    dispatch({
      types: [
        UPDATE_SERVICE_MOVING_JOURNAL_REQUEST,
        UPDATE_SERVICE_MOVING_JOURNAL_SUCCESS,
        UPDATE_SERVICE_MOVING_JOURNAL_ERROR,
      ],
      callAPI: () => ApiClient.put(`service/ServiceMovingJournals/${id}`, data),
      payload: { data: { ...data, id }, formId },
    }),
  );
};

export const deleteServiceMovingJournal = id => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [
        DELETE_SERVICE_MOVING_JOURNAL_REQUEST,
        DELETE_SERVICE_MOVING_JOURNAL_SUCCESS,
        DELETE_SERVICE_MOVING_JOURNAL_ERROR,
      ],
      callAPI: () => ApiClient.delete(`service/ServiceMovingJournals/${id}`),
      payload: { data: { id } },
    }),
  );
};

const initialState = {
  payload: {},
  data: {},
  response: {},
  error: {},
  errorMessage: '',
  successMessage: '',
};

export default function reducer(state = initialState, action) {
  const { payload, response, error, successMessage, errorMessage } = action;
  switch (action.type) {
    case FETCH_SERVICE_MOVING_JOURNALS_REQUEST:
    case GET_SERVICE_MOVING_JOURNAL_DETAIL_REQUEST:
    case CREATE_SERVICE_MOVING_JOURNAL_REQUEST:
    case UPDATE_SERVICE_MOVING_JOURNAL_REQUEST:
    case DELETE_SERVICE_MOVING_JOURNAL_REQUEST:
      return {
        ...state,
        payload,
        response: {},
        error: {},
        successMessage: '',
        errorMessage: '',
      };

    case FETCH_SERVICE_MOVING_JOURNALS_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          items: response.data.data.items,
          pages: response.data.data.pageCount,
        },
        response,
        successMessage,
      };

    case GET_SERVICE_MOVING_JOURNAL_DETAIL_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          item: response.data.data,
        },
        response,
        successMessage,
      };

    case CREATE_SERVICE_MOVING_JOURNAL_SUCCESS:
    case UPDATE_SERVICE_MOVING_JOURNAL_SUCCESS:
    case DELETE_SERVICE_MOVING_JOURNAL_SUCCESS:
      return {
        ...state,
        response,
        successMessage,
      };

    case FETCH_SERVICE_MOVING_JOURNALS_ERROR:
    case GET_SERVICE_MOVING_JOURNAL_DETAIL_ERROR:
    case CREATE_SERVICE_MOVING_JOURNAL_ERROR:
    case UPDATE_SERVICE_MOVING_JOURNAL_ERROR:
    case DELETE_SERVICE_MOVING_JOURNAL_ERROR:
      return {
        ...state,
        payload,
        error,
        errorMessage,
      };
    default:
      return state;
  }
}
