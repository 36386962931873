import ApiClient from '../../../helpers/apiClient';
import { startSubmit } from 'redux-form';

export const FETCH_HOLIDAYS_REQUEST = 'FETCH_HOLIDAYS_REQUEST';
export const FETCH_HOLIDAYS_SUCCESS = 'FETCH_HOLIDAYS_SUCCESS';
export const FETCH_HOLIDAYS_ERROR = 'FETCH_HOLIDAYS_ERROR';

export const GET_HOLIDAY_DETAIL_REQUEST = 'GET_HOLIDAY_DETAIL_REQUEST';
export const GET_HOLIDAY_DETAIL_SUCCESS = 'GET_HOLIDAY_DETAIL_SUCCESS';
export const GET_HOLIDAY_DETAIL_ERROR = 'GET_HOLIDAY_DETAIL_ERROR';

export const CREATE_HOLIDAY_REQUEST = 'CREATE_HOLIDAY_REQUEST';
export const CREATE_HOLIDAY_SUCCESS = 'CREATE_HOLIDAY_SUCCESS';
export const CREATE_HOLIDAY_ERROR = 'CREATE_HOLIDAY_ERROR';

export const UPDATE_HOLIDAY_REQUEST = 'UPDATE_HOLIDAY_REQUEST';
export const UPDATE_HOLIDAY_SUCCESS = 'UPDATE_HOLIDAY_SUCCESS';
export const UPDATE_HOLIDAY_ERROR = 'UPDATE_HOLIDAY_ERROR';

export const DELETE_HOLIDAY_REQUEST = 'DELETE_HOLIDAY_REQUEST';
export const DELETE_HOLIDAY_SUCCESS = 'DELETE_HOLIDAY_SUCCESS';
export const DELETE_HOLIDAY_ERROR = 'DELETE_HOLIDAY_ERROR';

export const fetchHolidays = data => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [
        FETCH_HOLIDAYS_REQUEST,
        FETCH_HOLIDAYS_SUCCESS,
        FETCH_HOLIDAYS_ERROR,
      ],
      callAPI: () => ApiClient.post('service/Holidays/Search', data),
      payload: { data },
    }),
  );
};

export const getHolidayDetail = id => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [
        GET_HOLIDAY_DETAIL_REQUEST,
        GET_HOLIDAY_DETAIL_SUCCESS,
        GET_HOLIDAY_DETAIL_ERROR,
      ],
      callAPI: () => ApiClient.get(`service/Holidays/GetDetail/${id}`),
      payload: { data: { id } },
    }),
  );
};

export const createHoliday = (data, formId) => dispatch => {
  return Promise.resolve(dispatch(startSubmit(formId))).then(() =>
    dispatch({
      types: [
        CREATE_HOLIDAY_REQUEST,
        CREATE_HOLIDAY_SUCCESS,
        CREATE_HOLIDAY_ERROR,
      ],
      callAPI: () => ApiClient.post('service/Holidays', data),
      payload: { data, formId },
    }),
  );
};

export const updateHoliday = (id, data, formId) => dispatch => {
  return Promise.resolve(dispatch(startSubmit(formId))).then(() =>
    dispatch({
      types: [
        UPDATE_HOLIDAY_REQUEST,
        UPDATE_HOLIDAY_SUCCESS,
        UPDATE_HOLIDAY_ERROR,
      ],
      callAPI: () => ApiClient.put(`service/Holidays/${id}`, data),
      payload: { data: { ...data, id }, formId },
    }),
  );
};

export const deleteHoliday = id => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [
        DELETE_HOLIDAY_REQUEST,
        DELETE_HOLIDAY_SUCCESS,
        DELETE_HOLIDAY_ERROR,
      ],
      callAPI: () => ApiClient.delete(`service/Holidays/${id}`),
      payload: { data: { id } },
    }),
  );
};

const initialState = {
  payload: {},
  data: {},
  response: {},
  error: {},
  errorMessage: '',
  successMessage: '',
};

export default function reducer(state = initialState, action) {
  const { payload, response, error, successMessage, errorMessage } = action;
  switch (action.type) {
    case FETCH_HOLIDAYS_REQUEST:
    case GET_HOLIDAY_DETAIL_REQUEST:
    case CREATE_HOLIDAY_REQUEST:
    case UPDATE_HOLIDAY_REQUEST:
    case DELETE_HOLIDAY_REQUEST:
      return {
        ...state,
        payload,
        response: {},
        error: {},
        successMessage: '',
        errorMessage: '',
      };

    case FETCH_HOLIDAYS_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          items: response.data.data.items,
          pages: response.data.data.pageCount,
        },
        response,
        successMessage,
      };

    case GET_HOLIDAY_DETAIL_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          item: response.data.data,
        },
        response,
        successMessage,
      };

    case CREATE_HOLIDAY_SUCCESS:
    case UPDATE_HOLIDAY_SUCCESS:
    case DELETE_HOLIDAY_SUCCESS:
      return {
        ...state,
        response,
        successMessage,
      };

    case FETCH_HOLIDAYS_ERROR:
    case GET_HOLIDAY_DETAIL_ERROR:
    case CREATE_HOLIDAY_ERROR:
    case UPDATE_HOLIDAY_ERROR:
    case DELETE_HOLIDAY_ERROR:
      return {
        ...state,
        payload,
        error,
        errorMessage,
      };
    default:
      return state;
  }
}
