import { StorageKeys } from 'constants/storageKeys';
import { de } from 'faker/lib/locales';
import { getStoredObject, setStoredItem } from 'helpers/storageHelper';

export const SET_CART_DISTRIBUTOR = 'SET_CART_DISTRIBUTOR';
export const ADD_TO_CART = 'ADD_TO_CART';
export const UPDATE_CART = 'UPDATE_CART';
export const DELETE_FROM_CART = 'DELETE_FROM_CART';
export const CLEAR_CART = 'CLEAR_CART';

export const setCartDistributor = distributor => (dispatch, getState) => {
  const currentDistributor = getState().shoppingCart.data.distributor;
  if (!currentDistributor || currentDistributor.value != distributor.value) {
    dispatch({
      type: SET_CART_DISTRIBUTOR,
      payload: { distributor },
    });
  }

  return Promise.resolve();
};

export const addProductToCart = product => dispatch => {
  dispatch({
    type: ADD_TO_CART,
    payload: { product },
  });

  return Promise.resolve();
};

export const updateProductInCart = (product, qty) => dispatch => {
  const updatedProduct = { ...product, qty };
  dispatch({
    type: UPDATE_CART,
    payload: { product: updatedProduct },
  });

  return Promise.resolve();
};

export const deleteProductFromCart = product => dispatch => {
  dispatch({
    type: DELETE_FROM_CART,
    payload: { product },
  });

  return Promise.resolve();
};

export const clearCart = () => dispatch => {
  dispatch({
    type: CLEAR_CART,
    payload: {},
  });

  return Promise.resolve();
};

const shoppingCart = getStoredObject(StorageKeys.ShoppingCart);
const initialState = {
  payload: {},
  data: shoppingCart
    ? shoppingCart
    : {
        distributor: null,
        order: null,
        products: [],
      },
};

export default function reducer(state = initialState, action) {
  const { payload } = action;
  switch (action.type) {
    case SET_CART_DISTRIBUTOR: {
      const newData = {
        ...state.data,
        distributor: payload.distributor,
        order: { distributor: payload.distributor.value },
        products: [],
      };

      setStoredItem(StorageKeys.ShoppingCart, newData);

      return {
        ...state,
        payload,
        data: newData,
      };
    }

    case ADD_TO_CART: {
      let existedProducts = state.data.products;
      let existedProduct = existedProducts.find(
        p =>
          p.productCode == payload.product.productCode &&
          (!payload.product.variantCode || p.variantCode == payload.product.variantCode),
      );

      if (existedProduct) {
        existedProduct.qty = payload.product.qty;
        const newData = {
          ...state.data,
          products: existedProducts,
        };

        setStoredItem(StorageKeys.ShoppingCart, newData);

        return {
          ...state,
          payload,
          data: newData,
        };
      } else {
        const newData = {
          ...state.data,
          products: [...existedProducts, payload.product],
        };

        setStoredItem(StorageKeys.ShoppingCart, newData);

        return {
          ...state,
          payload,
          data: newData,
        };
      }
    }

    case UPDATE_CART: {
      let existedProducts = state.data.products;
      let existedProduct = existedProducts.find(
        p =>
          p.productCode == payload.product.productCode &&
          (!payload.product.variantCode || p.variantCode == payload.product.variantCode),
      );

      if (existedProduct) {
        existedProduct.qty = payload.product.qty;
        const newData = {
          ...state.data,
          products: [...existedProducts],
        };

        setStoredItem(StorageKeys.ShoppingCart, newData);

        return {
          ...state,
          payload,
          data: newData,
        };
      } else {
        const newData = {
          ...state.data,
          products: [...existedProducts, payload.product],
        };

        setStoredItem(StorageKeys.ShoppingCart, newData);

        return {
          ...state,
          payload,
          data: newData,
        };
      }
    }

    case DELETE_FROM_CART: {
      const newProducts = state.data.products.filter(
        p =>
          !(
            p.productCode == payload.product.productCode &&
            (!payload.product.variantCode || p.variantCode == payload.product.variantCode)
          ),
      );

      const newData = {
        ...state.data,
        products: newProducts,
      };

      setStoredItem(StorageKeys.ShoppingCart, newData);

      return {
        ...state,
        payload,
        data: newData,
      };
    }

    case CLEAR_CART: {
      const newData = {
        distributor: null,
        order: null,
        products: [],
      };

      setStoredItem(StorageKeys.ShoppingCart, newData);

      return {
        ...state,
        payload,
        data: newData,
      };
    }

    default:
      return state;
  }
}
