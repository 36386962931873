import axios from 'axios';
import { StorageKeys } from 'constants/storageKeys';
import { baseUrl } from '../configs/settings';
import {
  getStoredToken,
  getStoredCompany,
  getStoredRefreshToken,
  setStoredItem,
  clearStoredUserData,
} from './storageHelper';

const ApiClient = axios.create({
  baseURL: baseUrl,
});

const refreshToken = async refresh_token => {
  const params = new URLSearchParams();
  params.append('grant_type', 'refresh_token');
  params.append('refresh_token', refresh_token);
  params.append('scope', 'openid profile email offline_access admin network customer product agent service payment');
  params.append('client_id', 'dms-web-app');
  params.append('client_secret', 'dms-web-app');

  const config = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  };
  return await ApiClient.post('token', params, config);
};

ApiClient.interceptors.request.use(
  async function (config) {
    let token = getStoredToken();
    
    if (!token) {
      // If the token is empty, use an API Key
      const apiKey = 'ee104199-6c50-447d-ad5e-904ea9f27907'; 
      config.headers['X-API-KEY'] = apiKey;
    } else {
      // If the token is not empty, use it for Bearer authentication
      config.headers['Authorization'] = `Bearer ${token}`;
    }

    const company = getStoredCompany();
    if (company) {
      config.headers['Company'] = company;
    }

    return config;
  },
  function (error) {
    return Promise.reject(error);
  },
);

ApiClient.interceptors.response.use(
  res => {
    return res;
  },
  async err => {
    console.log(err.response);

    const originalConfig = err.config;

    if (originalConfig.url !== baseUrl + '/token' && err.response) {
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;
        try {
          const refresh_token = getStoredRefreshToken();
          if (refresh_token) {
            const rs = await refreshToken(refresh_token);
            setStoredItem(StorageKeys.AuthData, rs.data);
            
            return ApiClient(originalConfig);
          }
        } catch (_error) {
          return Promise.reject(_error);
        }
      }
    } else if (originalConfig.url == baseUrl + '/token' && err.response) {
      clearStoredUserData();
      window.location.href = '#/sign-in';
    }

    return Promise.reject(err);
  },
);

export default ApiClient;
