import { StorageKeys } from 'constants/storageKeys';
import { getStoredObject } from 'helpers/storageHelper';

export const hasPermission = (resource, action, rolePermissions) => {
  if (!rolePermissions) {
    const profile = getStoredObject(StorageKeys.Profile);

    if (profile && profile.isDmsAdmin) return true;

    if(profile){
      rolePermissions = profile.permissions;
    }
  }

  return rolePermissions && rolePermissions.find(rp => rp.resourceName == resource && rp.permission == action);
};
