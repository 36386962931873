import ApiClient from '../../../helpers/apiClient';
import { startSubmit } from 'redux-form';

export const FETCH_UOM_REQUEST = 'FETCH_UOM_REQUEST';
export const FETCH_UOM_SUCCESS = 'FETCH_UOM_SUCCESS';
export const FETCH_UOM_ERROR = 'FETCH_UOM_ERROR';

export const FETCH_UOM_OPTIONS_REQUEST = 'FETCH_UOM_OPTIONS_REQUEST';
export const FETCH_UOM_OPTIONS_SUCCESS = 'FETCH_UOM_OPTIONS_SUCCESS';
export const FETCH_UOM_OPTIONS_ERROR = 'FETCH_UOM_OPTIONS_ERROR';

export const FETCH_ENTITY_UOM_OPTIONS_REQUEST = 'FETCH_ENTITY_UOM_OPTIONS_REQUEST';
export const FETCH_ENTITY_UOM_OPTIONS_SUCCESS = 'FETCH_ENTITY_UOM_OPTIONS_SUCCESS';
export const FETCH_ENTITY_UOM_OPTIONS_ERROR = 'FETCH_ENTITY_UOM_OPTIONS_ERROR';

export const FETCH_RELATE_UOM_OPTIONS_REQUEST = 'FETCH_RELATE_UOM_OPTIONS_REQUEST';
export const FETCH_RELATE_UOM_OPTIONS_SUCCESS = 'FETCH_RELATE_UOM_OPTIONS_SUCCESS';
export const FETCH_RELATE_UOM_OPTIONS_ERROR = 'FETCH_RELATE_UOM_OPTIONS_ERROR';

export const GET_UOM_DETAIL_REQUEST = 'GET_UOM_DETAIL_REQUEST';
export const GET_UOM_DETAIL_SUCCESS = 'GET_UOM_DETAIL_SUCCESS';
export const GET_UOM_DETAIL_ERROR = 'GET_UOM_DETAIL_ERROR';

export const CREATE_UOM_REQUEST = 'CREATE_UOM_REQUEST';
export const CREATE_UOM_SUCCESS = 'CREATE_UOM_SUCCESS';
export const CREATE_UOM_ERROR = 'CREATE_UOM_ERROR';

export const UPDATE_UOM_REQUEST = 'UPDATE_UOM_REQUEST';
export const UPDATE_UOM_SUCCESS = 'UPDATE_UOM_SUCCESS';
export const UPDATE_UOM_ERROR = 'UPDATE_UOM_ERROR';

export const DELETE_UOM_REQUEST = 'DELETE_UOM_REQUEST';
export const DELETE_UOM_SUCCESS = 'DELETE_UOM_SUCCESS';
export const DELETE_UOM_ERROR = 'DELETE_UOM_ERROR';

export const GET_CONVERSIONS_TO_HOUR_REQUEST = 'GET_CONVERSIONS_TO_HOUR_REQUEST';
export const GET_CONVERSIONS_TO_HOUR_SUCCESS = 'GET_CONVERSIONS_TO_HOUR_SUCCESS';
export const GET_CONVERSIONS_TO_HOUR_ERROR = 'GET_CONVERSIONS_TO_HOUR_ERROR';

export const fetchUom = data => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [FETCH_UOM_REQUEST, FETCH_UOM_SUCCESS, FETCH_UOM_ERROR],
      callAPI: () => ApiClient.post('network/Uom/Search', data),
      payload: { data },
    }),
  );
};

export const fetchUomOptions = () => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [FETCH_UOM_OPTIONS_REQUEST, FETCH_UOM_OPTIONS_SUCCESS, FETCH_UOM_OPTIONS_ERROR],
      callAPI: () => ApiClient.get('network/Uom/GetSelectList'),
      payload: {},
    }),
  );
};

export const fetchEntityUomOptions = entity => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [FETCH_ENTITY_UOM_OPTIONS_REQUEST, FETCH_ENTITY_UOM_OPTIONS_SUCCESS, FETCH_ENTITY_UOM_OPTIONS_ERROR],
      callAPI: () => ApiClient.get(`network/Uom/GetOptionsByEntity/${entity}`),
      payload: { data: { entity } },
    }),
  );
};

export const fetchRelateUomOptions = id => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [FETCH_RELATE_UOM_OPTIONS_REQUEST, FETCH_RELATE_UOM_OPTIONS_SUCCESS, FETCH_RELATE_UOM_OPTIONS_ERROR],
      callAPI: () => ApiClient.get('network/Uom/GetRelateUomOptions/' + id),
      payload: { data: { id } },
    }),
  );
};

export const getUomDetail = id => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [GET_UOM_DETAIL_REQUEST, GET_UOM_DETAIL_SUCCESS, GET_UOM_DETAIL_ERROR],
      callAPI: () => ApiClient.get(`network/Uom/GetDetail/${id}`),
      payload: { data: { id } },
    }),
  );
};

export const createUom = (data, formId) => dispatch => {
  return Promise.resolve(dispatch(startSubmit(formId))).then(() =>
    dispatch({
      types: [CREATE_UOM_REQUEST, CREATE_UOM_SUCCESS, CREATE_UOM_ERROR],
      callAPI: () => ApiClient.post('network/Uom', data),
      payload: { data, formId },
    }),
  );
};

export const updateUom = (id, data, formId) => dispatch => {
  return Promise.resolve(dispatch(startSubmit(formId))).then(() =>
    dispatch({
      types: [UPDATE_UOM_REQUEST, UPDATE_UOM_SUCCESS, UPDATE_UOM_ERROR],
      callAPI: () => ApiClient.put(`network/Uom/${id}`, data),
      payload: { data: { ...data, id }, formId },
    }),
  );
};

export const deleteUom = id => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [DELETE_UOM_REQUEST, DELETE_UOM_SUCCESS, DELETE_UOM_ERROR],
      callAPI: () => ApiClient.delete(`network/Uom/${id}`),
      payload: { data: { id } },
    }),
  );
};

export const getConversionToHour = () => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [GET_CONVERSIONS_TO_HOUR_REQUEST, GET_CONVERSIONS_TO_HOUR_SUCCESS, GET_CONVERSIONS_TO_HOUR_ERROR],
      callAPI: () => ApiClient.get(`network/Uom/GetConvertionsToHour`),
      payload: {},
    }),
  );
};

const initialState = {
  payload: {},
  data: {},
  response: {},
  error: {},
  errorMessage: '',
  successMessage: '',
};

export default function reducer(state = initialState, action) {
  const { payload, response, error, successMessage, errorMessage } = action;
  switch (action.type) {
    case FETCH_UOM_REQUEST:
    case FETCH_UOM_OPTIONS_REQUEST:
    case FETCH_ENTITY_UOM_OPTIONS_REQUEST:
    case GET_UOM_DETAIL_REQUEST:
    case CREATE_UOM_REQUEST:
    case UPDATE_UOM_REQUEST:
    case DELETE_UOM_REQUEST:
    case GET_CONVERSIONS_TO_HOUR_REQUEST:
    case FETCH_RELATE_UOM_OPTIONS_REQUEST:
      return {
        ...state,
        payload,
        response: {},
        error: {},
        successMessage: '',
        errorMessage: '',
      };

    case FETCH_UOM_OPTIONS_SUCCESS:
      return {
        ...state,
        data: { ...state.data, uomOptions: response.data.data },
        response,
        successMessage,
      };

    case FETCH_ENTITY_UOM_OPTIONS_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          entityUomOptions: {
            ...state.data.entityUomOptions,
            [payload.data.entity + 'UomOptions']: response.data.data,
          },
        },
        response,
        successMessage,
      };

    case FETCH_RELATE_UOM_OPTIONS_SUCCESS:
      return {
        ...state,
        data: { ...state.data, relatedUomOptions: response.data.data },
        response,
        successMessage,
      };

    case FETCH_UOM_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          items: response.data.data.items,
          pages: response.data.data.pageCount,
        },
        response,
        successMessage,
      };

    case GET_UOM_DETAIL_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          item: response.data.data,
        },
        response,
        successMessage,
      };
    case GET_CONVERSIONS_TO_HOUR_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          toHourConversions: response.data.data,
        },
        response,
        successMessage,
      };

    case CREATE_UOM_SUCCESS:
    case UPDATE_UOM_SUCCESS:
    case DELETE_UOM_SUCCESS:
      return {
        ...state,
        response,
        successMessage,
      };

    case FETCH_UOM_ERROR:
    case FETCH_UOM_OPTIONS_ERROR:
    case FETCH_ENTITY_UOM_OPTIONS_ERROR:
    case GET_UOM_DETAIL_ERROR:
    case CREATE_UOM_ERROR:
    case UPDATE_UOM_ERROR:
    case DELETE_UOM_ERROR:
    case GET_CONVERSIONS_TO_HOUR_ERROR:
    case FETCH_RELATE_UOM_OPTIONS_ERROR:
      return {
        ...state,
        payload,
        error,
        errorMessage,
      };
    default:
      return state;
  }
}
