import { Header } from 'views/components/Layout';
import React from 'react';
import NotificationSystem from 'react-notification-system';
import { NOTIFICATION_SYSTEM_STYLE } from 'utils/constants';
import ModalRoot from '../Modals/ModalRoot';
import { connect } from 'react-redux';
import { Switch } from 'react-router-dom';
import PrivateRoute from '../Routes/PrivateRoute';
import PageSpinner from '../PageSpinner';
import { privateRoutes, privateTabRoutes } from 'views/routes';

class MainLayout extends React.Component {
  render() {
    return (
      <main className="cr-app bg-app flex-column">
        <Header />

        <div style={{ marginTop: 100 }}>
          <Switch>
            <React.Suspense fallback={<PageSpinner />}>
              {privateTabRoutes.map((route, idx) => {
                return <PrivateRoute key={idx} exact={route.exact} path={route.path} component={route.component} />;
              })}
              {privateRoutes.map((route, idx) => {
                return <PrivateRoute key={idx} exact={route.exact} path={route.path} component={route.component} />;
              })}
            </React.Suspense>
          </Switch>

          <ModalRoot />
          <NotificationSystem
            dismissible={false}
            ref={notificationSystem => (this.notificationSystem = notificationSystem)}
            style={NOTIFICATION_SYSTEM_STYLE}
          />
        </div>
      </main>
    );
  }
}

const mapStateToProps = state => ({});
const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(MainLayout);
