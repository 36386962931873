import ApiClient from '../../../helpers/apiClient';
import { startSubmit } from 'redux-form';

export const FETCH_PRODUCT_CATEGORY_REQUEST = 'FETCH_PRODUCT_CATEGORY_REQUEST';
export const FETCH_PRODUCT_CATEGORY_SUCCESS = 'FETCH_PRODUCT_CATEGORY_SUCCESS';
export const FETCH_PRODUCT_CATEGORY_ERROR = 'FETCH_PRODUCT_CATEGORY_ERROR';

export const FETCH_PRODUCT_CATEGORY_OPTIONS_REQUEST =
  'FETCH_PRODUCT_CATEGORY_OPTIONS_REQUEST';
export const FETCH_PRODUCT_CATEGORY_OPTIONS_SUCCESS =
  'FETCH_PRODUCT_CATEGORY_OPTIONS_SUCCESS';
export const FETCH_PRODUCT_CATEGORY_OPTIONS_ERROR =
  'FETCH_PRODUCT_CATEGORY_OPTIONS_ERROR';

export const GET_PRODUCT_CATEGORY_DETAIL_REQUEST =
  'GET_PRODUCT_CATEGORY_DETAIL_REQUEST';
export const GET_PRODUCT_CATEGORY_DETAIL_SUCCESS =
  'GET_PRODUCT_CATEGORY_DETAIL_SUCCESS';
export const GET_PRODUCT_CATEGORY_DETAIL_ERROR =
  'GET_PRODUCT_CATEGORY_DETAIL_ERROR';

export const CREATE_PRODUCT_CATEGORY_REQUEST =
  'CREATE_PRODUCT_CATEGORY_REQUEST';
export const CREATE_PRODUCT_CATEGORY_SUCCESS =
  'CREATE_PRODUCT_CATEGORY_SUCCESS';
export const CREATE_PRODUCT_CATEGORY_ERROR = 'CREATE_PRODUCT_CATEGORY_ERROR';

export const UPDATE_PRODUCT_CATEGORY_REQUEST =
  'UPDATE_PRODUCT_CATEGORY_REQUEST';
export const UPDATE_PRODUCT_CATEGORY_SUCCESS =
  'UPDATE_PRODUCT_CATEGORY_SUCCESS';
export const UPDATE_PRODUCT_CATEGORY_ERROR = 'UPDATE_PRODUCT_CATEGORY_ERROR';

export const DELETE_PRODUCT_CATEGORY_REQUEST =
  'DELETE_PRODUCT_CATEGORY_REQUEST';
export const DELETE_PRODUCT_CATEGORY_SUCCESS =
  'DELETE_PRODUCT_CATEGORY_SUCCESS';
export const DELETE_PRODUCT_CATEGORY_ERROR = 'DELETE_PRODUCT_CATEGORY_ERROR';

export const fetchProductCategory = data => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [
        FETCH_PRODUCT_CATEGORY_REQUEST,
        FETCH_PRODUCT_CATEGORY_SUCCESS,
        FETCH_PRODUCT_CATEGORY_ERROR,
      ],
      callAPI: () => ApiClient.post('product/ProductCategory/Search', data),
      payload: { data },
    }),
  );
};

export const fetchProductCategoryOptions = () => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [
        FETCH_PRODUCT_CATEGORY_OPTIONS_REQUEST,
        FETCH_PRODUCT_CATEGORY_OPTIONS_SUCCESS,
        FETCH_PRODUCT_CATEGORY_OPTIONS_ERROR,
      ],
      callAPI: () => ApiClient.get('product/ProductCategory/GetSelectList'),
      payload: {},
    }),
  );
};

export const getProductCategoryDetail = id => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [
        GET_PRODUCT_CATEGORY_DETAIL_REQUEST,
        GET_PRODUCT_CATEGORY_DETAIL_SUCCESS,
        GET_PRODUCT_CATEGORY_DETAIL_ERROR,
      ],
      callAPI: () => ApiClient.get(`product/ProductCategory/${id}`),
      payload: { data: { id } },
    }),
  );
};

export const createProductCategory = (data, formId) => dispatch => {
  return Promise.resolve(dispatch(startSubmit(formId))).then(() =>
    dispatch({
      types: [
        CREATE_PRODUCT_CATEGORY_REQUEST,
        CREATE_PRODUCT_CATEGORY_SUCCESS,
        CREATE_PRODUCT_CATEGORY_ERROR,
      ],
      callAPI: () => ApiClient.post('product/ProductCategory', data),
      payload: { data, formId },
    }),
  );
};

export const updateProductCategory = (id, data, formId) => dispatch => {
  return Promise.resolve(dispatch(startSubmit(formId))).then(() =>
    dispatch({
      types: [
        UPDATE_PRODUCT_CATEGORY_REQUEST,
        UPDATE_PRODUCT_CATEGORY_SUCCESS,
        UPDATE_PRODUCT_CATEGORY_ERROR,
      ],
      callAPI: () => ApiClient.put(`product/ProductCategory/${id}`, data),
      payload: { data: { ...data, id }, formId },
    }),
  );
};

export const deleteProductCategory = (id, formId) => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [
        DELETE_PRODUCT_CATEGORY_REQUEST,
        DELETE_PRODUCT_CATEGORY_SUCCESS,
        DELETE_PRODUCT_CATEGORY_ERROR,
      ],
      callAPI: () => ApiClient.delete(`product/ProductCategory/${id}`),
      payload: { data: { id }, formId },
    }),
  );
};

const initialState = {
  payload: {},
  data: {},
  response: {},
  error: {},
  errorMessage: '',
  successMessage: '',
};

export default function reducer(state = initialState, action) {
  const { payload, response, error, successMessage, errorMessage } = action;
  switch (action.type) {
    case FETCH_PRODUCT_CATEGORY_REQUEST:
    case FETCH_PRODUCT_CATEGORY_OPTIONS_REQUEST:
    case GET_PRODUCT_CATEGORY_DETAIL_REQUEST:
    case CREATE_PRODUCT_CATEGORY_REQUEST:
    case UPDATE_PRODUCT_CATEGORY_REQUEST:
    case DELETE_PRODUCT_CATEGORY_REQUEST:
      return {
        ...state,
        payload,
        response: {},
        error: {},
        successMessage: '',
        errorMessage: '',
      };

    case FETCH_PRODUCT_CATEGORY_OPTIONS_SUCCESS:
      return {
        ...state,
        data: { ...state.data, options: response.data.data },
        response,
        successMessage,
      };

    case FETCH_PRODUCT_CATEGORY_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          items: response.data.data.items,
          pages: response.data.data.pageCount,
        },
        response,
        successMessage,
      };

    case GET_PRODUCT_CATEGORY_DETAIL_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          item: response.data.data,
        },
        response,
        successMessage,
      };

    case CREATE_PRODUCT_CATEGORY_SUCCESS:
    case UPDATE_PRODUCT_CATEGORY_SUCCESS:
    case DELETE_PRODUCT_CATEGORY_SUCCESS:
      return {
        ...state,
        response,
        successMessage,
      };

    case FETCH_PRODUCT_CATEGORY_ERROR:
    case FETCH_PRODUCT_CATEGORY_OPTIONS_ERROR:
    case GET_PRODUCT_CATEGORY_DETAIL_ERROR:
    case CREATE_PRODUCT_CATEGORY_ERROR:
    case UPDATE_PRODUCT_CATEGORY_ERROR:
    case DELETE_PRODUCT_CATEGORY_ERROR:
      return {
        ...state,
        payload,
        error,
        errorMessage,
      };
    default:
      return state;
  }
}
