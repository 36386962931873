import ApiClient from '../../../helpers/apiClient';
import { startSubmit } from 'redux-form';

export const FETCH_MEMBERSHIP_OPTIONS_REQUEST =
  'FETCH_MEMBERSHIP_OPTIONS_REQUEST';
export const FETCH_MEMBERSHIP_OPTIONS_SUCCESS =
  'FETCH_MEMBERSHIP_OPTIONS_SUCCESS';
export const FETCH_MEMBERSHIP_OPTIONS_ERROR = 'FETCH_MEMBERSHIP_OPTIONS_ERROR';

export const FETCH_MEMBERSHIPS_REQUEST = 'FETCH_MEMBERSHIPS_REQUEST';
export const FETCH_MEMBERSHIPS_SUCCESS = 'FETCH_MEMBERSHIPS_SUCCESS';
export const FETCH_MEMBERSHIPS_ERROR = 'FETCH_MEMBERSHIPS_ERROR';

export const GET_MEMBERSHIP_DETAIL_REQUEST = 'GET_MEMBERSHIP_DETAIL_REQUEST';
export const GET_MEMBERSHIP_DETAIL_SUCCESS = 'GET_MEMBERSHIP_DETAIL_SUCCESS';
export const GET_MEMBERSHIP_DETAIL_ERROR = 'GET_MEMBERSHIP_DETAIL_ERROR';

export const CREATE_MEMBERSHIP_REQUEST = 'CREATE_MEMBERSHIP_REQUEST';
export const CREATE_MEMBERSHIP_SUCCESS = 'CREATE_MEMBERSHIP_SUCCESS';
export const CREATE_MEMBERSHIP_ERROR = 'CREATE_MEMBERSHIP_ERROR';

export const UPDATE_MEMBERSHIP_REQUEST = 'UPDATE_MEMBERSHIP_REQUEST';
export const UPDATE_MEMBERSHIP_SUCCESS = 'UPDATE_MEMBERSHIP_SUCCESS';
export const UPDATE_MEMBERSHIP_ERROR = 'UPDATE_MEMBERSHIP_ERROR';

export const DELETE_MEMBERSHIP_REQUEST = 'DELETE_MEMBERSHIP_REQUEST';
export const DELETE_MEMBERSHIP_SUCCESS = 'DELETE_MEMBERSHIP_SUCCESS';
export const DELETE_MEMBERSHIP_ERROR = 'DELETE_MEMBERSHIP_ERROR';

export const fetchMemberships = (data) => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [
        FETCH_MEMBERSHIPS_REQUEST,
        FETCH_MEMBERSHIPS_SUCCESS,
        FETCH_MEMBERSHIPS_ERROR,
      ],
      callAPI: () => ApiClient.post('customer/Memberships/Search', data),
      payload: { data },
    }),
  );
};

export const fetchMembershipOptions = () => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [
        FETCH_MEMBERSHIP_OPTIONS_REQUEST,
        FETCH_MEMBERSHIP_OPTIONS_SUCCESS,
        FETCH_MEMBERSHIP_OPTIONS_ERROR,
      ],
      callAPI: () => ApiClient.get('customer/Memberships/GetSelectList'),
      payload: {},
    }),
  );
};

export const getMembershipDetail = id => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [
        GET_MEMBERSHIP_DETAIL_REQUEST,
        GET_MEMBERSHIP_DETAIL_SUCCESS,
        GET_MEMBERSHIP_DETAIL_ERROR,
      ],
      callAPI: () => ApiClient.get(`customer/Memberships/GetDetail/${id}`),
      payload: { data: { id } },
    }),
  );
};

export const createMembership = (data, formId) => dispatch => {
  return Promise.resolve(dispatch(startSubmit(formId))).then(() =>
    dispatch({
      types: [
        CREATE_MEMBERSHIP_REQUEST,
        CREATE_MEMBERSHIP_SUCCESS,
        CREATE_MEMBERSHIP_ERROR,
      ],
      callAPI: () => ApiClient.post('customer/Memberships', data),
      payload: { data, formId },
    }),
  );
};

export const updateMembership = (id, data, formId) => dispatch => {
  return Promise.resolve(dispatch(startSubmit(formId))).then(() =>
    dispatch({
      types: [
        UPDATE_MEMBERSHIP_REQUEST,
        UPDATE_MEMBERSHIP_SUCCESS,
        UPDATE_MEMBERSHIP_ERROR,
      ],
      callAPI: () => ApiClient.put(`customer/Memberships/${id}`, data),
      payload: { data: { ...data, id }, formId },
    }),
  );
};

export const deleteMembership = id => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [
        DELETE_MEMBERSHIP_REQUEST,
        DELETE_MEMBERSHIP_SUCCESS,
        DELETE_MEMBERSHIP_ERROR,
      ],
      callAPI: () => ApiClient.delete(`customer/Memberships/${id}`),
      payload: { data: { id } },
    }),
  );
};

const initialState = {
  payload: {},
  data: {},
  response: {},
  error: {},
  errorMessage: '',
  successMessage: '',
};

export default function reducer(state = initialState, action) {
  const { payload, response, error, successMessage, errorMessage } = action;
  switch (action.type) {
    case FETCH_MEMBERSHIP_OPTIONS_REQUEST:
    case FETCH_MEMBERSHIPS_REQUEST:
    case GET_MEMBERSHIP_DETAIL_REQUEST:
    case CREATE_MEMBERSHIP_REQUEST:
    case UPDATE_MEMBERSHIP_REQUEST:
    case DELETE_MEMBERSHIP_REQUEST:
      return {
        ...state,
        payload,
        response: {},
        error: {},
        successMessage: '',
        errorMessage: '',
      };

    case FETCH_MEMBERSHIP_OPTIONS_SUCCESS:
      return {
        ...state,
        data: { ...state.data, membershipOptions: response.data.data },
        response,
        successMessage,
      };

    case FETCH_MEMBERSHIPS_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          items: response.data.data.items,
          pages: response.data.data.pageCount,
        },
        response,
        successMessage,
      };
    case GET_MEMBERSHIP_DETAIL_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          item: response.data.data,
        },
        response,
        successMessage,
      };

    case CREATE_MEMBERSHIP_SUCCESS:
    case UPDATE_MEMBERSHIP_SUCCESS:
    case DELETE_MEMBERSHIP_SUCCESS:
      return {
        ...state,
        response,
        successMessage,
      };

    case FETCH_MEMBERSHIP_OPTIONS_ERROR:
    case FETCH_MEMBERSHIPS_ERROR:
    case GET_MEMBERSHIP_DETAIL_ERROR:
    case CREATE_MEMBERSHIP_ERROR:
    case UPDATE_MEMBERSHIP_ERROR:
    case DELETE_MEMBERSHIP_ERROR:
      return {
        ...state,
        payload,
        error,
        errorMessage,
      };
    default:
      return state;
  }
}
