import React from 'react';
import { Spinner } from 'reactstrap';
import PropTypes from 'prop-types';

const Button = ({ children, loading, type, className, size, ...props }) => {
  return (
    <button
      {...props}
      type={type ?? 'button'}
      className={className ?? 'btn btn-secondary bg-gradient-theme-left border-0 ml-1'}
      disabled={loading}
    >
      {loading && <Spinner size={size ?? 'lg'} />}
      {!loading && children}
    </button>
  );
};

Button.prototype = {
  children: PropTypes.node.isRequired,
};

export default Button;
