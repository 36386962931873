import React, { useState } from 'react';
import cx from 'classnames';
import { Col, Row } from 'reactstrap';
import { getFullUrl } from 'helpers/imageHelpers';
import { useDispatch, useSelector } from 'react-redux';
import { calculatePrice, checkProductVariantStock } from 'redux/modules/product/Product';
import { formatAmount } from 'helpers/numberHelper';

const VariantCartItem = ({ item, onChangeQty, uomOptions, currency }) => {
  const dispatch = useDispatch();
  const productsInCart = useSelector(state => state.shoppingCart.data.products);
  const productInCart = productsInCart.find(
    p => p.productCode == item.productCode && p.variantValue == item.variantValue,
  );
  const qtyInCart = productInCart?.qty ?? 0;

  const [qty, setQty] = useState(qtyInCart);
  const [amount, setAmount] = useState(qtyInCart * item.unitPrice);
  const [loading, setLoading] = useState(false);

  let uomCode = 'RM';
  if (item.uomId && uomOptions) {
    uomCode = uomOptions.find(o => o.value == item.uomId)?.label;
  }

  const handleChangeQty = value => {
    let quantity = 0;
    if (value) {
      quantity = parseInt(value);
    }


    dispatch(checkProductVariantStock(item.productId, item.variantId, quantity)
    ).then(resp => {
      const status = resp.response.data.data;

      if (status.status === 'in stock') {
        setQty(value);

        if (item.discounts && item.discounts.length > 0 && quantity) {
          setLoading(true);
          dispatch(calculatePrice(item.productId, { quantity: quantity, variantId: item.variantId }))
            .then(resp => {
              setAmount(quantity * resp.response.data.data);
              onChangeQty && onChangeQty(item.variantCode, value, resp.response.data.data);
            })
            .finally(() => {
              setLoading(false);
            });
        } else {
          setAmount(quantity * item.unitPrice);
          onChangeQty && onChangeQty(item.variantCode, value, item.unitPrice);
        }
      }
      else {
        alert(`Insufficient Stock\nAvailable stock: ${status.availableQty}`);
      }
    });

  };

  return (
    <Row className="d-flex" style={{ margin: `32px 0px` }}>
      <Col xs="auto">
        <img className="react-autosuggest__image m-0" src={getFullUrl(item.image)} />
      </Col>

      <Col>
        <div className="muli-bold mb-1" style={{ fontSize: 19 }}>
          {item.variantName}
        </div>
        {item.days && (
          <div style={{ fontSize: 17 }}>
            <b>Validity:</b> {item.days} Day(s)
          </div>
        )}
        {item.data && (
          <div style={{ fontSize: 17 }}>
            <b>Data:</b> {item.data} GB
          </div>
        )}
        <div style={{ fontSize: 17 }}>
          <b>Price:</b> {currency} {formatAmount(item.unitPrice)}
        </div>
      </Col>

      <Col xs="auto">
        <Row>
          <div className="order-add-item">
            <span>{currency}</span>
            <span className="poppins" style={{ fontWeight: 'bold', fontSize: 16 }}>
              {qty ? formatAmount(amount) : '0.00'}
            </span>
          </div>

          <div className="order-add-item">
            <input
              type="number"
              min={1}
              className="form-control"
              value={qty}
              onChange={e => handleChangeQty(e.target.value)}
            />
            <span className="ml-2">{uomCode}</span>
          </div>
        </Row>
      </Col>
    </Row>
  );
};

VariantCartItem.defaultProps = {
  item: {},
  isEditable: false,
  isShowAction: true,
};

export default VariantCartItem;
