export const PATH_DASHBOARD = '/';
export const PATH_ADMIN = '/admin';
export const PATH_SETUP = '/setup';
export const PATH_ORDER = '/order';
export const PATH_ORDER_NEW = '/order-new';

export const PATH_PAYMENT_TERM = '/payment-term';
export const PATH_PAYMENT_TERM_DETAIL = '/payment-term-detail';

export const PATH_PRODUCT_LIST = '/product-list';
export const PATH_PRODUCT_DETAIL = '/product-detail';
