import React, { useState } from 'react';
import { Spinner } from 'reactstrap';
import { Col, Row } from 'reactstrap';
import { getFullUrl } from 'helpers/imageHelpers';
import { useDispatch, useSelector } from 'react-redux';
import { calculatePrice } from 'redux/modules/product/Product';
import { formatAmount } from 'helpers/numberHelper';

const ProductCartItem = ({ item, onChangeQty, uomOptions, currency }) => {
  const dispatch = useDispatch();

  const productsInCart = useSelector(state => state.shoppingCart.data.products);
  const productInCart = productsInCart.find(p => p.productCode == item.productCode);
  const qtyInCart = productInCart?.qty ?? 0;

  const [qty, setQty] = useState(qtyInCart);
  const [amount, setAmount] = useState(qtyInCart * item.unitPrice);
  const [loading, setLoading] = useState(false);

  const handleChangeQty = value => {
    let quantity = 0;
    if (value) {
      quantity = parseInt(value);
    }

    setQty(value);

    if (item.discounts && item.discounts.length > 0 && quantity) {
      setLoading(true);
      dispatch(calculatePrice(item.productId, { quantity: quantity }))
        .then(resp => {
          setAmount(quantity * resp.response.data.data);
          onChangeQty && onChangeQty(value, resp.response.data.data);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setAmount(quantity * item.unitPrice);
      onChangeQty && onChangeQty(value, item.unitPrice);
    }
  };

  return (
    <Row className="d-flex" style={{ margin: `32px 0px` }}>
      <Col xs="auto">
        <img className="react-autosuggest__image m-0" src={getFullUrl(item.image)} />
      </Col>

      <Col>
        <div className="muli-bold mb-1" style={{ fontSize: 19 }}>
          {item.productName}
        </div>
        <div style={{ fontSize: 17 }}>
          <b>Code:</b> {item.productCode}
        </div>
        <div style={{ fontSize: 17 }}>
          <b>Price:</b> {currency} {formatAmount(item.unitPrice)}
        </div>
      </Col>

      <Col xs="auto">
        <Row>
          {!loading && (
            <div className="order-add-item">
              <span>{currency}</span>
              <span className="poppins" style={{ fontWeight: 'bold', fontSize: 16 }}>
                {qty ? formatAmount(amount) : '0.00'}
              </span>
            </div>
          )}
          {loading && <Spinner animation="grow" variant="info" style={{ marginTop: 20 }} />}

          <div className="order-add-item">
            <input
              type="number"
              min={1}
              className="form-control"
              value={qty}
              onChange={e => handleChangeQty(e.target.value)}
            />
            <span className="ml-2">
              {uomOptions && item.uomId && uomOptions.find(o => o.value == item.uomId)?.label}
            </span>
          </div>
        </Row>
      </Col>
    </Row>
  );
};

ProductCartItem.defaultProps = {
  item: {},
  isEditable: false,
  isShowAction: true,
};

export default ProductCartItem;
