import { StorageKeys } from '../constants/storageKeys';
import { setCookie, getCookie, deleteCookie } from '../helpers/cookieHelper';

export function getStoredToken() {
  const authData = getStoredObject(StorageKeys.AuthData);
  if (authData) {
    return authData.access_token;
  }
}

export function getStoredRefreshToken() {
  const authData = getStoredObject(StorageKeys.AuthData);
  if (authData) {
    return authData.refresh_token;
  }
}

export function getStoredCompany() {
  return getStoredValue(StorageKeys.WorkingCompany);
}

export function getStoredCompanyInfo() {
  return getStoredObject(StorageKeys.WorkingCompanyInfo);
}

export function getFrewieCarts() {
  return getStoredObject(StorageKeys.FrewieShoppingCart);
}

export function getStoredCustomerProfileInfo() {
  return getStoredObject(StorageKeys.CustomerInfo);
}

// export function getStoredCompanyOptionInfo() {
//   return getStoredObject(StorageKeys.CompanyOptionInfo);
// }

export function getStoredCompanyName() {
  return getStoredValue(StorageKeys.WorkingCompanyName);
}

export function getStoredValue(key) {
  const isRemember = getCookie(StorageKeys.IsRemember);
  if (isRemember == 'true') {
    if (key == StorageKeys.AuthData) {
      return getCookie(key);
    } else {
      return localStorage.getItem(key);
    }
  } else {
    return sessionStorage.getItem(key);
  }
}

export function getStoredObject(key) {
  const isRemember = getCookie(StorageKeys.IsRemember);
  let objString = '';

  if (isRemember == 'true') {
    if (key == StorageKeys.AuthData) {
      objString = getCookie(key);
    } else {
      objString = localStorage.getItem(key);
    }
  } else {
    objString = sessionStorage.getItem(key);
  }

  if (!objString) return null;

  return JSON.parse(objString);
}

export function setStoredItem(key, value) {
  const isRemember = getCookie(StorageKeys.IsRemember);

  if (typeof value === 'object') {
    if (isRemember == 'true') {
      if (key == StorageKeys.AuthData) {
        setCookie(key, JSON.stringify(value), 1);
      } else {
        localStorage.setItem(key, JSON.stringify(value));
      }
    } else {
      sessionStorage.setItem(key, JSON.stringify(value));
    }
  } else {
    if (isRemember == 'true') {
      if (key == StorageKeys.AuthData) {
        setCookie(key, value, 1);
      } else {
        localStorage.setItem(key, value);
      }
    } else {
      sessionStorage.setItem(key, value);
    }
  }
}

export function clearStoredUserData() {
  const isRemember = getCookie(StorageKeys.IsRemember);

  deleteCookie(StorageKeys.IsRemember);

  if (isRemember == 'true') {
    deleteCookie(StorageKeys.AuthData);
    localStorage.removeItem(StorageKeys.Profile);
    localStorage.removeItem(StorageKeys.WorkingCompany);
    localStorage.removeItem(StorageKeys.WorkingCompanyName);
    localStorage.removeItem(StorageKeys.WorkingCompanyInfo);
    localStorage.removeItem(StorageKeys.CustomerInfo);
    localStorage.removeItem(StorageKeys.ShoppingCart);
  }

  clearStoredItem(StorageKeys.AuthData);
  clearStoredItem(StorageKeys.Profile);
  clearStoredItem(StorageKeys.WorkingCompany);
  clearStoredItem(StorageKeys.WorkingCompanyName);
  clearStoredItem(StorageKeys.WorkingCompanyInfo);
  clearStoredItem(StorageKeys.CustomerInfo);
  clearStoredItem(StorageKeys.ShoppingCart);
}

export function clearStoredItem(key) {
  sessionStorage.removeItem(key);
}
