import React, { Component } from 'react';
import { createValidator, required, maxLength } from '../../../../helpers/validator';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import PropTypes from 'prop-types';
import TextField from '../../Fields/TextField';
import TextAreaField from '../../Fields/TextAreaField';
import Button from '../../Controls/Button';
import { Card, CardBody, Row, Col, ButtonGroup } from 'reactstrap';

const validator = createValidator({
  planName: [required, maxLength(256)],
});

export const frewiePlanFormId = 'frewie-plan';

class FrewiePlanForm extends Component {
  render() {
    const { error, handleSubmit, pristine, reset, submitting, isEditting, onChangeEditMode, onDelete } = this.props;

    return (
      <form onSubmit={handleSubmit} className="ml-2 mr-2">
        <ButtonGroup style={{ marginBottom: 10 }}>
          {!isEditting && onChangeEditMode && (
            <Button
              type="button"
              size="lg"
              className="btn btn-secondary bg-gradient-theme-left border-0"
              onClick={() => onChangeEditMode(true)}
            >
              Edit
            </Button>
          )}
          {!isEditting && onDelete && (
            <Button
              type="button"
              size="lg"
              className="btn btn-secondary bg-gradient-theme-left border-0 ml-1"
              onClick={() => onDelete()}
            >
              Delete
            </Button>
          )}
          {isEditting && (
            <Button
              type="submit"
              size="lg"
              className="btn btn-secondary bg-gradient-theme-left border-0"
              loading={submitting}
              disabled={pristine}
            >
              Save
            </Button>
          )}
          {isEditting && onChangeEditMode && (
            <Button
              type="button"
              size="lg"
              className="btn btn-secondary bg-gradient-theme-left border-0 ml-1"
              onClick={() => onChangeEditMode(false)}
            >
              Cancel
            </Button>
          )}
        </ButtonGroup>
        {error && (
          <div className="my-2 text-left">
            <span className="form-error">{error}</span>
          </div>
        )}
        <Card style={{ marginBottom: '15px' }}>
          <CardBody>
            <Col xl={6} lg={6} md={8} sm={12} xs={12}>
              <Field
                name="planName"
                component={TextField}
                type="text"
                label="Plan Name"
                showLabel={true}
                required={true}
                disabled={!isEditting || submitting}
                horizontal={true}
              />
            </Col>
            <Col xl={6} lg={6} md={8} sm={12} xs={12}>
              <Field
                name="description"
                component={TextAreaField}
                maxLength={512}
                rows="3"
                label="Description"
                showLabel={true}
                disabled={!isEditting || submitting}
                horizontal={true}
              />
            </Col>
          </CardBody>
        </Card>
      </form>
    );
  }
}

FrewiePlanForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  invalid: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({});
const mapDispatchToProps = dispatch => ({});

FrewiePlanForm = reduxForm({
  form: frewiePlanFormId,
  validate: validator,
  enableReinitialize: true,
  destroyOnUnmount: false,
})(FrewiePlanForm);

export default connect(mapStateToProps, mapDispatchToProps)(FrewiePlanForm);
