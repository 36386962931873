import ApiClient from '../../../helpers/apiClient';
import { startSubmit } from 'redux-form';

export const FETCH_USAGE_NOTIFICATION_REQUEST = 'FETCH_USAGE_NOTIFICATION_REQUEST';
export const FETCH_USAGE_NOTIFICATION_SUCCESS = 'FETCH_USAGE_NOTIFICATION_SUCCESS';
export const FETCH_USAGE_NOTIFICATION_ERROR = 'FETCH_USAGE_NOTIFICATION_ERROR';

export const GET_USAGE_NOTIFICATION_DETAIL_REQUEST = 'GET_USAGE_NOTIFICATION_DETAIL_REQUEST';
export const GET_USAGE_NOTIFICATION_DETAIL_SUCCESS = 'GET_USAGE_NOTIFICATION_DETAIL_SUCCESS';
export const GET_USAGE_NOTIFICATION_DETAIL_ERROR = 'GET_USAGE_NOTIFICATION_DETAIL_ERROR';

export const GENERATE_USAGE_NOTIFICATION_REQUEST = 'GENERATE_USAGE_NOTIFICATION_REQUEST';
export const GENERATE_USAGE_NOTIFICATION_SUCCESS = 'GENERATE_USAGE_NOTIFICATION_SUCCESS';
export const GENERATE_USAGE_NOTIFICATION_ERROR = 'GENERATE_USAGE_NOTIFICATION_ERROR';

export const fetchUsageNotifications = data => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [FETCH_USAGE_NOTIFICATION_REQUEST, FETCH_USAGE_NOTIFICATION_SUCCESS, FETCH_USAGE_NOTIFICATION_ERROR],
      callAPI: () => ApiClient.post('network/Notification/Search', data),
      payload: { data },
    }),
  );
};

export const getUsageNotificationDetail = id => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [GET_USAGE_NOTIFICATION_DETAIL_REQUEST, GET_USAGE_NOTIFICATION_DETAIL_SUCCESS, GET_USAGE_NOTIFICATION_DETAIL_ERROR],
      callAPI: () => ApiClient.get(`network/Notification/GetDetail/${id}`),
      payload: { data: { id } },
    }),
  );
};

export const generateUsageNotificationCsv = data => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [
        GENERATE_USAGE_NOTIFICATION_REQUEST,
        GENERATE_USAGE_NOTIFICATION_SUCCESS,
        GENERATE_USAGE_NOTIFICATION_ERROR
      ],
      callAPI: () => ApiClient.post(`network/Notification/ExportNotificationCsv`, data),
      payload: { data },
    }),
  );
};

const initialState = {
  payload: {},
  data: {},
  response: {},
  error: {},
  errorMessage: '',
  successMessage: '',
};

export default function reducer(state = initialState, action) {
  const { payload, response, error, successMessage, errorMessage } = action;
  switch (action.type) {
    case FETCH_USAGE_NOTIFICATION_REQUEST:
    case GET_USAGE_NOTIFICATION_DETAIL_REQUEST:
      return {
        ...state,
        payload,
        response: {},
        error: {},
        successMessage: '',
        errorMessage: '',
      };

    case FETCH_USAGE_NOTIFICATION_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          items: response.data.data.items,
          pages: response.data.data.pageCount,
        },
        response,
        successMessage,
      };

    case GET_USAGE_NOTIFICATION_DETAIL_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          item: response.data.data,
        },
        response,
        successMessage,
      };

    case FETCH_USAGE_NOTIFICATION_ERROR:
      return {
        ...state,
        payload,
        error,
        errorMessage,
      };
    default:
      return state;
  }
}
