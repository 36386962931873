import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchConnectedCompanyOptions } from 'redux/modules/network/Company';
import { searchProductsOfDistributor } from 'redux/modules/product/Product';
import SearchBar from 'views/components/Fields/Searchbar';
import cx from 'classnames';
import PrevIcon from 'assets/img/icons/ic_prev.png';
import PrevWhiteIcon from 'assets/img/icons/ic_prev_white.png';
import NextIcon from 'assets/img/icons/ic_next.png';
import NextWhiteIcon from 'assets/img/icons/ic_next_white.png';
import Pagination from 'rc-pagination';
import 'rc-pagination/assets/index.css';
import 'styles/css/CTable.css';
import 'styles/components/_rc-pagination.scss';
import Page from 'views/components/Page';
import Select from 'react-select';
import DistributorProductCard from 'views/components/Card/DistributorProductCard';
import { fetchEntityUomOptions } from 'redux/modules/network/UOM';
import { setCartDistributor } from 'redux/modules/network/ShoppingCart';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchCountryOptions } from 'redux/modules/product/Country';
import CButton from 'views/components/Controls/CButton';
import OrderShoppingCart from '../../components/Forms/order/OrderShoppingCart';
import CloseIcon from 'assets/img/icons/ic_close.svg';
import { StorageKeys } from 'constants/storageKeys';
import { useHistory } from 'react-router-dom';
import { getStoredObject, setStoredItem } from 'helpers/storageHelper';
import {
  MdExitToApp,
  MdNotificationsActive,
  MdNotificationsNone,
  MdPersonPin,
  MdInbox,
  MdShoppingCart,
} from 'react-icons/md';
import {
  Button,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Nav,
  Navbar,
  NavItem,
  NavLink,
  Popover,
  PopoverBody,
  Col,
  Row
} from 'reactstrap';

const CatalogPage = ({ fetchCountryOptions, countryOptions, location, ...props }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [distributor, setDistributor] = useState(useSelector(state => state.shoppingCart.data.distributor));
  const [textSearch, setTextSearch] = useState('');
  const [loading, setLoading] = useState(false);
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(12);
  const [products, setProducts] = useState([]);
  const [uomOptions, setUomOptions] = useState(null);
  const [loadingDistributors, setLoadingDistributors] = useState(false);
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [searchDays, setSearchDays] = useState('');
  const [searchDataCapacity, setDataCapacity] = useState('');
  const [isSearchingDataPlan, setSearchingDataPlan] = useState(false);
  const [dataTypeChecked, setDataTypeChecked] = useState(true);
  const [productsInCart, setProductsInCart,] = useState([]);
  const [dayPassTypeChecked, setDayPassTypeChecked] = useState(true);
  const [isOpenCartModal, setIsOpenCartModal] = useState(false);
  const companyOptions = useSelector(state => state.company.data.distributorOptions);
  const pageCount = useSelector(state => state.product.data.pageCount);
  const totalCount = useSelector(state => state.product.data.totalCount);
  const productCarts = useSelector(state => state.shoppingCart.data.products);

  const PrevIconSwitch = pageIndex > 1 ? PrevWhiteIcon : PrevIcon;
  const NextIconSwitch = pageIndex < pageCount ? NextWhiteIcon : NextIcon;

  useEffect(() => {
    setLoadingDistributors(true);
    dispatch(fetchConnectedCompanyOptions()).finally(() => {
      setLoadingDistributors(false);
    });
  }, []);

  useEffect(() => {
    if (!dayPassTypeChecked && !dataTypeChecked) {
      alert('Please check at least one Data Type.');
      setDataTypeChecked(true);
      setDayPassTypeChecked(true);
    } else if (!dayPassTypeChecked) {
      setDataTypeChecked(true);
    } else if (!dataTypeChecked) {
      setDayPassTypeChecked(true);
    }
  }, [dataTypeChecked, dayPassTypeChecked]);

  useEffect(() => {
    fetchData();
    setProductsInCart(productCarts);
    fetchCountryOptions();
  }, [pageIndex, pageSize, distributor, textSearch, companyOptions, fetchCountryOptions, productCarts]);


  const fetchData = () => {
    if (!distributor && companyOptions && companyOptions.length > 0) {
      setDistributor(companyOptions[0]);
      dispatch(setCartDistributor(companyOptions[0]));
      dispatch(fetchEntityUomOptions(companyOptions[0].entityCode)).then(resp => {
        setUomOptions(resp.response.data.data);
      });
    }

    if (distributor && !uomOptions) {
      dispatch(fetchEntityUomOptions(distributor.entityCode)).then(resp => {
        setUomOptions(resp.response.data.data);
      });
    }

    setProducts([]);

    if (distributor) {
      let selectedCountryValues = [];

      if (selectedCountries) {
        selectedCountryValues = selectedCountries.map(country => country.value);
      }

      setLoading(true);
      dispatch(
        searchProductsOfDistributor({
          pageIndex: pageIndex,
          pageSize: pageSize,
          distributorEntityCode: distributor.entityCode,
          textSearch: textSearch,
          countryCode: selectedCountryValues,
          days: searchDays,
          data: searchDataCapacity,
          isDataType: dataTypeChecked,
          isDayPassType: dayPassTypeChecked,
        }),
      )
        .then(resp => {
          console.log(resp.response.data.data.items)
          setProducts(resp.response.data.data.items);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const handleReset = () => {
    // setHasSearched(false);
    setSearchDays('')
    setDataCapacity('')
    setSelectedCountries([])
    const checkboxes = document.querySelectorAll('input[type="checkbox"]');
    checkboxes.forEach((checkbox) => (checkbox.checked = false));
  }

  const toggleModal = () => {
    setIsOpenCartModal(!isOpenCartModal);
  };

  const handleSearch = ({ id, value }) => {
    // setTextSearch(value);

    if (!selectedCountries || selectedCountries.length === 0) {
      alert('Please select at least one country.');
      return;
    }
    // if (!searchDays) {
    //   alert('Please select number of days.');
    //   return;
    // }
    // if (!searchDataCapacity) {
    //   alert('Please select data capacity.');
    //   return;
    // }

    fetchData()
  };

  const handlePageChange = newPageIndex => {
    setPageIndex(newPageIndex);
  };

  const handleChangeDistributor = company => {
    setDistributor(company);
    dispatch(setCartDistributor(company));
    dispatch(fetchEntityUomOptions(company.entityCode)).then(resp => {
      setUomOptions(resp.response.data.data);
    });
  };

  const buttonItemRender = (current, type, element) => {
    if (type === 'prev') {
      return (
        <button type="button" aria-label="prev page" className="rc-pagination-item-link" disabled={pageIndex <= 1}>
          <img src={PrevIconSwitch} />
        </button>
      );
    }
    if (type === 'next') {
      return (
        <button
          type="button"
          aria-label="next page"
          className="rc-pagination-item-link"
          disabled={pageIndex >= pageCount}
        >
          <img src={NextIconSwitch} />
        </button>
      );
    }
    return element;
  };

  return (
    <Page loading={loading}>
      <div className="d-flex mb-3">
        {/* <SearchBar
          className="mr-3"
          id="searchText"
          label="Search product name, code, brand, category"
          input={textSearch}
          onSearchChange={data => handleSearch(data)}
        /> */}
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Select
            name="countryCodes"
            isMulti
            options={countryOptions}
            label="Country"
            placeholder={'Select Country...'}
            value={selectedCountries}
            onChange={selected => {
              setSelectedCountries(selected);
            }}
            styles={{
              container: base => ({
                ...base,
                width: 300,
                border: '1px solid lightgray'
              })
            }}
          />
          <input
            type="text"
            value={searchDays}
            onChange={(e) => setSearchDays(e.target.value)}
            style={{ marginLeft: '1rem', borderRadius: '5px', border: '1px solid lightgray', padding: '0.5rem', width: '80px' }}
          />
          <span style={{ marginLeft: '0.5rem' }}>Days</span>

          <input
            type="text"
            value={searchDataCapacity}
            onChange={(e) => setDataCapacity(e.target.value)}
            style={{ marginLeft: '1rem', borderRadius: '5px', border: '1px solid lightgray', padding: '0.5rem', width: '80px' }}
          />
          <span style={{ marginLeft: '0.5rem' }}>GB</span>


          <CButton
            loading={isSearchingDataPlan}
            onClick={handleSearch}
            style={{
              borderRadius: '5px',
              color: 'white',
              marginLeft: '1rem',
              backgroundColor: '#212529',
              border: '1px solid lightgray',
              padding: '0.5rem 1rem',
              fontSize: '16px',
              fontFamily: "'Helvetica Neue', sans-serif",
              fontWeight: 'bold'
            }}
          >
            Search
          </CButton>

          <CButton
            onClick={handleReset}
            style={{
              borderRadius: '5px',
              marginLeft: '1rem',
              color: 'white',
              backgroundColor: 'orange',
              border: '1px solid lightgray',
              padding: '0.5rem 1rem',
              fontSize: '16px',
              fontFamily: "'Helvetica Neue', sans-serif",
              fontWeight: 'bold'
            }}
          >
            Reset
          </CButton>
        </div>


        <div className="ml-auto d-flex justify-content-end align-items-center">
          {productsInCart && productsInCart.length > 0 && (
            <div>
              <NavLink id="Popover3">
                <MdShoppingCart size={40} className="can-click" color="#d83e7c" onClick={toggleModal} />
                <span>{productsInCart.length}</span>
              </NavLink>
              <Modal isOpen={isOpenCartModal} toggle={toggleModal} className="modal-xl">
                <ModalHeader
                  toggle={toggleModal}
                  close={
                    <button className="close" onClick={toggleModal}>
                      <img src={CloseIcon} />
                    </button>
                  }
                >
                  <MdShoppingCart size={30} color="#d83e7c" /> Purchase Order Shopping Cart
                </ModalHeader>
                <ModalBody>
                  <OrderShoppingCart />
                </ModalBody>
                <ModalFooter>
                  <Button
                    outline
                    color="primary"
                    style={{ marginBottom: '1rem' }}
                    onClick={() => {
                      history.push({
                        pathname: '/purchase-order-new',
                        state: { isFromCatalog: true }  // Pass the isFromCatalog prop
                      });
                      toggleModal();
                    }}
                  >
                    Order
                  </Button>
                </ModalFooter>
              </Modal>
            </div>
          )}
          <div style={{ width: 400 }}>
            <Select
              className="form-control"
              options={companyOptions}
              value={distributor}
              onChange={o => handleChangeDistributor(o)}
              placeholder="Select distributor"
              isLoading={loadingDistributors}
            />
          </div>
        </div>
      </div>

      <div className="d-flex mb-3">
        <label style={{ fontSize: '1.2rem' }}>
          <input
            type="checkbox"
            checked={dataTypeChecked}
            onChange={e => setDataTypeChecked(e.target.checked)}
            style={{ transform: 'scale(1.5)', marginRight: '0.5rem' }}
          />
          Data Type
        </label>

        <label style={{ marginLeft: '1rem', fontSize: '1.2rem' }}>
          <input
            type="checkbox"
            checked={dayPassTypeChecked}
            onChange={e => setDayPassTypeChecked(e.target.checked)}
            style={{ transform: 'scale(1.5)', marginRight: '0.5rem' }}
          />
          Day-Pass Type
        </label>
      </div>

      <Row>
        {products?.map((item, index) => (
          <Col key={index} xs={3}>
            <DistributorProductCard item={item} uomOptions={uomOptions} distributor={distributor} country={selectedCountries} days={searchDays} data={searchDataCapacity} />
          </Col>
        ))}
      </Row>

      <div className="d-flex align-items-center justify-content-between mt-4">
        <div className="d-flex align-items-center ">
          <li
            title="prev"
            className={cx('rc-pagination-prev', { 'rc-pagination-disabled': pageIndex <= 1 })}
            aria-disabled={cx({ true: pageIndex <= 1, false: pageIndex > 1 })}
            onClick={() => setPageIndex(1)}
            disabled={pageIndex <= 1}
          >
            <button type="button" aria-label="prev page" className="rc-pagination-item-link" disabled={pageIndex <= 1}>
              <img src={PrevIconSwitch} />
              <img src={PrevIconSwitch} />
            </button>
          </li>
          <Pagination
            onChange={handlePageChange}
            current={pageIndex}
            total={totalCount}
            pageSize={pageSize}
            defaultPageSize={pageSize}
            itemRender={buttonItemRender}
          />
          <li
            title="next"
            className={cx('rc-pagination-next', { 'rc-pagination-disabled': pageIndex >= pageCount })}
            aria-disabled={cx({ true: pageIndex => pageCount, false: pageIndex < pageCount })}
            onClick={() => setPageIndex(pageCount)}
          >
            <button
              type="button"
              aria-label="next page"
              className="rc-pagination-item-link"
              disabled={pageIndex >= pageCount}
            >
              <img src={NextIconSwitch} />
              <img src={NextIconSwitch} />
            </button>
          </li>
        </div>

        <div>
          <select
            className="select-page-size"
            value={pageSize}
            onChange={e => {
              setPageSize(Number(e.target.value));
            }}
          >
            {[8, 12, 16, 32, 64, 128].map(pageSize => (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            ))}
          </select>
          <span>
            Showing {(pageIndex - 1) * pageSize + 1} -{' '}
            {pageIndex * pageSize < totalCount ? pageIndex * pageSize : totalCount} of {totalCount}
          </span>
        </div>
      </div>
    </Page>
  );
};

const mapStateToProps = ({ shoppingCart, country }) => ({
  countryOptions: country.data.options,
  productsInCart: shoppingCart.data.products,
});

const mapDispatchToProps = dispatch => ({
  fetchCountryOptions: bindActionCreators(fetchCountryOptions, dispatch),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CatalogPage);
