import { stopSubmit } from 'redux-form';
import { transformToFormErrors } from '../../helpers/validator';
import { baseUrl } from '../../configs/settings';
import { clearStoredUserData } from '../../helpers/storageHelper';

export default function callAPIMiddleware({ dispatch, getState }) {
  return next => action => {
    const { types, callAPI, shouldCallAPI = () => true, payload = {} } = action;

    if (!types) {
      // Normal action: pass it on
      return next(action);
    }

    if (!Array.isArray(types) || types.length !== 3 || !types.every(type => typeof type === 'string')) {
      throw new Error('Expected an array of three string types.');
    }

    if (typeof callAPI !== 'function') {
      throw new Error('Expected callAPI to be a function.');
    }

    if (!shouldCallAPI(getState())) {
      return Promise.resolve();
    }

    const [requestType, successType, failureType] = types;
    dispatch(
      Object.assign(
        {},
        { payload },
        {
          type: requestType,
        },
      ),
    );

    return callAPI().then(
      response => {
        if (isIdentityServerResponse(response)) {
          if (payload && payload.formId && !payload.waitSubmit) {
            dispatch(stopSubmit(payload.formId));
          }

          return dispatch({
            type: successType,
            payload,
            response,
            errorMessage: '',
            successMessage: '',
          });
        }

        const data = response.data;
        const message = data ? data.message : '';

        if (data.statusCode === 0 || data instanceof Blob) {
          if (payload && payload.formId && !payload.waitSubmit) {
            dispatch(stopSubmit(payload.formId));
          }

          return dispatch({
            type: successType,
            payload,
            response,
            successMessage: message,
          });
        } else {
          if (payload && payload.formId) {
            dispatch(stopSubmit(payload.formId, { _error: message }));
          }

          return Promise.reject(
            dispatch({
              type: failureType,
              payload,
              response,
              errorMessage: message,
            }),
          );
        }
      },
      error => {
        console.log(error);

        // if (error.response && error.response.status === 401) {
        //   clearStoredUserData();
        //   window.location.href = '#/sign-in';
        // }

        const response = error.response ? error.response : {},
          data = response.data;

        let errorMessage = '';
        if (isIdentityServerResponse(response)) {
          if (data && data.error_description) {
            errorMessage = data.error_description.split('_').join(' ');
            errorMessage = errorMessage.charAt(0).toUpperCase() + errorMessage.substring(1);
          }
        } else {
          errorMessage = data ? data.message : payload.defaultMessage ? payload.defaultMessage : error.message;
        }

        //if ajax request if form submit
        if (payload && payload.formId) {
          let errors = {};
          //set form status to failed and update error messages
          if (data && data.errors) {
            errors = Object.assign(errors, transformToFormErrors(data.errors));
          } else {
            errors = Object.assign(errors, { _error: errorMessage });
          }

          dispatch(stopSubmit(payload.formId, errors));
          //remove error since we already use redux-form error
          errorMessage = '';
        }

        return Promise.reject(
          dispatch({
            type: failureType,
            payload,
            error,
            errorMessage,
          }),
        );
      },
    );
  };
}

const isIdentityServerResponse = response => {
  if (response && response.config && response.config.url) {
    return (
      response.config.url == `${baseUrl}/token` ||
      response.config.url == 'token' ||
      response.config.url == `${baseUrl}/register` ||
      response.config.url == 'register' ||
      response.config.url == `${baseUrl}/registerDistributor` ||
      response.config.url == 'registerDistributor' ||
      response.config.url == `${baseUrl}/registerCustomer` ||
      response.config.url == `${baseUrl}/identity/Account/RegisterBusinessCustomer` ||
      response.config.url == 'registerCustomer' ||
      response.config.url == `${baseUrl}/registerAgent` ||
      response.config.url == 'registerAgent' ||
      response.config.url == `${baseUrl}/confirm` ||
      response.config.url == 'confirm'
    );
  } else return false;
};
