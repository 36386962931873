import ApiClient from '../../../helpers/apiClient';
import { startSubmit } from 'redux-form';

export const GET_DASHBOARD_INFO_REQUEST = 'GET_DASHBOARD_INFO_REQUEST';
export const GET_DASHBOARD_INFO_SUCCESS = 'GET_DASHBOARD_INFO_SUCCESS';
export const GET_DASHBOARD_INFO_ERROR = 'GET_DASHBOARD_INFO_ERROR';

export const getDashboardInfo = data => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [
        GET_DASHBOARD_INFO_REQUEST,
        GET_DASHBOARD_INFO_SUCCESS,
        GET_DASHBOARD_INFO_ERROR,
      ],
      callAPI: () => ApiClient.post('admin/Dashboard/GetInfo', data),
      payload: { data },
    }),
  );
};

const initialState = {
  payload: {},
  data: {},
  response: {},
  error: {},
  errorMessage: '',
  successMessage: '',
};

export default function reducer(state = initialState, action) {
  const { payload, response, error, successMessage, errorMessage } = action;
  switch (action.type) {
    case GET_DASHBOARD_INFO_REQUEST:
      return {
        ...state,
        payload,
        response: {},
        error: {},
        successMessage: '',
        errorMessage: '',
      };

    case GET_DASHBOARD_INFO_SUCCESS:
      return {
        ...state,
        data: { ...state.data, dashboardInfo: response.data.data },
        response,
        successMessage,
      };

    case GET_DASHBOARD_INFO_ERROR:
      return {
        ...state,
        payload,
        error,
        errorMessage,
      };
    default:
      return state;
  }
}
