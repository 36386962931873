import React, { Component } from 'react';
import { createValidator, required, email } from '../../../../helpers/validator';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import PropTypes from 'prop-types';
import TextField from '../../Fields/TextField';
import Button from '../../Controls/Button';

const validator = createValidator({
  email: [required, email],
});

export const forgotPasswordFormId = 'forgotPassword';

class ForgotPasswordForm extends Component {
  render() {
    const { error, handleSubmit, pristine, reset, submitting } = this.props;

    return (
      <form onSubmit={handleSubmit} className="ml-2 mr-2">
        <Field
          name="email"
          component={TextField}
          type="text"
          label="Email"
          showLabel={true}
          required={true}
          disabled={submitting}
          
        />
        {error && (
          <div className="my-2 text-left">
            <span className="form-error">{error}</span>
          </div>
        )}
        <hr />
        <Button
          type="submit"
          size="lg"
          className="btn btn-secondary btn-lg btn-block bg-gradient-theme-left border-0"
          disabled={pristine}
          loading={submitting}
        >
          Reset Password
        </Button>
      </form>
    );
  }
}

ForgotPasswordForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  invalid: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({});
const mapDispatchToProps = dispatch => ({});

ForgotPasswordForm = reduxForm({
  form: forgotPasswordFormId,
  validate: validator,
  enableReinitialize: true,
  destroyOnUnmount: false,
})(ForgotPasswordForm);

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordForm);
