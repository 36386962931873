import ApiClient from '../../../helpers/apiClient';
import { startSubmit } from 'redux-form';

// Location Setup
export const GET_LOCATION_SETUP_REQUEST = 'GET_LOCATION_SETUP_REQUEST';
export const GET_LOCATION_SETUP_SUCCESS = 'GET_LOCATION_SETUP_SUCCESS';
export const GET_LOCATION_SETUP_ERROR = 'GET_LOCATION_SETUP_ERROR';

export const UPDATE_LOCATION_SETUP_REQUEST = 'UPDATE_LOCATION_SETUP_REQUEST';
export const UPDATE_LOCATION_SETUP_SUCCESS = 'UPDATE_LOCATION_SETUP_SUCCESS';
export const UPDATE_LOCATION_SETUP_ERROR = 'UPDATE_LOCATION_SETUP_ERROR';

// Location Unit
export const FETCH_LOCATION_UNIT_REQUEST = 'FETCH_LOCATION_UNIT_REQUEST';
export const FETCH_LOCATION_UNIT_SUCCESS = 'FETCH_LOCATION_UNIT_SUCCESS';
export const FETCH_LOCATION_UNIT_ERROR = 'FETCH_LOCATION_UNIT_ERROR';

export const FETCH_LOCATION_UNIT_OPTIONS_REQUEST =
  'FETCH_LOCATION_UNIT_OPTIONS_REQUEST';
export const FETCH_LOCATION_UNIT_OPTIONS_SUCCESS =
  'FETCH_LOCATION_UNIT_OPTIONS_SUCCESS';
export const FETCH_LOCATION_UNIT_OPTIONS_ERROR =
  'FETCH_LOCATION_UNIT_OPTIONS_ERROR';

export const GET_LOCATION_UNIT_DETAIL_REQUEST =
  'GET_LOCATION_UNIT_DETAIL_REQUEST';
export const GET_LOCATION_UNIT_DETAIL_SUCCESS =
  'GET_LOCATION_UNIT_DETAIL_SUCCESS';
export const GET_LOCATION_UNIT_DETAIL_ERROR = 'GET_LOCATION_UNIT_DETAIL_ERROR';

export const CREATE_LOCATION_UNIT_REQUEST = 'CREATE_LOCATION_UNIT_REQUEST';
export const CREATE_LOCATION_UNIT_SUCCESS = 'CREATE_LOCATION_UNIT_SUCCESS';
export const CREATE_LOCATION_UNIT_ERROR = 'CREATE_LOCATION_UNIT_ERROR';

export const UPDATE_LOCATION_UNIT_REQUEST = 'UPDATE_LOCATION_UNIT_REQUEST';
export const UPDATE_LOCATION_UNIT_SUCCESS = 'UPDATE_LOCATION_UNIT_SUCCESS';
export const UPDATE_LOCATION_UNIT_ERROR = 'UPDATE_LOCATION_UNIT_ERROR';

export const DELETE_LOCATION_UNIT_REQUEST = 'DELETE_LOCATION_UNIT_REQUEST';
export const DELETE_LOCATION_UNIT_SUCCESS = 'DELETE_LOCATION_UNIT_SUCCESS';
export const DELETE_LOCATION_UNIT_ERROR = 'DELETE_LOCATION_UNIT_ERROR';

// Location Sorting
export const FETCH_LOCATION_SORTING_REQUEST = 'FETCH_LOCATION_SORTING_REQUEST';
export const FETCH_LOCATION_SORTING_SUCCESS = 'FETCH_LOCATION_SORTING_SUCCESS';
export const FETCH_LOCATION_SORTING_ERROR = 'FETCH_LOCATION_SORTING_ERROR';

export const GET_LOCATION_SORTING_DETAIL_REQUEST =
  'GET_LOCATION_SORTING_DETAIL_REQUEST';
export const GET_LOCATION_SORTING_DETAIL_SUCCESS =
  'GET_LOCATION_SORTING_DETAIL_SUCCESS';
export const GET_LOCATION_SORTING_DETAIL_ERROR =
  'GET_LOCATION_SORTING_DETAIL_ERROR';

export const CREATE_LOCATION_SORTING_REQUEST =
  'CREATE_LOCATION_SORTING_REQUEST';
export const CREATE_LOCATION_SORTING_SUCCESS =
  'CREATE_LOCATION_SORTING_SUCCESS';
export const CREATE_LOCATION_SORTING_ERROR = 'CREATE_LOCATION_SORTING_ERROR';

export const UPDATE_LOCATION_SORTING_REQUEST =
  'UPDATE_LOCATION_SORTING_REQUEST';
export const UPDATE_LOCATION_SORTING_SUCCESS =
  'UPDATE_LOCATION_SORTING_SUCCESS';
export const UPDATE_LOCATION_SORTING_ERROR = 'UPDATE_LOCATION_SORTING_ERROR';

export const DELETE_LOCATION_SORTING_REQUEST =
  'DELETE_LOCATION_SORTING_REQUEST';
export const DELETE_LOCATION_SORTING_SUCCESS =
  'DELETE_LOCATION_SORTING_SUCCESS';
export const DELETE_LOCATION_SORTING_ERROR = 'DELETE_LOCATION_SORTING_ERROR';

// Location
export const FETCH_LOCATIONS_REQUEST = 'FETCH_LOCATIONS_REQUEST';
export const FETCH_LOCATIONS_SUCCESS = 'FETCH_LOCATIONS_SUCCESS';
export const FETCH_LOCATIONS_ERROR = 'FETCH_LOCATIONS_ERROR';

export const FETCH_LOCATION_OPTIONS_REQUEST = 'FETCH_LOCATION_OPTIONS_REQUEST';
export const FETCH_LOCATION_OPTIONS_SUCCESS = 'FETCH_LOCATION_OPTIONS_SUCCESS';
export const FETCH_LOCATION_OPTIONS_ERROR = 'FETCH_LOCATION_OPTIONS_ERROR';

export const GET_LOCATION_DETAIL_REQUEST = 'GET_LOCATION_DETAIL_REQUEST';
export const GET_LOCATION_DETAIL_SUCCESS = 'GET_LOCATION_DETAIL_SUCCESS';
export const GET_LOCATION_DETAIL_ERROR = 'GET_LOCATION_DETAIL_ERROR';

export const GENERATE_LOCATIONS_REQUEST = 'GENERATE_LOCATIONS_REQUEST';
export const GENERATE_LOCATIONS_SUCCESS = 'GENERATE_LOCATIONS_SUCCESS';
export const GENERATE_LOCATIONS_ERROR = 'GENERATE_LOCATIONS_ERROR';

export const UPDATE_LOCATION_REQUEST = 'UPDATE_LOCATION_REQUEST';
export const UPDATE_LOCATION_SUCCESS = 'UPDATE_LOCATION_SUCCESS';
export const UPDATE_LOCATION_ERROR = 'UPDATE_LOCATION_ERROR';

// Location Setup
export const getLocationSetup = () => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [
        GET_LOCATION_SETUP_REQUEST,
        GET_LOCATION_SETUP_SUCCESS,
        GET_LOCATION_SETUP_ERROR,
      ],
      callAPI: () => ApiClient.get('product/LocationSetup'),
      payload: {},
    }),
  );
};

export const updateLocationSetup = (data, formId) => dispatch => {
  return Promise.resolve(dispatch(startSubmit(formId))).then(() =>
    dispatch({
      types: [
        UPDATE_LOCATION_SETUP_REQUEST,
        UPDATE_LOCATION_SETUP_SUCCESS,
        UPDATE_LOCATION_SETUP_ERROR,
      ],
      callAPI: () => ApiClient.put(`product/LocationSetup`, data),
      payload: { data, formId },
    }),
  );
};

// Location Unit
export const fetchLocationUnit = data => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [
        FETCH_LOCATION_UNIT_REQUEST,
        FETCH_LOCATION_UNIT_SUCCESS,
        FETCH_LOCATION_UNIT_ERROR,
      ],
      callAPI: () => ApiClient.post('product/LocationUnit/Search', data),
      payload: { data },
    }),
  );
};

export const fetchLocationUnitOptions = () => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [
        FETCH_LOCATION_UNIT_OPTIONS_REQUEST,
        FETCH_LOCATION_UNIT_OPTIONS_SUCCESS,
        FETCH_LOCATION_UNIT_OPTIONS_ERROR,
      ],
      callAPI: () => ApiClient.get('product/LocationUnit/GetSelectList'),
      payload: {},
    }),
  );
};

export const getLocationUnitDetail = id => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [
        GET_LOCATION_UNIT_DETAIL_REQUEST,
        GET_LOCATION_UNIT_DETAIL_SUCCESS,
        GET_LOCATION_UNIT_DETAIL_ERROR,
      ],
      callAPI: () => ApiClient.get(`product/LocationUnit/GetDetail/${id}`),
      payload: { data: { id } },
    }),
  );
};

export const createLocationUnit = (data, formId) => dispatch => {
  return Promise.resolve(dispatch(startSubmit(formId))).then(() =>
    dispatch({
      types: [
        CREATE_LOCATION_UNIT_REQUEST,
        CREATE_LOCATION_UNIT_SUCCESS,
        CREATE_LOCATION_UNIT_ERROR,
      ],
      callAPI: () => ApiClient.post('product/LocationUnit', data),
      payload: { data, formId },
    }),
  );
};

export const updateLocationUnit = (id, data, formId) => dispatch => {
  return Promise.resolve(dispatch(startSubmit(formId))).then(() =>
    dispatch({
      types: [
        UPDATE_LOCATION_UNIT_REQUEST,
        UPDATE_LOCATION_UNIT_SUCCESS,
        UPDATE_LOCATION_UNIT_ERROR,
      ],
      callAPI: () => ApiClient.put(`product/LocationUnit/${id}`, data),
      payload: { data: { ...data, id }, formId },
    }),
  );
};

export const deleteLocationUnit = id => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [
        DELETE_LOCATION_UNIT_REQUEST,
        DELETE_LOCATION_UNIT_SUCCESS,
        DELETE_LOCATION_UNIT_ERROR,
      ],
      callAPI: () => ApiClient.delete(`product/LocationUnit/${id}`),
      payload: { data: { id } },
    }),
  );
};

// Location Sorting
export const fetchLocationSorting = data => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [
        FETCH_LOCATION_SORTING_REQUEST,
        FETCH_LOCATION_SORTING_SUCCESS,
        FETCH_LOCATION_SORTING_ERROR,
      ],
      callAPI: () => ApiClient.post('product/LocSortingHeader/Search', data),
      payload: { data },
    }),
  );
};

export const getLocationSortingDetail = id => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [
        GET_LOCATION_SORTING_DETAIL_REQUEST,
        GET_LOCATION_SORTING_DETAIL_SUCCESS,
        GET_LOCATION_SORTING_DETAIL_ERROR,
      ],
      callAPI: () => ApiClient.get(`product/LocSortingHeader/GetDetail/${id}`),
      payload: { data: { id } },
    }),
  );
};

export const createLocationSorting = (data, formId) => dispatch => {
  return Promise.resolve(dispatch(startSubmit(formId))).then(() =>
    dispatch({
      types: [
        CREATE_LOCATION_SORTING_REQUEST,
        CREATE_LOCATION_SORTING_SUCCESS,
        CREATE_LOCATION_SORTING_ERROR,
      ],
      callAPI: () => ApiClient.post('product/LocSortingHeader', data),
      payload: { data, formId },
    }),
  );
};

export const updateLocationSorting = (id, data, formId) => dispatch => {
  return Promise.resolve(dispatch(startSubmit(formId))).then(() =>
    dispatch({
      types: [
        UPDATE_LOCATION_SORTING_REQUEST,
        UPDATE_LOCATION_SORTING_SUCCESS,
        UPDATE_LOCATION_SORTING_ERROR,
      ],
      callAPI: () => ApiClient.put(`product/LocSortingHeader/${id}`, data),
      payload: { data: { ...data, id }, formId },
    }),
  );
};

export const deleteLocationSorting = id => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [
        DELETE_LOCATION_SORTING_REQUEST,
        DELETE_LOCATION_SORTING_SUCCESS,
        DELETE_LOCATION_SORTING_ERROR,
      ],
      callAPI: () => ApiClient.delete(`product/LocSortingHeader/${id}`),
      payload: { data: { id } },
    }),
  );
};

// Location
export const fetchLocations = data => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [
        FETCH_LOCATIONS_REQUEST,
        FETCH_LOCATIONS_SUCCESS,
        FETCH_LOCATIONS_ERROR,
      ],
      callAPI: () => ApiClient.post('product/Location/Search', data),
      payload: { data },
    }),
  );
};

export const fetchLocationOptions = () => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [
        FETCH_LOCATION_OPTIONS_REQUEST,
        FETCH_LOCATION_OPTIONS_SUCCESS,
        FETCH_LOCATION_OPTIONS_ERROR,
      ],
      callAPI: () => ApiClient.get('product/Location/GetSelectList'),
      payload: {},
    }),
  );
};

export const getLocationDetail = id => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [
        GET_LOCATION_DETAIL_REQUEST,
        GET_LOCATION_DETAIL_SUCCESS,
        GET_LOCATION_DETAIL_ERROR,
      ],
      callAPI: () => ApiClient.get(`product/Location/${id}`),
      payload: { data: { id } },
    }),
  );
};

export const generateLocations = () => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [
        GENERATE_LOCATIONS_REQUEST,
        GENERATE_LOCATIONS_SUCCESS,
        GENERATE_LOCATIONS_ERROR,
      ],
      callAPI: () => ApiClient.post('product/Location'),
      payload: {},
    }),
  );
};

export const updateLocation = (id, data, formId) => dispatch => {
  return Promise.resolve(dispatch(startSubmit(formId))).then(() =>
    dispatch({
      types: [
        UPDATE_LOCATION_REQUEST,
        UPDATE_LOCATION_SUCCESS,
        UPDATE_LOCATION_ERROR,
      ],
      callAPI: () => ApiClient.put(`product/Location/${id}`, data),
      payload: { data: { ...data, id }, formId },
    }),
  );
};

const initialState = {
  payload: {},
  data: {},
  response: {},
  error: {},
  errorMessage: '',
  successMessage: '',
};

export default function reducer(state = initialState, action) {
  const { payload, response, error, successMessage, errorMessage } = action;
  switch (action.type) {
    case GET_LOCATION_SETUP_REQUEST:
    case UPDATE_LOCATION_SETUP_REQUEST:

    case FETCH_LOCATION_UNIT_REQUEST:
    case FETCH_LOCATION_UNIT_OPTIONS_REQUEST:
    case GET_LOCATION_UNIT_DETAIL_REQUEST:
    case CREATE_LOCATION_UNIT_REQUEST:
    case UPDATE_LOCATION_UNIT_REQUEST:
    case DELETE_LOCATION_UNIT_REQUEST:

    case FETCH_LOCATION_SORTING_REQUEST:
    case GET_LOCATION_SORTING_DETAIL_REQUEST:
    case CREATE_LOCATION_SORTING_REQUEST:
    case UPDATE_LOCATION_SORTING_REQUEST:
    case DELETE_LOCATION_SORTING_REQUEST:

    case FETCH_LOCATIONS_REQUEST:
    case FETCH_LOCATION_OPTIONS_REQUEST:
    case GENERATE_LOCATIONS_REQUEST:
    case GET_LOCATION_DETAIL_REQUEST:
    case UPDATE_LOCATION_REQUEST:
      return {
        ...state,
        payload,
        response: {},
        error: {},
        successMessage: '',
        errorMessage: '',
      };

    case FETCH_LOCATION_UNIT_OPTIONS_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          locationUnitOptions: response.data.data,
        },
        response,
        successMessage,
      };

    case FETCH_LOCATION_OPTIONS_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          locationOptions: response.data.data,
        },
        response,
        successMessage,
      };

    case FETCH_LOCATION_UNIT_SUCCESS:
    case FETCH_LOCATION_SORTING_SUCCESS:
    case FETCH_LOCATIONS_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          items: response.data.data.items,
          pages: response.data.data.pageCount,
        },
        response,
        successMessage,
      };

    case GET_LOCATION_SETUP_SUCCESS:
    case GET_LOCATION_UNIT_DETAIL_SUCCESS:
    case GET_LOCATION_SORTING_DETAIL_SUCCESS:
    case GET_LOCATION_DETAIL_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          item: response.data.data,
        },
        response,
        successMessage,
      };

    case UPDATE_LOCATION_SETUP_SUCCESS:

    case CREATE_LOCATION_UNIT_SUCCESS:
    case UPDATE_LOCATION_UNIT_SUCCESS:
    case DELETE_LOCATION_UNIT_SUCCESS:

    case CREATE_LOCATION_SORTING_SUCCESS:
    case UPDATE_LOCATION_SORTING_SUCCESS:
    case DELETE_LOCATION_SORTING_SUCCESS:

    case GENERATE_LOCATIONS_SUCCESS:
    case UPDATE_LOCATION_SUCCESS:
      return {
        ...state,
        response,
        successMessage,
      };

    case GET_LOCATION_SETUP_ERROR:
    case UPDATE_LOCATION_SETUP_ERROR:

    case FETCH_LOCATION_UNIT_ERROR:
    case FETCH_LOCATION_UNIT_OPTIONS_ERROR:
    case GET_LOCATION_UNIT_DETAIL_ERROR:
    case CREATE_LOCATION_UNIT_ERROR:
    case UPDATE_LOCATION_UNIT_ERROR:
    case DELETE_LOCATION_UNIT_ERROR:

    case FETCH_LOCATION_SORTING_ERROR:
    case GET_LOCATION_SORTING_DETAIL_ERROR:
    case CREATE_LOCATION_SORTING_ERROR:
    case UPDATE_LOCATION_SORTING_ERROR:
    case DELETE_LOCATION_SORTING_ERROR:

    case FETCH_LOCATIONS_ERROR:
    case FETCH_LOCATION_OPTIONS_ERROR:
    case GENERATE_LOCATIONS_ERROR:
    case GET_LOCATION_DETAIL_ERROR:
    case UPDATE_LOCATION_ERROR:
      return {
        ...state,
        payload,
        error,
        errorMessage,
      };
    default:
      return state;
  }
}
