import ApiClient from '../../../helpers/apiClient';
import { startSubmit } from 'redux-form';

export const FETCH_TIMESLOTS_REQUEST = 'FETCH_TIMESLOTS_REQUEST';
export const FETCH_TIMESLOTS_SUCCESS = 'FETCH_TIMESLOTS_SUCCESS';
export const FETCH_TIMESLOTS_ERROR = 'FETCH_TIMESLOTS_ERROR';

export const FETCH_TIMESLOT_OPTIONS_REQUEST = 'FETCH_TIMESLOT_OPTIONS_REQUEST';
export const FETCH_TIMESLOT_OPTIONS_SUCCESS = 'FETCH_TIMESLOT_OPTIONS_SUCCESS';
export const FETCH_TIMESLOT_OPTIONS_ERROR = 'FETCH_TIMESLOT_OPTIONS_ERROR';

export const GET_TIMESLOT_DETAIL_REQUEST = 'GET_TIMESLOT_DETAIL_REQUEST';
export const GET_TIMESLOT_DETAIL_SUCCESS = 'GET_TIMESLOT_DETAIL_SUCCESS';
export const GET_TIMESLOT_DETAIL_ERROR = 'GET_TIMESLOT_DETAIL_ERROR';

export const CREATE_TIMESLOT_REQUEST = 'CREATE_TIMESLOT_REQUEST';
export const CREATE_TIMESLOT_SUCCESS = 'CREATE_TIMESLOT_SUCCESS';
export const CREATE_TIMESLOT_ERROR = 'CREATE_TIMESLOT_ERROR';

export const UPDATE_TIMESLOT_REQUEST = 'UPDATE_TIMESLOT_REQUEST';
export const UPDATE_TIMESLOT_SUCCESS = 'UPDATE_TIMESLOT_SUCCESS';
export const UPDATE_TIMESLOT_ERROR = 'UPDATE_TIMESLOT_ERROR';

export const DELETE_TIMESLOT_REQUEST = 'DELETE_TIMESLOT_REQUEST';
export const DELETE_TIMESLOT_SUCCESS = 'DELETE_TIMESLOT_SUCCESS';
export const DELETE_TIMESLOT_ERROR = 'DELETE_TIMESLOT_ERROR';

export const fetchTimeslots = data => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [
        FETCH_TIMESLOTS_REQUEST,
        FETCH_TIMESLOTS_SUCCESS,
        FETCH_TIMESLOTS_ERROR,
      ],
      callAPI: () => ApiClient.post('service/Timeslots/Search', data),
      payload: { data },
    }),
  );
};

export const fetchTimeslotOptions = () => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [
        FETCH_TIMESLOT_OPTIONS_REQUEST,
        FETCH_TIMESLOT_OPTIONS_SUCCESS,
        FETCH_TIMESLOT_OPTIONS_ERROR,
      ],
      callAPI: () => ApiClient.get('service/Timeslots/GetSelectList'),
      payload: {},
    }),
  );
};

export const getTimeslotDetail = id => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [
        GET_TIMESLOT_DETAIL_REQUEST,
        GET_TIMESLOT_DETAIL_SUCCESS,
        GET_TIMESLOT_DETAIL_ERROR,
      ],
      callAPI: () => ApiClient.get(`service/Timeslots/GetDetail/${id}`),
      payload: { data: { id } },
    }),
  );
};

export const createTimeslot = (data, formId) => dispatch => {
  return Promise.resolve(dispatch(startSubmit(formId))).then(() =>
    dispatch({
      types: [
        CREATE_TIMESLOT_REQUEST,
        CREATE_TIMESLOT_SUCCESS,
        CREATE_TIMESLOT_ERROR,
      ],
      callAPI: () => ApiClient.post('service/Timeslots', data),
      payload: { data, formId },
    }),
  );
};

export const updateTimeslot = (id, data, formId) => dispatch => {
  return Promise.resolve(dispatch(startSubmit(formId))).then(() =>
    dispatch({
      types: [
        UPDATE_TIMESLOT_REQUEST,
        UPDATE_TIMESLOT_SUCCESS,
        UPDATE_TIMESLOT_ERROR,
      ],
      callAPI: () => ApiClient.put(`service/Timeslots/${id}`, data),
      payload: { data: { ...data, id }, formId },
    }),
  );
};

export const deleteTimeslot = id => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [
        DELETE_TIMESLOT_REQUEST,
        DELETE_TIMESLOT_SUCCESS,
        DELETE_TIMESLOT_ERROR,
      ],
      callAPI: () => ApiClient.delete(`service/Timeslots/${id}`),
      payload: { data: { id } },
    }),
  );
};

const initialState = {
  payload: {},
  data: {},
  response: {},
  error: {},
  errorMessage: '',
  successMessage: '',
};

export default function reducer(state = initialState, action) {
  const { payload, response, error, successMessage, errorMessage } = action;
  switch (action.type) {
    case FETCH_TIMESLOTS_REQUEST:
    case FETCH_TIMESLOT_OPTIONS_REQUEST:
    case GET_TIMESLOT_DETAIL_REQUEST:
    case CREATE_TIMESLOT_REQUEST:
    case UPDATE_TIMESLOT_REQUEST:
    case DELETE_TIMESLOT_REQUEST:
      return {
        ...state,
        payload,
        response: {},
        error: {},
        successMessage: '',
        errorMessage: '',
      };

    case FETCH_TIMESLOT_OPTIONS_SUCCESS:
      return {
        ...state,
        data: { ...state.data, timeslotOptions: response.data.data },
        response,
        successMessage,
      };

    case FETCH_TIMESLOTS_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          items: response.data.data.items,
          pages: response.data.data.pageCount,
        },
        response,
        successMessage,
      };

    case GET_TIMESLOT_DETAIL_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          item: response.data.data,
        },
        response,
        successMessage,
      };

    case CREATE_TIMESLOT_SUCCESS:
    case UPDATE_TIMESLOT_SUCCESS:
    case DELETE_TIMESLOT_SUCCESS:
      return {
        ...state,
        response,
        successMessage,
      };

    case FETCH_TIMESLOTS_ERROR:
    case FETCH_TIMESLOT_OPTIONS_ERROR:
    case GET_TIMESLOT_DETAIL_ERROR:
    case CREATE_TIMESLOT_ERROR:
    case UPDATE_TIMESLOT_ERROR:
    case DELETE_TIMESLOT_ERROR:
      return {
        ...state,
        payload,
        error,
        errorMessage,
      };
    default:
      return state;
  }
}
