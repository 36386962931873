import React from 'react';
import PropTypes from 'utils/propTypes';
import PageSpinner from './PageSpinner';
import bn from 'utils/bemnames';

import { Breadcrumb, BreadcrumbItem } from 'reactstrap';

import Typography from './Typography';
import { Link } from 'react-router-dom';
import OverlayLoader from './Controls/OverlayLoader';

const bem = bn.create('page');

const Page = ({ title, breadcrumbs, tag: Tag, className, children, loading, ...restProps }) => {
  const classes = bem.b('p-4', className);

  return (
    <OverlayLoader active={loading}>
      <Tag className={classes} {...restProps}>
        <div className={bem.e('header')}>
          {title && typeof title === 'string' ? (
            <Typography type="h1" className={bem.e('title')}>
              {title}
            </Typography>
          ) : (
            title
          )}
          {breadcrumbs && (
            <Breadcrumb className={bem.e('breadcrumb')}>
              <BreadcrumbItem>
                <Link to="/">Home</Link>
              </BreadcrumbItem>
              {breadcrumbs.length &&
                breadcrumbs.map(({ name, active, link }, index) => (
                  <BreadcrumbItem key={index} active={active}>
                    {link && <Link to={link}>{name}</Link>}
                    {!link && name}
                  </BreadcrumbItem>
                ))}
            </Breadcrumb>
          )}
        </div>
        {children}
      </Tag>
    </OverlayLoader>
  );
};

Page.propTypes = {
  tag: PropTypes.component,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  className: PropTypes.string,
  children: PropTypes.node,
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      active: PropTypes.bool,
    }),
  ),
};

Page.defaultProps = {
  tag: 'div',
  title: '',
};

export default Page;
