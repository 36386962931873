import ApiClient from '../../../helpers/apiClient';
import { startSubmit } from 'redux-form';

export const FETCH_MOVEMENT_JOURNAL_REQUEST = 'FETCH_MOVEMENT_JOURNAL_REQUEST';
export const FETCH_MOVEMENT_JOURNAL_SUCCESS = 'FETCH_MOVEMENT_JOURNAL_SUCCESS';
export const FETCH_MOVEMENT_JOURNAL_ERROR = 'FETCH_MOVEMENT_JOURNAL_ERROR';

export const GET_MOVEMENT_JOURNAL_DETAIL_REQUEST = 'GET_MOVEMENT_JOURNAL_DETAIL_REQUEST';
export const GET_MOVEMENT_JOURNAL_DETAIL_SUCCESS = 'GET_MOVEMENT_JOURNAL_DETAIL_SUCCESS';
export const GET_MOVEMENT_JOURNAL_DETAIL_ERROR = 'GET_MOVEMENT_JOURNAL_DETAIL_ERROR';

export const CREATE_MOVEMENT_JOURNAL_REQUEST = 'CREATE_MOVEMENT_JOURNAL_REQUEST';
export const CREATE_MOVEMENT_JOURNAL_SUCCESS = 'CREATE_MOVEMENT_JOURNAL_SUCCESS';
export const CREATE_MOVEMENT_JOURNAL_ERROR = 'CREATE_MOVEMENT_JOURNAL_ERROR';

export const ADJUST_MOVEMENT_JOURNAL_REQUEST = 'UPDATE_MOVEMENT_JOURNAL_REQUEST';
export const ADJUST_MOVEMENT_JOURNAL_SUCCESS = 'UPDATE_MOVEMENT_JOURNAL_SUCCESS';
export const ADJUST_MOVEMENT_JOURNAL_ERROR = 'UPDATE_MOVEMENT_JOURNAL_ERROR';

export const fetchMovementJournals = data => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [FETCH_MOVEMENT_JOURNAL_REQUEST, FETCH_MOVEMENT_JOURNAL_SUCCESS, FETCH_MOVEMENT_JOURNAL_ERROR],
      callAPI: () => ApiClient.post('product/MovingJournal/Search', data),
      payload: { data },
    }),
  );
};

export const getMovementJournalDetail = id => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [
        GET_MOVEMENT_JOURNAL_DETAIL_REQUEST,
        GET_MOVEMENT_JOURNAL_DETAIL_SUCCESS,
        GET_MOVEMENT_JOURNAL_DETAIL_ERROR,
      ],
      callAPI: () => ApiClient.get(`product/MovingJournal/GetDetail/${id}`),
      payload: { data: { id } },
    }),
  );
};

export const createMovementJournal = (data, formId) => dispatch => {
  return Promise.resolve(dispatch(startSubmit(formId))).then(() =>
    dispatch({
      types: [CREATE_MOVEMENT_JOURNAL_REQUEST, CREATE_MOVEMENT_JOURNAL_SUCCESS, CREATE_MOVEMENT_JOURNAL_ERROR],
      callAPI: () => ApiClient.post('product/MovingJournal', data),
      payload: { data, formId },
    }),
  );
};

export const adjustMovementJournal = (id, data, formId) => dispatch => {
  return Promise.resolve(dispatch(startSubmit(formId))).then(() =>
    dispatch({
      types: [ADJUST_MOVEMENT_JOURNAL_REQUEST, ADJUST_MOVEMENT_JOURNAL_SUCCESS, ADJUST_MOVEMENT_JOURNAL_ERROR],
      callAPI: () => ApiClient.put(`product/MovingJournal/Adjust/${id}`, data),
      payload: { data: { id, ...data }, formId },
    }),
  );
};

const initialState = {
  payload: {},
  data: {},
  response: {},
  error: {},
  errorMessage: '',
  successMessage: '',
};

export default function reducer(state = initialState, action) {
  const { payload, response, error, successMessage, errorMessage } = action;
  switch (action.type) {
    case FETCH_MOVEMENT_JOURNAL_REQUEST:
    case GET_MOVEMENT_JOURNAL_DETAIL_REQUEST:
    case CREATE_MOVEMENT_JOURNAL_REQUEST:
    case ADJUST_MOVEMENT_JOURNAL_REQUEST:
      return {
        ...state,
        payload,
        response: {},
        error: {},
        successMessage: '',
        errorMessage: '',
      };

    case FETCH_MOVEMENT_JOURNAL_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          items: response.data.data.items,
          pages: response.data.data.pageCount,
        },
        response,
        successMessage,
      };

    case GET_MOVEMENT_JOURNAL_DETAIL_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          item: response.data.data,
        },
        response,
        successMessage,
      };

    case CREATE_MOVEMENT_JOURNAL_SUCCESS:
    case ADJUST_MOVEMENT_JOURNAL_SUCCESS:
      return {
        ...state,
        response,
        successMessage,
      };

    case FETCH_MOVEMENT_JOURNAL_ERROR:
    case GET_MOVEMENT_JOURNAL_DETAIL_ERROR:
    case CREATE_MOVEMENT_JOURNAL_ERROR:
    case ADJUST_MOVEMENT_JOURNAL_ERROR:
      return {
        ...state,
        payload,
        error,
        errorMessage,
      };
    default:
      return state;
  }
}
