import React from 'react';
import ValidationMessage from '../Controls/ValidationMessage';
import classnames from 'classnames';

const TextField = ({
  input,
  showLabel,
  required,
  horizontal,
  label,
  meta: { touched, error },
  description,
  ...props
}) => (
  <div className="col-12 form-group">
    <div className="row">
      {horizontal && showLabel && (
        <div className="col-sm-4 col-form-label">
          <label>
            {label}
            {required && !props.disabled && (
              <span className="mark-required">*</span>
            )}
          </label>
        </div>
      )}
      {horizontal && (
        <div className="col-sm-8">
          <input
            className={classnames('form-control', {
              'textbox-error': error && touched,
            })}
            {...input}
            {...props}
          />
          {description && (
            <div className="field-description">{description}</div>
          )}
          <ValidationMessage label={label} touched={touched} error={error} />
        </div>
      )}

      {!horizontal && showLabel && (
        <label>
          {label}
          {required && !props.disabled && (
            <span className="mark-required">*</span>
          )}{' '}
        </label>
      )}
      {!horizontal && (
        <input
          className={classnames('form-control', {
            'textbox-error': error && touched,
          })}
          {...input}
          {...props}
        />
      )}
      {!horizontal && description && (
        <div className="field-description">{description}</div>
      )}
      {!horizontal && (
        <ValidationMessage label={label} touched={touched} error={error} />
      )}
    </div>
  </div>
);

export default TextField;
