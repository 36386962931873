import ApiClient from '../../../helpers/apiClient';
import { startSubmit } from 'redux-form';

// --- Customer Group ---------------
export const FETCH_CUSTOMER_GROUP_OPTIONS_REQUEST = 'FETCH_CUSTOMER_GROUP_OPTIONS_REQUEST';
export const FETCH_CUSTOMER_GROUP_OPTIONS_SUCCESS = 'FETCH_CUSTOMER_GROUP_OPTIONS_SUCCESS';
export const FETCH_CUSTOMER_GROUP_OPTIONS_ERROR = 'FETCH_CUSTOMER_GROUP_OPTIONS_ERROR';

export const FETCH_CUSTOMER_GROUPS_REQUEST = 'FETCH_CUSTOMER_GROUPS_REQUEST';
export const FETCH_CUSTOMER_GROUPS_SUCCESS = 'FETCH_CUSTOMER_GROUPS_SUCCESS';
export const FETCH_CUSTOMER_GROUPS_ERROR = 'FETCH_CUSTOMER_GROUPS_ERROR';

export const FETCH_CONSUMER_GROUPS_REQUEST = 'FETCH_CONSUMER_GROUPS_REQUEST';
export const FETCH_CONSUMER_GROUPS_SUCCESS = 'FETCH_CONSUMER_GROUPS_SUCCESS';
export const FETCH_CONSUMER_GROUPS_ERROR = 'FETCH_CONSUMER_GROUPS_ERROR';

export const GET_CUSTOMER_GROUP_DETAIL_REQUEST = 'GET_CUSTOMER_GROUP_DETAIL_REQUEST';
export const GET_CUSTOMER_GROUP_DETAIL_SUCCESS = 'GET_CUSTOMER_GROUP_DETAIL_SUCCESS';
export const GET_CUSTOMER_GROUP_DETAIL_ERROR = 'GET_CUSTOMER_GROUP_DETAIL_ERROR';

export const CREATE_CUSTOMER_GROUP_REQUEST = 'CREATE_CUSTOMER_GROUP_REQUEST';
export const CREATE_CUSTOMER_GROUP_SUCCESS = 'CREATE_CUSTOMER_GROUP_SUCCESS';
export const CREATE_CUSTOMER_GROUP_ERROR = 'CREATE_CUSTOMER_GROUP_ERROR';

export const UPDATE_CUSTOMER_GROUP_REQUEST = 'UPDATE_CUSTOMER_GROUP_REQUEST';
export const UPDATE_CUSTOMER_GROUP_SUCCESS = 'UPDATE_CUSTOMER_GROUP_SUCCESS';
export const UPDATE_CUSTOMER_GROUP_ERROR = 'UPDATE_CUSTOMER_GROUP_ERROR';

export const DELETE_CUSTOMER_GROUP_REQUEST = 'DELETE_CUSTOMER_GROUP_REQUEST';
export const DELETE_CUSTOMER_GROUP_SUCCESS = 'DELETE_CUSTOMER_GROUP_SUCCESS';
export const DELETE_CUSTOMER_GROUP_ERROR = 'DELETE_CUSTOMER_GROUP_ERROR';

// --- Customer --------------------------
export const FETCH_CUSTOMERS_REQUEST = 'FETCH_CUSTOMERS_REQUEST';
export const FETCH_CUSTOMERS_SUCCESS = 'FETCH_CUSTOMERS_SUCCESS';
export const FETCH_CUSTOMERS_ERROR = 'FETCH_CUSTOMERS_ERROR';

export const FETCH_CUSTOMER_OPTIONS_REQUEST = 'FETCH_CUSTOMER_OPTIONS_REQUEST';
export const FETCH_CUSTOMER_OPTIONS_SUCCESS = 'FETCH_CUSTOMER_OPTIONS_SUCCESS';
export const FETCH_CUSTOMER_OPTIONS_ERROR = 'FETCH_CUSTOMER_OPTIONS_ERROR';

export const FETCH_CONSUMER_GROUPS_OPTIONS_REQUEST = 'FETCH_CONSUMER_GROUPS_OPTIONS_REQUEST';
export const FETCH_CONSUMER_GROUPS_OPTIONS_SUCCESS = 'FETCH_CONSUMER_GROUPS_OPTIONS_SUCCESS';
export const FETCH_CONSUMER_GROUPS_OPTIONS_ERROR = 'FETCH_CONSUMER_GROUPS_OPTIONS_ERROR';

export const FETCH_CUSTOMER_BY_ENTITY_REQUEST = 'FETCH_CUSTOMER_BY_ENTITY_REQUEST';
export const FETCH_CUSTOMER_BY_ENTITY_SUCCESS = 'FETCH_CUSTOMER_BY_ENTITY_SUCCESS';
export const FETCH_CUSTOMER_BY_ENTITY_ERROR = 'FETCH_CUSTOMER_BY_ENTITY_ERROR';

export const GET_CUSTOMER_DETAIL_REQUEST = 'GET_CUSTOMER_DETAIL_REQUEST';
export const GET_CUSTOMER_DETAIL_SUCCESS = 'GET_CUSTOMER_DETAIL_SUCCESS';
export const GET_CUSTOMER_DETAIL_ERROR = 'GET_CUSTOMER_DETAIL_ERROR';

export const CREATE_CUSTOMER_REQUEST = 'CREATE_CUSTOMER_REQUEST';
export const CREATE_CUSTOMER_SUCCESS = 'CREATE_CUSTOMER_SUCCESS';
export const CREATE_CUSTOMER_ERROR = 'CREATE_CUSTOMER_ERROR';

export const CREATE_BY_CUSTOMER_REQUEST = 'CREATE_BY_CUSTOMER_REQUEST';
export const CREATE_BY_CUSTOMER_SUCCESS = 'CREATE_BY_CUSTOMER_SUCCESS';
export const CREATE_BY_CUSTOMER_ERROR = 'CREATE_BY_CUSTOMER_ERROR';

export const UPDATE_CUSTOMER_REQUEST = 'UPDATE_CUSTOMER_REQUEST';
export const UPDATE_CUSTOMER_SUCCESS = 'UPDATE_CUSTOMER_SUCCESS';
export const UPDATE_CUSTOMER_ERROR = 'UPDATE_CUSTOMER_ERROR';

export const DELETE_CUSTOMER_REQUEST = 'DELETE_CUSTOMER_REQUEST';
export const DELETE_CUSTOMER_SUCCESS = 'DELETE_CUSTOMER_SUCCESS';
export const DELETE_CUSTOMER_ERROR = 'DELETE_CUSTOMER_ERROR';

// --- Customer Group ---------
export const fetchCustomerGroups = data => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [FETCH_CUSTOMER_GROUPS_REQUEST, FETCH_CUSTOMER_GROUPS_SUCCESS, FETCH_CUSTOMER_GROUPS_ERROR],
      callAPI: () => ApiClient.post('customer/CustomerGroups/Search', data),
      payload: { data },
    }),
  );
};

export const fetchConsumerGroupOptions = () => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [
        FETCH_CUSTOMER_GROUP_OPTIONS_REQUEST,
        FETCH_CONSUMER_GROUPS_OPTIONS_SUCCESS,
        FETCH_CUSTOMER_GROUP_OPTIONS_ERROR,
      ],
      callAPI: () => ApiClient.get('customer/ConsumerGroups/GetSelectList'),
      payload: {},
    }),
  );
};

export const fetchCustomerGroupOptions = () => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [
        FETCH_CUSTOMER_GROUP_OPTIONS_REQUEST,
        FETCH_CUSTOMER_GROUP_OPTIONS_SUCCESS,
        FETCH_CUSTOMER_GROUP_OPTIONS_ERROR,
      ],
      callAPI: () => ApiClient.get('customer/CustomerGroups/GetSelectList'),
      payload: {},
    }),
  );
};

export const getCustomerGroupDetail = id => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [GET_CUSTOMER_GROUP_DETAIL_REQUEST, GET_CUSTOMER_GROUP_DETAIL_SUCCESS, GET_CUSTOMER_GROUP_DETAIL_ERROR],
      callAPI: () => ApiClient.get(`customer/CustomerGroups/GetDetail/${id}`),
      payload: { data: { id } },
    }),
  );
};

export const createCustomerGroup = (data, formId) => dispatch => {
  return Promise.resolve(dispatch(startSubmit(formId))).then(() =>
    dispatch({
      types: [CREATE_CUSTOMER_GROUP_REQUEST, CREATE_CUSTOMER_GROUP_SUCCESS, CREATE_CUSTOMER_GROUP_ERROR],
      callAPI: () => ApiClient.post('customer/CustomerGroups', data),
      payload: { data, formId },
    }),
  );
};

export const updateCustomerGroup = (id, data, formId) => dispatch => {
  return Promise.resolve(dispatch(startSubmit(formId))).then(() =>
    dispatch({
      types: [UPDATE_CUSTOMER_GROUP_REQUEST, UPDATE_CUSTOMER_GROUP_SUCCESS, UPDATE_CUSTOMER_GROUP_ERROR],
      callAPI: () => ApiClient.put(`customer/CustomerGroups/${id}`, data),
      payload: { data: { ...data, id }, formId },
    }),
  );
};

export const deleteCustomerGroup = id => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [DELETE_CUSTOMER_GROUP_REQUEST, DELETE_CUSTOMER_GROUP_SUCCESS, DELETE_CUSTOMER_GROUP_ERROR],
      callAPI: () => ApiClient.delete(`customer/CustomerGroups/${id}`),
      payload: { data: { id } },
    }),
  );
};

// --- Customer ---------------
export const fetchCustomers = data => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [FETCH_CUSTOMERS_REQUEST, FETCH_CUSTOMERS_SUCCESS, FETCH_CUSTOMERS_ERROR],
      callAPI: () => ApiClient.post('customer/Customers/Search', data),
      payload: { data },
    }),
  );
};

export const fetchCustomerOptions = () => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [FETCH_CUSTOMER_OPTIONS_REQUEST, FETCH_CUSTOMER_OPTIONS_SUCCESS, FETCH_CUSTOMER_OPTIONS_ERROR],
      callAPI: () => ApiClient.get('customer/Customers/GetSelectList'),
      payload: {},
    }),
  );
};

export const fetchCustomerByEntity = () => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [FETCH_CUSTOMER_BY_ENTITY_REQUEST, FETCH_CUSTOMER_BY_ENTITY_SUCCESS, FETCH_CUSTOMER_BY_ENTITY_ERROR],
      callAPI: () => ApiClient.get('customer/Customers/GetByEntity'),
      payload: {},
    }),
  );
};

export const getCustomerDetail = id => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [GET_CUSTOMER_DETAIL_REQUEST, GET_CUSTOMER_DETAIL_SUCCESS, GET_CUSTOMER_DETAIL_ERROR],
      callAPI: () => ApiClient.get(`customer/Customers/GetDetail/${id}`),
      payload: { data: { id } },
    }),
  );
};

export const createCustomer = (data, formId) => dispatch => {
  return Promise.resolve(dispatch(startSubmit(formId))).then(() =>
    dispatch({
      types: [CREATE_CUSTOMER_REQUEST, CREATE_CUSTOMER_SUCCESS, CREATE_CUSTOMER_ERROR],
      callAPI: () => ApiClient.post('customer/Customers', data),
      payload: { data, formId },
    }),
  );
};

export const createByInvitation = (id, data, formId) => dispatch => {
  return Promise.resolve(dispatch(startSubmit(formId))).then(() =>
    dispatch({
      types: [CREATE_BY_CUSTOMER_REQUEST, CREATE_BY_CUSTOMER_SUCCESS, CREATE_BY_CUSTOMER_ERROR],
      callAPI: () => ApiClient.post('customer/Customers/CreateByInvitation/' + id, data),
      payload: { data, formId },
    }),
  );
};

export const updateCustomer = (id, data, formId) => dispatch => {
  return Promise.resolve(dispatch(startSubmit(formId))).then(() =>
    dispatch({
      types: [UPDATE_CUSTOMER_REQUEST, UPDATE_CUSTOMER_SUCCESS, UPDATE_CUSTOMER_ERROR],
      callAPI: () => ApiClient.put(`customer/Customers/${id}`, data),
      payload: { data: { ...data, id }, formId },
    }),
  );
};

export const deleteCustomer = id => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [DELETE_CUSTOMER_REQUEST, DELETE_CUSTOMER_SUCCESS, DELETE_CUSTOMER_ERROR],
      callAPI: () => ApiClient.delete(`customer/Customers/${id}`),
      payload: { data: { id } },
    }),
  );
};

const initialState = {
  payload: {},
  data: {},
  response: {},
  error: {},
  errorMessage: '',
  successMessage: '',
};

export default function reducer(state = initialState, action) {
  const { payload, response, error, successMessage, errorMessage } = action;
  switch (action.type) {
    // Customer Group
    case FETCH_CUSTOMER_GROUP_OPTIONS_REQUEST:
    case FETCH_CUSTOMER_GROUPS_REQUEST:
    case GET_CUSTOMER_GROUP_DETAIL_REQUEST:
    case CREATE_CUSTOMER_GROUP_REQUEST:
    case UPDATE_CUSTOMER_GROUP_REQUEST:
    case DELETE_CUSTOMER_GROUP_REQUEST:
    // Customer
    case FETCH_CUSTOMERS_REQUEST:
    case FETCH_CUSTOMER_OPTIONS_REQUEST:
    case FETCH_CUSTOMER_BY_ENTITY_REQUEST:
    case GET_CUSTOMER_DETAIL_REQUEST:
    case CREATE_CUSTOMER_REQUEST:
    case CREATE_BY_CUSTOMER_REQUEST:
    case UPDATE_CUSTOMER_REQUEST:
    case DELETE_CUSTOMER_REQUEST:
      return {
        ...state,
        payload,
        response: {},
        error: {},
        successMessage: '',
        errorMessage: '',
      };

    case FETCH_CUSTOMER_GROUP_OPTIONS_SUCCESS:
      return {
        ...state,
        data: { ...state.data, customerGroupOptions: response.data.data },
        response,
        successMessage,
      };

    case FETCH_CONSUMER_GROUPS_OPTIONS_SUCCESS:
      return {
        ...state,
        data: { ...state.data, consumerGroupOptions: response.data.data },
        response,
        successMessage,
      };


    case FETCH_CUSTOMER_OPTIONS_SUCCESS:
      return {
        ...state,
        data: { ...state.data, customerOptions: response.data.data },
        response,
        successMessage,
      };
    case FETCH_CUSTOMER_BY_ENTITY_SUCCESS:
      return {
        ...state,
        data: { ...state.data, userCustomers: response.data.data },
        response,
        successMessage,
      };

    case FETCH_CUSTOMER_GROUPS_SUCCESS:
    case FETCH_CUSTOMERS_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          items: response.data.data.items,
          pages: response.data.data.pageCount,
        },
        response,
        successMessage,
      };
    case GET_CUSTOMER_GROUP_DETAIL_SUCCESS:
    case GET_CUSTOMER_DETAIL_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          item: response.data.data,
        },
        response,
        successMessage,
      };

    case CREATE_CUSTOMER_GROUP_SUCCESS:
    case UPDATE_CUSTOMER_GROUP_SUCCESS:
    case DELETE_CUSTOMER_GROUP_SUCCESS:
    case CREATE_CUSTOMER_SUCCESS:
    case CREATE_BY_CUSTOMER_SUCCESS:
    case UPDATE_CUSTOMER_SUCCESS:
    case DELETE_CUSTOMER_SUCCESS:
      return {
        ...state,
        response,
        successMessage,
      };

    // Customer Group
    case FETCH_CUSTOMER_GROUP_OPTIONS_ERROR:
    case FETCH_CUSTOMER_GROUPS_ERROR:
    case GET_CUSTOMER_GROUP_DETAIL_ERROR:
    case CREATE_CUSTOMER_GROUP_ERROR:
    case UPDATE_CUSTOMER_GROUP_ERROR:
    case DELETE_CUSTOMER_GROUP_ERROR:

    // Customer
    case FETCH_CUSTOMERS_ERROR:
    case FETCH_CUSTOMER_OPTIONS_ERROR:
    case FETCH_CUSTOMER_BY_ENTITY_ERROR:
    case GET_CUSTOMER_DETAIL_ERROR:
    case CREATE_CUSTOMER_ERROR:
    case CREATE_BY_CUSTOMER_ERROR:
    case UPDATE_CUSTOMER_ERROR:
    case DELETE_CUSTOMER_ERROR:
      return {
        ...state,
        payload,
        error,
        errorMessage,
      };
    default:
      return state;
  }
}
