import ApiClient from '../../../helpers/apiClient';
import { startSubmit } from 'redux-form';
import axios from 'axios';
export const FETCH_PURCHASE_ORDERS_REQUEST = 'FETCH_PURCHASE_ORDERS_REQUEST';
export const FETCH_PURCHASE_ORDERS_SUCCESS = 'FETCH_PURCHASE_ORDERS_SUCCESS';
export const FETCH_PURCHASE_ORDERS_ERROR = 'FETCH_PURCHASE_ORDERS_ERROR';

export const FETCH_RECEIVED_ORDERS_REQUEST = 'FETCH_RECEIVED_ORDERS_REQUEST';
export const FETCH_RECEIVED_ORDERS_SUCCESS = 'FETCH_RECEIVED_ORDERS_SUCCESS';
export const FETCH_RECEIVED_ORDERS_ERROR = 'FETCH_RECEIVED_ORDERS_ERROR';

export const GET_PURCHASE_ORDER_DETAIL_REQUEST = 'GET_PURCHASE_ORDER_DETAIL_REQUEST';
export const GET_PURCHASE_ORDER_DETAIL_SUCCESS = 'GET_PURCHASE_ORDER_DETAIL_SUCCESS';
export const GET_PURCHASE_ORDER_DETAIL_ERROR = 'GET_PURCHASE_ORDER_DETAIL_ERROR';

export const GET_RECEIVED_ORDER_DETAIL_REQUEST = 'GET_RECEIVED_ORDER_DETAIL_REQUEST';
export const GET_RECEIVED_ORDER_DETAIL_SUCCESS = 'GET_RECEIVED_ORDER_DETAIL_SUCCESS';
export const GET_RECEIVED_ORDER_DETAIL_ERROR = 'GET_RECEIVED_ORDER_DETAIL_ERROR';

export const CREATE_PURCHASE_ORDER_REQUEST = 'CREATE_PURCHASE_ORDER_REQUEST';
export const CREATE_PURCHASE_ORDER_SUCCESS = 'CREATE_PURCHASE_ORDER_SUCCESS';
export const CREATE_PURCHASE_ORDER_ERROR = 'CREATE_PURCHASE_ORDER_ERROR';

export const UPDATE_PURCHASE_ORDER_REQUEST = 'UPDATE_PURCHASE_ORDER_REQUEST';
export const UPDATE_PURCHASE_ORDER_SUCCESS = 'UPDATE_PURCHASE_ORDER_SUCCESS';
export const UPDATE_PURCHASE_ORDER_ERROR = 'UPDATE_PURCHASE_ORDER_ERROR';

export const DELETE_PURCHASE_ORDER_REQUEST = 'DELETE_PURCHASE_ORDER_REQUEST';
export const DELETE_PURCHASE_ORDER_SUCCESS = 'DELETE_PURCHASE_ORDER_SUCCESS';
export const DELETE_PURCHASE_ORDER_ERROR = 'DELETE_PURCHASE_ORDER_ERROR';

export const GENERATE_SUMMARY_PO_PDF_REQUEST = 'GENERATE_SUMMARY_PO_PDF_REQUEST';
export const GENERATE_SUMMARY_PO_PDF_SUCCESS = 'GENERATE_SUMMARY_PO_PDF_SUCCESS';
export const GENERATE_PO_SUMMARY_PDF_ERROR = 'GENERATE_PO_SUMMARY_PDF_ERROR';

export const fetchPurchaseOrders = data => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [FETCH_PURCHASE_ORDERS_REQUEST, FETCH_PURCHASE_ORDERS_SUCCESS, FETCH_PURCHASE_ORDERS_ERROR],
      callAPI: () => ApiClient.post('network/PurchaseOrders/Search', data),
      payload: { data },
    }),
  );
};

export const generateSummaryPdf = (startDate, endDate) => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [
        GENERATE_SUMMARY_PO_PDF_REQUEST,
        GENERATE_SUMMARY_PO_PDF_SUCCESS,
        GENERATE_PO_SUMMARY_PDF_ERROR
      ],
      callAPI: () => ApiClient.post(`network/PurchaseOrders/GenerateSummaryPDF/${startDate}/${endDate}`),
      payload: { data: { startDate, endDate } },
    }),
  );
};

export const fetchReceivedOrders = data => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [FETCH_RECEIVED_ORDERS_REQUEST, FETCH_RECEIVED_ORDERS_SUCCESS, FETCH_RECEIVED_ORDERS_ERROR],
      callAPI: () => ApiClient.post('network/PurchaseOrders/SearchReceivedOrders', data),
      payload: { data },
    }),
  );
};

export const getPurchaseOrderDetail = id => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [GET_PURCHASE_ORDER_DETAIL_REQUEST, GET_PURCHASE_ORDER_DETAIL_SUCCESS, GET_PURCHASE_ORDER_DETAIL_ERROR],
      callAPI: () => ApiClient.get(`network/PurchaseOrders/GetDetail/${id}`),
      payload: { data: { id } },
    }),
  );
};

export const getReceivedOrderDetail = id => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [GET_RECEIVED_ORDER_DETAIL_REQUEST, GET_RECEIVED_ORDER_DETAIL_SUCCESS, GET_RECEIVED_ORDER_DETAIL_ERROR],
      callAPI: () => ApiClient.get(`network/PurchaseOrders/GetReceivedOrderDetail/${id}`),
      payload: { data: { id } },
    }),
  );
};

export const createPurchaseOrder = (data, formId) => dispatch => {
  return Promise.resolve(dispatch(startSubmit(formId))).then(() =>
    dispatch({
      types: [CREATE_PURCHASE_ORDER_REQUEST, CREATE_PURCHASE_ORDER_SUCCESS, CREATE_PURCHASE_ORDER_ERROR],
      callAPI: () => ApiClient.post('network/PurchaseOrders', data),
      payload: { data, formId },
    }),
  );
};

export const updatePurchaseOrder = (id, data, formId) => dispatch => {
  console.log(data)
  return Promise.resolve(dispatch(startSubmit(formId))).then(() =>
    dispatch({
      types: [UPDATE_PURCHASE_ORDER_REQUEST, UPDATE_PURCHASE_ORDER_SUCCESS, UPDATE_PURCHASE_ORDER_ERROR],
      callAPI: () => ApiClient.put(`network/PurchaseOrders/${id}`, data),
      payload: { data: { ...data, id }, formId },
    }),
  );
};

export const deletePurchaseOrder = id => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [DELETE_PURCHASE_ORDER_REQUEST, DELETE_PURCHASE_ORDER_SUCCESS, DELETE_PURCHASE_ORDER_ERROR],
      callAPI: () => ApiClient.delete(`network/PurchaseOrders/${id}`),
      payload: { data: { id } },
    }),
  );
};


const initialState = {
  payload: {},
  data: {},
  response: {},
  error: {},
  errorMessage: '',
  successMessage: '',
};

export default function reducer(state = initialState, action) {
  const { payload, response, error, successMessage, errorMessage } = action;
  switch (action.type) {
    case FETCH_PURCHASE_ORDERS_REQUEST:
    case GET_PURCHASE_ORDER_DETAIL_REQUEST:
    case CREATE_PURCHASE_ORDER_REQUEST:
    case UPDATE_PURCHASE_ORDER_REQUEST:
    case DELETE_PURCHASE_ORDER_REQUEST:
    case FETCH_RECEIVED_ORDERS_REQUEST:
    case GET_RECEIVED_ORDER_DETAIL_REQUEST:
      return {
        ...state,
        payload,
        response: {},
        error: {},
        successMessage: '',
        errorMessage: '',
      };

    case FETCH_PURCHASE_ORDERS_SUCCESS:
    case FETCH_RECEIVED_ORDERS_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          items: response.data.data.items,
          pages: response.data.data.pageCount,
        },
        response,
        successMessage,
      };
    case GET_PURCHASE_ORDER_DETAIL_SUCCESS:
    case GET_RECEIVED_ORDER_DETAIL_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          item: response.data.data,
        },
        response,
        successMessage,
      };

    case CREATE_PURCHASE_ORDER_SUCCESS:
    case UPDATE_PURCHASE_ORDER_SUCCESS:
    case DELETE_PURCHASE_ORDER_SUCCESS:
      return {
        ...state,
        response,
        successMessage,
      };

    case FETCH_PURCHASE_ORDERS_ERROR:
    case GET_PURCHASE_ORDER_DETAIL_ERROR:
    case CREATE_PURCHASE_ORDER_ERROR:
    case UPDATE_PURCHASE_ORDER_ERROR:
    case DELETE_PURCHASE_ORDER_ERROR:
    case FETCH_RECEIVED_ORDERS_ERROR:
    case GET_RECEIVED_ORDER_DETAIL_ERROR:
      return {
        ...state,
        payload,
        error,
        errorMessage,
      };
    default:
      return state;
  }
}
