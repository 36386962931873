import React from 'react';
import { Spinner, Button } from 'reactstrap';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const CButton = ({ children, loading, type, className, style, ...props }) => {
  let buttonClass = classNames(className, 'cbutton-base');

  return loading ? (
    <Button {...props} type="button" disabled className={buttonClass} style={style} >
      <Spinner size={props.size} />
    </Button>
  ) : (
    <Button {...props} type={type} className={buttonClass} style={style}>
      {children}
    </Button>
  );
};

CButton.prototype = {
  loading: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

export default CButton;
