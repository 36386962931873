import ApiClient from '../../../helpers/apiClient';
import { startSubmit } from 'redux-form';

// --Tax Code----------------------
export const FETCH_PURCHASE_SETUPS_REQUEST = 'FETCH_PURCHASE_SETUPS_REQUEST';
export const FETCH_PURCHASE_SETUPS_SUCCESS = 'FETCH_PURCHASE_SETUPS_SUCCESS';
export const FETCH_PURCHASE_SETUPS_ERROR = 'FETCH_PURCHASE_SETUPS_ERROR';

export const GET_PURCHASE_SETUP_DETAIL_REQUEST =
  'GET_PURCHASE_SETUP_DETAIL_REQUEST';
export const GET_PURCHASE_SETUP_DETAIL_SUCCESS =
  'GET_PURCHASE_SETUP_DETAIL_SUCCESS';
export const GET_PURCHASE_SETUP_DETAIL_ERROR =
  'GET_PURCHASE_SETUP_DETAIL_ERROR';

export const UPDATE_PURCHASE_SETUP_REQUEST = 'UPDATE_PURCHASE_SETUP_REQUEST';
export const UPDATE_PURCHASE_SETUP_SUCCESS = 'UPDATE_PURCHASE_SETUP_SUCCESS';
export const UPDATE_PURCHASE_SETUP_ERROR = 'UPDATE_PURCHASE_SETUP_ERROR';

export const fetchPurchaseSetups = () => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [
        FETCH_PURCHASE_SETUPS_REQUEST,
        FETCH_PURCHASE_SETUPS_SUCCESS,
        FETCH_PURCHASE_SETUPS_ERROR,
      ],
      callAPI: () => ApiClient.get('network/PurchaseSetups'),
      payload: {},
    }),
  );
};

export const getPurchaseSetupDetail = id => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [
        GET_PURCHASE_SETUP_DETAIL_REQUEST,
        GET_PURCHASE_SETUP_DETAIL_SUCCESS,
        GET_PURCHASE_SETUP_DETAIL_ERROR,
      ],
      callAPI: () => ApiClient.get(`network/PurchaseSetups/GetDetail/${id}`),
      payload: { data: { id } },
    }),
  );
};

export const updatePurchaseSetup = (id, data, formId) => dispatch => {
  return Promise.resolve(dispatch(startSubmit(formId))).then(() =>
    dispatch({
      types: [
        UPDATE_PURCHASE_SETUP_REQUEST,
        UPDATE_PURCHASE_SETUP_SUCCESS,
        UPDATE_PURCHASE_SETUP_ERROR,
      ],
      callAPI: () => ApiClient.put(`network/PurchaseSetups/${id}`, data),
      payload: { data: { ...data, id }, formId },
    }),
  );
};

const initialState = {
  payload: {},
  data: {},
  response: {},
  error: {},
  errorMessage: '',
  successMessage: '',
};

export default function reducer(state = initialState, action) {
  const { payload, response, error, successMessage, errorMessage } = action;
  switch (action.type) {
    // Tax Code
    case FETCH_PURCHASE_SETUPS_REQUEST:
    case GET_PURCHASE_SETUP_DETAIL_REQUEST:
    case UPDATE_PURCHASE_SETUP_REQUEST:
      return {
        ...state,
        payload,
        response: {},
        error: {},
        successMessage: '',
        errorMessage: '',
      };

    case FETCH_PURCHASE_SETUPS_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          items: response.data.data,
        },
        response,
        successMessage,
      };

    case GET_PURCHASE_SETUP_DETAIL_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          item: response.data.data,
        },
        response,
        successMessage,
      };

    case UPDATE_PURCHASE_SETUP_SUCCESS:
      return {
        ...state,
        response,
        successMessage,
      };

    // Tax Code
    case FETCH_PURCHASE_SETUPS_ERROR:
    case GET_PURCHASE_SETUP_DETAIL_ERROR:
    case UPDATE_PURCHASE_SETUP_ERROR:
      return {
        ...state,
        payload,
        error,
        errorMessage,
      };
    default:
      return state;
  }
}
