import React from 'react';
import { Input, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import SearchIcon from 'assets/img/icons/ic_search.svg';
import ValidationMessage from '../Controls/ValidationMessage';
import cx from 'classnames';
import 'styles/css/SearchBar.css';

const SearchBar = ({ input, className, id, label, onSearchChange, style, ...props }) => {
  const classes = cx('search-group', className);

  return (
    <InputGroup className={classes} style={style}>
      <InputGroupAddon addonType="prepend">
        <InputGroupText className="search-input-icon">
          <img className="icon" src={SearchIcon} alt={label} />
        </InputGroupText>
      </InputGroupAddon>
      <Input
        className="search-input"
        id={id}
        placeholder={label}
        value={input}
        onChange={e => onSearchChange(e.target)}
        {...props}
      />
    </InputGroup>
  );
};

export default SearchBar;
