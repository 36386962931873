import ApiClient from '../../../helpers/apiClient';
import { startSubmit } from 'redux-form';

export const FETCH_DIMENSION_SETUP_REQUEST = 'FETCH_DIMENSION_SETUP_REQUEST';
export const FETCH_DIMENSION_SETUP_SUCCESS = 'FETCH_DIMENSION_SETUP_SUCCESS';
export const FETCH_DIMENSION_SETUP_ERROR = 'FETCH_DIMENSION_SETUP_ERROR';

export const FETCH_DIMENSION_SETUP_OPTIONS_REQUEST =
  'FETCH_DIMENSION_SETUP_OPTIONS_REQUEST';
export const FETCH_DIMENSION_SETUP_OPTIONS_SUCCESS =
  'FETCH_DIMENSION_SETUP_OPTIONS_SUCCESS';
export const FETCH_DIMENSION_SETUP_OPTIONS_ERROR =
  'FETCH_DIMENSION_SETUP_OPTIONS_ERROR';

export const GET_DIMENSION_SETUP_DETAIL_REQUEST =
  'GET_DIMENSION_SETUP_DETAIL_REQUEST';
export const GET_DIMENSION_SETUP_DETAIL_SUCCESS =
  'GET_DIMENSION_SETUP_DETAIL_SUCCESS';
export const GET_DIMENSION_SETUP_DETAIL_ERROR =
  'GET_DIMENSION_SETUP_DETAIL_ERROR';

export const CREATE_DIMENSION_SETUP_REQUEST = 'CREATE_DIMENSION_SETUP_REQUEST';
export const CREATE_DIMENSION_SETUP_SUCCESS = 'CREATE_DIMENSION_SETUP_SUCCESS';
export const CREATE_DIMENSION_SETUP_ERROR = 'CREATE_DIMENSION_SETUP_ERROR';

export const UPDATE_DIMENSION_SETUP_REQUEST = 'UPDATE_DIMENSION_SETUP_REQUEST';
export const UPDATE_DIMENSION_SETUP_SUCCESS = 'UPDATE_DIMENSION_SETUP_SUCCESS';
export const UPDATE_DIMENSION_SETUP_ERROR = 'UPDATE_DIMENSION_SETUP_ERROR';

export const DELETE_DIMENSION_SETUP_REQUEST = 'DELETE_DIMENSION_SETUP_REQUEST';
export const DELETE_DIMENSION_SETUP_SUCCESS = 'DELETE_DIMENSION_SETUP_SUCCESS';
export const DELETE_DIMENSION_SETUP_ERROR = 'DELETE_DIMENSION_SETUP_ERROR';

export const fetchDimensionSetup = data => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [
        FETCH_DIMENSION_SETUP_REQUEST,
        FETCH_DIMENSION_SETUP_SUCCESS,
        FETCH_DIMENSION_SETUP_ERROR,
      ],
      callAPI: () => ApiClient.post('product/DimensionSetup/Search', data),
      payload: { data },
    }),
  );
};

export const fetchDimensionSetupOptions = () => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [
        FETCH_DIMENSION_SETUP_OPTIONS_REQUEST,
        FETCH_DIMENSION_SETUP_OPTIONS_SUCCESS,
        FETCH_DIMENSION_SETUP_OPTIONS_ERROR,
      ],
      callAPI: () => ApiClient.get('product/DimensionSetup/GetSelectList'),
      payload: {},
    }),
  );
};

export const getDimensionSetupDetail = id => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [
        GET_DIMENSION_SETUP_DETAIL_REQUEST,
        GET_DIMENSION_SETUP_DETAIL_SUCCESS,
        GET_DIMENSION_SETUP_DETAIL_ERROR,
      ],
      callAPI: () => ApiClient.get(`product/DimensionSetup/${id}`),
      payload: { data: { id } },
    }),
  );
};

export const createDimensionSetup = (data, formId) => dispatch => {
  return Promise.resolve(dispatch(startSubmit(formId))).then(() =>
    dispatch({
      types: [
        CREATE_DIMENSION_SETUP_REQUEST,
        CREATE_DIMENSION_SETUP_SUCCESS,
        CREATE_DIMENSION_SETUP_ERROR,
      ],
      callAPI: () => ApiClient.post('product/DimensionSetup', data),
      payload: { data, formId },
    }),
  );
};

export const updateDimensionSetup = (id, data, formId) => dispatch => {
  return Promise.resolve(dispatch(startSubmit(formId))).then(() =>
    dispatch({
      types: [
        UPDATE_DIMENSION_SETUP_REQUEST,
        UPDATE_DIMENSION_SETUP_SUCCESS,
        UPDATE_DIMENSION_SETUP_ERROR,
      ],
      callAPI: () => ApiClient.put(`product/DimensionSetup/${id}`, data),
      payload: { data: { ...data, id }, formId },
    }),
  );
};

export const deleteDimensionSetup = id => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [
        DELETE_DIMENSION_SETUP_REQUEST,
        DELETE_DIMENSION_SETUP_SUCCESS,
        DELETE_DIMENSION_SETUP_ERROR,
      ],
      callAPI: () => ApiClient.delete(`product/DimensionSetup/${id}`),
      payload: { data: { id } },
    }),
  );
};

const initialState = {
  payload: {},
  data: {},
  response: {},
  error: {},
  errorMessage: '',
  successMessage: '',
};

export default function reducer(state = initialState, action) {
  const { payload, response, error, successMessage, errorMessage } = action;
  switch (action.type) {
    case FETCH_DIMENSION_SETUP_REQUEST:
    case FETCH_DIMENSION_SETUP_OPTIONS_REQUEST:
    case GET_DIMENSION_SETUP_DETAIL_REQUEST:
    case CREATE_DIMENSION_SETUP_REQUEST:
    case UPDATE_DIMENSION_SETUP_REQUEST:
    case DELETE_DIMENSION_SETUP_REQUEST:
      return {
        ...state,
        payload,
        response: {},
        error: {},
        successMessage: '',
        errorMessage: '',
      };

    case FETCH_DIMENSION_SETUP_OPTIONS_SUCCESS:
      return {
        ...state,
        data: { ...state.data, options: response.data.data },
        response,
        successMessage,
      };

    case FETCH_DIMENSION_SETUP_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          items: response.data.data.items,
          pages: response.data.data.pageCount,
        },
        response,
        successMessage,
      };

    case GET_DIMENSION_SETUP_DETAIL_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          item: response.data.data,
        },
        response,
        successMessage,
      };

    case CREATE_DIMENSION_SETUP_SUCCESS:
    case UPDATE_DIMENSION_SETUP_SUCCESS:
    case DELETE_DIMENSION_SETUP_SUCCESS:
      return {
        ...state,
        response,
        successMessage,
      };

    case FETCH_DIMENSION_SETUP_ERROR:
    case FETCH_DIMENSION_SETUP_OPTIONS_ERROR:
    case GET_DIMENSION_SETUP_DETAIL_ERROR:
    case CREATE_DIMENSION_SETUP_ERROR:
    case UPDATE_DIMENSION_SETUP_ERROR:
    case DELETE_DIMENSION_SETUP_ERROR:
      return {
        ...state,
        payload,
        error,
        errorMessage,
      };
    default:
      return state;
  }
}
