import React from 'react';
import { connect } from 'react-redux';

// These are regular React components we will write soon
import BasicMessageModal from './BasicMessageModal';
import BasicConfirmModal from './BasicConfirmModal';

const MODAL_COMPONENTS = {
  MESSAGE: BasicMessageModal,
  CONFIRM_MESSAGE: BasicConfirmModal,
  /* other modals */
};

const ModalRoot = ({ modalType, isOpen, modalProps }) => {
  if (!modalType) {
    return null;
  }

  const SpecificModal = MODAL_COMPONENTS[modalType];
  return <SpecificModal isOpen={isOpen} {...modalProps} />;
};

export default connect(state => state.modal)(ModalRoot);
