import ApiClient from '../../../helpers/apiClient';
import { startSubmit } from 'redux-form';

export const FETCH_FREWIE_PRODUCT_COUNTRY_REQUEST = 'FETCH_FREWIE_PRODUCT_COUNTRY_REQUEST';
export const FETCH_FREWIE_PRODUCT_COUNTRY_SUCCESS = 'FETCH_FREWIE_PRODUCT_COUNTRY_SUCCESS';
export const FETCH_FREWIE_PRODUCT_COUNTRY_ERROR = 'FETCH_FREWIE_PRODUCT_COUNTRY_ERROR';

export const FETCH_FREWIE_PRODUCT_COUNTRY_OPTIONS_REQUEST =
    'FETCH_FREWIE_PRODUCT_COUNTRY_OPTIONS_REQUEST';
export const FETCH_FREWIE_PRODUCT_COUNTRY_OPTIONS_SUCCESS =
    'FETCH_FREWIE_PRODUCT_COUNTRY_OPTIONS_SUCCESS';
export const FETCH_FREWIE_PRODUCT_COUNTRY_OPTIONS_ERROR =
    'FETCH_FREWIE_PRODUCT_COUNTRY_OPTIONS_ERROR';

export const GET_FREWIE_PRODUCT_COUNTRY_DETAIL_REQUEST =
    'GET_FREWIE_PRODUCT_COUNTRY_DETAIL_REQUEST';
export const GET_FREWIE_PRODUCT_COUNTRY_DETAIL_SUCCESS =
    'GET_FREWIE_PRODUCT_COUNTRY_DETAIL_SUCCESS';
export const GET_FREWIE_PRODUCT_COUNTRY_DETAIL_ERROR = 'GET_FREWIE_PRODUCT_COUNTRY_DETAIL_ERROR';

export const CREATE_FREWIE_PRODUCT_COUNTRY_REQUEST = 'CREATE_FREWIE_PRODUCT_COUNTRY_REQUEST';
export const CREATE_FREWIE_PRODUCT_COUNTRY_SUCCESS = 'CREATE_FREWIE_PRODUCT_COUNTRY_SUCCESS';
export const CREATE_FREWIE_PRODUCT_COUNTRY_ERROR = 'CREATE_FREWIE_PRODUCT_COUNTRY_ERROR';

export const UPDATE_FREWIE_PRODUCT_COUNTRY_REQUEST = 'UPDATE_FREWIE_PRODUCT_COUNTRY_REQUEST';
export const UPDATE_FREWIE_PRODUCT_COUNTRY_SUCCESS = 'UPDATE_FREWIE_PRODUCT_COUNTRY_SUCCESS';
export const UPDATE_FREWIE_PRODUCT_COUNTRY_ERROR = 'UPDATE_FREWIE_PRODUCT_COUNTRY_ERROR';

export const DELETE_FREWIE_PRODUCT_COUNTRY_REQUEST = 'DELETE_FREWIE_PRODUCT_COUNTRY_REQUEST';
export const DELETE_FREWIE_PRODUCT_COUNTRY_SUCCESS = 'DELETE_FREWIE_PRODUCT_COUNTRY_SUCCESS';
export const DELETE_FREWIE_PRODUCT_COUNTRY_ERROR = 'DELETE_FREWIE_PRODUCT_COUNTRY_ERROR';

export const fetchFrewieProductCountryOptions = id => dispatch => {
    return Promise.resolve().then(() =>
      dispatch({
        types: [
          FETCH_FREWIE_PRODUCT_COUNTRY_OPTIONS_REQUEST,
          FETCH_FREWIE_PRODUCT_COUNTRY_OPTIONS_SUCCESS,
          FETCH_FREWIE_PRODUCT_COUNTRY_OPTIONS_ERROR,
        ],
        callAPI: () => ApiClient.get(`product/FrewieProductCountry/GetSelectList/${id}`),
        payload: { data: { id } },
      }),
    );
};

export const fetchFrewieProductOptionsByCountry = countryCode => dispatch => {
    return Promise.resolve().then(() =>
      dispatch({
        types: [
          FETCH_FREWIE_PRODUCT_COUNTRY_OPTIONS_REQUEST,
          FETCH_FREWIE_PRODUCT_COUNTRY_OPTIONS_SUCCESS,
          FETCH_FREWIE_PRODUCT_COUNTRY_OPTIONS_ERROR,
        ],
        callAPI: () => ApiClient.get(`product/FrewieProductCountry/GetProductSelectListByCountry/${countryCode}`),
        payload: { data: { countryCode } },
      }),
    );
};

const initialState = {
    payload: {},
    data: {},
    response: {},
    error: {},
    errorMessage: '',
    successMessage: '',
};

export default function reducer(state = initialState, action) {
    const { payload, response, error, successMessage, errorMessage } = action;
    switch (action.type) {
        case FETCH_FREWIE_PRODUCT_COUNTRY_REQUEST:
        case FETCH_FREWIE_PRODUCT_COUNTRY_OPTIONS_REQUEST:
        case GET_FREWIE_PRODUCT_COUNTRY_DETAIL_REQUEST:
        case CREATE_FREWIE_PRODUCT_COUNTRY_REQUEST:
        case UPDATE_FREWIE_PRODUCT_COUNTRY_REQUEST:
        case DELETE_FREWIE_PRODUCT_COUNTRY_REQUEST:
            return {
                ...state,
                payload,
                response: {},
                error: {},
                successMessage: '',
                errorMessage: '',
            };

        case FETCH_FREWIE_PRODUCT_COUNTRY_OPTIONS_SUCCESS:
            return {
                ...state,
                data: { ...state.data, options: response.data.data },
                response,
                successMessage,
            };

        case FETCH_FREWIE_PRODUCT_COUNTRY_SUCCESS:
            return {
                ...state,
                data: {
                    ...state.data,
                    items: response.data.data.items,
                    pages: response.data.data.pageCount,
                },
                response,
                successMessage,
            };

        case GET_FREWIE_PRODUCT_COUNTRY_DETAIL_SUCCESS:
            return {
                ...state,
                data: {
                    ...state.data,
                    item: response.data.data,
                },
                response,
                successMessage,
            };

        case CREATE_FREWIE_PRODUCT_COUNTRY_SUCCESS:
        case UPDATE_FREWIE_PRODUCT_COUNTRY_SUCCESS:
        case DELETE_FREWIE_PRODUCT_COUNTRY_SUCCESS:
            return {
                ...state,
                response,
                successMessage,
            };

        case FETCH_FREWIE_PRODUCT_COUNTRY_ERROR:
        case FETCH_FREWIE_PRODUCT_COUNTRY_OPTIONS_ERROR:
        case GET_FREWIE_PRODUCT_COUNTRY_DETAIL_ERROR:
        case CREATE_FREWIE_PRODUCT_COUNTRY_ERROR:
        case UPDATE_FREWIE_PRODUCT_COUNTRY_ERROR:
        case DELETE_FREWIE_PRODUCT_COUNTRY_ERROR:
            return {
                ...state,
                payload,
                error,
                errorMessage,
            };
        default:
            return state;
    }
}
