import ApiClient from '../../helpers/apiClient';

export const FETCH_LOG_REQUEST = 'FETCH_LOG_REQUEST';
export const FETCH_LOG_SUCCESS = 'FETCH_LOG_SUCCESS';
export const FETCH_LOG_ERROR = 'FETCH_LOG_ERROR';

export const GET_LOG_DETAIL_REQUEST = 'GET_LOG_DETAIL_REQUEST';
export const GET_LOG_DETAIL_SUCCESS = 'GET_LOG_DETAIL_SUCCESS';
export const GET_LOG_DETAIL_ERROR = 'GET_LOG_DETAIL_ERROR';

const initialState = {
    payload: {},
    data: {},
    response: {},
    error: {},
    errorMessage: '',
    successMessage: '',
};

export const fetchLog = values => dispatch => {

    return Promise.resolve().then(() =>
        dispatch({
            types: [
                FETCH_LOG_REQUEST,
                FETCH_LOG_SUCCESS,
                FETCH_LOG_ERROR,
            ],
            callAPI: () => ApiClient.post('admin/ApiLogs/Search', values),
            payload: values,
        }),
    );
};

export const getLogDetail = id => dispatch => {
    return Promise.resolve().then(() =>
      dispatch({
        types: [
          GET_LOG_DETAIL_REQUEST,
          GET_LOG_DETAIL_SUCCESS,
          GET_LOG_DETAIL_ERROR,
        ],
        callAPI: () => ApiClient.get(`admin/ApiLogs/${id}`),
        payload: id,
      }),
    );
  };

export default function reducer(state = initialState, action) {
    const { payload, response, error, successMessage, errorMessage } = action;
    switch (action.type) {
        case FETCH_LOG_REQUEST:
        case GET_LOG_DETAIL_REQUEST:
            return {
                ...state,
                payload,
                successMessage: '',
                errorMessage: '',
            };
        case FETCH_LOG_SUCCESS:        
            return {
                ...state,
                data: {
                    ...state.data,
                    items: response.data.data.items,
                    pages: response.data.data.pageCount,
                },
                payload,
                response,
                successMessage,
            };
        case GET_LOG_DETAIL_SUCCESS:
            return {
                ...state,
                data: {
                  ...state.data,
                  item: response.data.data,
                },
                response,
                successMessage,
              };
        case FETCH_LOG_ERROR:
        case GET_LOG_DETAIL_ERROR:
            return {
                ...state,
                payload,
                error,
                errorMessage,
            };
        default:
            return state;

    }
}