export const PermissionActions = {
  View: 'View',
  Create: 'Create',
  Edit: 'Edit',
  Delete: 'Delete',
  Upgrade: 'Upgrade',
  AcceptOrDeny: 'AcceptOrDeny',
  Complete: 'Complete',
  Cancel: 'Cancel',
  Confirm: 'Confirm',
  Adjust: 'Adjust',
  ViewReceivedOrders: 'ViewReceivedOrders',
};
