import ApiClient from '../../../helpers/apiClient';
import { startSubmit } from 'redux-form';

export const GET_DISPLAY_SETTINGS_REQUEST = 'GET_DISPLAY_SETTINGS_REQUEST';
export const GET_DISPLAY_SETTINGS_SUCCESS = 'GET_DISPLAY_SETTINGS_SUCCESS';
export const GET_DISPLAY_SETTINGS_ERROR = 'GET_DISPLAY_SETTINGS_ERROR';

export const getDisplaySettings = () => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [
        GET_DISPLAY_SETTINGS_REQUEST,
        GET_DISPLAY_SETTINGS_SUCCESS,
        GET_DISPLAY_SETTINGS_ERROR,
      ],
      callAPI: () => ApiClient.get('admin/Configuration/GetDisplaySettings'),
      payload: {},
    }),
  );
};

const initialState = {
  payload: {},
  data: {},
  response: {},
  error: {},
  errorMessage: '',
  successMessage: '',
};

export default function reducer(state = initialState, action) {
  const { payload, response, error, successMessage, errorMessage } = action;
  switch (action.type) {
    case GET_DISPLAY_SETTINGS_REQUEST:
      return {
        ...state,
        payload,
        response: {},
        error: {},
        successMessage: '',
        errorMessage: '',
      };

    case GET_DISPLAY_SETTINGS_SUCCESS:
      return {
        ...state,
        data: { ...state.data, displaySettings: response.data.data },
        response,
        successMessage,
      };

    case GET_DISPLAY_SETTINGS_ERROR:
      return {
        ...state,
        payload,
        error,
        errorMessage,
      };
    default:
      return state;
  }
}
