import ApiClient from '../../../helpers/apiClient';
import { startSubmit } from 'redux-form';

export const FETCH_BRAND_REQUEST = 'FETCH_BRAND_REQUEST';
export const FETCH_BRAND_SUCCESS = 'FETCH_BRAND_SUCCESS';
export const FETCH_BRAND_ERROR = 'FETCH_BRAND_ERROR';

export const FETCH_BRAND_OPTIONS_REQUEST = 'FETCH_BRAND_OPTIONS_REQUEST';
export const FETCH_BRAND_OPTIONS_SUCCESS = 'FETCH_BRAND_OPTIONS_SUCCESS';
export const FETCH_BRAND_OPTIONS_ERROR = 'FETCH_BRAND_OPTIONS_ERROR';

export const GET_BRAND_DETAIL_REQUEST = 'GET_BRAND_DETAIL_REQUEST';
export const GET_BRAND_DETAIL_SUCCESS = 'GET_BRAND_DETAIL_SUCCESS';
export const GET_BRAND_DETAIL_ERROR = 'GET_BRAND_DETAIL_ERROR';

export const CREATE_BRAND_REQUEST = 'CREATE_BRAND_REQUEST';
export const CREATE_BRAND_SUCCESS = 'CREATE_BRAND_SUCCESS';
export const CREATE_BRAND_ERROR = 'CREATE_BRAND_ERROR';

export const UPDATE_BRAND_REQUEST = 'UPDATE_BRAND_REQUEST';
export const UPDATE_BRAND_SUCCESS = 'UPDATE_BRAND_SUCCESS';
export const UPDATE_BRAND_ERROR = 'UPDATE_BRAND_ERROR';

export const DELETE_BRAND_REQUEST = 'DELETE_BRAND_REQUEST';
export const DELETE_BRAND_SUCCESS = 'DELETE_BRAND_SUCCESS';
export const DELETE_BRAND_ERROR = 'DELETE_BRAND_ERROR';

export const fetchBrands = data => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [FETCH_BRAND_REQUEST, FETCH_BRAND_SUCCESS, FETCH_BRAND_ERROR],
      callAPI: () => ApiClient.post('product/Brand/Search', data),
      payload: { data },
    }),
  );
};

export const fetchBrandOptions = () => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [
        FETCH_BRAND_OPTIONS_REQUEST,
        FETCH_BRAND_OPTIONS_SUCCESS,
        FETCH_BRAND_OPTIONS_ERROR,
      ],
      callAPI: () => ApiClient.get('product/Brand/GetSelectList'),
      payload: {},
    }),
  );
};

export const getBrandDetail = id => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [
        GET_BRAND_DETAIL_REQUEST,
        GET_BRAND_DETAIL_SUCCESS,
        GET_BRAND_DETAIL_ERROR,
      ],
      callAPI: () => ApiClient.get(`product/Brand/${id}`),
      payload: { data: { id } },
    }),
  );
};

export const createBrand = (data, formId) => dispatch => {
  return Promise.resolve(dispatch(startSubmit(formId))).then(() =>
    dispatch({
      types: [CREATE_BRAND_REQUEST, CREATE_BRAND_SUCCESS, CREATE_BRAND_ERROR],
      callAPI: () => ApiClient.post('product/Brand', data),
      payload: { data, formId },
    }),
  );
};

export const updateBrand = (id, data, formId) => dispatch => {
  return Promise.resolve(dispatch(startSubmit(formId))).then(() =>
    dispatch({
      types: [UPDATE_BRAND_REQUEST, UPDATE_BRAND_SUCCESS, UPDATE_BRAND_ERROR],
      callAPI: () => ApiClient.put(`product/Brand/${id}`, data),
      payload: { data: { ...data, id }, formId },
    }),
  );
};

export const deleteBrand = id => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [DELETE_BRAND_REQUEST, DELETE_BRAND_SUCCESS, DELETE_BRAND_ERROR],
      callAPI: () => ApiClient.delete(`product/Brand/${id}`),
      payload: { data: { id } },
    }),
  );
};

const initialState = {
  payload: {},
  data: {},
  response: {},
  error: {},
  errorMessage: '',
  successMessage: '',
};

export default function reducer(state = initialState, action) {
  const { payload, response, error, successMessage, errorMessage } = action;
  switch (action.type) {
    case FETCH_BRAND_REQUEST:
    case FETCH_BRAND_OPTIONS_REQUEST:
    case GET_BRAND_DETAIL_REQUEST:
    case CREATE_BRAND_REQUEST:
    case UPDATE_BRAND_REQUEST:
    case DELETE_BRAND_REQUEST:
      return {
        ...state,
        payload,
        response: {},
        error: {},
        successMessage: '',
        errorMessage: '',
      };

    case FETCH_BRAND_OPTIONS_SUCCESS:
      return {
        ...state,
        data: { ...state.data, options: response.data.data },
        response,
        successMessage,
      };

    case FETCH_BRAND_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          items: response.data.data.items,
          pages: response.data.data.pageCount,
        },
        response,
        successMessage,
      };

    case GET_BRAND_DETAIL_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          item: response.data.data,
        },
        response,
        successMessage,
      };

    case CREATE_BRAND_SUCCESS:
    case UPDATE_BRAND_SUCCESS:
    case DELETE_BRAND_SUCCESS:
      return {
        ...state,
        response,
        successMessage,
      };

    case FETCH_BRAND_ERROR:
    case FETCH_BRAND_OPTIONS_ERROR:
    case GET_BRAND_DETAIL_ERROR:
    case CREATE_BRAND_ERROR:
    case UPDATE_BRAND_ERROR:
    case DELETE_BRAND_ERROR:
      return {
        ...state,
        payload,
        error,
        errorMessage,
      };
    default:
      return state;
  }
}
