import ApiClient from '../../../helpers/apiClient';
import { startSubmit } from 'redux-form';

// Cost Type Setup
export const FETCH_COST_TYPE_SETUP_REQUEST = 'FETCH_COST_TYPE_SETUP_REQUEST';
export const FETCH_COST_TYPE_SETUP_SUCCESS = 'FETCH_COST_TYPE_SETUP_SUCCESS';
export const FETCH_COST_TYPE_SETUP_ERROR = 'FETCH_COST_TYPE_SETUP_ERROR';

export const FETCH_COST_TYPE_SETUP_OPTIONS_REQUEST =
  'FETCH_COST_TYPE_SETUP_OPTIONS_REQUEST';
export const FETCH_COST_TYPE_SETUP_OPTIONS_SUCCESS =
  'FETCH_COST_TYPE_SETUP_OPTIONS_SUCCESS';
export const FETCH_COST_TYPE_SETUP_OPTIONS_ERROR =
  'FETCH_COST_TYPE_SETUP_OPTIONS_ERROR';

export const GET_COST_TYPE_SETUP_DETAIL_REQUEST =
  'GET_COST_TYPE_SETUP_DETAIL_REQUEST';
export const GET_COST_TYPE_SETUP_DETAIL_SUCCESS =
  'GET_COST_TYPE_SETUP_DETAIL_SUCCESS';
export const GET_COST_TYPE_SETUP_DETAIL_ERROR =
  'GET_COST_TYPE_SETUP_DETAIL_ERROR';

export const CREATE_COST_TYPE_SETUP_REQUEST = 'CREATE_COST_TYPE_SETUP_REQUEST';
export const CREATE_COST_TYPE_SETUP_SUCCESS = 'CREATE_COST_TYPE_SETUP_SUCCESS';
export const CREATE_COST_TYPE_SETUP_ERROR = 'CREATE_COST_TYPE_SETUP_ERROR';

export const UPDATE_COST_TYPE_SETUP_REQUEST = 'UPDATE_COST_TYPE_SETUP_REQUEST';
export const UPDATE_COST_TYPE_SETUP_SUCCESS = 'UPDATE_COST_TYPE_SETUP_SUCCESS';
export const UPDATE_COST_TYPE_SETUP_ERROR = 'UPDATE_COST_TYPE_SETUP_ERROR';

export const DELETE_COST_TYPE_SETUP_REQUEST = 'DELETE_COST_TYPE_SETUP_REQUEST';
export const DELETE_COST_TYPE_SETUP_SUCCESS = 'DELETE_COST_TYPE_SETUP_SUCCESS';
export const DELETE_COST_TYPE_SETUP_ERROR = 'DELETE_COST_TYPE_SETUP_ERROR';

// Cost Setup
export const FETCH_COST_SETUP_REQUEST = 'FETCH_COST_SETUP_REQUEST';
export const FETCH_COST_SETUP_SUCCESS = 'FETCH_COST_SETUP_SUCCESS';
export const FETCH_COST_SETUP_ERROR = 'FETCH_COST_SETUP_ERROR';

export const FETCH_COST_SETUP_OPTIONS_REQUEST =
  'FETCH_COST_SETUP_OPTIONS_REQUEST';
export const FETCH_COST_SETUP_OPTIONS_SUCCESS =
  'FETCH_COST_SETUP_OPTIONS_SUCCESS';
export const FETCH_COST_SETUP_OPTIONS_ERROR = 'FETCH_COST_SETUP_OPTIONS_ERROR';

export const GET_COST_SETUP_DETAIL_REQUEST = 'GET_COST_SETUP_DETAIL_REQUEST';
export const GET_COST_SETUP_DETAIL_SUCCESS = 'GET_COST_SETUP_DETAIL_SUCCESS';
export const GET_COST_SETUP_DETAIL_ERROR = 'GET_COST_SETUP_DETAIL_ERROR';

export const CREATE_COST_SETUP_REQUEST = 'CREATE_COST_SETUP_REQUEST';
export const CREATE_COST_SETUP_SUCCESS = 'CREATE_COST_SETUP_SUCCESS';
export const CREATE_COST_SETUP_ERROR = 'CREATE_COST_SETUP_ERROR';

export const UPDATE_COST_SETUP_REQUEST = 'UPDATE_COST_SETUP_REQUEST';
export const UPDATE_COST_SETUP_SUCCESS = 'UPDATE_COST_SETUP_SUCCESS';
export const UPDATE_COST_SETUP_ERROR = 'UPDATE_COST_SETUP_ERROR';

export const DELETE_COST_SETUP_REQUEST = 'DELETE_COST_SETUP_REQUEST';
export const DELETE_COST_SETUP_SUCCESS = 'DELETE_COST_SETUP_SUCCESS';
export const DELETE_COST_SETUP_ERROR = 'DELETE_COST_SETUP_ERROR';

// Cost Type Setup
export const fetchCostTypeSetup = data => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [
        FETCH_COST_TYPE_SETUP_REQUEST,
        FETCH_COST_TYPE_SETUP_SUCCESS,
        FETCH_COST_TYPE_SETUP_ERROR,
      ],
      callAPI: () => ApiClient.post('product/CostTypeSetup/Search', data),
      payload: { data },
    }),
  );
};

export const fetchCostTypeSetupOptions = () => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [
        FETCH_COST_TYPE_SETUP_OPTIONS_REQUEST,
        FETCH_COST_TYPE_SETUP_OPTIONS_SUCCESS,
        FETCH_COST_TYPE_SETUP_OPTIONS_ERROR,
      ],
      callAPI: () => ApiClient.get('product/CostTypeSetup/GetSelectList'),
      payload: {},
    }),
  );
};

export const getCostTypeSetupDetail = id => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [
        GET_COST_TYPE_SETUP_DETAIL_REQUEST,
        GET_COST_TYPE_SETUP_DETAIL_SUCCESS,
        GET_COST_TYPE_SETUP_DETAIL_ERROR,
      ],
      callAPI: () => ApiClient.get(`product/CostTypeSetup/${id}`),
      payload: { data: { id } },
    }),
  );
};

export const createCostTypeSetup = (data, formId) => dispatch => {
  return Promise.resolve(dispatch(startSubmit(formId))).then(() =>
    dispatch({
      types: [
        CREATE_COST_TYPE_SETUP_REQUEST,
        CREATE_COST_TYPE_SETUP_SUCCESS,
        CREATE_COST_TYPE_SETUP_ERROR,
      ],
      callAPI: () => ApiClient.post('product/CostTypeSetup', data),
      payload: { data, formId },
    }),
  );
};

export const updateCostTypeSetup = (id, data, formId) => dispatch => {
  return Promise.resolve(dispatch(startSubmit(formId))).then(() =>
    dispatch({
      types: [
        UPDATE_COST_TYPE_SETUP_REQUEST,
        UPDATE_COST_TYPE_SETUP_SUCCESS,
        UPDATE_COST_TYPE_SETUP_ERROR,
      ],
      callAPI: () => ApiClient.put(`product/CostTypeSetup/${id}`, data),
      payload: { data: { ...data, id }, formId },
    }),
  );
};

export const deleteCostTypeSetup = id => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [
        DELETE_COST_TYPE_SETUP_REQUEST,
        DELETE_COST_TYPE_SETUP_SUCCESS,
        DELETE_COST_TYPE_SETUP_ERROR,
      ],
      callAPI: () => ApiClient.delete(`product/CostTypeSetup/${id}`),
      payload: { data: { id } },
    }),
  );
};

// Cost Setup
export const fetchCostSetup = data => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [
        FETCH_COST_SETUP_REQUEST,
        FETCH_COST_SETUP_SUCCESS,
        FETCH_COST_SETUP_ERROR,
      ],
      callAPI: () => ApiClient.post('product/CostSetup/Search', data),
      payload: { data },
    }),
  );
};

export const fetchCostSetupOptions = () => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [
        FETCH_COST_SETUP_OPTIONS_REQUEST,
        FETCH_COST_SETUP_OPTIONS_SUCCESS,
        FETCH_COST_SETUP_OPTIONS_ERROR,
      ],
      callAPI: () => ApiClient.get('product/CostSetup/GetSelectList'),
      payload: {},
    }),
  );
};

export const getCostSetupDetail = id => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [
        GET_COST_SETUP_DETAIL_REQUEST,
        GET_COST_SETUP_DETAIL_SUCCESS,
        GET_COST_SETUP_DETAIL_ERROR,
      ],
      callAPI: () => ApiClient.get(`product/CostSetup/GetDetail/${id}`),
      payload: { data: { id } },
    }),
  );
};

export const createCostSetup = (data, formId) => dispatch => {
  return Promise.resolve(dispatch(startSubmit(formId))).then(() =>
    dispatch({
      types: [
        CREATE_COST_SETUP_REQUEST,
        CREATE_COST_SETUP_SUCCESS,
        CREATE_COST_SETUP_ERROR,
      ],
      callAPI: () => ApiClient.post('product/CostSetup', data),
      payload: { data, formId },
    }),
  );
};

export const updateCostSetup = (id, data, formId) => dispatch => {
  return Promise.resolve(dispatch(startSubmit(formId))).then(() =>
    dispatch({
      types: [
        UPDATE_COST_SETUP_REQUEST,
        UPDATE_COST_SETUP_SUCCESS,
        UPDATE_COST_SETUP_ERROR,
      ],
      callAPI: () => ApiClient.put(`product/CostSetup/${id}`, data),
      payload: { data: { ...data, id }, formId },
    }),
  );
};

export const deleteCostSetup = id => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [
        DELETE_COST_SETUP_REQUEST,
        DELETE_COST_SETUP_SUCCESS,
        DELETE_COST_SETUP_ERROR,
      ],
      callAPI: () => ApiClient.delete(`product/CostSetup/${id}`),
      payload: { data: { id } },
    }),
  );
};

const initialState = {
  payload: {},
  data: {},
  response: {},
  error: {},
  errorMessage: '',
  successMessage: '',
};

export default function reducer(state = initialState, action) {
  const { payload, response, error, successMessage, errorMessage } = action;
  switch (action.type) {
    case FETCH_COST_TYPE_SETUP_REQUEST:
    case FETCH_COST_TYPE_SETUP_OPTIONS_REQUEST:
    case GET_COST_TYPE_SETUP_DETAIL_REQUEST:
    case CREATE_COST_TYPE_SETUP_REQUEST:
    case UPDATE_COST_TYPE_SETUP_REQUEST:
    case DELETE_COST_TYPE_SETUP_REQUEST:

    case FETCH_COST_SETUP_REQUEST:
    case FETCH_COST_SETUP_OPTIONS_REQUEST:
    case GET_COST_SETUP_DETAIL_REQUEST:
    case CREATE_COST_SETUP_REQUEST:
    case UPDATE_COST_SETUP_REQUEST:
    case DELETE_COST_SETUP_REQUEST:
      return {
        ...state,
        payload,
        response: {},
        error: {},
        successMessage: '',
        errorMessage: '',
      };

    case FETCH_COST_TYPE_SETUP_OPTIONS_SUCCESS:
      return {
        ...state,
        data: { ...state.data, costTypeOptions: response.data.data },
        response,
        successMessage,
      };
    case FETCH_COST_SETUP_OPTIONS_SUCCESS:
      return {
        ...state,
        data: { ...state.data, costSetupOptions: response.data.data },
        response,
        successMessage,
      };

    case FETCH_COST_TYPE_SETUP_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          costTypeSetups: response.data.data.items,
          pages: response.data.data.pageCount,
        },
        response,
        successMessage,
      };
    case FETCH_COST_SETUP_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          costSetups: response.data.data.items,
          pages: response.data.data.pageCount,
        },
        response,
        successMessage,
      };

    case GET_COST_TYPE_SETUP_DETAIL_SUCCESS:
    case GET_COST_SETUP_DETAIL_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          item: response.data.data,
        },
        response,
        successMessage,
      };

    case CREATE_COST_TYPE_SETUP_SUCCESS:
    case UPDATE_COST_TYPE_SETUP_SUCCESS:
    case DELETE_COST_TYPE_SETUP_SUCCESS:
    case CREATE_COST_SETUP_SUCCESS:
    case UPDATE_COST_SETUP_SUCCESS:
    case DELETE_COST_SETUP_SUCCESS:
      return {
        ...state,
        response,
        successMessage,
      };

    case FETCH_COST_TYPE_SETUP_ERROR:
    case FETCH_COST_TYPE_SETUP_OPTIONS_ERROR:
    case GET_COST_TYPE_SETUP_DETAIL_ERROR:
    case CREATE_COST_TYPE_SETUP_ERROR:
    case UPDATE_COST_TYPE_SETUP_ERROR:
    case DELETE_COST_TYPE_SETUP_ERROR:

    case FETCH_COST_SETUP_ERROR:
    case FETCH_COST_SETUP_OPTIONS_ERROR:
    case GET_COST_SETUP_DETAIL_ERROR:
    case CREATE_COST_SETUP_ERROR:
    case UPDATE_COST_SETUP_ERROR:
    case DELETE_COST_SETUP_ERROR:
      return {
        ...state,
        payload,
        error,
        errorMessage,
      };
    default:
      return state;
  }
}
