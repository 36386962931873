import sidebarBgImage from 'assets/img/sidebar/network-2.jpg';
import SourceLink from 'views/components/SourceLink';
import React, { Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import { Nav, Navbar, NavItem, NavLink as BSNavLink, Collapse } from 'reactstrap';
import bn from 'utils/bemnames';
import { navGeneralItems, navGeneralTaxItems } from '../../../configs/navigationItems';
import { getStoredObject } from '../../../helpers/storageHelper';
import { StorageKeys } from '../../../constants/storageKeys';
import { connect } from 'react-redux';
import cx from 'classnames';
import { MdKeyboardArrowDown, MdSettings } from 'react-icons/md';
import { hasPermission } from 'services/permissionService';

const sidebarBackground = {
  backgroundImage: `url("${sidebarBgImage}")`,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
};

const bem = bn.create('sidebar');

class GeneralSidebar extends React.Component {
  state = {
    userRoles: [],
    currentLink: '',
    isOpenTaxSetup: false,
  };

  componentDidMount() {
    const path = window.location.hash.slice('#/general'.length);
    const roleNames = this.getRoleNames();
    this.setState({ currentLink: path, userRoles: roleNames });

    if (navGeneralTaxItems.find(i => i.to == path || i.childrenRoutes?.find(r => path.startsWith(r)))) {
      this.setState({ isOpenTaxSetup: true });
    }
  }

  showGroup = (navGroupItems, userRoles) => {
    return navGroupItems.some(item => this.showNavItem(item.roles, userRoles, item.resource, item.action));
  };

  showNavItem = (rolesAllow, userRoles, resource, action) => {
    if (!rolesAllow) {
      const { profile } = this.props;

      if (profile && profile.isDmsAdmin) return true;

      return hasPermission(resource, action, profile.permissions);
    }
    
    if (rolesAllow.length === 0) {
      return false;
    }

    return userRoles.some(r => rolesAllow.includes(r?.roleName));
    // return userRoles.some(r => rolesAllow.includes(r));
  };

  getRoleNames = () => {
    let { profile } = this.props;
    if (!profile || !profile.userRoleEntities) {
      profile = getStoredObject(StorageKeys.Profile);
    }
    if (profile && profile.userRoleEntities) {
      return profile.userRoleEntities;
    } else {
      return [];
    }
  };

  handleClick = name => () => {
    this.setState(prevState => {
      const isOpen = prevState[`isOpen${name}`];

      return {
        [`isOpen${name}`]: !isOpen,
      };
    });
  };

  renderMenuItem = ({ to, name, exact, Icon, roles, childrenRoutes, resource, action }, index, className) => {
    const { userRoles, currentLink } = this.state;
    const path = window.location.hash.substring('#/general'.length);
    let isCurrentLink = path === to;

    if (!isCurrentLink) {
      if (currentLink && currentLink === to) {
        isCurrentLink = true;
      } else if (childrenRoutes && childrenRoutes.find(r => path.startsWith(r))) {
        isCurrentLink = true;
      }
    }

    return (
      this.showNavItem(roles, userRoles, resource, action) && (
        <NavItem key={index} className={cx({ 'active-item-tab': isCurrentLink }, className)}>
          <BSNavLink
            id={`navItem-${name}-${index}`}
            tag={NavLink}
            to={`/general${to}`}
            className={isCurrentLink ? 'active' : ''}
            exact={exact}
            onClick={() => this.setState({ currentLink: to })}
          >
            <Icon className={bem.e('nav-item-icon')} />
            <span className="">{name}</span>
          </BSNavLink>
        </NavItem>
      )
    );
  };

  render() {
    const { userRoles } = this.state;

    return (
      <aside className={bem.b()} data-image={sidebarBgImage}>
        <div className={bem.e('background')} style={sidebarBackground} />
        <div className={bem.e('content')}>
          <Navbar>
            <SourceLink className="navbar-brand d-flex">
              <span className="text-white">DMS</span>
            </SourceLink>
          </Navbar>
          <Nav vertical>
            {this.showGroup(navGeneralItems, userRoles) &&
              navGeneralItems.map((item, index) => this.renderMenuItem(item, index))}

            {this.showGroup(navGeneralTaxItems, userRoles) && (
              <Fragment>
                <NavItem className={bem.e('nav-item')} onClick={this.handleClick('TaxSetup')}>
                  <BSNavLink className={bem.e('nav-item-collapse')}>
                    <div className="d-flex">
                      <MdSettings className={bem.e('nav-item-icon')} />
                      <span className=" align-self-start">Tax</span>
                    </div>
                    <MdKeyboardArrowDown
                      className={bem.e('nav-item-icon')}
                      style={{
                        padding: 0,
                        transform: this.state.isOpenTaxSetup ? 'rotate(0deg)' : 'rotate(-90deg)',
                        transitionDuration: '0.3s',
                        transitionProperty: 'transform',
                      }}
                    />
                  </BSNavLink>
                </NavItem>
                <Collapse isOpen={this.state.isOpenTaxSetup}>
                  {navGeneralTaxItems.map((item, index) => this.renderMenuItem(item, index, 'pl-2'))}
                </Collapse>
              </Fragment>
            )}
          </Nav>
        </div>
      </aside>
    );
  }
}

const mapStateToProps = ({ account }) => ({
  profile: account.profile,
});

export default connect(mapStateToProps, null)(GeneralSidebar);
