import ApiClient from '../../../helpers/apiClient';
import { startSubmit } from 'redux-form';

// --Tax Code----------------------
export const FETCH_TAX_CODES_REQUEST = 'FETCH_TAX_CODES_REQUEST';
export const FETCH_TAX_CODES_SUCCESS = 'FETCH_TAX_CODES_SUCCESS';
export const FETCH_TAX_CODES_ERROR = 'FETCH_TAX_CODES_ERROR';

export const FETCH_TAX_CODE_OPTIONS_REQUEST = 'FETCH_TAX_CODE_OPTIONS_REQUEST';
export const FETCH_TAX_CODE_OPTIONS_SUCCESS = 'FETCH_TAX_CODE_OPTIONS_SUCCESS';
export const FETCH_TAX_CODE_OPTIONS_ERROR = 'FETCH_TAX_CODE_OPTIONS_ERROR';

export const GET_TAX_CODE_DETAIL_REQUEST = 'GET_TAX_CODE_DETAIL_REQUEST';
export const GET_TAX_CODE_DETAIL_SUCCESS = 'GET_TAX_CODE_DETAIL_SUCCESS';
export const GET_TAX_CODE_DETAIL_ERROR = 'GET_TAX_CODE_DETAIL_ERROR';

export const GET_TAX_CODE_REQUEST = 'GET_TAX_CODE_REQUEST';
export const GET_TAX_CODE_SUCCESS = 'GET_TAX_CODE_SUCCESS';
export const GET_TAX_CODE_ERROR = 'GET_TAX_CODE_ERROR';

export const CREATE_TAX_CODE_REQUEST = 'CREATE_TAX_CODE_REQUEST';
export const CREATE_TAX_CODE_SUCCESS = 'CREATE_TAX_CODE_SUCCESS';
export const CREATE_TAX_CODE_ERROR = 'CREATE_TAX_CODE_ERROR';

export const UPDATE_TAX_CODE_REQUEST = 'UPDATE_TAX_CODE_REQUEST';
export const UPDATE_TAX_CODE_SUCCESS = 'UPDATE_TAX_CODE_SUCCESS';
export const UPDATE_TAX_CODE_ERROR = 'UPDATE_TAX_CODE_ERROR';

export const DELETE_TAX_CODE_REQUEST = 'DELETE_TAX_CODE_REQUEST';
export const DELETE_TAX_CODE_SUCCESS = 'DELETE_TAX_CODE_SUCCESS';
export const DELETE_TAX_CODE_ERROR = 'DELETE_TAX_CODE_ERROR';

// --Tax Group----------------------
export const FETCH_TAX_GROUPS_REQUEST = 'FETCH_TAX_GROUPS_REQUEST';
export const FETCH_TAX_GROUPS_SUCCESS = 'FETCH_TAX_GROUPS_SUCCESS';
export const FETCH_TAX_GROUPS_ERROR = 'FETCH_TAX_GROUPS_ERROR';

export const FETCH_TAX_GROUP_OPTIONS_REQUEST = 'FETCH_TAX_GROUP_OPTIONS_REQUEST';
export const FETCH_TAX_GROUP_OPTIONS_SUCCESS = 'FETCH_TAX_GROUP_OPTIONS_SUCCESS';
export const FETCH_TAX_GROUP_OPTIONS_ERROR = 'FETCH_TAX_GROUP_OPTIONS_ERROR';

export const GET_TAX_GROUP_DETAIL_REQUEST = 'GET_TAX_GROUP_DETAIL_REQUEST';
export const GET_TAX_GROUP_DETAIL_SUCCESS = 'GET_TAX_GROUP_DETAIL_SUCCESS';
export const GET_TAX_GROUP_DETAIL_ERROR = 'GET_TAX_GROUP_DETAIL_ERROR';

export const CREATE_TAX_GROUP_REQUEST = 'CREATE_TAX_GROUP_REQUEST';
export const CREATE_TAX_GROUP_SUCCESS = 'CREATE_TAX_GROUP_SUCCESS';
export const CREATE_TAX_GROUP_ERROR = 'CREATE_TAX_GROUP_ERROR';

export const UPDATE_TAX_GROUP_REQUEST = 'UPDATE_TAX_GROUP_REQUEST';
export const UPDATE_TAX_GROUP_SUCCESS = 'UPDATE_TAX_GROUP_SUCCESS';
export const UPDATE_TAX_GROUP_ERROR = 'UPDATE_TAX_GROUP_ERROR';

export const DELETE_TAX_GROUP_REQUEST = 'DELETE_TAX_GROUP_REQUEST';
export const DELETE_TAX_GROUP_SUCCESS = 'DELETE_TAX_GROUP_SUCCESS';
export const DELETE_TAX_GROUP_ERROR = 'DELETE_TAX_GROUP_ERROR';

// --Item Tax Group----------------------
export const FETCH_ITEM_TAX_GROUPS_REQUEST = 'FETCH_ITEM_TAX_GROUPS_REQUEST';
export const FETCH_ITEM_TAX_GROUPS_SUCCESS = 'FETCH_ITEM_TAX_GROUPS_SUCCESS';
export const FETCH_ITEM_TAX_GROUPS_ERROR = 'FETCH_ITEM_TAX_GROUPS_ERROR';

export const FETCH_ITEM_TAX_GROUP_OPTIONS_REQUEST = 'FETCH_ITEM_TAX_GROUP_OPTIONS_REQUEST';
export const FETCH_ITEM_TAX_GROUP_OPTIONS_SUCCESS = 'FETCH_ITEM_TAX_GROUP_OPTIONS_SUCCESS';
export const FETCH_ITEM_TAX_GROUP_OPTIONS_ERROR = 'FETCH_ITEM_TAX_GROUP_OPTIONS_ERROR';

export const FETCH_ENTITY_ITEM_TAX_GROUP_OPTIONS_REQUEST = 'FETCH_ENTITY_ITEM_TAX_GROUP_OPTIONS_REQUEST';
export const FETCH_ENTITY_ITEM_TAX_GROUP_OPTIONS_SUCCESS = 'FETCH_ENTITY_ITEM_TAX_GROUP_OPTIONS_SUCCESS';
export const FETCH_ENTITY_ITEM_TAX_GROUP_OPTIONS_ERROR = 'FETCH_ENTITY_ITEM_TAX_GROUP_OPTIONS_ERROR';

export const GET_ITEM_TAX_GROUP_DETAIL_REQUEST = 'GET_ITEM_TAX_GROUP_DETAIL_REQUEST';
export const GET_ITEM_TAX_GROUP_DETAIL_SUCCESS = 'GET_ITEM_TAX_GROUP_DETAIL_SUCCESS';
export const GET_ITEM_TAX_GROUP_DETAIL_ERROR = 'GET_ITEM_TAX_GROUP_DETAIL_ERROR';

export const CREATE_ITEM_TAX_GROUP_REQUEST = 'CREATE_ITEM_TAX_GROUP_REQUEST';
export const CREATE_ITEM_TAX_GROUP_SUCCESS = 'CREATE_ITEM_TAX_GROUP_SUCCESS';
export const CREATE_ITEM_TAX_GROUP_ERROR = 'CREATE_ITEM_TAX_GROUP_ERROR';

export const UPDATE_ITEM_TAX_GROUP_REQUEST = 'UPDATE_ITEM_TAX_GROUP_REQUEST';
export const UPDATE_ITEM_TAX_GROUP_SUCCESS = 'UPDATE_ITEM_TAX_GROUP_SUCCESS';
export const UPDATE_ITEM_TAX_GROUP_ERROR = 'UPDATE_ITEM_TAX_GROUP_ERROR';

export const DELETE_ITEM_TAX_GROUP_REQUEST = 'DELETE_ITEM_TAX_GROUP_REQUEST';
export const DELETE_ITEM_TAX_GROUP_SUCCESS = 'DELETE_ITEM_TAX_GROUP_SUCCESS';
export const DELETE_ITEM_TAX_GROUP_ERROR = 'DELETE_ITEM_TAX_GROUP_ERROR';

export const GET_TAX_PER_ITEM_REQUEST = 'GET_TAX_PER_ITEM_REQUEST';
export const GET_TAX_PER_ITEM_SUCCESS = 'GET_TAX_PER_ITEM_SUCCESS';
export const GET_TAX_PER_ITEM_ERROR = 'GET_TAX_PER_ITEM_ERROR';

// --Tax Code------------------
export const fetchTaxCodes = data => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [FETCH_TAX_CODES_REQUEST, FETCH_TAX_CODES_SUCCESS, FETCH_TAX_CODES_ERROR],
      callAPI: () => ApiClient.post('network/TaxCodes/Search', data),
      payload: { data },
    }),
  );
};

export const fetchTaxCodeOptions = () => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [FETCH_TAX_CODE_OPTIONS_REQUEST, FETCH_TAX_CODE_OPTIONS_SUCCESS, FETCH_TAX_CODE_OPTIONS_ERROR],
      callAPI: () => ApiClient.get('network/TaxCodes/GetSelectList'),
      payload: {},
    }),
  );
};

export const getTaxCode = id => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [GET_TAX_CODE_REQUEST, GET_TAX_CODE_SUCCESS, GET_TAX_CODE_ERROR],
      callAPI: () => ApiClient.get(`network/TaxCodes/${id}`),
      payload: { data: { id } },
    }),
  );
};

export const getTaxCodeDetail = id => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [GET_TAX_CODE_DETAIL_REQUEST, GET_TAX_CODE_DETAIL_SUCCESS, GET_TAX_CODE_DETAIL_ERROR],
      callAPI: () => ApiClient.get(`network/TaxCodes/GetDetail/${id}`),
      payload: { data: { id } },
    }),
  );
};

export const createTaxCode = (data, formId) => dispatch => {
  return Promise.resolve(dispatch(startSubmit(formId))).then(() =>
    dispatch({
      types: [CREATE_TAX_CODE_REQUEST, CREATE_TAX_CODE_SUCCESS, CREATE_TAX_CODE_ERROR],
      callAPI: () => ApiClient.post('network/TaxCodes', data),
      payload: { data, formId },
    }),
  );
};

export const updateTaxCode = (id, data, formId) => dispatch => {
  return Promise.resolve(dispatch(startSubmit(formId))).then(() =>
    dispatch({
      types: [UPDATE_TAX_CODE_REQUEST, UPDATE_TAX_CODE_SUCCESS, UPDATE_TAX_CODE_ERROR],
      callAPI: () => ApiClient.put(`network/TaxCodes/${id}`, data),
      payload: { data: { ...data, id }, formId },
    }),
  );
};

export const deleteTaxCode = id => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [DELETE_TAX_CODE_REQUEST, DELETE_TAX_CODE_SUCCESS, DELETE_TAX_CODE_ERROR],
      callAPI: () => ApiClient.delete(`network/TaxCodes/${id}`),
      payload: { data: { id } },
    }),
  );
};

// --Tax Group------------------
export const fetchTaxGroups = data => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [FETCH_TAX_GROUPS_REQUEST, FETCH_TAX_GROUPS_SUCCESS, FETCH_TAX_GROUPS_ERROR],
      callAPI: () => ApiClient.post('network/TaxGroups/Search', data),
      payload: { data },
    }),
  );
};

export const fetchTaxGroupOptions = () => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [FETCH_TAX_GROUP_OPTIONS_REQUEST, FETCH_TAX_GROUP_OPTIONS_SUCCESS, FETCH_TAX_GROUP_OPTIONS_ERROR],
      callAPI: () => ApiClient.get('network/TaxGroups/GetSelectList'),
      payload: {},
    }),
  );
};

export const getTaxGroupDetail = id => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [GET_TAX_GROUP_DETAIL_REQUEST, GET_TAX_GROUP_DETAIL_SUCCESS, GET_TAX_GROUP_DETAIL_ERROR],
      callAPI: () => ApiClient.get(`network/TaxGroups/GetDetail/${id}`),
      payload: { data: { id } },
    }),
  );
};

export const createTaxGroup = (data, formId) => dispatch => {
  return Promise.resolve(dispatch(startSubmit(formId))).then(() =>
    dispatch({
      types: [CREATE_TAX_GROUP_REQUEST, CREATE_TAX_GROUP_SUCCESS, CREATE_TAX_GROUP_ERROR],
      callAPI: () => ApiClient.post('network/TaxGroups', data),
      payload: { data, formId },
    }),
  );
};

export const updateTaxGroup = (id, data, formId) => dispatch => {
  return Promise.resolve(dispatch(startSubmit(formId))).then(() =>
    dispatch({
      types: [UPDATE_TAX_GROUP_REQUEST, UPDATE_TAX_GROUP_SUCCESS, UPDATE_TAX_GROUP_ERROR],
      callAPI: () => ApiClient.put(`network/TaxGroups/${id}`, data),
      payload: { data: { ...data, id }, formId },
    }),
  );
};

export const deleteTaxGroup = id => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [DELETE_TAX_GROUP_REQUEST, DELETE_TAX_GROUP_SUCCESS, DELETE_TAX_GROUP_ERROR],
      callAPI: () => ApiClient.delete(`network/TaxGroups/${id}`),
      payload: { data: { id } },
    }),
  );
};

// --Item Tax Group------------------
export const fetchItemTaxGroups = data => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [FETCH_ITEM_TAX_GROUPS_REQUEST, FETCH_ITEM_TAX_GROUPS_SUCCESS, FETCH_ITEM_TAX_GROUPS_ERROR],
      callAPI: () => ApiClient.post('network/ItemTaxGroups/Search', data),
      payload: { data },
    }),
  );
};

export const fetchItemTaxGroupOptions = () => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [
        FETCH_ITEM_TAX_GROUP_OPTIONS_REQUEST,
        FETCH_ITEM_TAX_GROUP_OPTIONS_SUCCESS,
        FETCH_ITEM_TAX_GROUP_OPTIONS_ERROR,
      ],
      callAPI: () => ApiClient.get('network/ItemTaxGroups/GetSelectList'),
      payload: {},
    }),
  );
};

export const fetchEntityItemTaxGroupOptions = entity => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [
        FETCH_ENTITY_ITEM_TAX_GROUP_OPTIONS_REQUEST,
        FETCH_ENTITY_ITEM_TAX_GROUP_OPTIONS_SUCCESS,
        FETCH_ENTITY_ITEM_TAX_GROUP_OPTIONS_ERROR,
      ],
      callAPI: () => ApiClient.get(`network/ItemTaxGroups/GetOptionsByEntity/${entity}`),
      payload: { data: { entity } },
    }),
  );
};

export const createItemTaxGroup = (data, formId) => dispatch => {
  return Promise.resolve(dispatch(startSubmit(formId))).then(() =>
    dispatch({
      types: [CREATE_ITEM_TAX_GROUP_REQUEST, CREATE_ITEM_TAX_GROUP_SUCCESS, CREATE_ITEM_TAX_GROUP_ERROR],
      callAPI: () => ApiClient.post('network/ItemTaxGroups', data),
      payload: { data, formId },
    }),
  );
};

export const getItemTaxGroupDetail = id => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [GET_ITEM_TAX_GROUP_DETAIL_REQUEST, GET_ITEM_TAX_GROUP_DETAIL_SUCCESS, GET_ITEM_TAX_GROUP_DETAIL_ERROR],
      callAPI: () => ApiClient.get(`network/ItemTaxGroups/GetDetail/${id}`),
      payload: { data: { id } },
    }),
  );
};

export const updateItemTaxGroup = (id, data, formId) => dispatch => {
  return Promise.resolve(dispatch(startSubmit(formId))).then(() =>
    dispatch({
      types: [UPDATE_ITEM_TAX_GROUP_REQUEST, UPDATE_ITEM_TAX_GROUP_SUCCESS, UPDATE_ITEM_TAX_GROUP_ERROR],
      callAPI: () => ApiClient.put(`network/ItemTaxGroups/${id}`, data),
      payload: { data: { ...data, id }, formId },
    }),
  );
};

export const deleteItemTaxGroup = id => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [DELETE_ITEM_TAX_GROUP_REQUEST, DELETE_ITEM_TAX_GROUP_SUCCESS, DELETE_ITEM_TAX_GROUP_ERROR],
      callAPI: () => ApiClient.delete(`network/ItemTaxGroups/${id}`),
      payload: { data: { id } },
    }),
  );
};

export const getTaxPerItem = (productId, netPrice, variantId, customerId, customerTaxGroupId) => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [GET_TAX_PER_ITEM_REQUEST, GET_TAX_PER_ITEM_SUCCESS, GET_TAX_PER_ITEM_ERROR],
      callAPI: () => {
        let url = `network/TaxGroups/GetTaxPerItem?productId=${productId}&netPrice=${netPrice}`;
        if (variantId) {
          url = url + `&variantId=${variantId}`;
        }
        if (customerId) {
          url = url + `&customerId=${customerId}`;
        }
        if (customerTaxGroupId) {
          url = url + `&customerTaxGroupId=${customerTaxGroupId}`;
        }
        return ApiClient.get(url);
      },
      payload: { data: { productId, variantId, customerId, customerTaxGroupId } },
    }),
  );
};

const initialState = {
  payload: {},
  data: {},
  response: {},
  error: {},
  errorMessage: '',
  successMessage: '',
};

export default function reducer(state = initialState, action) {
  const { payload, response, error, successMessage, errorMessage } = action;
  switch (action.type) {
    // Tax Code
    case FETCH_TAX_CODES_REQUEST:
    case FETCH_TAX_CODE_OPTIONS_REQUEST:
    case GET_TAX_CODE_DETAIL_REQUEST:
    case GET_TAX_CODE_REQUEST:
    case CREATE_TAX_CODE_REQUEST:
    case UPDATE_TAX_CODE_REQUEST:
    case DELETE_TAX_CODE_REQUEST:
    // Tax Group
    case FETCH_TAX_GROUPS_REQUEST:
    case FETCH_TAX_GROUP_OPTIONS_REQUEST:
    case GET_TAX_GROUP_DETAIL_REQUEST:
    case CREATE_TAX_GROUP_REQUEST:
    case UPDATE_TAX_GROUP_REQUEST:
    case DELETE_TAX_GROUP_REQUEST:
    // Item Tax Group
    case FETCH_ITEM_TAX_GROUPS_REQUEST:
    case FETCH_ITEM_TAX_GROUP_OPTIONS_REQUEST:
    case FETCH_ENTITY_ITEM_TAX_GROUP_OPTIONS_REQUEST:
    case GET_ITEM_TAX_GROUP_DETAIL_REQUEST:
    case CREATE_ITEM_TAX_GROUP_REQUEST:
    case UPDATE_ITEM_TAX_GROUP_REQUEST:
    case DELETE_ITEM_TAX_GROUP_REQUEST:
    case GET_TAX_PER_ITEM_REQUEST:
      return {
        ...state,
        payload,
        response: {},
        error: {},
        successMessage: '',
        errorMessage: '',
      };

    case FETCH_TAX_CODE_OPTIONS_SUCCESS:
      return {
        ...state,
        data: { ...state.data, taxCodeOptions: response.data.data },
        response,
        successMessage,
      };
    case FETCH_TAX_GROUP_OPTIONS_SUCCESS:
      return {
        ...state,
        data: { ...state.data, taxGroupOptions: response.data.data },
        response,
        successMessage,
      };
    case FETCH_ITEM_TAX_GROUP_OPTIONS_SUCCESS:
      return {
        ...state,
        data: { ...state.data, itemTaxGroupOptions: response.data.data },
        response,
        successMessage,
      };
    case FETCH_ENTITY_ITEM_TAX_GROUP_OPTIONS_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          entityItemTaxGroupOptions: {
            ...state.data.entityItemTaxGroupOptions,
            [payload.data.entity + 'ItemTaxGroupOptions']: response.data.data,
          },
        },
        response,
        successMessage,
      };

    case FETCH_TAX_CODES_SUCCESS:
    case FETCH_TAX_GROUPS_SUCCESS:
    case FETCH_ITEM_TAX_GROUPS_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          items: response.data.data.items,
          pages: response.data.data.pageCount,
        },
        response,
        successMessage,
      };

    case GET_TAX_CODE_DETAIL_SUCCESS:
    case GET_TAX_GROUP_DETAIL_SUCCESS:
    case GET_ITEM_TAX_GROUP_DETAIL_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          item: response.data.data,
        },
        response,
        successMessage,
      };

    case GET_TAX_CODE_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          taxCode: response.data.data,
        },
        response,
        successMessage,
      };

    // Tax Code
    case CREATE_TAX_CODE_SUCCESS:
    case UPDATE_TAX_CODE_SUCCESS:
    case DELETE_TAX_CODE_SUCCESS:
    // Tax Group
    case CREATE_TAX_GROUP_SUCCESS:
    case UPDATE_TAX_GROUP_SUCCESS:
    case DELETE_TAX_GROUP_SUCCESS:
    // Item Tax Group
    case CREATE_ITEM_TAX_GROUP_SUCCESS:
    case UPDATE_ITEM_TAX_GROUP_SUCCESS:
    case DELETE_ITEM_TAX_GROUP_SUCCESS:
    case GET_TAX_PER_ITEM_SUCCESS:
      return {
        ...state,
        response,
        successMessage,
      };

    // Tax Code
    case FETCH_TAX_CODES_ERROR:
    case FETCH_TAX_CODE_OPTIONS_ERROR:
    case GET_TAX_CODE_DETAIL_ERROR:
    case GET_TAX_CODE_ERROR:
    case CREATE_TAX_CODE_ERROR:
    case UPDATE_TAX_CODE_ERROR:
    case DELETE_TAX_CODE_ERROR:
    // Tax Group
    case FETCH_TAX_GROUPS_ERROR:
    case FETCH_TAX_GROUP_OPTIONS_ERROR:
    case GET_TAX_GROUP_DETAIL_ERROR:
    case CREATE_TAX_GROUP_ERROR:
    case UPDATE_TAX_GROUP_ERROR:
    case DELETE_TAX_GROUP_ERROR:
    // Item Tax Group
    case FETCH_ITEM_TAX_GROUPS_ERROR:
    case FETCH_ITEM_TAX_GROUP_OPTIONS_ERROR:
    case GET_ITEM_TAX_GROUP_DETAIL_ERROR:
    case CREATE_ITEM_TAX_GROUP_ERROR:
    case UPDATE_ITEM_TAX_GROUP_ERROR:
    case DELETE_ITEM_TAX_GROUP_ERROR:
    case GET_TAX_PER_ITEM_ERROR:
      return {
        ...state,
        payload,
        error,
        errorMessage,
      };
    default:
      return state;
  }
}
