import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import { required, email, createValidator } from '../../../helpers/validator';
import TextField from '../Fields/TextField';
import PropTypes from 'prop-types';
import Button from '../Controls/Button';
import { Link } from 'react-router-dom';
import { Col, FormGroup, Input, Label, UncontrolledAlert } from 'reactstrap';
import IconTextField from '../Fields/IconTextField';
import EmailSvg from 'assets/img/icons/ic_email.svg';
import PasswordSvg from 'assets/img/icons/ic_password.svg';
import Row from 'reactstrap/lib/Row';
import CButton from '../Controls/CButton';
import history from '../../../history';
import CheckField from '../Fields/CheckField';

const validator = createValidator({
  email: [required, email],
  password: [required],
});

export const loginFormId = 'login';

class LoginForm extends Component {
  render() {
    const { error, handleSubmit, pristine, reset, submitting } = this.props;

    return (
      <form onSubmit={handleSubmit} className="ml-2 mr-2">
        <div className="mb-3">
          <Field
            className="top"
            name="email"
            component={IconTextField}
            type="text"
            label="Email Address"
            icon={EmailSvg}
            showLabel={true}
            required={true}
            disabled={submitting}
          />
          <Field
            className="bottom"
            name="password"
            component={IconTextField}
            type="password"
            label="Password"
            icon={PasswordSvg}
            showLabel={true}
            required={true}
            disabled={submitting}
          />
        </div>

        <Row className="align-items-center justify-content-between">
          <Col xs="auto">
            <Field
              name="isRemember"
              component={CheckField}
              label="Remember Me"
              showLabel={true}
              disabled={submitting}
            />
          </Col>
          <Col xs="auto">
            <Link to="/forgot-password" style={{ color: 'black' }}>
              Forgot Password?
            </Link>
          </Col>
        </Row>

        {error && (
          <div className="my-2 text-left">
            <span className="form-error">{error}</span>
          </div>
        )}

        <div style={{ marginBottom: 100 }} />

        <CButton
          type="submit"
          size="lg"
          color="primary"
          disabled={pristine}
          loading={submitting}
          style={{ minWidth: 250, minHeight: 80, marginRight: 12 }}
        >
          Login Now
        </CButton>

        {/* <CButton
          type="button"
          size="lg"
          color="secondary"
          disabled={submitting}
          style={{ minWidth: 250, minHeight: 80 }}
          onClick={() => {
            history.push('/distributor-sign-up');
          }}
        >
          Create Account
        </CButton> */}
      </form>
    );
  }
}

LoginForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  invalid: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({});
const mapDispatchToProps = dispatch => ({});

LoginForm = reduxForm({
  form: loginFormId,
  validate: validator,
  enableReinitialize: true,
  destroyOnUnmount: false,
})(LoginForm);

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
