import React from 'react';
import { Card, Col, Row } from 'reactstrap';
import { getProfile, login, registerDistributor } from '../../../redux/modules/Account';
import { connect } from 'react-redux';
import Logo from 'assets/img/logo/logo_no_text.png';
import DistributorRegisterForm, { distributorRegisterFormId } from '../../components/Forms/DistributorRegisterForm';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { showMessageModal } from '../../../redux/modules/Modal';

const initialValues = {
  email: '',
  password: '',
  confirmedPassword: '',
  countryCode: '',
  contactNumber: '',
  companyName: '',
  registerNumber: '',
  tncAgree: false,
};

class DistributorSignUpPage extends React.Component {
  handleSubmit = (values, dispatch) => {
    dispatch(registerDistributor(values, distributorRegisterFormId)).then(resp1 => {
      this.props.showMessageModal('You have registered successfully.', () => {
        dispatch(login({ email: values.email, password: values.password }, distributorRegisterFormId)).then(resp2 => {
          dispatch(getProfile()).then(data => {
            this.props.history.push('/general/company-detail?showSteps=true&edit=true');
          });
        });
      });
    });
  };

  render() {
    return (
      <Row
        style={{
          height: '100vh',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Col md={6} lg={4}>
          <Card body>
            <div className="row mb-4">
              <div className="col-12 text-left pl-4 pt-3">
                <h4 className="title">
                  <Link to="/">
                    <img src={Logo} className="rounded" alt="logo" />
                  </Link>{' '}
                  Sign Up
                </h4>
              </div>
            </div>
            <DistributorRegisterForm initialValues={initialValues} onSubmit={this.handleSubmit} />
          </Card>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = state => ({});
const mapDispatchToProps = dispatch => ({
  showMessageModal: bindActionCreators(showMessageModal, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(DistributorSignUpPage);
