export const StorageKeys = {
  AccessToken: 'access_token',
  RefreshToken: 'refresh_token',
  AuthData: 'auth_data',
  Profile: 'profile',
  CustomerProfile: 'customerProfile',
  WorkingCompany: 'working_company',
  WorkingCompanyName: 'working_company_name',
  WorkingCompanyInfo: 'working_company_info',
  FrewieShoppingCart: 'frewie_shopping_cart',
  CustomerInfo: 'customer_info',
  // CompanyOptionInfo: 'company_option_info',
  IsRemember: 'is_remember',
  ShoppingCart: 'shopping_cart',
};
