import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { hideModal } from '../../../redux/modules/Modal';

class BasicConfirmModal extends Component {
  handleConfirmClick = () => {
    const { onConfirmClick, hideModal } = this.props;
    onConfirmClick && onConfirmClick();
    hideModal();
  };
  handleCancelClick = () => {
    const { onCancelClick, hideModal } = this.props;
    onCancelClick && onCancelClick();
    hideModal();
  };

  render() {
    const { isOpen, message, confirmButtonText, cancelButtonText } = this.props;
    return (
      <Modal isOpen={isOpen}>
        <ModalHeader>Confirmation</ModalHeader>
        <ModalBody>{message}</ModalBody>
        <ModalFooter>
          <Button outline color="primary" onClick={this.handleConfirmClick}>
            {confirmButtonText ? confirmButtonText : 'Yes'}
          </Button>
          <Button outline onClick={this.handleCancelClick}>
            {cancelButtonText ? cancelButtonText : 'No'}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

const mapStateToProps = ({ modal }) => ({ ...modal });

const mapDispatchToProps = dispatch => ({
  hideModal: bindActionCreators(hideModal, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(BasicConfirmModal);
