import React from 'react';
import LoadingOverlay from 'react-loading-overlay';
import { BounceLoader } from 'react-spinners';

export default function OverlayLoader({ active, children }) {
  LoadingOverlay.propTypes = undefined;
  return (
    <LoadingOverlay active={active} spinner={<BounceLoader />}>
      {children}
    </LoadingOverlay>
  );
}
