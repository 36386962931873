import { startSubmit } from 'redux-form';
import apiClient from '../../../helpers/apiClient';

export const GET_ROLES_REQUEST = 'GET_ROLES_REQUEST';
export const GET_ROLES_SUCCESS = 'GET_ROLES_SUCCESS';
export const GET_ROLES_ERROR = 'GET_ROLES_ERROR';

export const ADD_ROLE_REQUEST = 'ADD_ROLE_REQUEST';
export const ADD_ROLE_SUCCESS = 'ADD_ROLE_SUCCESS';
export const ADD_ROLE_ERROR = 'ADD_ROLE_ERROR';

export const UPDATE_ROLE_REQUEST = 'UPDATE_ROLE_REQUEST';
export const UPDATE_ROLE_SUCCESS = 'UPDATE_ROLE_SUCCESS';
export const UPDATE_ROLE_ERROR = 'UPDATE_ROLE_ERROR';

export const DELETE_ROLE_REQUEST = 'DELETE_ROLE_REQUEST';
export const DELETE_ROLE_SUCCESS = 'DELETE_ROLE_SUCCESS';
export const DELETE_ROLE_ERROR = 'DELETE_ROLE_ERROR';

// --- User -----
export const FETCH_USERS_REQUEST = 'FETCH_USERS_REQUEST';
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS';
export const FETCH_USERS_ERROR = 'FETCH_USERS_ERROR';

export const FETCH_USER_OPTIONS_REQUEST = 'FETCH_USER_OPTIONS_REQUEST';
export const FETCH_USER_OPTIONS_SUCCESS = 'FETCH_USER_OPTIONS_SUCCESS';
export const FETCH_USER_OPTIONS_ERROR = 'FETCH_USER_OPTIONS_ERROR';

export const FETCH_SALESMAN_OPTIONS_REQUEST = 'FETCH_SALESMAN_OPTIONS_REQUEST';
export const FETCH_SALESMAN_OPTIONS_SUCCESS = 'FETCH_SALESMAN_OPTIONS_SUCCESS';
export const FETCH_SALESMAN_OPTIONS_ERROR = 'FETCH_SALESMAN_OPTIONS_ERROR';

export const GET_USER_REQUEST = 'GET_USER_REQUEST';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_ERROR = 'GET_USER_ERROR';

export const CREATE_USER_REQUEST = 'CREATE_USER_REQUEST';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const CREATE_USER_ERROR = 'CREATE_USER_ERROR';

export const DELETE_USER_REQUEST = 'DELETE_USER_REQUEST';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_ERROR = 'DELETE_USER_ERROR';

export const DELETE_MANY_USER_REQUEST = 'DELETE_MANY_USER_REQUEST';
export const DELETE_MANY_USER_SUCCESS = 'DELETE_MANY_USER_SUCCESS';
export const DELETE_MANY_USER_ERROR = 'DELETE_MANY_USER_ERROR';

export const UPDATE_USER_ROLES_REQUEST = 'UPDATE_USER_ROLES_REQUEST';
export const UPDATE_USER_ROLES_SUCCESS = 'UPDATE_USER_ROLES_SUCCESS';
export const UPDATE_USER_ROLES_ERROR = 'UPDATE_USER_ROLES_ERROR';

export const CLEAN_ENTITY_ROLES_REQUEST = 'CLEAN_ENTITY_ROLES_REQUEST';
export const CLEAN_ENTITY_ROLES_SUCCESS = 'CLEAN_ENTITY_ROLES_SUCCESS';
export const CLEAN_ENTITY_ROLES_ERROR = 'CLEAN_ENTITY_ROLES_ERROR';

const initialState = {
  payload: {},
  data: {},
  response: {},
  error: {},
  errorMessage: '',
  successMessage: '',
};

// --- User ---
export const searchUsers = values => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [FETCH_USERS_REQUEST, FETCH_USERS_SUCCESS, FETCH_USERS_ERROR],
      callAPI: () => apiClient.post('admin/Users/search', values),
      payload: values,
    }),
  );
};

export const fetchUserOptions = id => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [FETCH_USER_OPTIONS_REQUEST, FETCH_USER_OPTIONS_SUCCESS, FETCH_USER_OPTIONS_ERROR],
      callAPI: () => apiClient.get(`admin/Users/GetSelectList`),
      payload: {},
    }),
  );
};

export const fetchSalesmanOptions = id => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [FETCH_SALESMAN_OPTIONS_REQUEST, FETCH_SALESMAN_OPTIONS_SUCCESS, FETCH_SALESMAN_OPTIONS_ERROR],
      callAPI: () => apiClient.get(`admin/Users/GetSalesmanOptions`),
      payload: {},
    }),
  );
};

export const getUser = id => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [GET_USER_REQUEST, GET_USER_SUCCESS, GET_USER_ERROR],
      callAPI: () => apiClient.get(`admin/Users/${id}`),
      payload: { id },
    }),
  );
};

export const createUser = (data, formId) => dispatch => {
  return Promise.resolve(startSubmit(formId)).then(() =>
    dispatch({
      types: [CREATE_USER_REQUEST, CREATE_USER_SUCCESS, CREATE_USER_ERROR],
      callAPI: () => apiClient.post(`admin/Users`, data),
      payload: { data, formId },
    }),
  );
};

export const deleteUser = id => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [DELETE_USER_REQUEST, DELETE_USER_SUCCESS, DELETE_USER_ERROR],
      callAPI: () => apiClient.delete(`admin/Users/${id}`),
      payload: { id },
    }),
  );
};

export const deleteManyUser = userIds => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [DELETE_MANY_USER_REQUEST, DELETE_MANY_USER_SUCCESS, DELETE_MANY_USER_ERROR],
      callAPI: () => apiClient.post(`admin/Users/DeleteMany`, { userIds }),
      payload: { userIds },
    }),
  );
};

export const updateUserRoles = data => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [UPDATE_USER_ROLES_REQUEST, UPDATE_USER_ROLES_SUCCESS, UPDATE_USER_ROLES_ERROR],
      callAPI: () => apiClient.put('admin/UserRoles', data),
      payload: data,
    }),
  );
};

// --- Role ---
export const getRoles = () => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [GET_ROLES_REQUEST, GET_ROLES_SUCCESS, GET_ROLES_ERROR],
      callAPI: () => apiClient.get('admin/Roles'),
      payload: {},
    }),
  );
};

export const addRole = data => dispatch => {
  return Promise.resolve().then(() => {
    return dispatch({
      types: [ADD_ROLE_REQUEST, ADD_ROLE_SUCCESS, ADD_ROLE_ERROR],
      callAPI: () => apiClient.post('admin/Roles', data),
      payload: data,
    });
  });
};

export const updateRole = data => dispatch => {
  return Promise.resolve().then(() => {
    return dispatch({
      types: [UPDATE_ROLE_REQUEST, UPDATE_ROLE_SUCCESS, UPDATE_ROLE_ERROR],
      callAPI: () => apiClient.put('admin/Roles', data),
      payload: data,
    });
  });
};

export const deleteRole = name => dispatch => {
  return Promise.resolve().then(() => {
    return dispatch({
      types: [DELETE_ROLE_REQUEST, DELETE_ROLE_SUCCESS, DELETE_ROLE_ERROR],
      callAPI: () => apiClient.delete(`admin/Roles/${name}`),
      payload: { name },
    });
  });
};

export const cleanEntityRoles = entity => dispatch => {
  return Promise.resolve().then(() => {
    return dispatch({
      types: [CLEAN_ENTITY_ROLES_REQUEST, CLEAN_ENTITY_ROLES_SUCCESS, CLEAN_ENTITY_ROLES_ERROR],
      callAPI: () => apiClient.delete(`admin/UserRoles/CleanEntity/${entity}`),
      payload: { entity },
    });
  });
};

export default function reducer(state = initialState, action) {
  const { payload, response, error, successMessage, errorMessage } = action;
  switch (action.type) {
    // User
    case FETCH_USERS_REQUEST:
    case FETCH_USER_OPTIONS_REQUEST:
    case GET_USER_REQUEST:
    case CREATE_USER_REQUEST:
    case DELETE_USER_REQUEST:
    case DELETE_MANY_USER_REQUEST:
    case UPDATE_USER_ROLES_REQUEST:
    case FETCH_SALESMAN_OPTIONS_REQUEST:

    // Role
    case GET_ROLES_REQUEST:
    case ADD_ROLE_REQUEST:
    case UPDATE_ROLE_REQUEST:
    case DELETE_ROLE_REQUEST:
    case CLEAN_ENTITY_ROLES_REQUEST:
      return {
        ...state,
        payload,
        successMessage: '',
        errorMessage: '',
      };

    case UPDATE_USER_ROLES_SUCCESS:
    case UPDATE_ROLE_SUCCESS:
    case ADD_ROLE_SUCCESS:
    case DELETE_ROLE_SUCCESS:
    case CLEAN_ENTITY_ROLES_SUCCESS:
    case DELETE_USER_SUCCESS:
    case DELETE_MANY_USER_SUCCESS:
    case CREATE_USER_SUCCESS:
      return {
        ...state,
        payload,
        response,
        successMessage,
      };
    case GET_ROLES_SUCCESS:
      return {
        ...state,
        data: { ...state.data, roles: response.data.data },
        payload,
        response,
        successMessage,
      };
    case FETCH_USERS_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          users: response.data.data.items,
          userPages: response.data.data.pageCount,
        },
        payload,
        response,
        successMessage,
      };
    case FETCH_USER_OPTIONS_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          userOptions: response.data.data,
        },
        response,
        successMessage,
      };
    case FETCH_SALESMAN_OPTIONS_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          salesmanOptions: response.data.data,
        },
        response,
        successMessage,
      };
    case GET_USER_SUCCESS:
      return {
        ...state,
        data: { ...state.data, user: response.data.data },
        payload,
        response,
        successMessage,
      };

    // User
    case FETCH_USERS_ERROR:
    case FETCH_USER_OPTIONS_ERROR:
    case FETCH_SALESMAN_OPTIONS_ERROR:
    case GET_USER_ERROR:
    case UPDATE_USER_ROLES_ERROR:
    case CREATE_USER_ERROR:
    case DELETE_USER_ERROR:
    case DELETE_MANY_USER_ERROR:
    // Role
    case GET_ROLES_ERROR:
    case UPDATE_ROLE_ERROR:
    case ADD_ROLE_ERROR:
    case DELETE_ROLE_ERROR:
    case CLEAN_ENTITY_ROLES_ERROR:
      return {
        ...state,
        payload,
        error,
        errorMessage,
      };
    default:
      return state;
  }
}
