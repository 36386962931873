import ApiClient from '../../../helpers/apiClient';
import { startSubmit } from 'redux-form';

export const FETCH_APPOINMENTS_REQUEST = 'FETCH_APPOINMENTS_REQUEST';
export const FETCH_APPOINMENTS_SUCCESS = 'FETCH_APPOINMENTS_SUCCESS';
export const FETCH_APPOINMENTS_ERROR = 'FETCH_APPOINMENTS_ERROR';

export const GET_APPOINMENT_DETAIL_REQUEST = 'GET_APPOINMENT_DETAIL_REQUEST';
export const GET_APPOINMENT_DETAIL_SUCCESS = 'GET_APPOINMENT_DETAIL_SUCCESS';
export const GET_APPOINMENT_DETAIL_ERROR = 'GET_APPOINMENT_DETAIL_ERROR';

export const CREATE_APPOINMENT_REQUEST = 'CREATE_APPOINMENT_REQUEST';
export const CREATE_APPOINMENT_SUCCESS = 'CREATE_APPOINMENT_SUCCESS';
export const CREATE_APPOINMENT_ERROR = 'CREATE_APPOINMENT_ERROR';

export const UPDATE_APPOINMENT_REQUEST = 'UPDATE_APPOINMENT_REQUEST';
export const UPDATE_APPOINMENT_SUCCESS = 'UPDATE_APPOINMENT_SUCCESS';
export const UPDATE_APPOINMENT_ERROR = 'UPDATE_APPOINMENT_ERROR';

export const DELETE_APPOINMENT_REQUEST = 'DELETE_APPOINMENT_REQUEST';
export const DELETE_APPOINMENT_SUCCESS = 'DELETE_APPOINMENT_SUCCESS';
export const DELETE_APPOINMENT_ERROR = 'DELETE_APPOINMENT_ERROR';

export const GET_APPOINMENT_CONFIG_REQUEST = 'GET_APPOINMENT_CONFIG_REQUEST';
export const GET_APPOINMENT_CONFIG_SUCCESS = 'GET_APPOINMENT_CONFIG_SUCCESS';
export const GET_APPOINMENT_CONFIG_ERROR = 'GET_APPOINMENT_CONFIG_ERROR';

export const UPDATE_APPOINMENT_CONFIG_REQUEST =
  'UPDATE_APPOINMENT_CONFIG_REQUEST';
export const UPDATE_APPOINMENT_CONFIG_SUCCESS =
  'UPDATE_APPOINMENT_CONFIG_SUCCESS';
export const UPDATE_APPOINMENT_CONFIG_ERROR = 'UPDATE_APPOINMENT_CONFIG_ERROR';

export const GET_TIME_PERIOD_BY_DATE_REQUEST =
  'GET_TIME_PERIOD_BY_DATE_REQUEST';
export const GET_TIME_PERIOD_BY_DATE_SUCCESS =
  'GET_TIME_PERIOD_BY_DATE_SUCCESS';
export const GET_TIME_PERIOD_BY_DATE_ERROR = 'GET_TIME_PERIOD_BY_DATE_ERROR';

export const CONFIRM_APPOINMENT_REQUEST = 'CONFIRM_APPOINMENT_REQUEST';
export const CONFIRM_APPOINMENT_SUCCESS = 'CONFIRM_APPOINMENT_SUCCESS';
export const CONFIRM_APPOINMENT_ERROR = 'CONFIRM_APPOINMENT_ERROR';

export const CANCEL_APPOINMENT_REQUEST = 'CANCEL_APPOINMENT_REQUEST';
export const CANCEL_APPOINMENT_SUCCESS = 'CANCEL_APPOINMENT_SUCCESS';
export const CANCEL_APPOINMENT_ERROR = 'CANCEL_APPOINMENT_ERROR';

export const COMPLETE_APPOINMENT_REQUEST = 'COMPLETE_APPOINMENT_REQUEST';
export const COMPLETE_APPOINMENT_SUCCESS = 'COMPLETE_APPOINMENT_SUCCESS';
export const COMPLETE_APPOINMENT_ERROR = 'COMPLETE_APPOINMENT_ERROR';

export const ADJUST_APPOINMENT_REQUEST = 'ADJUST_APPOINMENT_REQUEST';
export const ADJUST_APPOINMENT_SUCCESS = 'ADJUST_APPOINMENT_SUCCESS';
export const ADJUST_APPOINMENT_ERROR = 'ADJUST_APPOINMENT_ERROR';

export const fetchAppointments = data => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [
        FETCH_APPOINMENTS_REQUEST,
        FETCH_APPOINMENTS_SUCCESS,
        FETCH_APPOINMENTS_ERROR,
      ],
      callAPI: () => ApiClient.post('service/Appointments/Search', data),
      payload: { data },
    }),
  );
};

export const getAppointmentDetail = id => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [
        GET_APPOINMENT_DETAIL_REQUEST,
        GET_APPOINMENT_DETAIL_SUCCESS,
        GET_APPOINMENT_DETAIL_ERROR,
      ],
      callAPI: () => ApiClient.get(`service/Appointments/GetDetail/${id}`),
      payload: { data: { id } },
    }),
  );
};

export const createAppointment = (data, formId) => dispatch => {
  return Promise.resolve(dispatch(startSubmit(formId))).then(() =>
    dispatch({
      types: [
        CREATE_APPOINMENT_REQUEST,
        CREATE_APPOINMENT_SUCCESS,
        CREATE_APPOINMENT_ERROR,
      ],
      callAPI: () => ApiClient.post('service/Appointments', data),
      payload: { data, formId },
    }),
  );
};

export const updateAppointment = (id, data, formId) => dispatch => {
  return Promise.resolve(dispatch(startSubmit(formId))).then(() =>
    dispatch({
      types: [
        UPDATE_APPOINMENT_REQUEST,
        UPDATE_APPOINMENT_SUCCESS,
        UPDATE_APPOINMENT_ERROR,
      ],
      callAPI: () => ApiClient.put(`service/Appointments/${id}`, data),
      payload: { data: { ...data, id }, formId },
    }),
  );
};

export const deleteAppointment = id => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [
        DELETE_APPOINMENT_REQUEST,
        DELETE_APPOINMENT_SUCCESS,
        DELETE_APPOINMENT_ERROR,
      ],
      callAPI: () => ApiClient.delete(`service/Appointments/${id}`),
      payload: { data: { id } },
    }),
  );
};

export const getAppointmentConfig = () => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [
        GET_APPOINMENT_CONFIG_REQUEST,
        GET_APPOINMENT_CONFIG_SUCCESS,
        GET_APPOINMENT_CONFIG_ERROR,
      ],
      callAPI: () => ApiClient.get(`service/Appointments/GetConfig`),
      payload: {},
    }),
  );
};

export const updateAppointmentConfig = (data, formId) => dispatch => {
  return Promise.resolve(dispatch(startSubmit(formId))).then(() =>
    dispatch({
      types: [
        UPDATE_APPOINMENT_CONFIG_REQUEST,
        UPDATE_APPOINMENT_CONFIG_SUCCESS,
        UPDATE_APPOINMENT_CONFIG_ERROR,
      ],
      callAPI: () => ApiClient.put(`service/Appointments/UpdateConfig`, data),
      payload: { data, formId },
    }),
  );
};

export const getTimePeriodByDate = data => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [
        GET_TIME_PERIOD_BY_DATE_REQUEST,
        GET_TIME_PERIOD_BY_DATE_SUCCESS,
        GET_TIME_PERIOD_BY_DATE_ERROR,
      ],
      callAPI: () =>
        ApiClient.post(`service/Appointments/GetTimePeriodByDate`, data),
      payload: { data },
    }),
  );
};

export const confirmAppointment = (id, data, formId) => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [
        CONFIRM_APPOINMENT_REQUEST,
        CONFIRM_APPOINMENT_SUCCESS,
        CONFIRM_APPOINMENT_ERROR,
      ],
      callAPI: () => ApiClient.put(`service/Appointments/Confirm/${id}`, data),
      payload: { id, data, formId },
    }),
  );
};

export const cancelAppointment = (id, data, formId) => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [
        CANCEL_APPOINMENT_REQUEST,
        CANCEL_APPOINMENT_SUCCESS,
        CANCEL_APPOINMENT_ERROR,
      ],
      callAPI: () => ApiClient.put(`service/Appointments/Cancel/${id}`, data),
      payload: { id, data, formId },
    }),
  );
};

export const completeAppointment = id => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [
        COMPLETE_APPOINMENT_REQUEST,
        COMPLETE_APPOINMENT_SUCCESS,
        COMPLETE_APPOINMENT_ERROR,
      ],
      callAPI: () => ApiClient.put(`service/Appointments/Complete/${id}`),
      payload: { data: { id } },
    }),
  );
};

export const adjustAppointment = (id, products, formId) => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [
        ADJUST_APPOINMENT_REQUEST,
        ADJUST_APPOINMENT_SUCCESS,
        ADJUST_APPOINMENT_ERROR,
      ],
      callAPI: () => ApiClient.put(`service/Appointments/Adjust/${id}`, { products }),
      payload: { data: { id, products }, formId },
    }),
  );
};

const initialState = {
  payload: {},
  data: {},
  response: {},
  error: {},
  errorMessage: '',
  successMessage: '',
};

export default function reducer(state = initialState, action) {
  const { payload, response, error, successMessage, errorMessage } = action;
  switch (action.type) {
    case FETCH_APPOINMENTS_REQUEST:
    case GET_APPOINMENT_DETAIL_REQUEST:
    case CREATE_APPOINMENT_REQUEST:
    case UPDATE_APPOINMENT_REQUEST:
    case DELETE_APPOINMENT_REQUEST:
    case GET_APPOINMENT_CONFIG_REQUEST:
    case UPDATE_APPOINMENT_CONFIG_REQUEST:
    case GET_TIME_PERIOD_BY_DATE_REQUEST:
    case CONFIRM_APPOINMENT_REQUEST:
    case COMPLETE_APPOINMENT_REQUEST:
    case CANCEL_APPOINMENT_REQUEST:
    case ADJUST_APPOINMENT_REQUEST:
      return {
        ...state,
        payload,
        response: {},
        error: {},
        successMessage: '',
        errorMessage: '',
      };

    case FETCH_APPOINMENTS_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          items: response.data.data.items,
          pages: response.data.data.pageCount,
        },
        response,
        successMessage,
      };

    case GET_APPOINMENT_CONFIG_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          config: response.data.data,
        },
        response,
        successMessage,
      };

    case GET_APPOINMENT_DETAIL_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          item: response.data.data,
        },
        response,
        successMessage,
      };

    case CREATE_APPOINMENT_SUCCESS:
    case UPDATE_APPOINMENT_SUCCESS:
    case DELETE_APPOINMENT_SUCCESS:
    case UPDATE_APPOINMENT_CONFIG_SUCCESS:
    case GET_TIME_PERIOD_BY_DATE_SUCCESS:
    case CONFIRM_APPOINMENT_SUCCESS:
    case COMPLETE_APPOINMENT_SUCCESS:
    case CANCEL_APPOINMENT_SUCCESS:
    case ADJUST_APPOINMENT_SUCCESS:
      return {
        ...state,
        response,
        successMessage,
      };

    case FETCH_APPOINMENTS_ERROR:
    case GET_APPOINMENT_DETAIL_ERROR:
    case CREATE_APPOINMENT_ERROR:
    case UPDATE_APPOINMENT_ERROR:
    case DELETE_APPOINMENT_ERROR:
    case GET_APPOINMENT_CONFIG_ERROR:
    case UPDATE_APPOINMENT_CONFIG_ERROR:
    case GET_TIME_PERIOD_BY_DATE_ERROR:
    case CONFIRM_APPOINMENT_ERROR:
    case COMPLETE_APPOINMENT_ERROR:
    case CANCEL_APPOINMENT_ERROR:
    case ADJUST_APPOINMENT_ERROR:
      return {
        ...state,
        payload,
        error,
        errorMessage,
      };
    default:
      return state;
  }
}
