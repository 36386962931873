export const isEmpty = value =>
  value === undefined || value === null || value === '';

export const isObject = obj =>
  obj !== undefined &&
  obj !== null &&
  Object.keys(obj).length > 0 &&
  obj.constructor === Object;

export const isEmptyObj = obj =>
  obj === undefined ||
  obj === null ||
  (Object.keys(obj).length === 0 && obj.constructor === Object);

const join = rules => (value, data) =>
  rules
    .map(rule => rule(value, data))
    .filter(error => !!error)[0 /* first error */];

export const fillError = (value, { label, displayName }) => {
  let error = value;
  if (displayName) {
    label = displayName;
  }
  if (label && error.includes('${label}')) {
    error = error.split('${label}').join(label);
  }
  return error;
};

export function transformToFormErrors(errors) {
  if (!errors) return;
  const keys = Object.keys(errors);
  let result = {};

  keys.forEach(k => {
    const newKey = k.charAt(0).toLowerCase() + k.substr(1);
    result[newKey] = errors[k][0];
  });

  return result;
}

// -- validators --
export function required(value) {
  if (typeof value === typeof true && !value) {
    return '${label} is required';
  }
  if (isEmpty(value)) {
    return '${label} is required';
  }
}

export function email(value) {
  if (
    !isEmpty(value) &&
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
  ) {
    return 'Email is invalid';
  }
}

export function digit(value) {
  if (!isEmpty(value) && !/^[0-9]+$/i.test(value)) {
    return '${label} should have only digits';
  }
}

export function match(field, label) {
  return (value, data) => {
    if (data) {
      if (value !== data[field]) {
        return '${label} does not match with ' + label;
      }
    }
  };
}

export function requiredIf(field) {
  return (value, data) => {
    if (data && data[field]) {
      return required(value);
    }
  };
}

export function minLength(min) {
  return value => {
    if (!isEmpty(value) && value.length < min) {
      return '${label} should have minimum of ' + min + ' characters';
    }
  };
}

export function maxLength(max) {
  return value => {
    if (!isEmpty(value) && value.length > max) {
      return '${label} should have maximum of ' + max + ' characters';
    }
  };
}

export function fixedLength(fxlen) {
  return value => {
    if (!isEmpty(value) && value.length !== fxlen) {
      return '${label} should have exactly ' + fxlen + ' characters';
    }
  };
}

export function minValue(min) {
  return value => {
    if (!isNaN(value) && value < min) {
      return '${label} should have minimum value of ' + min;
    }
  };
}

export function maxValue(max) {
  return value => {
    if (!isNaN(value) && value > max) {
      return '${label} should have maximum value of ' + max;
    }
  };
}

export function integer(value) {
  if (!Number.isInteger(Number(value))) {
    return '${label} should be an integer';
  }
}

// -- create validators --
export function createValidator(rules) {
  return (data = {}) => {
    const errors = {};
    Object.keys(rules).forEach(key => {
      if (isObject(rules[key])) {
        const _rules = rules[key];
        Object.keys(_rules).forEach(_key => {
          const rule = join([].concat(_rules[_key]));
          const error = data[key] && rule(data[key][_key], data);

          if (error) {
            if (!errors[key]) errors[key] = {};
            errors[key] = { ...errors[key], [_key]: error };
          }
        });
      } else {
        const rule = join([].concat(rules[key])); // concat enables both functions and arrays of functions
        const error = rule(data[key], data);

        if (error) {
          errors[key] = error;
        }
      }
    });
    return errors;
  };
}

export function password(value) {
  if (
    !isEmpty(value) &&
    // !/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z!@#\$%\^&]{8,}$/.test(value)
    !/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{8,}$/.test(value)
  ) {
    return 'Password must be at least 8 characters, contains uppercase, lower case letter, alpha-numeric, special character';
  }
}
