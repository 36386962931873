import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { deleteProductFromCart, updateProductInCart } from 'redux/modules/network/ShoppingCart';
import { fetchEntityUomOptions } from 'redux/modules/network/UOM';
import OrderAddItem from './OrderAddItem';

const OrderShoppingCart = () => {
  const dispatch = useDispatch();

  const [uomOptions, setUomOptions] = useState([]);

  const products = useSelector(state => state.shoppingCart.data.products);
  const distributor = useSelector(state => state.shoppingCart.data.distributor);

  useEffect(() => {
    if (distributor) {
      dispatch(fetchEntityUomOptions(distributor.entityCode)).then(resp => {
        setUomOptions(resp.response.data.data);
      });
    }
  }, [distributor]);

  const onDeleteClick = product => {
    dispatch(deleteProductFromCart(product));
  };
  const onChangeQty = (product, qty) => {
    dispatch(updateProductInCart(product, qty));
  };

  return (
    <div style={{ maxHeight: 600, overflow: 'auto' }}>
      {products &&
        products.map((item, index) => (
          <Fragment key={index}>
            <OrderAddItem
              item={item}
              index={index}
              isEditable
              onDeleteClick={() => onDeleteClick(item)}
              uomOptions={uomOptions}
              currency = {distributor.defaultCurrency}
              onChangeQty={(index, qty) => onChangeQty(item, qty)}
            />
            {index !== products.length - 1 && <div style={{ borderBottom: `1px solid #E8E8E8` }} />}
          </Fragment>
        ))}
    </div>
  );
};

export default OrderShoppingCart;
