import React from 'react';
import { Route, Redirect, useLocation } from 'react-router-dom';
import { getStoredToken, getStoredObject } from '../../../helpers/storageHelper';
import { StorageKeys } from '../../../constants/storageKeys';
import { useSelector } from 'react-redux';
import { Roles } from 'constants/roles';

const PrivateRoute = ({ component: Component, roles, ...props }) => {
  const company = useSelector(state => state.company.data.info);
  const token = getStoredToken();
  const profile = getStoredObject(StorageKeys.Profile);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const showSteps = params.get('showSteps');

  let hasPermisison = true;
  if (roles) {
    hasPermisison =
      profile && (profile.isDmsAdmin || (profile.roleNames && profile.roleNames.find(r => roles.includes(r))));
  }

  if (company && !company.isSetupFully && !showSteps && profile.roleNames.includes(Roles.CompanyAdmin)) {
    return (
      <Redirect
        to={{
          pathname: `/general/company-detail?showSteps=true&edit=true`,
          state: { from: location.pathname },
        }}
      />
    );
  }

  return (
    <Route
      {...props}
      render={props =>
        token && hasPermisison ? (
          <div>
            <Component {...props} />
          </div>
        ) : (
          <Redirect
            to={{
              pathname: `/sign-in`,
              state: { from: location.pathname },
            }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
