import Avatar from 'views/components/Avatar';
import { UserCard } from 'views/components/Card';
import Notifications from 'views/components/Notifications';
import { notificationsData } from 'demos/header';
import withBadge from 'hocs/withBadge';
import { Roles } from 'constants/roles';
import React from 'react';
import {
  MdExitToApp,
  MdNotificationsActive,
  MdNotificationsNone,
  MdPersonPin,
  MdInbox,
  MdShoppingCart,
} from 'react-icons/md';
import {
  Button,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Nav,
  Navbar,
  NavItem,
  NavLink,
  Popover,
  PopoverBody,
} from 'reactstrap';
import bn from 'utils/bemnames';
import { logout } from '../../../redux/modules/Account';
import { updateStoredCompanyInfo } from '../../../redux/modules/network/Company';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';
import { setStoredItem, getStoredCompanyName, getStoredCompany } from '../../../helpers/storageHelper';
import { StorageKeys } from '../../../constants/storageKeys';
import { RoleNames } from '../../../constants/roles';
import { Link } from 'react-router-dom';
import Logo from 'assets/img/logo/img_logo.png';
import classnames from 'classnames';
import 'styles/css/Header.css';
import CloseIcon from 'assets/img/icons/ic_close.svg';
import OrderShoppingCart from '../Forms/order/OrderShoppingCart';
import { hasPermission } from 'services/permissionService';
import { ApiResources } from 'constants/apiResources';
import { PermissionActions } from 'constants/permissionActions';
import { getWalletBalance } from '../../../redux/modules/payment/Wallet';

const bem = bn.create('header');
const MdNotificationsActiveWithBadge = withBadge({
  size: 'md',
  color: 'primary',
  style: {
    top: -10,
    right: -10,
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  children: <small>5</small>,
})(MdNotificationsActive);

class Header extends React.Component {
  state = {
    isOpenNotificationPopover: false,
    isNotificationConfirmed: false,
    isOpenUserCardPopover: false,
    isOpenEntityCardPopover: false,
    workingCompanyName: '',
    activeTab: 1,
    isOpenModal: false,
    showSteps: false,
  };

  toggleModal = () => {
    // if (this.state.isOpenModal) {
    //   window.location.reload();
    // }

    this.setState({
      isOpenModal: !this.state.isOpenModal,
    });
  };

  handleNavLinkClick = () => {
    const { getWalletBalance, walletBalance } = this.props;

    if (walletBalance === null || walletBalance === undefined) {
      getWalletBalance();
    }
  };

  componentDidMount() {
    const { profile, customerProfile, getWalletBalance, walletBalance } = this.props;

    let workingCompany = getStoredCompany();
    const workingCompanyName = getStoredCompanyName();

    if (profile && profile.companies && profile.companies.length > 0) {
      if (!workingCompany || !workingCompanyName) {
        const companyInfo = profile.companies[0];

        setStoredItem(StorageKeys.WorkingCompany, companyInfo.entityCode);
        setStoredItem(StorageKeys.WorkingCompanyName, companyInfo.companyName);
        setStoredItem(StorageKeys.WorkingCompanyInfo, companyInfo);

        this.setState({ workingCompanyName: companyInfo.companyName });
        this.props.updateStoredCompanyInfo(companyInfo);
      } else {
        this.setState({ workingCompanyName: workingCompanyName });
      }

      getWalletBalance();
    }
    if (profile) {
      if (profile.roleNames && profile.roleNames.includes(Roles.Customer)) {
        setStoredItem(StorageKeys.CustomerInfo, customerProfile);
      }
    }

    const search = this.props.location.search;
    const params = new URLSearchParams(search);
    let showSteps = params.get('showSteps');
    this.setState({ showSteps: showSteps });

    if (showSteps) {
      this.tabToggle(7);
    }
  }

  toggleNotificationPopover = () => {
    this.setState({
      isOpenNotificationPopover: !this.state.isOpenNotificationPopover,
    });

    if (!this.state.isNotificationConfirmed) {
      this.setState({ isNotificationConfirmed: true });
    }
  };

  toggleUserCardPopover = () => {
    this.setState({
      isOpenUserCardPopover: !this.state.isOpenUserCardPopover,
    });
  };

  toggleEntityCardPopover = () => {
    this.setState({
      isOpenEntityCardPopover: !this.state.isOpenEntityCardPopover,
    });
  };

  handleSidebarControlButton = event => {
    event.preventDefault();
    event.stopPropagation();

    document.querySelector('.cr-sidebar').classList.toggle('cr-sidebar--open');
  };

  handleLogout = () => {
    this.props.logout();
    this.props.history.push('/sign-in');
  };

  goBack = () => {
    this.props.history.goBack();
  };

  goHome = () => {
    this.props.history.push('/');
  };

  tabCallback = event => {
    console.log(`event`, event);
  };

  tabToggle = tab => {
    if (this.state.activeTab !== tab) this.setState({ activeTab: tab });
  };

  handleChangeCompany = company => {
    setStoredItem(StorageKeys.WorkingCompany, company.entityCode);
    setStoredItem(StorageKeys.WorkingCompanyName, company.companyName);
    setStoredItem(StorageKeys.WorkingCompanyInfo, company);

    this.props.updateStoredCompanyInfo(company);
    window.location.reload();
  };

  render() {
    const { isNotificationConfirmed, showSteps, isWalletBalanceLoaded } = this.state;
    const { profile, currentCompanyInfo, productsInCart, walletBalance } = this.props;
    const isWalletBalanceAvailable = walletBalance !== null && walletBalance !== undefined;

    let roleLabel = '';
    let roleNames = [];
    if (profile.roleNames) {
      profile.roleNames.map(r => {
        roleNames.push(RoleNames[r]);
      });
      roleLabel = roleNames.join(', ');
    }

    let { activeTab } = this.state;

    if (!showSteps)
      switch (window.location.hash.split('/')[1]) {
        case 'dashboard':
          activeTab = 1;
          break;
        case 'admin':
          activeTab = 2;
          break;
        case 'general':
          activeTab = 3;
          break;
        case 'setup':
          activeTab = 4;
          break;
        case 'catalog':
          activeTab = 5;
          break;
        case 'purchase-order-list':
        case 'purchase-order-new':
        case 'purchase-order-edit':
        case 'purchase-order-detail':
          activeTab = 6;
          break;

        case 'wallet':
          activeTab = 8;
          break;

        default:
          activeTab = 1;
          break;
      }

    return (
      <header style={{ position: 'fixed', width: '100%', zIndex: 1000 }}>
        <Navbar light expand className={classnames(bem.b('bg-white'), 'nav-header')}>
          <img src={Logo} alt="Logo" onClick={this.goHome} />

          <Nav className="mx-auto nav-tab">
            {profile && activeTab != 7 && (
              <>
                {(profile.roleNames && !profile.roleNames.includes(Roles.Customer) && !profile.roleNames.includes(Roles.OperatorStaff) && !profile.roleNames.includes(Roles.CustomerService) && (profile.isDmsAdmin || (currentCompanyInfo && currentCompanyInfo.isSetupFully))) && (
                  <NavItem className={classnames('header-nav-item', { 'active-item-tab': activeTab === 1 })}>
                    <NavLink
                      tag={Link}
                      to={'/'}
                      className={classnames({ active: activeTab === 1 })}
                      onClick={() => this.tabToggle(1)}
                    >
                      Dashboard
                    </NavLink>
                  </NavItem>
                )}
                {(profile.roleNames && !profile.roleNames.includes(Roles.Customer) && !profile.roleNames.includes(Roles.OperatorStaff) && !profile.roleNames.includes(Roles.OperatorAdmin) && !profile.roleNames.includes(Roles.CustomerService) && (profile.isDmsAdmin || (currentCompanyInfo && currentCompanyInfo.isSetupFully))) && (
                  <NavItem className={classnames('header-nav-item', { 'active-item-tab': activeTab === 2 })}>
                    <NavLink
                      tag={Link}
                      to={'/admin'}
                      className={classnames({ active: activeTab === 2 })}
                      onClick={() => this.tabToggle(2)}
                    >
                      Admin
                    </NavLink>
                  </NavItem>
                )}

                {(profile.roleNames && !profile.roleNames.includes(Roles.Customer) && !profile.roleNames.includes(Roles.OperatorStaff) && !profile.roleNames.includes(Roles.CustomerService) && (profile.isDmsAdmin || (currentCompanyInfo && currentCompanyInfo.isSetupFully))) && (
                  <NavItem className={classnames('header-nav-item', { 'active-item-tab': activeTab === 3 })}>
                    <NavLink
                      tag={Link}
                      to={'/general'}
                      className={classnames({ active: activeTab === 3 })}
                      onClick={() => this.tabToggle(3)}
                    >
                      General
                    </NavLink>
                  </NavItem>
                )}

                {(profile.roleNames && !profile.roleNames.includes(Roles.Customer) && (profile.isDmsAdmin || (currentCompanyInfo && currentCompanyInfo.isSetupFully))) && (
                  <NavItem className={classnames('header-nav-item', { 'active-item-tab': activeTab === 4 })}>
                    <NavLink
                      tag={Link}
                      to={'/setup'}
                      className={classnames({ active: activeTab === 4 })}
                      onClick={() => this.tabToggle(4)}
                    >
                      Setup
                    </NavLink>
                  </NavItem>
                )}

                {/* {profile.roleNames && !profile.roleNames.includes(Roles.OperatorStaff) && !profile.roleNames.includes(Roles.CustomerService) && !profile.roleNames.includes(Roles.Customer) && (profile.isDmsAdmin || (profile.roleNames && profile.roleNames.includes(Roles.Customer)) || (currentCompanyInfo && currentCompanyInfo.isSetupFully)) && (
                  <NavItem className={classnames('header-nav-item', { 'active-item-tab': activeTab === 5 })}>
                    <NavLink
                      tag={Link}
                      to={'/catalog'}
                      className={classnames({ active: activeTab === 5 })}
                      onClick={() => this.tabToggle(5)}
                    >
                      Catalog
                    </NavLink>
                  </NavItem>
                )} */}


                {profile && hasPermission(ApiResources.PurchaseOrder, PermissionActions.View) && (
                  <NavItem className={classnames('header-nav-item', { 'active-item-tab': activeTab === 6 })}>
                    <NavLink
                      tag={Link}
                      to={'/purchase-order-list'}
                      className={classnames({ active: activeTab === 6 })}
                      onClick={() => {
                        this.tabToggle(6);
                        this.handleNavLinkClick(); // Fetch wallet balance when NavLink is clicked
                      }}
                    >
                      Order
                    </NavLink>
                  </NavItem>
                )}

                {profile && profile.roleNames && profile.roleNames.includes(Roles.Customer) && !profile.roleNames.includes(Roles.OperatorStaff) && (
                  <NavItem className={classnames('header-nav-item', { 'active-item-tab': activeTab === 8 })}>
                    <NavLink
                      tag={Link}
                      to={'/wallet'}
                      className={classnames({ active: activeTab === 8 })}
                      onClick={() => {
                        this.tabToggle(8);
                        this.handleNavLinkClick(); // Fetch wallet balance when NavLink is clicked
                      }}
                    >
                      Wallet
                    </NavLink>
                  </NavItem>
                )}
              </>
            )}

            {activeTab === 7 && (
              <NavItem className={classnames('header-nav-item', { 'active-item-tab': true })}>
                <NavLink tag={Link} className={classnames({ active: true })} to="#">
                  SignUp
                </NavLink>
              </NavItem>
            )}
          </Nav>

          {profile && profile.roleNames && (
            <Nav navbar className={bem.e('nav-right')} style={{ marginLeft: 0 }}>
              {profile && profile.companies && (
                <NavItem className="d-inline-flex">
                  <NavLink id="EntityPop">
                    <div onClick={this.toggleEntityCardPopover} className="can-click">
                      {this.state.workingCompanyName}
                    </div>
                    <div>
                      {(profile.roleNames && profile.roleNames.includes(Roles.Customer) && (!profile.isDmsAdmin || (currentCompanyInfo && currentCompanyInfo.isSetupFully))) && (
                        isWalletBalanceAvailable ? (
                          <div>Wallet Balance: {walletBalance}</div>
                        ) : (
                          <div onClick={this.handleNavLinkClick} className="can-click">Loading wallet balance...</div>
                        )
                      )}
                    </div>
                  </NavLink>
                  <Popover
                    placement="bottom-end"
                    isOpen={this.state.isOpenEntityCardPopover}
                    toggle={this.toggleEntityCardPopover}
                    target="EntityPop"
                    className="p-0 border-0"
                    style={{ minWidth: 250 }}
                  >
                    <PopoverBody className="p-0 border-light">
                      <ListGroup flush>
                        {profile &&
                          profile.companies.map((com, idx) => (
                            <ListGroupItem
                              tag="button"
                              action
                              className="border-light"
                              key={idx}
                              onClick={() => this.handleChangeCompany(com)}
                            >
                              {com.label}
                            </ListGroupItem>
                          ))}
                      </ListGroup>
                    </PopoverBody>
                  </Popover>
                </NavItem>
              )}
              {/* <NavItem className="d-inline-flex">
  <NavLink id="Popover1" className="position-relative">
    {isNotificationConfirmed ? (
      <MdNotificationsNone
        size={25}
        className="text-secondary can-click"
        onClick={this.toggleNotificationPopover}
      />
    ) : (
      <MdNotificationsActiveWithBadge
        size={25}
        className="text-secondary can-click animated swing infinite"
        onClick={this.toggleNotificationPopover}
      />
    )}
  </NavLink>
  <Popover
    placement="bottom"
    isOpen={this.state.isOpenNotificationPopover}
    toggle={this.toggleNotificationPopover}
    target="Popover1"
  >
    <PopoverBody>
      <Notifications notificationsData={notificationsData} />
    </PopoverBody>
  </Popover>
</NavItem> */}

              <NavItem>
                <NavLink id="Popover2">
                  <Avatar onClick={this.toggleUserCardPopover} className="can-click" />
                </NavLink>
                <Popover
                  placement="bottom-end"
                  isOpen={this.state.isOpenUserCardPopover}
                  toggle={this.toggleUserCardPopover}
                  target="Popover2"
                  className="p-0 border-0"
                  style={{ minWidth: 250 }}
                >
                  <PopoverBody className="p-0 border-light">
                    <UserCard
                      title={
                        (profile.firstName ? profile.firstName : '') + ' ' + (profile.lastName ? profile.lastName : '')
                      }
                      subtitle={profile.userName}
                      text={roleLabel}
                      className="border-light"
                    >
                      <ListGroup flush>
                        <ListGroupItem tag="button" action className="border-light">
                          <Link to="/profile" onClick={this.toggleUserCardPopover}>
                            <MdPersonPin /> Profile
                          </Link>
                        </ListGroupItem>
                        <ListGroupItem tag="button" action className="border-light">
                          <Link to="/invitations" onClick={this.toggleUserCardPopover}>
                            <MdInbox /> Invitations
                          </Link>
                        </ListGroupItem>
                        <ListGroupItem tag="button" action className="border-light">
                          <div onClick={this.handleLogout}>
                            <MdExitToApp /> Signout
                          </div>
                        </ListGroupItem>
                      </ListGroup>
                    </UserCard>
                  </PopoverBody>
                </Popover>
              </NavItem>
              {/* {productsInCart && productsInCart.length > 0 && (
                <NavItem>
                  <NavLink id="Popover3">
                    <MdShoppingCart size={40} className="can-click" color="#d83e7c" onClick={this.toggleModal} />
                    <span>{productsInCart.length}</span>
                  </NavLink>
                  <Modal isOpen={this.state.isOpenModal} toggle={this.toggleModal} className="modal-xl">
                    <ModalHeader
                      toggle={this.toggleModal}
                      close={
                        <button className="close" onClick={this.toggleModal}>
                          <img src={CloseIcon} />
                        </button>
                      }
                    >
                      <MdShoppingCart size={30} color="#d83e7c" /> Purchase Order Shopping Cart
                    </ModalHeader>
                    <ModalBody>
                      <OrderShoppingCart />
                    </ModalBody>
                    <ModalFooter>
                      <Button
                        outline
                        color="primary"
                        style={{ marginBottom: '1rem' }}
                        onClick={() => {
                          this.props.history.push({
                            pathname: '/purchase-order-new',
                            state: { isFromCatalog: true }  // Pass the isFromCatalog prop
                          });
                          this.toggleModal();
                        }}
                      >
                        Order
                      </Button>
                    </ModalFooter>
                  </Modal>
                </NavItem>
              )} */}
            </Nav>
          )}
        </Navbar>
      </header>
    );
  }
}

const mapStateToProps = ({ account, company, shoppingCart, wallet }) => ({
  profile: account.profile,
  customerProfile: account.customerProfile,
  currentCompanyInfo: company.data.info,
  productsInCart: shoppingCart.data.products,
  walletBalance: wallet.data.item ? wallet.data.item.walletBalance : null,
});

const mapDispatchToProps = dispatch => ({
  logout: bindActionCreators(logout, dispatch),
  updateStoredCompanyInfo: bindActionCreators(updateStoredCompanyInfo, dispatch),
  getWalletBalance: bindActionCreators(getWalletBalance, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
