import React from 'react';
import { Card, Col, Row } from 'reactstrap';
import { getProfile, login, registerAgent } from '../../../redux/modules/Account';
import { connect } from 'react-redux';
import Logo from 'assets/img/logo/logo_no_text.png';
import AgentRegisterForm, {
  agentRegisterFormId,
} from '../../components/Forms/AgentRegisterForm';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { showMessageModal } from '../../../redux/modules/Modal';

const initialValues = {
  email: '',
  password: '',
  confirmedPassword: '',
  countryCode: '',
  contactNumber: '',
  agentName: '',
  identityCardNumber: '',
  tncAgree: false,
};

class AgentSignupPage extends React.Component {
  state = {
    token: '',
  };

  componentDidMount() {
    const searchParam = this.props.location.search;
    let token = '';
    if (searchParam) {
      token = searchParam.substring(1).split('=')[1];
    }

    this.setState({ token: token });
  }

  handleSubmit = (values, dispatch) => {
    const { token } = this.state;

    values.token = token;

    dispatch(registerAgent(values, agentRegisterFormId)).then(resp => {
      this.props.showMessageModal('You have registered successfully.', () => {
        dispatch(
          login(
            { email: values.email, password: values.password },
            agentRegisterFormId,
          ),
        ).then(resp => {
          dispatch(getProfile()).then(data => {
            this.props.history.push('/');
          });
        });
      });
    });
  };

  render() {
    return (
      <Row
        style={{
          height: '100vh',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Col md={6} lg={4}>
          <Card body>
            <div className="row mb-4">
              <div className="col-12 text-left pl-4 pt-3">
                <h4 className="title">
                  <Link to="/">
                    <img
                      src={Logo}
                      className="rounded"
                      alt="logo"
                    />
                  </Link>{' '}
                  Sign Up
                </h4>
              </div>
            </div>
            <AgentRegisterForm
              initialValues={initialValues}
              onSubmit={this.handleSubmit}
            />
          </Card>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = state => ({});
const mapDispatchToProps = dispatch => ({
  showMessageModal: bindActionCreators(showMessageModal, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(AgentSignupPage);
