import React from 'react';
import ValidationMessage from '../Controls/ValidationMessage';
import classnames from 'classnames';

const TextAreaField = ({
  input,
  showLabel,
  required,
  horizontal,
  label,
  placeholder,
  meta: { touched, error },
  ...props
}) => (
  <div className="col-12 form-group">
    <div className="row">
      {horizontal && showLabel && (
        <div className="col-sm-4 col-form-label">
          <label>
            {label}
            {required && !props.disabled && <span className="mark-required">*</span>}
          </label>
        </div>
      )}
      {horizontal && (
        <div className="col-sm-8">
          <textarea
            className={classnames('form-control c-text-input', {
              'textbox-error': error && touched,
            })}
            placeholder={placeholder}
            {...input}
            {...props}
          ></textarea>
          <ValidationMessage label={label} touched={touched} error={error} />
        </div>
      )}

      {!horizontal && showLabel && (
        <label>
          {label}
          {required && !props.disabled && <span className="mark-required">*</span>}
        </label>
      )}
      {!horizontal && (
        <textarea
          className={classnames('form-control c-text-input', {
            'textbox-error': error && touched,
          })}
          placeholder={placeholder}
          {...input}
          {...props}
        ></textarea>
      )}
      {!horizontal && <ValidationMessage label={label} touched={touched} error={error} />}
    </div>
  </div>
);

export default TextAreaField;
