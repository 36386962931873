import ApiClient from '../../../helpers/apiClient';
import { startSubmit } from 'redux-form';
import { getStoredCompanyInfo } from 'helpers/storageHelper';

export const FETCH_COMPANY_OPTION_REQUEST = 'FETCH_COMPANY_OPTION_REQUEST';
export const FETCH_COMPANY_OPTION_SUCCESS = 'FETCH_COMPANY_OPTION_SUCCESS';
export const FETCH_COMPANY_OPTION_ERROR = 'FETCH_COMPANY_OPTION_ERROR';

export const FETCH_CONNECTED_COMPANY_OPTION_REQUEST = 'FETCH_CONNECTED_COMPANY_OPTION_REQUEST';
export const FETCH_CONNECTED_COMPANY_OPTION_SUCCESS = 'FETCH_CONNECTED_COMPANY_OPTION_SUCCESS';
export const FETCH_CONNECTED_COMPANY_OPTION_ERROR = 'FETCH_CONNECTED_COMPANY_OPTION_ERROR';

export const FETCH_COMPANY_REQUEST = 'FETCH_COMPANY_REQUEST';
export const FETCH_COMPANY_SUCCESS = 'FETCH_COMPANY_SUCCESS';
export const FETCH_COMPANY_ERROR = 'FETCH_COMPANY_ERROR';

export const FETCH_WORKING_COMPANY_REQUEST = 'FETCH_WORKING_COMPANY_REQUEST';
export const FETCH_WORKING_COMPANY_SUCCESS = 'FETCH_WORKING_COMPANY_SUCCESS';
export const FETCH_WORKING_COMPANY_ERROR = 'FETCH_WORKING_COMPANY_ERROR';

export const GET_COMPANY_DETAIL_REQUEST = 'GET_COMPANY_DETAIL_REQUEST';
export const GET_COMPANY_DETAIL_SUCCESS = 'GET_COMPANY_DETAIL_SUCCESS';
export const GET_COMPANY_DETAIL_ERROR = 'GET_COMPANY_DETAIL_ERROR';

export const GET_CURRENT_COMPANY_DETAIL_REQUEST = 'GET_CURRENT_DETAIL_REQUEST';
export const GET_CURRENT_COMPANY_DETAIL_SUCCESS = 'GET_COMPANY_CURRENT_DETAIL_SUCCESS';
export const GET_CURRENT_COMPANY_DETAIL_ERROR = 'GET_COMPANY_CURRENT_DETAIL_ERROR';

export const GET_COMPANY_INFO_BY_CODE_REQUEST = 'GET_COMPANY_INFO_BY_CODE_REQUEST';
export const GET_COMPANY_INFO_BY_CODE_SUCCESS = 'GET_COMPANY_INFO_BY_CODE_SUCCESS';
export const GET_COMPANY_INFO_BY_CODE_ERROR = 'GET_COMPANY_INFO_BY_CODE_ERROR';

export const CREATE_COMPANY_REQUEST = 'CREATE_COMPANY_REQUEST';
export const CREATE_COMPANY_SUCCESS = 'CREATE_COMPANY_SUCCESS';
export const CREATE_COMPANY_ERROR = 'CREATE_COMPANY_ERROR';

export const UPDATE_COMPANY_REQUEST = 'UPDATE_COMPANY_REQUEST';
export const UPDATE_COMPANY_SUCCESS = 'UPDATE_COMPANY_SUCCESS';
export const UPDATE_COMPANY_ERROR = 'UPDATE_COMPANY_ERROR';

export const DELETE_COMPANY_REQUEST = 'DELETE_COMPANY_REQUEST';
export const DELETE_COMPANY_SUCCESS = 'DELETE_COMPANY_SUCCESS';
export const DELETE_COMPANY_ERROR = 'DELETE_COMPANY_ERROR';

export const CHECK_SETUP_REQUEST = 'CHECK_SETUP_REQUEST';
export const CHECK_SETUP_SUCCESS = 'CHECK_SETUP_SUCCESS';
export const CHECK_SETUP_ERROR = 'CHECK_SETUP_ERROR';

export const UPDATE_STORED_COMPANY_INFO_REQUEST = 'UPDATE_STORED_COMPANY_INFO_REQUEST';

// --Company Profile----------------
export const fetchCompanyOptions = () => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [FETCH_COMPANY_OPTION_REQUEST, FETCH_COMPANY_OPTION_SUCCESS, FETCH_COMPANY_OPTION_ERROR],
      callAPI: () => ApiClient.get('network/CompanyProfiles/GetSelectList'),
      payload: {},
    }),
  );
};

export const fetchConnectedCompanyOptions = () => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [
        FETCH_CONNECTED_COMPANY_OPTION_REQUEST,
        FETCH_CONNECTED_COMPANY_OPTION_SUCCESS,
        FETCH_CONNECTED_COMPANY_OPTION_ERROR,
      ],
      callAPI: () => ApiClient.get('network/CompanyProfiles/GetConnectedDistributorOptions'),
      payload: {},
    }),
  );
};

export const fetchCompanies = data => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [FETCH_COMPANY_REQUEST, FETCH_COMPANY_SUCCESS, FETCH_COMPANY_ERROR],
      callAPI: () => ApiClient.post('network/CompanyProfiles/Search', data),
      payload: { data },
    }),
  );
};

export const getCompanyDetail = id => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [GET_COMPANY_DETAIL_REQUEST, GET_COMPANY_DETAIL_SUCCESS, GET_COMPANY_DETAIL_ERROR],
      callAPI: () => ApiClient.get(`network/CompanyProfiles/GetDetail/${id}`),
      payload: { data: { id } },
    }),
  );
};
export const getCurrentCompanyDetail = () => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [GET_CURRENT_COMPANY_DETAIL_REQUEST, GET_CURRENT_COMPANY_DETAIL_SUCCESS, GET_CURRENT_COMPANY_DETAIL_ERROR],
      callAPI: () => ApiClient.get(`network/CompanyProfiles/GetDetail`),
      payload: {},
    }),
  );
};

export const getCompanyInfoByCode = code => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [GET_COMPANY_INFO_BY_CODE_REQUEST, GET_COMPANY_INFO_BY_CODE_SUCCESS, GET_COMPANY_INFO_BY_CODE_ERROR],
      callAPI: () => ApiClient.get(`network/CompanyProfiles/GetInfo/${code}`),
      payload: { data: { code } },
    }),
  );
};

export const createCompany = (data, formId) => dispatch => {
  return Promise.resolve(dispatch(startSubmit(formId))).then(() =>
    dispatch({
      types: [CREATE_COMPANY_REQUEST, CREATE_COMPANY_SUCCESS, CREATE_COMPANY_ERROR],
      callAPI: () => ApiClient.post('network/CompanyProfiles', data),
      payload: { data, formId },
    }),
  );
};

export const updateCompany = (id, data, formId) => dispatch => {
  return Promise.resolve(dispatch(startSubmit(formId))).then(() =>
    dispatch({
      types: [UPDATE_COMPANY_REQUEST, UPDATE_COMPANY_SUCCESS, UPDATE_COMPANY_ERROR],
      callAPI: () => ApiClient.put(`network/CompanyProfiles/${id}`, data),
      payload: { data: { ...data, id }, formId },
    }),
  );
};

export const deleteCompany = id => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [DELETE_COMPANY_REQUEST, DELETE_COMPANY_SUCCESS, DELETE_COMPANY_ERROR],
      callAPI: () => ApiClient.delete(`network/CompanyProfiles/${id}`),
      payload: { data: { id } },
    }),
  );
};

export const isSetupFully = () => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [CHECK_SETUP_REQUEST, CHECK_SETUP_SUCCESS, CHECK_SETUP_ERROR],
      callAPI: () => ApiClient.post(`network/CompanyProfiles/IsSetupFully`),
      payload: {},
    }),
  );
};

export const updateStoredCompanyInfo = companyInfo => dispatch => {
  dispatch({
    type: UPDATE_STORED_COMPANY_INFO_REQUEST,
    payload: { companyInfo },
  });

  return Promise.resolve();
};

const companyInfo = getStoredCompanyInfo();
const initialState = {
  payload: {},
  data: {
    info: companyInfo,
  },
  response: {},
  error: {},
  errorMessage: '',
  successMessage: '',
};

export default function reducer(state = initialState, action) {
  const { payload, response, error, successMessage, errorMessage } = action;
  switch (action.type) {
    // Company
    case FETCH_COMPANY_OPTION_REQUEST:
    case FETCH_COMPANY_REQUEST:
    case FETCH_WORKING_COMPANY_REQUEST:
    case GET_COMPANY_DETAIL_REQUEST:
    case GET_CURRENT_COMPANY_DETAIL_REQUEST:
    case GET_COMPANY_INFO_BY_CODE_REQUEST:
    case CREATE_COMPANY_REQUEST:
    case UPDATE_COMPANY_REQUEST:
    case DELETE_COMPANY_REQUEST:
    case CHECK_SETUP_REQUEST:
    case FETCH_CONNECTED_COMPANY_OPTION_REQUEST:
      return {
        ...state,
        payload,
        response: {},
        error: {},
        successMessage: '',
        errorMessage: '',
      };

    case UPDATE_STORED_COMPANY_INFO_REQUEST:
      return {
        ...state,
        data: { ...state.data, info: payload.companyInfo },
        payload,
        response: {},
        error: {},
        successMessage: '',
        errorMessage: '',
      };

    case FETCH_COMPANY_OPTION_SUCCESS:
      return {
        ...state,
        data: { ...state.data, options: response.data.data },
        response,
        successMessage,
      };
    case FETCH_CONNECTED_COMPANY_OPTION_SUCCESS:
      return {
        ...state,
        data: { ...state.data, distributorOptions: response.data.data },
        response,
        successMessage,
      };

    case FETCH_WORKING_COMPANY_SUCCESS:
      return {
        ...state,
        data: { ...state.data, workingCompanies: response.data.data },
        response,
        successMessage,
      };

    case FETCH_COMPANY_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          items: response.data.data.items,
          pages: response.data.data.pageCount,
        },
        response,
        successMessage,
      };
    case GET_COMPANY_DETAIL_SUCCESS:
    case GET_CURRENT_COMPANY_DETAIL_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          item: response.data.data,
        },
        response,
        successMessage,
      };
    case GET_COMPANY_INFO_BY_CODE_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          info: response.data.data,
        },
        response,
        successMessage,
      };

    case CREATE_COMPANY_SUCCESS:
    case UPDATE_COMPANY_SUCCESS:
      return {
        ...state,
        response,
        data: { ...state.data, item: payload.data },
        successMessage,
      };

    case DELETE_COMPANY_SUCCESS:
    case CHECK_SETUP_SUCCESS:
      return {
        ...state,
        response,
        successMessage,
      };

    case FETCH_COMPANY_OPTION_ERROR:
    case FETCH_COMPANY_ERROR:
    case FETCH_WORKING_COMPANY_ERROR:
    case GET_COMPANY_DETAIL_ERROR:
    case GET_CURRENT_COMPANY_DETAIL_ERROR:
    case GET_COMPANY_INFO_BY_CODE_ERROR:
    case CREATE_COMPANY_ERROR:
    case UPDATE_COMPANY_ERROR:
    case DELETE_COMPANY_ERROR:
    case CHECK_SETUP_ERROR:
    case FETCH_CONNECTED_COMPANY_OPTION_ERROR:
      return {
        ...state,
        payload,
        error,
        errorMessage,
      };
    default:
      return state;
  }
}
