import ApiClient from '../../../helpers/apiClient';
import { startSubmit } from 'redux-form';

export const FETCH_FREWIE_PLAN_REQUEST = 'FETCH_FREWIE_PLAN_REQUEST';
export const FETCH_FREWIE_PLAN_SUCCESS = 'FETCH_FREWIE_PLAN_SUCCESS';
export const FETCH_FREWIE_PLAN_ERROR = 'FETCH_FREWIE_PLAN_ERROR';

export const FETCH_FREWIE_PLAN_OPTIONS_REQUEST =
    'FETCH_FREWIE_PLAN_OPTIONS_REQUEST';
export const FETCH_FREWIE_PLAN_OPTIONS_SUCCESS =
    'FETCH_FREWIE_PLAN_OPTIONS_SUCCESS';
export const FETCH_FREWIE_PLAN_OPTIONS_ERROR =
    'FETCH_FREWIE_PLAN_OPTIONS_ERROR';

export const GET_FREWIE_PLAN_DETAIL_REQUEST =
    'GET_FREWIE_PLAN_DETAIL_REQUEST';
export const GET_FREWIE_PLAN_DETAIL_SUCCESS =
    'GET_FREWIE_PLAN_DETAIL_SUCCESS';
export const GET_FREWIE_PLAN_DETAIL_ERROR = 'GET_FREWIE_PLAN_DETAIL_ERROR';

export const CREATE_FREWIE_PLAN_REQUEST = 'CREATE_FREWIE_PLAN_REQUEST';
export const CREATE_FREWIE_PLAN_SUCCESS = 'CREATE_FREWIE_PLAN_SUCCESS';
export const CREATE_FREWIE_PLAN_ERROR = 'CREATE_FREWIE_PLAN_ERROR';

export const UPDATE_FREWIE_PLAN_REQUEST = 'UPDATE_FREWIE_PLAN_REQUEST';
export const UPDATE_FREWIE_PLAN_SUCCESS = 'UPDATE_FREWIE_PLAN_SUCCESS';
export const UPDATE_FREWIE_PLAN_ERROR = 'UPDATE_FREWIE_PLAN_ERROR';

export const DELETE_FREWIE_PLAN_REQUEST = 'DELETE_FREWIE_PLAN_REQUEST';
export const DELETE_FREWIE_PLAN_SUCCESS = 'DELETE_FREWIE_PLAN_SUCCESS';
export const DELETE_FREWIE_PLAN_ERROR = 'DELETE_FREWIE_PLAN_ERROR';

export const IMPORT_SPARK_PLAN_REQUEST = 'IMPORT_SPARK_PLAN_REQUEST';
export const IMPORT_SPARK_PLAN_SUCCESS = 'IMPORT_SPARK_PLAN_SUCCESS';
export const IMPORT_SPARK_PLAN_ERROR = 'IMPORT_SPARK_PLAN_ERROR';

export const IMPORT_PRODUCT_VARIANT_REQUEST = 'IMPORT_PRODUCT_VARIANT_REQUEST';
export const IMPORT_PRODUCT_VARIANT_SUCCESS = 'IMPORT_PRODUCT_VARIANT_SUCCESS';
export const IMPORT_PRODUCT_VARIANT_ERROR = 'IMPORT_PRODUCT_VARIANT_ERROR';


export const FETCH_PACKAGE_PLAN_REQUEST = 'FETCH_PACKAGE_PLAN_REQUEST';
export const FETCH_PACKAGE_PLAN_SUCCESS = 'FETCH_PACKAGE_PLAN_SUCCESS';
export const FETCH_PACKAGE_PLAN_ERROR = 'FETCH_PACKAGE_PLAN_ERROR';


export const importSparkPackageVariantPlan = (formFileData, id) => dispatch => {
    return Promise.resolve().then(() =>
        dispatch({
            types: [
                IMPORT_SPARK_PLAN_REQUEST,
                IMPORT_SPARK_PLAN_SUCCESS,
                IMPORT_SPARK_PLAN_ERROR,
            ],
            callAPI: () =>
                ApiClient.post(`product/FrewieProductPlan/Import/${id}`, formFileData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                }),
            payload: {},
        }),
    );
};

export const importProductVariantPlan = (formFileData, id) => dispatch => {
    return Promise.resolve().then(() =>
        dispatch({
            types: [
                IMPORT_PRODUCT_VARIANT_REQUEST,
                IMPORT_PRODUCT_VARIANT_SUCCESS,
                IMPORT_PRODUCT_VARIANT_ERROR,
            ],
            callAPI: () =>
                ApiClient.post(`product/FrewieProductPlan/ImportProductVariant/${id}`, formFileData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                }),
            payload: {},
        }),
    );
};

export const exportProductVariant = (productId) => dispatch => {
    return Promise.resolve().then(() =>
      dispatch({
        types: [IMPORT_PRODUCT_VARIANT_REQUEST, IMPORT_PRODUCT_VARIANT_SUCCESS, IMPORT_PRODUCT_VARIANT_ERROR],
        callAPI: () => ApiClient.get(`product/FrewieProductPlan/ExportProductVariant/${productId}`),
        payload: {  },
      }),
    );
  };

export const fetchFrewiePackagePlanImportHistories = data => dispatch => {
    return Promise.resolve().then(() =>
        dispatch({
            types: [
                FETCH_PACKAGE_PLAN_REQUEST,
                FETCH_PACKAGE_PLAN_SUCCESS,
                FETCH_PACKAGE_PLAN_ERROR,
            ],
            callAPI: () => ApiClient.post(`product/FrewieProductPlan/SearchImportHistory`, data),
            payload: { data },
        }),
    );
};

export const fetchFrewiePlan = data => dispatch => {
    return Promise.resolve().then(() =>
        dispatch({
            types: [
                FETCH_FREWIE_PLAN_REQUEST,
                FETCH_FREWIE_PLAN_SUCCESS,
                FETCH_FREWIE_PLAN_ERROR,
            ],
            callAPI: () => ApiClient.post('product/FrewieProductPlan/Search', data),
            payload: { data },
        }),
    );
};

export const fetchFrewiePlanOptions = () => dispatch => {
    return Promise.resolve().then(() =>
        dispatch({
            types: [
                FETCH_FREWIE_PLAN_OPTIONS_REQUEST,
                FETCH_FREWIE_PLAN_OPTIONS_SUCCESS,
                FETCH_FREWIE_PLAN_OPTIONS_ERROR,
            ],
            callAPI: () => ApiClient.get('product/FrewieProductPlan/GetSelectList'),
            payload: {},
        }),
    );
};

export const getFrewiePlanDetail = id => dispatch => {
    return Promise.resolve().then(() =>
        dispatch({
            types: [
                GET_FREWIE_PLAN_DETAIL_REQUEST,
                GET_FREWIE_PLAN_DETAIL_SUCCESS,
                GET_FREWIE_PLAN_DETAIL_ERROR,
            ],
            callAPI: () => ApiClient.get(`product/FrewieProductPlan/${id}`),
            payload: { data: { id } },
        }),
    );
};

export const createFrewiePlan = (data, formId) => dispatch => {
    return Promise.resolve(dispatch(startSubmit(formId))).then(() =>
        dispatch({
            types: [
                CREATE_FREWIE_PLAN_REQUEST,
                CREATE_FREWIE_PLAN_SUCCESS,
                CREATE_FREWIE_PLAN_ERROR,
            ],
            callAPI: () => ApiClient.post('product/FrewieProductPlan', data),
            payload: { data, formId },
        }),
    );
};

export const updateFrewiePlan = (id, data, formId) => dispatch => {
    return Promise.resolve(dispatch(startSubmit(formId))).then(() =>
        dispatch({
            types: [
                UPDATE_FREWIE_PLAN_REQUEST,
                UPDATE_FREWIE_PLAN_SUCCESS,
                UPDATE_FREWIE_PLAN_ERROR,
            ],
            callAPI: () => ApiClient.put(`product/FrewieProductPlan/${id}`, data),
            payload: { data: { ...data, id }, formId },
        }),
    );
};

export const deleteFrewiePlan = id => dispatch => {
    return Promise.resolve().then(() =>
        dispatch({
            types: [
                DELETE_FREWIE_PLAN_REQUEST,
                DELETE_FREWIE_PLAN_SUCCESS,
                DELETE_FREWIE_PLAN_ERROR,
            ],
            callAPI: () => ApiClient.delete(`product/FrewieProductPlan/${id}`),
            payload: { data: { id } },
        }),
    );
};

const initialState = {
    payload: {},
    data: {},
    response: {},
    error: {},
    errorMessage: '',
    successMessage: '',
};

export default function reducer(state = initialState, action) {
    const { payload, response, error, successMessage, errorMessage } = action;
    switch (action.type) {
        case FETCH_FREWIE_PLAN_REQUEST:
        case FETCH_FREWIE_PLAN_OPTIONS_REQUEST:
        case GET_FREWIE_PLAN_DETAIL_REQUEST:
        case CREATE_FREWIE_PLAN_REQUEST:
        case UPDATE_FREWIE_PLAN_REQUEST:
        case DELETE_FREWIE_PLAN_REQUEST:
            return {
                ...state,
                payload,
                response: {},
                error: {},
                successMessage: '',
                errorMessage: '',
            };

        case FETCH_FREWIE_PLAN_OPTIONS_SUCCESS:
            return {
                ...state,
                data: { ...state.data, options: response.data.data },
                response,
                successMessage,
            };

        case FETCH_FREWIE_PLAN_SUCCESS:
        case FETCH_PACKAGE_PLAN_SUCCESS:
            return {
                ...state,
                data: {
                    ...state.data,
                    items: response.data.data.items,
                    pages: response.data.data.pageCount,
                },
                response,
                successMessage,
            };

        case GET_FREWIE_PLAN_DETAIL_SUCCESS:
            return {
                ...state,
                data: {
                    ...state.data,
                    item: response.data.data,
                },
                response,
                successMessage,
            };

        case CREATE_FREWIE_PLAN_SUCCESS:
        case UPDATE_FREWIE_PLAN_SUCCESS:
        case DELETE_FREWIE_PLAN_SUCCESS:
            return {
                ...state,
                response,
                successMessage,
            };

        case FETCH_FREWIE_PLAN_ERROR:
        case FETCH_FREWIE_PLAN_OPTIONS_ERROR:
        case GET_FREWIE_PLAN_DETAIL_ERROR:
        case CREATE_FREWIE_PLAN_ERROR:
        case UPDATE_FREWIE_PLAN_ERROR:
        case DELETE_FREWIE_PLAN_ERROR:
            return {
                ...state,
                payload,
                error,
                errorMessage,
            };
        default:
            return state;
    }
}
