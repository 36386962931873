import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, Col, Row } from 'reactstrap';
import Logo from 'assets/img/logo/logo_no_text.png';
import ForgotPasswordForm, {
  forgotPasswordFormId,
} from '../../components/Forms/admin/ForgotPasswordForm';
import { Link } from 'react-router-dom';
import { forgotPassword } from '../../../redux/modules/Account';
import { showMessageModal } from '../../../redux/modules/Modal';

const initialValues = {
  email: '',
};

class ForgotPasswordPage extends Component {
  handleSubmit = (values, dispatch) => {
    dispatch(forgotPassword(values, forgotPasswordFormId)).then(resp => {
      dispatch(showMessageModal('A reset link has been sent to your email'));
    });
  };

  render() {
    return (
      <Row
        id="forgotPassword"
        style={{
          height: '100vh',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Col md={6} lg={4}>
          <Card body>
            <div className="row mb-4">
              <div className="col-12 text-left pl-4 pt-3">
                <h4 className="title">
                  <Link to="/">
                    <img
                      src={Logo}
                      className="rounded"
                      alt="logo"
                    />
                  </Link>{' '}
                  Forgot Password
                </h4>
              </div>
            </div>
            <ForgotPasswordForm
              initialValues={initialValues}
              onSubmit={this.handleSubmit}
            />
          </Card>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = ({ account }) => ({});
const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordPage);
