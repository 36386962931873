export const formatAmount = amount => {
  if (amount == undefined || amount == '') {
    return '0.00';
  }

  let fixedAmt = parseFloat(amount).toFixed(2);
  const numberPart = fixedAmt.substring(0, fixedAmt.length - 3);
  const decimalPart = fixedAmt.substring(fixedAmt.length - 3, fixedAmt.length);

  return parseInt(numberPart).toLocaleString() + decimalPart;
};

export const formatNumber = number => {
  if (number == undefined || number == '') {
    return '';
  }

  return number.toLocaleString();
};

export const isNullOrUndefined = value => {
  return value == null || value == undefined;
};

export const valueIfNullOrUndefined = (value, newValue) => {
  if (value == null || value == undefined) {
    return newValue;
  }

  return value;
};
