import { Content, GeneralSideBar } from 'views/components/Layout';
import React from 'react';
import { connect } from 'react-redux';
import { Switch } from 'react-router-dom';
import PrivateRoute from '../Routes/PrivateRoute';
import PageSpinner from '../PageSpinner';
import { generalRoutes } from 'views/routes';

class GeneralLayout extends React.Component {
  static isSidebarOpen() {
    const sideBar = document.querySelector('.cr-sidebar');
    return sideBar && sideBar.classList.contains('cr-sidebar--open');
  }

  componentWillReceiveProps({ breakpoint }) {
    if (breakpoint !== this.props.breakpoint) {
      this.checkBreakpoint(breakpoint);
    }
  }

  componentDidMount() {
    this.checkBreakpoint(this.props.breakpoint);
  }

  // close sidebar when
  handleContentClick = event => {
    // close sidebar if sidebar is open and screen size is less than `md`
    if (
      GeneralLayout.isSidebarOpen() &&
      (this.props.breakpoint === 'xs' || this.props.breakpoint === 'sm' || this.props.breakpoint === 'md')
    ) {
      this.openSidebar('close');
    }
  };

  checkBreakpoint(breakpoint) {
    switch (breakpoint) {
      case 'xs':
      case 'sm':
      case 'md':
        return this.openSidebar('close');

      case 'lg':
      case 'xl':
      default:
        return this.openSidebar('open');
    }
  }

  openSidebar(openOrClose) {
    const sideBar = document.querySelector('.cr-sidebar');
    if (!sideBar) {
      return;
    }

    if (openOrClose === 'open') {
      return sideBar.classList.add('cr-sidebar--open');
    }
    sideBar.classList.remove('cr-sidebar--open');
  }

  render() {
    const showSteps = window.location.hash.includes('showSteps=true');

    return (
      <div className="cr-app bg-app" style={{ overflow: 'hidden' }}>
        {!showSteps && <GeneralSideBar />}

        <Content fluid onClick={this.handleContentClick}>
          <Switch>
            <React.Suspense fallback={<PageSpinner />}>
              {generalRoutes.map((route, idx) => {
                return (
                  <PrivateRoute
                    key={idx}
                    exact={route.exact}
                    path={`/general${route.path}`}
                    component={route.component}
                  />
                );
              })}
            </React.Suspense>
          </Switch>
        </Content>
      </div>
    );
  }
}

const mapStateToProps = state => ({});
const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(GeneralLayout);
