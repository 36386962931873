import React from 'react';
import { Col, Row } from 'reactstrap';
import { getProfile, getCustomerProfile, login } from '../../../redux/modules/Account';
import { connect } from 'react-redux';
import LoginForm, { loginFormId } from '../../components/Forms/LoginForm';
import Logo from 'assets/img/logo/logo_frewie.png';
import { isSetupFully } from 'redux/modules/network/Company';
import { Roles } from 'constants/roles';
import { StorageKeys } from 'constants/storageKeys';
import { setStoredItem } from 'helpers/storageHelper';
import { setCookie } from 'helpers/cookieHelper';
import { fetchConnectedCompanyOptions } from 'redux/modules/network/Company';
import { getStoredObject } from '../../../helpers/storageHelper';

const initialValues = {
  email: '',
  password: '',
};

class SignInPage extends React.Component {
  handleSubmit = (values, dispatch) => {
    setCookie(StorageKeys.IsRemember, values.isRemember, 1);

    dispatch(login(values, loginFormId)).then(resp => {
      this.getUserProfile(dispatch);
    });
  };

  getUserProfile = dispatch => {
    dispatch(getProfile()).then(data => {
      const profile = data.response.data.data;
      console.log(profile);

      setStoredItem(StorageKeys.Profile, profile);
      if (profile.roleNames.includes(Roles.Customer)) {
        dispatch(getCustomerProfile()).then(data => {
          const customerProfile = data.response.data.data;
          setStoredItem(StorageKeys.CustomerInfo, customerProfile);
        });
      }

      if (profile.roleNames.includes(Roles.CompanyAdmin) || profile.userRoleEntities.some(entity => entity.roleName === 'Customer')) {
        const companyInfo = profile.companies[0];
        console.log(profile);
        console.log(companyInfo);
        setStoredItem(StorageKeys.WorkingCompany, companyInfo.entityCode);
        this.checkCompanySetup(dispatch);
      } else {
        this.props.history.push('/');
      }
    });
  };

  checkCompanySetup = dispatch => {
    dispatch(isSetupFully()).then(result => {
      if (result.response.data.data.isMissing == true) {
        const step = result.response.data.data.step;
        if (step == 0) {
          this.props.history.push('general/company-detail?showSteps=true&edit=true');
        } else if (step == 2) {
          this.props.history.push('setup/product-list?showSteps=true');
        } else {
          this.props.history.push('/');
        }
      }
      else {
        const profile = getStoredObject(StorageKeys.Profile);

        if (profile.roleNames && profile.roleNames.includes(Roles.Customer) && (!profile.isDmsAdmin)) {
          this.props.history.push('/purchase-order-list');
        }
        else {
          this.props.history.push('/');
        }
      }
    });
  };

  render() {
    return (
      <Row
        id="signIn"
        style={{
          height: '100vh',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundImage: 'url(/img/bg/img_login.png)',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'contain',
          backgroundPosition: 'left bottom',
        }}
      >
        <Col md={6} lg={{ size: 6, offset: 6 }}>
          <div className="mx-auto" style={{ width: 600 }}>
            <div className="row" style={{ marginBottom: 100 }}>
              <div className="col-12 text-left pl-4 pt-3">
                <img src={Logo} alt="logo" className="mb-3" />
                <h1 className="title"> Welcome to Frewie</h1>
              </div>
            </div>

            <LoginForm initialValues={initialValues} onSubmit={this.handleSubmit} />
          </div>
        </Col>
      </Row>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    login: (email, password) => dispatch(login(email, password)),
  };
};

export default connect(null, mapDispatchToProps)(SignInPage);
