import sidebarBgImage from 'assets/img/sidebar/network-2.jpg';
import SourceLink from 'views/components/SourceLink';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { Nav, Navbar, NavItem, NavLink as BSNavLink } from 'reactstrap';
import bn from 'utils/bemnames';
import { navAdminItems } from '../../../configs/navigationItems';
import { getStoredObject } from '../../../helpers/storageHelper';
import { StorageKeys } from '../../../constants/storageKeys';
import { connect } from 'react-redux';
import cx from 'classnames';
import { PATH_ADMIN } from 'configs/navigationUrl';
import { hasPermission } from 'services/permissionService';

const sidebarBackground = {
  backgroundImage: `url("${sidebarBgImage}")`,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
};

const bem = bn.create('sidebar');

class AdminSidebar extends React.Component {
  state = {
    userRoles: [],
    currentLink: '',
  };

  componentDidMount() {
    const path = window.location.hash.slice('#/admin'.length);
    const roleNames = this.getRoleNames();
    this.setState({ currentLink: path, userRoles: roleNames });
  }

  showGroup = (navGroupItems, userRoles) => {
    return navGroupItems.some(item => this.showNavItem(item.roles, userRoles, item.resource, item.action));
  };

  showNavItem = (rolesAllow, userRoles, resource, action) => {
    if (!rolesAllow) {
      const { profile } = this.props;
      
      if (profile && profile.isDmsAdmin) return true;

      return hasPermission(resource, action, profile.permissions);
    }
    if (rolesAllow.length === 0) {
      return false;
    }
    return userRoles.some(r => rolesAllow.includes(r?.roleName));
    // return userRoles.some(r => rolesAllow.includes(r));
  };

  getRoleNames = () => {
    let { profile } = this.props;
    if (!profile || !profile.userRoleEntities) {
      profile = getStoredObject(StorageKeys.Profile);
    }
    if (profile && profile.userRoleEntities) {
      return profile.userRoleEntities;
    } else {
      return [];
    }
  };

  renderMenuItem = ({ to, name, exact, Icon, roles, childrenRoutes, resource, action }, index) => {
    const { userRoles, currentLink } = this.state;
    const path = window.location.hash.substring('#/admin'.length);

    let isCurrentLink = path === to;
    if (!isCurrentLink) {
      if (currentLink && currentLink === to) {
        isCurrentLink = true;
      } else if (childrenRoutes && childrenRoutes.find(r => path.startsWith(r))) {
        isCurrentLink = true;
      }
    }

    return (
      this.showNavItem(roles, userRoles, resource, action) && (
        <NavItem key={index} className={cx({ 'active-item-tab': isCurrentLink })}>
          <BSNavLink
            id={`navItem-${name}-${index}`}
            tag={NavLink}
            to={`${PATH_ADMIN}${to}`}
            className={isCurrentLink ? 'active' : ''}
            exact={exact}
            onClick={() => this.setState({ currentLink: to })}
          >
            <Icon className={bem.e('nav-item-icon')} />
            <span className="">{name}</span>
          </BSNavLink>
        </NavItem>
      )
    );
  };

  render() {
    const { userRoles } = this.state;

    return (
      <aside className={bem.b()} data-image={sidebarBgImage}>
        <div className={bem.e('background')} style={sidebarBackground} />
        <div className={bem.e('content')}>
          <Navbar>
            <SourceLink className="navbar-brand d-flex">
              <span className="text-white">DMS</span>
            </SourceLink>
          </Navbar>
          <Nav vertical>
            {this.showGroup(navAdminItems, userRoles) &&
              navAdminItems.map((item, index) => this.renderMenuItem(item, index))}
          </Nav>
        </div>
      </aside>
    );
  }
}

const mapStateToProps = ({ account }) => ({
  profile: account.profile,
});

export default connect(mapStateToProps, null)(AdminSidebar);
