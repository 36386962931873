import { storageBaseUrl } from 'configs/settings';
import React, { useEffect, useState, Fragment } from 'react';
import { Button, Card, CardBody, Col, Row, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import styles from 'styles/css/ProductCard.module.css';
import { MdAddShoppingCart } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { addProductToCart } from 'redux/modules/network/ShoppingCart';
import CloseIcon from 'assets/img/icons/ic_close.svg';
import VariantCartItem from '../Forms/order/VariantCartItem';
import ProductCartItem from '../Forms/order/ProductCartItem';
import DiscountInfo from '../Forms/order/DiscountInfo';
import { formatAmount } from 'helpers/numberHelper';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchCountryOptions } from 'redux/modules/product/Country';
import Select from 'react-select';
import CButton from 'views/components/Controls/CButton';


const DistributorProductCard = ({ item, uomOptions, distributor, fetchCountryOptions, countryOptions, days, data, country }) => {
  const dispatch = useDispatch();

  const products = useSelector(state => state.shoppingCart.data.products);

  const [qty, setQty] = useState(0);
  const [price, setPrice] = useState(item.unitPrice);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [variants, setVariants] = useState([]);
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [searchDays, setSearchDays] = useState('');
  const [searchDataCapacity, setDataCapacity] = useState('');
  const [isSearchingDataPlan, setSearchingDataPlan] = useState(false);
  const [dataTypeChecked, setDataTypeChecked] = useState(true);
  const [dayPassTypeChecked, setDayPassTypeChecked] = useState(true);
  const [filteredVariants, setFilteredVariants] = useState([]);

  let uomCode = 'RM';
  if (item.uomId && uomOptions) {
    uomCode = uomOptions.find(o => o.value == item.uomId)?.label;
  }

  useEffect(() => {
    if (products) {
      const items = products.filter(
        p => p.productCode == item.productCode && (!item.variantCode || p.variantCode == item.variantCode),
      );

      if (items && items.length > 0) {
        let totalQty = 0;

        items.forEach(p => {
          totalQty += parseFloat(p.qty);
        });
        setQty(totalQty);
      }
    }
  }, [products]);

  useEffect(() => {
    setSearchDays(days);
  }, [days]);

  useEffect(() => {
    setDataCapacity(data);
  }, [data]);

  useEffect(() => {
    setSelectedCountries(country);
  }, [country]);

  useEffect(() => {
    setFilteredVariants(item.productVariants);
  }, [item.productVariants]);

  const handleChangeVariantQuantity = (variantCode, qty, price) => {
    var variant = item.productVariants.find(v => v.variantCode == variantCode);
    let newVariants = variants.filter(v => v.variantCode != variantCode);
    newVariants.push({ ...variant, qty: parseFloat(qty) });

    setVariants(newVariants);

    let totalQty = 0;
    newVariants.forEach(v => {
      totalQty += parseFloat(v.qty);
    });

    setQty(totalQty);
    setPrice(price);
  };

  const handleChangeProductQuantity = (qty, price) => {
    setQty(parseFloat(qty));
    setPrice(price);
  };

  const addToCart = () => {
    if (item.productVariants && item.productVariants.length > 0) {
      if (variants && variants.length > 0) {
        variants.forEach(v => {
          dispatch(
            addProductToCart({
              productName: item.productName,
              productCode: item.productCode,
              variantId: v.variantId,
              days: v.days,
              data: v.data,
              isDayPass: v.isDayPass,
              variantCode: v.variantCode,
              variantValue: v.variant,
              variantName: v.variantName,
              qty: v.qty,
              unitPrice: price,
              image: v.image ?? item.image,
              uomId: item.uomId,
            }),
          );
        });
      }
    } else {
      dispatch(
        addProductToCart({
          productName: item.productName,
          productCode: item.productCode,
          qty: qty,
          unitPrice: price,
          image: item.image,
          uomId: item.uomId,
        }),
      );
    }

    toggleModal();
  };

  const toggleModal = () => {
    setIsOpenModal(!isOpenModal);
  };

  const handleReset = () => {
    setSearchDays('')
    setDataCapacity('')

    setFilteredVariants(item.productVariants);
  }

  const handleSearch = ({ id, value }) => {
    if (!searchDays) {
      alert('Please select number of days.');
      return;
    }
    if (!searchDataCapacity) {
      alert('Please select data capacity.');
      return;
    }

    const filteredVariants = item.productVariants.filter(variant => {
      if (dayPassTypeChecked && variant.isDayPass) {
        // If dayPassTypeChecked is true, allow variants where isDayPass is true
        return variant.days >= parseInt(searchDays) && variant.data >= parseInt(searchDataCapacity);
      }

      if (dataTypeChecked && !variant.isDayPass) {
        // If dataTypeChecked is true, allow variants where isDayPass is false
        return variant.days >= parseInt(searchDays) && variant.data >= parseInt(searchDataCapacity);
      }

      return false;  // Exclude other cases
    });

    setFilteredVariants(filteredVariants);
  };


  return (
    <Card>
      <img
        className={styles.cardImage}
        src={item.image ? storageBaseUrl + '/' + item.image : ''}
        alt={item.productName}
      />
      <CardBody className={styles.cardBody}>
        <div className={styles.cardProductHead}>{item.productName}</div>
        <Row>
          <Col>
            <div className={styles.cardProductSub}>Code: {item.productCode}</div>
            <div className="pt-1">
              {item.productVariants && item.productVariants.length > 0 && <i>Multi Variants</i>}
              {(!item.productVariants || item.productVariants.length == 0) && (
                <i>
                  <b>
                    {distributor.defaultCurrency} {item.unitPrice ? formatAmount(item.unitPrice) : '0.00'}
                  </b>{' '}
                  / {uomCode}
                </i>
              )}
            </div>
          </Col>
          <Col>
            <Button outline color="primary" style={{ marginBottom: '1rem' }} onClick={toggleModal}>
              <MdAddShoppingCart /> Add to cart {qty ? `(${qty})` : ''}
            </Button>
          </Col>
        </Row>
        <div className={styles.cardProductDescription}>
          <div dangerouslySetInnerHTML={{ __html: item.description }} />
        </div>

        <Modal isOpen={isOpenModal} toggle={toggleModal} className="modal-xl">
          <ModalHeader
            toggle={toggleModal}
            close={
              <button className="close" onClick={toggleModal}>
                <img src={CloseIcon} />
              </button>
            }
          >
            <MdAddShoppingCart size={30} color="#d83e7c" /> Add To Cart
          </ModalHeader>
          <ModalBody>

            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Select
                name="countryCodes"
                isMulti
                options={countryOptions}
                label="Country"
                placeholder={'Select Country...'}
                value={selectedCountries}
                isDisabled={true}
                styles={{
                  container: base => ({
                    ...base,
                    width: 300,
                    border: '1px solid lightgray'
                  })
                }}
              />
              <input
                type="text"
                value={searchDays}
                onChange={(e) => setSearchDays(e.target.value)}
                style={{ marginLeft: '1rem', borderRadius: '5px', border: '1px solid lightgray', padding: '0.5rem', width: '80px' }}
              />
              <span style={{ marginLeft: '0.5rem' }}>Days</span>

              <input
                type="text"
                value={searchDataCapacity}
                onChange={(e) => setDataCapacity(e.target.value)}
                style={{ marginLeft: '1rem', borderRadius: '5px', border: '1px solid lightgray', padding: '0.5rem', width: '80px' }}
              />
              <span style={{ marginLeft: '0.5rem' }}>GB</span>


              <CButton
                loading={isSearchingDataPlan}
                onClick={handleSearch}
                style={{
                  borderRadius: '5px',
                  color: 'white',
                  marginLeft: '1rem',
                  backgroundColor: '#212529',
                  border: '1px solid lightgray',
                  padding: '0.5rem 1rem',
                  fontSize: '16px',
                  fontFamily: "'Helvetica Neue', sans-serif",
                  fontWeight: 'bold'
                }}
              >
                Search
              </CButton>

              <CButton
                onClick={handleReset}
                style={{
                  borderRadius: '5px',
                  marginLeft: '1rem',
                  color: 'white',
                  backgroundColor: 'orange',
                  border: '1px solid lightgray',
                  padding: '0.5rem 1rem',
                  fontSize: '16px',
                  fontFamily: "'Helvetica Neue', sans-serif",
                  fontWeight: 'bold'
                }}
              >
                Reset
              </CButton>
            </div>
            <div className="d-flex mt-2">
              <label style={{ fontSize: '1.2rem' }}>
                <input
                  type="checkbox"
                  checked={dataTypeChecked}
                  onChange={e => setDataTypeChecked(e.target.checked)}
                  style={{ transform: 'scale(1.5)', marginRight: '0.5rem' }}
                />
                Data Type
              </label>

              <label style={{ marginLeft: '1rem', fontSize: '1.2rem' }}>
                <input
                  type="checkbox"
                  checked={dayPassTypeChecked}
                  onChange={e => setDayPassTypeChecked(e.target.checked)}
                  style={{ transform: 'scale(1.5)', marginRight: '0.5rem' }}
                />
                Day-Pass Type
              </label>
            </div>

            {item.discountInfos && item.discountInfos.length > 0 && (
              <DiscountInfo discounts={item.discountInfos} currency={distributor.defaultCurrency} uomCode={uomCode} />
            )}

            <div style={{ maxHeight: 600, overflow: 'auto' }}>
              {filteredVariants &&
                filteredVariants.map((v, index) => {
                  return (
                    <Fragment key={index}>
                      <VariantCartItem
                        item={{
                          productId: item.productId,
                          variantId: v.variantId,
                          image: v.image,
                          productCode: item.productCode,
                          productName: item.productName,
                          data: v.data,
                          days: v.days,
                          networkName: v.networkName,
                          variantCode: v.variantCode,
                          isDayPass: v.isDayPass,
                          variantValue: v.variant,
                          variantName: v.variantName,
                          unitPrice: v.unitPrice,
                          uomId: item.uomId,
                          discounts: item.discountInfos,
                        }}
                        uomOptions={uomOptions}
                        onChangeQty={handleChangeVariantQuantity}
                        currency={distributor.defaultCurrency}
                      />
                      {index !== products.length - 1 && <div style={{ borderBottom: `1px solid #E8E8E8` }} />}
                    </Fragment>
                  );
                })}
              {/* {(!filteredVariants || filteredVariants.length === 0) && (
                <ProductCartItem
                  item={{
                    productId: item.productId,
                    image: item.image,
                    productCode: item.productCode,
                    productName: item.productName,
                    unitPrice: item.unitPrice,
                    uomId: item.uomId,
                    discounts: item.discountInfos,
                  }}
                  uomOptions={uomOptions}
                  onChangeQty={handleChangeProductQuantity}
                  currency={distributor.defaultCurrency}
                />
              )} */}
            </div>

          </ModalBody>
          <ModalFooter>
            <Button outline color="primary" style={{ marginBottom: '1rem' }} onClick={addToCart}>
              Add To Cart
            </Button>
          </ModalFooter>
        </Modal>
      </CardBody>
    </Card>
  );
};

const mapStateToProps = ({ country }) => ({
  countryOptions: country.data.options,
});

const mapDispatchToProps = dispatch => ({
  fetchCountryOptions: bindActionCreators(fetchCountryOptions, dispatch),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DistributorProductCard);