import { createStore, applyMiddleware, compose } from 'redux';
import rootReducer from './modules/Reducer';
import thunk from 'redux-thunk';
import callAPIMiddleware from './middlewares/CallApiMiddleware';
import { routerMiddleware, connectRouter } from 'connected-react-router';

export default function configureStore(history, initialState) {
  const middleware = [thunk, callAPIMiddleware, routerMiddleware(history)];
  
  // In development, use the browser's Redux dev tools extension if installed
  const enhancers = [];
  const isDevelopment = process.env.NODE_ENV === 'development';
  if (
    // isDevelopment &&
    typeof window !== 'undefined' &&
    window.__REDUX_DEVTOOLS_EXTENSION__
  ) {
    enhancers.push(window.__REDUX_DEVTOOLS_EXTENSION__());
  }
  if (isDevelopment) {
    //middleware.push(logger)
  }

  return createStore(
    connectRouter(history)(rootReducer(history)),
    initialState,
    compose(applyMiddleware(...middleware), ...enhancers),
  );
}
