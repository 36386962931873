import React from 'react';
import { Card, Col, Row } from 'reactstrap';
import { getProfile, login, registerBusinessCustomer, registerDistributor } from '../../../redux/modules/Account';
import { connect } from 'react-redux';
import Logo from 'assets/img/logo/logo_no_text.png';
import RegisterForm, { registerFormId } from '../../components/Forms/RegisterForm';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { showMessageModal } from '../../../redux/modules/Modal';
import { setStoredItem } from 'helpers/storageHelper';
import { StorageKeys } from 'constants/storageKeys';

class SignUpPage extends React.Component {
  state = {
    token: '',
    email: '',
  };
  componentDidMount() {
    const params = new URLSearchParams(this.props.location.search);
    this.setState({ token: params.get('token'), email: params.get('email') });
  }

  handleSubmit = (values, dispatch) => {
    const { token } = this.state;
    if (token) {
      values.token = token;
      this.registerBusinessCustomer(values, dispatch);
    } else {
      this.registerDistributor(values, dispatch);
    }
  };

  registerDistributor = (values, dispatch) => {
    dispatch(registerDistributor(values, registerFormId)).then(resp => {
      this.props.showMessageModal(
        'You have registered successfully.',
        // + 'Please check your email for confirmation.'
        () => {
          dispatch(login({ email: values.email, password: values.password }, registerFormId)).then(resp => {
            dispatch(getProfile()).then(data => {
              const profile = data.response.data.data;
              setStoredItem(StorageKeys.Profile, profile);

              const companyInfo = profile.companies[0];
              setStoredItem(StorageKeys.WorkingCompany, companyInfo.entityCode);

              this.props.history.push('/general/company-detail?showSteps=true&edit=true');
            });
          });
        },
      );
    });
  };

  registerBusinessCustomer = (values, dispatch) => {
    dispatch(registerBusinessCustomer(values, registerFormId)).then(resp => {
      this.props.showMessageModal(
        'You have registered successfully.',
        // + 'Please check your email for confirmation.'
        () => {
          dispatch(login({ email: values.email, password: values.password }, registerFormId)).then(resp => {
            dispatch(getProfile()).then(data => {
              const profile = data.response.data.data;
              setStoredItem(StorageKeys.Profile, profile);

              const companyInfo = profile.companies[0];
              setStoredItem(StorageKeys.WorkingCompany, companyInfo.entityCode);

              this.props.history.push('/general/company-detail?showSteps=true&edit=true');
            });
          });
        },
      );
    });
  };

  render() {
    const initialValues = {
      email: this.state.email,
      password: '',
      firstName: '',
      lastName: '',
      countryCode: '',
      phoneNumber: '',
      companyName: '',
      companyCode: '',
      contactNumber: '',
      tncAgree: false,
    };

    const enableInputEmail = !initialValues.email;

    return (
      <Row
        style={{
          height: '100vh',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Col md={6} lg={4}>
          <Card body>
            <div className="row mb-4">
              <div className="col-12 text-left pl-4 pt-3">
                <h4 className="title">
                  <Link to="/">
                    <img src={Logo} className="rounded" alt="logo" />
                  </Link>{' '}
                  Sign Up
                </h4>
              </div>
            </div>
            <RegisterForm
              initialValues={initialValues}
              onSubmit={this.handleSubmit}
              enableInputEmail={enableInputEmail}
            />
          </Card>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = state => ({});
const mapDispatchToProps = dispatch => ({
  showMessageModal: bindActionCreators(showMessageModal, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(SignUpPage);
