import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import GAListener from 'views/components/GAListener';
import { EmptyLayout, LayoutRoute, MainLayout } from 'views/components/Layout';
import { publicRoutes } from './views/routes';
import React from 'react';
import componentQueries from 'react-component-queries';
import { HashRouter, Redirect, Switch, withRouter } from 'react-router-dom';
import './styles/reduction.scss';
import { connect } from 'react-redux';

class App extends React.Component {
  render() {
    return (
      <GAListener>
        <HashRouter>
          <Switch>
            {publicRoutes.map((route, idx) => {
              return (
                <LayoutRoute
                  key={idx}
                  exact={route.exact}
                  path={route.path}
                  layout={EmptyLayout}
                  component={props => <route.component {...props} />}
                />
              );
            })}
            <MainLayout />
            <Redirect to="/" />
          </Switch>
        </HashRouter>
      </GAListener>
    );
  }
}

const query = ({ width }) => {
  if (width < 575) {
    return { breakpoint: 'xs' };
  }

  if (576 < width && width < 767) {
    return { breakpoint: 'sm' };
  }

  if (768 < width && width < 991) {
    return { breakpoint: 'md' };
  }

  if (992 < width && width < 1199) {
    return { breakpoint: 'lg' };
  }

  if (width > 1200) {
    return { breakpoint: 'xl' };
  }

  return { breakpoint: 'xs' };
};

App = componentQueries(query)(App);

const mapStateToProps = state => ({});
const mapDispatchToProps = dispatch => ({});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
