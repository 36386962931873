import React, { Component } from 'react';
import FrewiePlanForm, { frewiePlanFormId } from '../../../components/Forms/frewie/FrewiePlanForm';
import { showMessageModal } from '../../../../redux/modules/Modal';
import { connect } from 'react-redux';
import { createFrewiePlan } from '../../../../redux/modules/frewie/FrewiePlan';
import Page from 'views/components/Page';

const initialValues = {
  planName: '',
  description: '',
};

class FrewiePlanNew extends Component {
  handleSubmit = (values, dispatch) => {
    dispatch(createFrewiePlan(values, frewiePlanFormId)).then(resp => {
      dispatch(
        showMessageModal('Create successfully!', () =>
          this.props.history.push('/setup/frewie-plan-list')
        ),
      );
    });
  };

  render() {
    return (
      <Page
        title="Product Plan New"
        breadcrumbs={[
          { name: 'Product Plan', link: '/setup/frewie-plan-list' },
          { name: 'New', active: true },
        ]}
      >
        <FrewiePlanForm initialValues={initialValues} onSubmit={this.handleSubmit} isEditting={true} />
      </Page>
    );
  }
}

const mapStateToProps = state => ({});
const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(FrewiePlanNew);
