import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { showMessageModal, showConfirmMessage } from '../../../../redux/modules/Modal';
import FrewiePlanForm, { frewiePlanFormId } from '../../../components/Forms/frewie/FrewiePlanForm';
import { getFrewiePlanDetail, deleteFrewiePlan, updateFrewiePlan } from '../../../../redux/modules/frewie/FrewiePlan';
import Page from 'views/components/Page';

class FrewiePlanEdit extends Component {
  state = {
    isEditting: false,
  };

  componentDidMount() {
    const id = this.props.match.params.id;
    this.props.getFrewiePlanDetail(id);
  }

  handleChangeEditMode = isEditting => {
    this.setState({ isEditting: isEditting });
  };

  handleSubmit = (values, dispatch) => {
    const id = this.props.match.params.id;
    const { showMessageModal } = this.props;

    dispatch(updateFrewiePlan(id, values, frewiePlanFormId)).then(resp => {
      showMessageModal('Update successfully!', () => {
        // this.handleChangeEditMode(false);
        this.props.history.push('/setup/frewie-plan-list');
      });
    });
  };

  handleDelete = () => {
    const id = this.props.match.params.id;
    const { deleteFrewiePlan, showMessageModal, showConfirmMessage, history } = this.props;

    showConfirmMessage('Are you sure to delete?', () => {
      deleteFrewiePlan(id).then(resp => {
        showMessageModal('Delete successfully!', () => this.props.history.push('/setup/frewie-plan-list'));
      });
    });
  };

  render() {
    const { item } = this.props;
    return (
      <Page
        title="Product Plan Detail"
        breadcrumbs={[
          { name: 'Product Plan', link: '/setup/frewie-plan-list' },
          { name: 'Detail', active: true },
        ]}
      >
        <FrewiePlanForm
          initialValues={item}
          onSubmit={this.handleSubmit}
          onChangeEditMode={this.handleChangeEditMode}
          isEditting={this.state.isEditting}
          onDelete={this.handleDelete}
        />
      </Page>
    );
  }
}

const mapStateToProps = ({ frewiePlan }) => ({
  item: frewiePlan.data.item,
});

const mapDispatchToProps = dispatch => ({
  getFrewiePlanDetail: bindActionCreators(getFrewiePlanDetail, dispatch),
  deleteFrewiePlan: bindActionCreators(deleteFrewiePlan, dispatch),
  showConfirmMessage: bindActionCreators(showConfirmMessage, dispatch),
  showMessageModal: bindActionCreators(showMessageModal, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(FrewiePlanEdit);
