import { baseUrl } from '../../configs/settings';
import { StorageKeys } from '../../constants/storageKeys';
import { getStoredObject } from 'helpers/storageHelper';

export const UPLOAD_FILE_REQUEST = 'UPLOAD_FILE_REQUEST';
export const UPLOAD_FILE_SUCCESS = 'UPLOAD_FILE_SUCCESS';
export const UPLOAD_FILE_ERROR = 'UPLOAD_FILE_ERROR';

export const getUploadHeaders = () => {
  const authData = getStoredObject(StorageKeys.AuthData);
  const headers = {
    Authorization: 'Bearer ' + authData.access_token,
  };
  return headers;
};

export const getServiceUploadParams = service => {
  return () => {
    return {
      url: baseUrl + `/${service}/files`,
      headers: getUploadHeaders(),
    };
  };
};

const initialState = {
  payload: {},
  response: {},
  error: {},
  errorMessage: '',
  successMessage: '',
};

export default function reducer(state = initialState, action) {
  const { payload, response, error, successMessage, errorMessage } = action;
  switch (action.type) {
    case UPLOAD_FILE_REQUEST:
      return {
        ...state,
        payload,
        successMessage: '',
        errorMessage: '',
      };

    case UPLOAD_FILE_SUCCESS:
      return {
        ...state,
        payload,
        response,
        successMessage,
      };

    case UPLOAD_FILE_ERROR:
      return {
        ...state,
        payload,
        error,
        errorMessage,
      };
    default:
      return state;
  }
}
