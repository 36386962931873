import ApiClient from '../../../helpers/apiClient';
import { startSubmit } from 'redux-form';

export const FETCH_TRANSACTIONS_REQUEST = 'FETCH_TRANSACTIONS_REQUEST';
export const FETCH_TRANSACTIONS_SUCCESS = 'FETCH_TRANSACTIONS_SUCCESS';
export const FETCH_TRANSACTIONS_ERROR = 'FETCH_TRANSACTIONS_ERROR';

export const GET_TRANSACTION_DETAIL_REQUEST = 'GET_TRANSACTION_DETAIL_REQUEST';
export const GET_TRANSACTION_DETAIL_SUCCESS = 'GET_TRANSACTION_DETAIL_SUCCESS';
export const GET_TRANSACTION_DETAIL_ERROR = 'GET_TRANSACTION_DETAIL_ERROR';

export const CREATE_TRANSACTION_REQUEST = 'CREATE_TRANSACTION_REQUEST';
export const CREATE_TRANSACTION_SUCCESS = 'CREATE_TRANSACTION_SUCCESS';
export const CREATE_TRANSACTION_ERROR = 'CREATE_TRANSACTION_ERROR';

export const UPDATE_TRANSACTION_REQUEST = 'UPDATE_TRANSACTION_REQUEST';
export const UPDATE_TRANSACTION_SUCCESS = 'UPDATE_TRANSACTION_SUCCESS';
export const UPDATE_TRANSACTION_ERROR = 'UPDATE_TRANSACTION_ERROR';

export const DELETE_TRANSACTION_REQUEST = 'DELETE_TRANSACTION_REQUEST';
export const DELETE_TRANSACTION_SUCCESS = 'DELETE_TRANSACTION_SUCCESS';
export const DELETE_TRANSACTION_ERROR = 'DELETE_TRANSACTION_ERROR';

export const fetchTransactions = data => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [
        FETCH_TRANSACTIONS_REQUEST,
        FETCH_TRANSACTIONS_SUCCESS,
        FETCH_TRANSACTIONS_ERROR,
      ],
      callAPI: () => ApiClient.post('payment/Transactions/Search', data),
      payload: { data },
    }),
  );
};

export const getTransactionDetail = id => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [
        GET_TRANSACTION_DETAIL_REQUEST,
        GET_TRANSACTION_DETAIL_SUCCESS,
        GET_TRANSACTION_DETAIL_ERROR,
      ],
      callAPI: () => ApiClient.get(`payment/Transactions/GetDetail/${id}`),
      payload: { data: { id } },
    }),
  );
};

export const createTransaction = (data, formId) => dispatch => {
  return Promise.resolve(dispatch(startSubmit(formId))).then(() =>
    dispatch({
      types: [
        CREATE_TRANSACTION_REQUEST,
        CREATE_TRANSACTION_SUCCESS,
        CREATE_TRANSACTION_ERROR,
      ],
      callAPI: () => ApiClient.post('payment/Transactions', data),
      payload: { data, formId },
    }),
  );
};

export const updateTransaction = (id, data, formId) => dispatch => {
  return Promise.resolve(dispatch(startSubmit(formId))).then(() =>
    dispatch({
      types: [
        UPDATE_TRANSACTION_REQUEST,
        UPDATE_TRANSACTION_SUCCESS,
        UPDATE_TRANSACTION_ERROR,
      ],
      callAPI: () => ApiClient.put(`payment/Transactions/${id}`, data),
      payload: { data: { ...data, id }, formId },
    }),
  );
};

export const deleteTransaction = id => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [
        DELETE_TRANSACTION_REQUEST,
        DELETE_TRANSACTION_SUCCESS,
        DELETE_TRANSACTION_ERROR,
      ],
      callAPI: () => ApiClient.delete(`payment/Transactions/${id}`),
      payload: { data: { id } },
    }),
  );
};

const initialState = {
  payload: {},
  data: {},
  response: {},
  error: {},
  errorMessage: '',
  successMessage: '',
};

export default function reducer(state = initialState, action) {
  const { payload, response, error, successMessage, errorMessage } = action;
  switch (action.type) {
    case FETCH_TRANSACTIONS_REQUEST:
    case GET_TRANSACTION_DETAIL_REQUEST:
    case CREATE_TRANSACTION_REQUEST:
    case UPDATE_TRANSACTION_REQUEST:
    case DELETE_TRANSACTION_REQUEST:
      return {
        ...state,
        payload,
        response: {},
        error: {},
        successMessage: '',
        errorMessage: '',
      };

    case FETCH_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          items: response.data.data.items,
          pages: response.data.data.pageCount,
        },
        response,
        successMessage,
      };
    case GET_TRANSACTION_DETAIL_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          item: response.data.data,
        },
        response,
        successMessage,
      };

    case CREATE_TRANSACTION_SUCCESS:
    case UPDATE_TRANSACTION_SUCCESS:
    case DELETE_TRANSACTION_SUCCESS:
      return {
        ...state,
        response,
        successMessage,
      };

    case FETCH_TRANSACTIONS_ERROR:
    case GET_TRANSACTION_DETAIL_ERROR:
    case CREATE_TRANSACTION_ERROR:
    case UPDATE_TRANSACTION_ERROR:
    case DELETE_TRANSACTION_ERROR:
      return {
        ...state,
        payload,
        error,
        errorMessage,
      };
    default:
      return state;
  }
}
