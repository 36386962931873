import React, { Component } from 'react';
import { connect } from 'react-redux';
import ResetPasswordForm, {
  resetPasswordFormId,
} from '../../components/Forms/admin/ResetPasswordForm';
import { resetPassword } from '../../../redux/modules/Account';
import { Card, Col, Row } from 'reactstrap';
import Logo from 'assets/img/logo/logo_no_text.png';
import { Link } from 'react-router-dom';
import { showMessageModal } from '../../../redux/modules/Modal';

const initialValues = {
  newPassword: '',
  confirmedNewPassword: '',
};

class ResetPasswordPage extends Component {
  handleSubmit = (values, dispatch) => {
    const params = this.props.location.search.substring(1).split('&');
    values.userId = params[0].split('=')[1];
    values.token = params[1].split('=')[1];

    dispatch(resetPassword(values, resetPasswordFormId)).then(resp => {
      dispatch(
        showMessageModal('Your password has been changed successfully.', () => {
          this.props.history.push('/sign-in');
        }),
      );
    });
  };

  render() {
    return (
      <Row
        id="forgotPassword"
        style={{
          height: '100vh',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Col md={6} lg={4}>
          <Card body>
            <div className="row mb-4">
              <div className="col-12 text-left pl-4 pt-3">
                <h4 className="title">
                  <Link to="/">
                    <img
                      src={Logo}
                      className="rounded"
                      alt="logo"
                    />
                  </Link>{' '}
                  Reset Password
                </h4>
              </div>
            </div>
            <ResetPasswordForm
              initialValues={initialValues}
              onSubmit={this.handleSubmit}
            />
          </Card>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = state => ({});
const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordPage);
