import React from 'react';
import { Route } from 'react-router-dom';
import ModalRoot from '../Modals/ModalRoot';
import { connect } from 'react-redux';

const LayoutRoute = ({ component: Component, layout: Layout, ...rest }) => (
  <Route
    {...rest}
    render={props => (
      <Layout>
        <Component {...props} />
        <ModalRoot />
      </Layout>
    )}
  />
);

const mapStateToProps = state => ({});
const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(LayoutRoute);
