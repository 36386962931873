import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { hideModal } from '../../../redux/modules/Modal';
import ReactHtmlParser from 'react-html-parser';
import { MdErrorOutline, MdCheckCircle } from 'react-icons/md';

class BasicMessageModal extends Component {
  handleCloseClick = () => {
    const { onCloseClick, hideModal } = this.props;
    onCloseClick && onCloseClick();
    hideModal();
  };

  renderHeaderIcon = messageType => {
    switch (messageType) {
      case 'success':
        return <MdCheckCircle color="#98c04f" size={50} />;
      case 'error':
        return <MdErrorOutline color="#e74340" size={50} />;
      default:
        return '';
    }
  };

  render() {
    const { title, message, isOpen, isHtmlMessage, messageType } = this.props;
    return (
      <Modal isOpen={isOpen}>
        <ModalHeader>
          {this.renderHeaderIcon(messageType)} {title}
        </ModalHeader>
        <ModalBody>{isHtmlMessage ? ReactHtmlParser(message) : message}</ModalBody>
        <ModalFooter>
          <Button outline onClick={this.handleCloseClick}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

const mapStateToProps = ({ modal }) => ({});

const mapDispatchToProps = dispatch => ({
  hideModal: bindActionCreators(hideModal, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(BasicMessageModal);
