import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import ValidationMessage from '../Controls/ValidationMessage';

const CheckField = ({
  input,
  label,
  disabled,
  required,
  horizontal,
  className,
  meta: { touched, error },
  ...props
}) => {
  return (
    <div
      className={classnames('form-group', 'text-left', className)}
      style={
        horizontal
          ? { paddingTop: 'calc(0.375rem + 1px)' }
          : { paddingTop: 'calc(2rem + 1px)' }
      }
    >
      <div className="checkbox-container">
        <input
          {...input}
          id={input.name}
          checked={input.value}
          type="checkbox"
          disabled={disabled}
          className={classnames('checkbox')}
        />{' '}
        <label data-required={required && !disabled} htmlFor={input.name}>
          {label}
        </label>
      </div>

      <ValidationMessage touched={touched} error={error} {...props} />
    </div>
  );
};

CheckField.propTypes = {
  input: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  text: PropTypes.string,
  disabled: PropTypes.bool,
};

export default CheckField;
