import ApiClient from '../../../helpers/apiClient';
import { startSubmit } from 'redux-form';

// Warranty type
export const FETCH_WARRANTY_TYPE_REQUEST = 'FETCH_WARRANTY_TYPE_REQUEST';
export const FETCH_WARRANTY_TYPE_SUCCESS = 'FETCH_WARRANTY_TYPE_SUCCESS';
export const FETCH_WARRANTY_TYPE_ERROR = 'FETCH_WARRANTY_TYPE_ERROR';

export const FETCH_WARRANTY_TYPE_OPTIONS_REQUEST =
  'FETCH_WARRANTY_TYPE_OPTIONS_REQUEST';
export const FETCH_WARRANTY_TYPE_OPTIONS_SUCCESS =
  'FETCH_WARRANTY_TYPE_OPTIONS_SUCCESS';
export const FETCH_WARRANTY_TYPE_OPTIONS_ERROR =
  'FETCH_WARRANTY_TYPE_OPTIONS_ERROR';

export const GET_WARRANTY_TYPE_DETAIL_REQUEST =
  'GET_WARRANTY_TYPE_DETAIL_REQUEST';
export const GET_WARRANTY_TYPE_DETAIL_SUCCESS =
  'GET_WARRANTY_TYPE_DETAIL_SUCCESS';
export const GET_WARRANTY_TYPE_DETAIL_ERROR = 'GET_WARRANTY_TYPE_DETAIL_ERROR';

export const CREATE_WARRANTY_TYPE_REQUEST = 'CREATE_WARRANTY_TYPE_REQUEST';
export const CREATE_WARRANTY_TYPE_SUCCESS = 'CREATE_WARRANTY_TYPE_SUCCESS';
export const CREATE_WARRANTY_TYPE_ERROR = 'CREATE_WARRANTY_TYPE_ERROR';

export const UPDATE_WARRANTY_TYPE_REQUEST = 'UPDATE_WARRANTY_TYPE_REQUEST';
export const UPDATE_WARRANTY_TYPE_SUCCESS = 'UPDATE_WARRANTY_TYPE_SUCCESS';
export const UPDATE_WARRANTY_TYPE_ERROR = 'UPDATE_WARRANTY_TYPE_ERROR';

export const DELETE_WARRANTY_TYPE_REQUEST = 'DELETE_WARRANTY_TYPE_REQUEST';
export const DELETE_WARRANTY_TYPE_SUCCESS = 'DELETE_WARRANTY_TYPE_SUCCESS';
export const DELETE_WARRANTY_TYPE_ERROR = 'DELETE_WARRANTY_TYPE_ERROR';

// Warranty
export const FETCH_WARRANTY_REQUEST = 'FETCH_WARRANTY_REQUEST';
export const FETCH_WARRANTY_SUCCESS = 'FETCH_WARRANTY_SUCCESS';
export const FETCH_WARRANTY_ERROR = 'FETCH_WARRANTY_ERROR';

export const FETCH_WARRANTY_OPTIONS_REQUEST = 'FETCH_WARRANTY_OPTIONS_REQUEST';
export const FETCH_WARRANTY_OPTIONS_SUCCESS = 'FETCH_WARRANTY_OPTIONS_SUCCESS';
export const FETCH_WARRANTY_OPTIONS_ERROR = 'FETCH_WARRANTY_OPTIONS_ERROR';

export const GET_WARRANTY_DETAIL_REQUEST = 'GET_WARRANTY_DETAIL_REQUEST';
export const GET_WARRANTY_DETAIL_SUCCESS = 'GET_WARRANTY_DETAIL_SUCCESS';
export const GET_WARRANTY_DETAIL_ERROR = 'GET_WARRANTY_DETAIL_ERROR';

export const CREATE_WARRANTY_REQUEST = 'CREATE_WARRANTY_REQUEST';
export const CREATE_WARRANTY_SUCCESS = 'CREATE_WARRANTY_SUCCESS';
export const CREATE_WARRANTY_ERROR = 'CREATE_WARRANTY_ERROR';

export const UPDATE_WARRANTY_REQUEST = 'UPDATE_WARRANTY_REQUEST';
export const UPDATE_WARRANTY_SUCCESS = 'UPDATE_WARRANTY_SUCCESS';
export const UPDATE_WARRANTY_ERROR = 'UPDATE_WARRANTY_ERROR';

export const DELETE_WARRANTY_REQUEST = 'DELETE_WARRANTY_REQUEST';
export const DELETE_WARRANTY_SUCCESS = 'DELETE_WARRANTY_SUCCESS';
export const DELETE_WARRANTY_ERROR = 'DELETE_WARRANTY_ERROR';

// Warranty type
export const fetchWarrantyType = data => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [
        FETCH_WARRANTY_TYPE_REQUEST,
        FETCH_WARRANTY_TYPE_SUCCESS,
        FETCH_WARRANTY_TYPE_ERROR,
      ],
      callAPI: () => ApiClient.post('product/WarrantyType/Search', data),
      payload: { data },
    }),
  );
};

export const fetchWarrantyTypeOptions = () => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [
        FETCH_WARRANTY_TYPE_OPTIONS_REQUEST,
        FETCH_WARRANTY_TYPE_OPTIONS_SUCCESS,
        FETCH_WARRANTY_TYPE_OPTIONS_ERROR,
      ],
      callAPI: () => ApiClient.get('product/WarrantyType/GetSelectList'),
      payload: {},
    }),
  );
};

export const getWarrantyTypeDetail = id => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [
        GET_WARRANTY_TYPE_DETAIL_REQUEST,
        GET_WARRANTY_TYPE_DETAIL_SUCCESS,
        GET_WARRANTY_TYPE_DETAIL_ERROR,
      ],
      callAPI: () => ApiClient.get(`product/WarrantyType/${id}`),
      payload: { data: { id } },
    }),
  );
};

export const createWarrantyType = (data, formId) => dispatch => {
  return Promise.resolve(dispatch(startSubmit(formId))).then(() =>
    dispatch({
      types: [
        CREATE_WARRANTY_TYPE_REQUEST,
        CREATE_WARRANTY_TYPE_SUCCESS,
        CREATE_WARRANTY_TYPE_ERROR,
      ],
      callAPI: () => ApiClient.post('product/WarrantyType', data),
      payload: { data, formId },
    }),
  );
};

export const updateWarrantyType = (id, data, formId) => dispatch => {
  return Promise.resolve(dispatch(startSubmit(formId))).then(() =>
    dispatch({
      types: [
        UPDATE_WARRANTY_TYPE_REQUEST,
        UPDATE_WARRANTY_TYPE_SUCCESS,
        UPDATE_WARRANTY_TYPE_ERROR,
      ],
      callAPI: () => ApiClient.put(`product/WarrantyType/${id}`, data),
      payload: { data: { ...data, id }, formId },
    }),
  );
};

export const deleteWarrantyType = (id, formId) => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [
        DELETE_WARRANTY_TYPE_REQUEST,
        DELETE_WARRANTY_TYPE_SUCCESS,
        DELETE_WARRANTY_TYPE_ERROR,
      ],
      callAPI: () => ApiClient.delete(`product/WarrantyType/${id}`),
      payload: { data: { id }, formId },
    }),
  );
};

// Warranty
export const fetchWarranty = data => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [
        FETCH_WARRANTY_REQUEST,
        FETCH_WARRANTY_SUCCESS,
        FETCH_WARRANTY_ERROR,
      ],
      callAPI: () => ApiClient.post('product/Warranty/Search', data),
      payload: { data },
    }),
  );
};

export const fetchWarrantyOptions = () => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [
        FETCH_WARRANTY_OPTIONS_REQUEST,
        FETCH_WARRANTY_OPTIONS_SUCCESS,
        FETCH_WARRANTY_OPTIONS_ERROR,
      ],
      callAPI: () => ApiClient.get('product/Warranty/GetSelectList'),
      payload: {},
    }),
  );
};

export const getWarrantyDetail = id => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [
        GET_WARRANTY_DETAIL_REQUEST,
        GET_WARRANTY_DETAIL_SUCCESS,
        GET_WARRANTY_DETAIL_ERROR,
      ],
      callAPI: () => ApiClient.get(`product/Warranty/${id}`),
      payload: { data: { id } },
    }),
  );
};

export const createWarranty = (data, formId) => dispatch => {
  return Promise.resolve(dispatch(startSubmit(formId))).then(() =>
    dispatch({
      types: [
        CREATE_WARRANTY_REQUEST,
        CREATE_WARRANTY_SUCCESS,
        CREATE_WARRANTY_ERROR,
      ],
      callAPI: () => ApiClient.post('product/Warranty', data),
      payload: { data, formId },
    }),
  );
};

export const updateWarranty = (id, data, formId) => dispatch => {
  return Promise.resolve(dispatch(startSubmit(formId))).then(() =>
    dispatch({
      types: [
        UPDATE_WARRANTY_REQUEST,
        UPDATE_WARRANTY_SUCCESS,
        UPDATE_WARRANTY_ERROR,
      ],
      callAPI: () => ApiClient.put(`product/Warranty/${id}`, data),
      payload: { data: { ...data, id }, formId },
    }),
  );
};

export const deleteWarranty = (id, formId) => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [
        DELETE_WARRANTY_REQUEST,
        DELETE_WARRANTY_SUCCESS,
        DELETE_WARRANTY_ERROR,
      ],
      callAPI: () => ApiClient.delete(`product/Warranty/${id}`),
      payload: { data: { id }, formId },
    }),
  );
};

const initialState = {
  payload: {},
  data: {},
  response: {},
  error: {},
  errorMessage: '',
  successMessage: '',
};

export default function reducer(state = initialState, action) {
  const { payload, response, error, successMessage, errorMessage } = action;
  switch (action.type) {
    case FETCH_WARRANTY_TYPE_REQUEST:
    case FETCH_WARRANTY_TYPE_OPTIONS_REQUEST:
    case GET_WARRANTY_TYPE_DETAIL_REQUEST:
    case CREATE_WARRANTY_TYPE_REQUEST:
    case UPDATE_WARRANTY_TYPE_REQUEST:
    case DELETE_WARRANTY_TYPE_REQUEST:

    case FETCH_WARRANTY_REQUEST:
    case FETCH_WARRANTY_OPTIONS_REQUEST:
    case GET_WARRANTY_DETAIL_REQUEST:
    case CREATE_WARRANTY_REQUEST:
    case UPDATE_WARRANTY_REQUEST:
    case DELETE_WARRANTY_REQUEST:
      return {
        ...state,
        payload,
        response: {},
        error: {},
        successMessage: '',
        errorMessage: '',
      };

    case FETCH_WARRANTY_OPTIONS_SUCCESS:
      return {
        ...state,
        data: { ...state.data, warrantyOptions: response.data.data },
        response,
        successMessage,
      };
    case FETCH_WARRANTY_TYPE_OPTIONS_SUCCESS:
      return {
        ...state,
        data: { ...state.data, warrantyTypeOptions: response.data.data },
        response,
        successMessage,
      };

    case FETCH_WARRANTY_TYPE_SUCCESS:
    case FETCH_WARRANTY_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          items: response.data.data.items,
          pages: response.data.data.pageCount,
        },
        response,
        successMessage,
      };

    case GET_WARRANTY_TYPE_DETAIL_SUCCESS:
    case GET_WARRANTY_DETAIL_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          item: response.data.data,
        },
        response,
        successMessage,
      };

    case CREATE_WARRANTY_TYPE_SUCCESS:
    case UPDATE_WARRANTY_TYPE_SUCCESS:
    case DELETE_WARRANTY_TYPE_SUCCESS:
    case CREATE_WARRANTY_SUCCESS:
    case UPDATE_WARRANTY_SUCCESS:
    case DELETE_WARRANTY_SUCCESS:
      return {
        ...state,
        response,
        successMessage,
      };

    case FETCH_WARRANTY_TYPE_ERROR:
    case FETCH_WARRANTY_TYPE_OPTIONS_ERROR:
    case GET_WARRANTY_TYPE_DETAIL_ERROR:
    case CREATE_WARRANTY_TYPE_ERROR:
    case UPDATE_WARRANTY_TYPE_ERROR:
    case DELETE_WARRANTY_TYPE_ERROR:

    case FETCH_WARRANTY_ERROR:
    case FETCH_WARRANTY_OPTIONS_ERROR:
    case GET_WARRANTY_DETAIL_ERROR:
    case CREATE_WARRANTY_ERROR:
    case UPDATE_WARRANTY_ERROR:
    case DELETE_WARRANTY_ERROR:
      return {
        ...state,
        payload,
        error,
        errorMessage,
      };
    default:
      return state;
  }
}
