import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { confirmEmail } from '../../../redux/modules/Account';
import { showMessageModal } from '../../../redux/modules/Modal';
import { Col, Card, Row, Button } from 'reactstrap';

class ConfirmEmailPage extends Component {
  confirmEmail = () => {
    const params = this.props.location.search.substring(1).split('&');
    const email = params[0].split('=')[1];
    const token = params[1].split('=')[1];

    this.props.confirmEmail({ email, token }).then(
      resp => {
        this.props.showMessageModal(
          'You have confirmed email successfully!',
          () => {
            this.props.history.push('/sign-in');
          },
        );
      },
      error => {
        this.props.showMessageModal('Confirmation fail!', () => {
          this.props.history.push('/sign-in');
        });
      },
    );
  };

  render() {
    return (
      <Row
        id="confirmEmail"
        style={{
          height: '100vh',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Col md={6} lg={4}>
          <Card body>
            <label>Click here to confirm you email</label>
            <Button
              className="btn btn-secondary btn-lg btn-block bg-gradient-theme-left border-0"
              onClick={this.confirmEmail}
            >
              Confirm
            </Button>
          </Card>
        </Col>
      </Row>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    confirmEmail: bindActionCreators(confirmEmail, dispatch),
    showMessageModal: bindActionCreators(showMessageModal, dispatch),
  };
};

export default connect(null, mapDispatchToProps)(ConfirmEmailPage);
