import React from 'react';
import { Card, List } from 'reactstrap';
import classNames from 'classnames';
import 'styles/css/DashboardCard.css';

const DashboardCard = ({ card, className, ...restProps }) => {
  const classes = classNames('dashboard-card', className);

  return (
    <Card className={classes} {...restProps}>
      <div className="head d-flex">
        <img className="dashboard-card-image" src={card.image} alt={card.title} />
        <div className="d-flex flex-column dasboard-card-title">
          <span className="muli-bold">{card.total}</span>
          <span className="muli-semi">{card.title}</span>
        </div>
      </div>
      <ul className="dashboard-card-body dashboard-bullet muli-bold">
        <li>
          <span>{card.allocated}</span>
        </li>
        <li>
          <span>{card.available}</span>
        </li>
        <li>
          <span>{card.awaiting}</span>
        </li>
        <li>
          <span>{card.damage}</span>
        </li>
        <li>
          <span>{card.inTransit}</span>
        </li>
        <li>
          <span>{card.onHand}</span>
        </li>
        <li>
          <span>{card.onOrder}</span>
        </li>
      </ul>
    </Card>
  );
};

export default DashboardCard;
