import ApiClient from '../../../helpers/apiClient';
import { startSubmit } from 'redux-form';

export const FETCH_FREWIE_SERIAL_NUMBER_REQUEST = 'FETCH_FREWIE_SERIAL_NUMBER_REQUEST';
export const FETCH_FREWIE_SERIAL_NUMBER_SUCCESS = 'FETCH_FREWIE_SERIAL_NUMBER_SUCCESS';
export const FETCH_FREWIE_SERIAL_NUMBER_ERROR = 'FETCH_FREWIE_SERIAL_NUMBER_ERROR';

export const GET_FREWIE_SERIAL_NUMBER_DETAIL_REQUEST = 'GET_FREWIE_SERIAL_NUMBER_DETAIL_REQUEST';
export const GET_FREWIE_SERIAL_NUMBER_DETAIL_SUCCESS = 'GET_FREWIE_SERIAL_NUMBER_DETAIL_SUCCESS';
export const GET_FREWIE_SERIAL_NUMBER_DETAIL_ERROR = 'GET_FREWIE_SERIAL_NUMBER_DETAIL_ERROR';

export const GET_FREWIE_SERIAL_NUMBER_BY_REQUEST = 'GET_FREWIE_SERIAL_NUMBER_BY_REQUEST';
export const GET_FREWIE_SERIAL_NUMBER_BY_SUCCESS = 'GET_FREWIE_SERIAL_NUMBER_BY_SUCCESS';
export const GET_FREWIE_SERIAL_NUMBER_BY_ERROR = 'GET_FREWIE_SERIAL_NUMBER_BY_ERROR';

export const CREATE_FREWIE_SERIAL_NUMBER_REQUEST = 'CREATE_FREWIE_SERIAL_NUMBER_REQUEST';
export const CREATE_FREWIE_SERIAL_NUMBER_SUCCESS = 'CREATE_FREWIE_SERIAL_NUMBER_SUCCESS';
export const CREATE_FREWIE_SERIAL_NUMBER_ERROR = 'CREATE_FREWIE_SERIAL_NUMBER_ERROR';

export const UPDATE_FREWIE_SERIAL_NUMBER_REQUEST = 'UPDATE_FREWIE_SERIAL_NUMBER_REQUEST';
export const UPDATE_FREWIE_SERIAL_NUMBER_SUCCESS = 'UPDATE_FREWIE_SERIAL_NUMBER_SUCCESS';
export const UPDATE_FREWIE_SERIAL_NUMBER_ERROR = 'UPDATE_FREWIE_SERIAL_NUMBER_ERROR';

export const DELETE_FREWIE_SERIAL_NUMBER_REQUEST = 'DELETE_FREWIE_SERIAL_NUMBER_REQUEST';
export const DELETE_FREWIE_SERIAL_NUMBER_SUCCESS = 'DELETE_FREWIE_SERIAL_NUMBER_SUCCESS';
export const DELETE_FREWIE_SERIAL_NUMBER_ERROR = 'DELETE_FREWIE_SERIAL_NUMBER_ERROR';

export const IMPORT_FREWIE_SERIAL_NUMBER_REQUEST = 'IMPORT_FREWIE_SERIAL_NUMBER_REQUEST';
export const IMPORT_FREWIE_SERIAL_NUMBER_SUCCESS = 'IMPORT_FREWIE_SERIAL_NUMBER_SUCCESS';
export const IMPORT_FREWIE_SERIAL_NUMBER_ERROR = 'IMPORT_FREWIE_SERIAL_NUMBER_ERROR';

export const FETCH_FREWIE_SERIAL_NUMBER_IMPORT_HISTORY_REQUEST = 'FETCH_FREWIE_SERIAL_NUMBER_IMPORT_HISTORY_REQUEST';
export const FETCH_FREWIE_SERIAL_NUMBER_IMPORT_HISTORY_SUCCESS = 'FETCH_FREWIE_SERIAL_NUMBER_IMPORT_HISTORY_SUCCESS';
export const FETCH_FREWIE_SERIAL_NUMBER_IMPORT_HISTORY_ERROR = 'FETCH_FREWIE_SERIAL_NUMBER_IMPORT_HISTORY_ERROR';

export const fetchFrewieSerialNumber = data => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [FETCH_FREWIE_SERIAL_NUMBER_REQUEST, FETCH_FREWIE_SERIAL_NUMBER_SUCCESS, FETCH_FREWIE_SERIAL_NUMBER_ERROR],
      callAPI: () => ApiClient.post('product/FrewieSerialNumber/Search', data),
      payload: { data },
    }),
  );
};

export const getFrewieSerialNumberDetail = id => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [
        GET_FREWIE_SERIAL_NUMBER_DETAIL_REQUEST,
        GET_FREWIE_SERIAL_NUMBER_DETAIL_SUCCESS,
        GET_FREWIE_SERIAL_NUMBER_DETAIL_ERROR,
      ],
      callAPI: () => ApiClient.get(`product/FrewieSerialNumber/${id}`),
      payload: { data: { id } },
    }),
  );
};

export const getFrewieSerialNumberBy = (productId, variantId, quantity) => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [
        GET_FREWIE_SERIAL_NUMBER_BY_REQUEST,
        GET_FREWIE_SERIAL_NUMBER_BY_SUCCESS,
        GET_FREWIE_SERIAL_NUMBER_BY_ERROR,
      ],
      callAPI: () =>
        ApiClient.get(
          `product/FrewieSerialNumber/GetSerialNumberByQuantity?productId=${productId}` +
            (variantId ? `&variantId=${variantId}` : '') +
            (quantity ? `&quantity=${quantity}` : ''),
        ),
      payload: { data: { productId, variantId } },
    }),
  );
};

export const createFrewieSerialNumber = (data, formId) => dispatch => {
  return Promise.resolve(dispatch(startSubmit(formId))).then(() =>
    dispatch({
      types: [
        CREATE_FREWIE_SERIAL_NUMBER_REQUEST,
        CREATE_FREWIE_SERIAL_NUMBER_SUCCESS,
        CREATE_FREWIE_SERIAL_NUMBER_ERROR,
      ],
      callAPI: () => ApiClient.post('product/FrewieSerialNumber', data),
      payload: { data, formId },
    }),
  );
};

export const updateFrewieSerialNumber = (id, data, formId) => dispatch => {
  return Promise.resolve(dispatch(startSubmit(formId))).then(() =>
    dispatch({
      types: [
        UPDATE_FREWIE_SERIAL_NUMBER_REQUEST,
        UPDATE_FREWIE_SERIAL_NUMBER_SUCCESS,
        UPDATE_FREWIE_SERIAL_NUMBER_ERROR,
      ],
      callAPI: () => ApiClient.put(`product/FrewieSerialNumber/${id}`, data),
      payload: { data: { ...data, id }, formId },
    }),
  );
};

export const deleteFrewieSerialNumber = id => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [
        DELETE_FREWIE_SERIAL_NUMBER_REQUEST,
        DELETE_FREWIE_SERIAL_NUMBER_SUCCESS,
        DELETE_FREWIE_SERIAL_NUMBER_ERROR,
      ],
      callAPI: () => ApiClient.delete(`product/FrewieSerialNumber/${id}`),
      payload: { data: { id } },
    }),
  );
};

export const importFrewieSerialNumber = formFileData => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [
        IMPORT_FREWIE_SERIAL_NUMBER_REQUEST,
        IMPORT_FREWIE_SERIAL_NUMBER_SUCCESS,
        IMPORT_FREWIE_SERIAL_NUMBER_ERROR,
      ],
      callAPI: () =>
        ApiClient.post(`product/FrewieSerialNumber/Import`, formFileData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }),
      payload: {},
    }),
  );
};

export const fetchFrewieSerialNumberImportHistories = data => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [
        FETCH_FREWIE_SERIAL_NUMBER_IMPORT_HISTORY_REQUEST,
        FETCH_FREWIE_SERIAL_NUMBER_IMPORT_HISTORY_SUCCESS,
        FETCH_FREWIE_SERIAL_NUMBER_IMPORT_HISTORY_ERROR,
      ],
      callAPI: () => ApiClient.post(`product/FrewieSerialNumber/SearchImportHistory`, data),
      payload: { data },
    }),
  );
};

export const BulkInvoiceDetailsValidationAsync = data => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [
        IMPORT_FREWIE_SERIAL_NUMBER_REQUEST,
        IMPORT_FREWIE_SERIAL_NUMBER_SUCCESS,
        IMPORT_FREWIE_SERIAL_NUMBER_ERROR,
      ],
      callAPI: () => ApiClient.post('product/FrewieSerialNumber/BulkInvoiceDetailsValidation', data),
      payload: { data },
    }),
  );
};

const initialState = {
  payload: {},
  data: {},
  response: {},
  error: {},
  errorMessage: '',
  successMessage: '',
};

export default function reducer(state = initialState, action) {
  const { payload, response, error, successMessage, errorMessage } = action;
  switch (action.type) {
    case FETCH_FREWIE_SERIAL_NUMBER_REQUEST:
    case GET_FREWIE_SERIAL_NUMBER_DETAIL_REQUEST:
    case CREATE_FREWIE_SERIAL_NUMBER_REQUEST:
    case UPDATE_FREWIE_SERIAL_NUMBER_REQUEST:
    case DELETE_FREWIE_SERIAL_NUMBER_REQUEST:
    case GET_FREWIE_SERIAL_NUMBER_BY_REQUEST:
    case IMPORT_FREWIE_SERIAL_NUMBER_REQUEST:
    case FETCH_FREWIE_SERIAL_NUMBER_IMPORT_HISTORY_REQUEST:
      return {
        ...state,
        payload,
        response: {},
        error: {},
        successMessage: '',
        errorMessage: '',
      };

    case FETCH_FREWIE_SERIAL_NUMBER_SUCCESS:
    case FETCH_FREWIE_SERIAL_NUMBER_IMPORT_HISTORY_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          items: response.data.data.items,
          pages: response.data.data.pageCount,
        },
        response,
        successMessage,
      };

    case GET_FREWIE_SERIAL_NUMBER_DETAIL_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          item: response.data.data,
        },
        response,
        successMessage,
      };

    case CREATE_FREWIE_SERIAL_NUMBER_SUCCESS:
    case UPDATE_FREWIE_SERIAL_NUMBER_SUCCESS:
    case DELETE_FREWIE_SERIAL_NUMBER_SUCCESS:
    case GET_FREWIE_SERIAL_NUMBER_BY_SUCCESS:
    case IMPORT_FREWIE_SERIAL_NUMBER_SUCCESS:
      return {
        ...state,
        response,
        successMessage,
      };

    case FETCH_FREWIE_SERIAL_NUMBER_ERROR:
    case GET_FREWIE_SERIAL_NUMBER_DETAIL_ERROR:
    case CREATE_FREWIE_SERIAL_NUMBER_ERROR:
    case UPDATE_FREWIE_SERIAL_NUMBER_ERROR:
    case DELETE_FREWIE_SERIAL_NUMBER_ERROR:
    case GET_FREWIE_SERIAL_NUMBER_BY_ERROR:
    case IMPORT_FREWIE_SERIAL_NUMBER_ERROR:
    case FETCH_FREWIE_SERIAL_NUMBER_IMPORT_HISTORY_ERROR:
      return {
        ...state,
        payload,
        error,
        errorMessage,
      };
    default:
      return state;
  }
}
