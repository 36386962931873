import ApiClient from '../../../helpers/apiClient';
import { startSubmit } from 'redux-form';

export const FETCH_BANK_OPTION_REQUEST = 'FETCH_BANK_OPTION_REQUEST';
export const FETCH_BANK_OPTION_SUCCESS = 'FETCH_BANK_OPTION_SUCCESS';
export const FETCH_BANK_OPTION_ERROR = 'FETCH_BANK_OPTION_ERROR';

export const FETCH_BANK_REQUEST = 'FETCH_BANK_REQUEST';
export const FETCH_BANK_SUCCESS = 'FETCH_BANK_SUCCESS';
export const FETCH_BANK_ERROR = 'FETCH_BANK_ERROR';

export const GET_BANK_DETAIL_REQUEST = 'GET_BANK_DETAIL_REQUEST';
export const GET_BANK_DETAIL_SUCCESS = 'GET_BANK_DETAIL_SUCCESS';
export const GET_BANK_DETAIL_ERROR = 'GET_BANK_DETAIL_ERROR';

export const CREATE_BANK_REQUEST = 'CREATE_BANK_REQUEST';
export const CREATE_BANK_SUCCESS = 'CREATE_BANK_SUCCESS';
export const CREATE_BANK_ERROR = 'CREATE_BANK_ERROR';

export const UPDATE_BANK_REQUEST = 'UPDATE_BANK_REQUEST';
export const UPDATE_BANK_SUCCESS = 'UPDATE_BANK_SUCCESS';
export const UPDATE_BANK_ERROR = 'UPDATE_BANK_ERROR';

export const DELETE_BANK_REQUEST = 'DELETE_BANK_REQUEST';
export const DELETE_BANK_SUCCESS = 'DELETE_BANK_SUCCESS';
export const DELETE_BANK_ERROR = 'DELETE_BANK_ERROR';

export const fetchBanks = data => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [FETCH_BANK_REQUEST, FETCH_BANK_SUCCESS, FETCH_BANK_ERROR],
      callAPI: () => ApiClient.post('network/BankMaster/Search', data),
      payload: { data },
    }),
  );
};

export const fetchBankOptions = () => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [FETCH_BANK_OPTION_REQUEST, FETCH_BANK_OPTION_SUCCESS, FETCH_BANK_OPTION_ERROR],
      callAPI: () => ApiClient.get('network/BankMaster/GetSelectList'),
      payload: {},
    }),
  );
};

export const getBankDetail = id => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [GET_BANK_DETAIL_REQUEST, GET_BANK_DETAIL_SUCCESS, GET_BANK_DETAIL_ERROR],
      callAPI: () => ApiClient.get(`network/BankMaster/GetDetail/${id}`),
      payload: { data: { id } },
    }),
  );
};

export const createBank = (data, formId) => dispatch => {
  return Promise.resolve(dispatch(startSubmit(formId))).then(() =>
    dispatch({
      types: [CREATE_BANK_REQUEST, CREATE_BANK_SUCCESS, CREATE_BANK_ERROR],
      callAPI: () => ApiClient.post('network/BankMaster', data),
      payload: { data, formId },
    }),
  );
};

export const updateBank = (id, data, formId) => dispatch => {
  return Promise.resolve(dispatch(startSubmit(formId))).then(() =>
    dispatch({
      types: [UPDATE_BANK_REQUEST, UPDATE_BANK_SUCCESS, UPDATE_BANK_ERROR],
      callAPI: () => ApiClient.put(`network/BankMaster/${id}`, data),
      payload: { data: { ...data, id }, formId },
    }),
  );
};

export const deleteBank = id => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [DELETE_BANK_REQUEST, DELETE_BANK_SUCCESS, DELETE_BANK_ERROR],
      callAPI: () => ApiClient.delete(`network/BankMaster/${id}`),
      payload: { data: { id } },
    }),
  );
};

const initialState = {
  payload: {},
  data: {},
  response: {},
  error: {},
  errorMessage: '',
  successMessage: '',
};

export default function reducer(state = initialState, action) {
  const { payload, response, error, successMessage, errorMessage } = action;
  switch (action.type) {
    case FETCH_BANK_OPTION_REQUEST:
    case FETCH_BANK_REQUEST:
    case GET_BANK_DETAIL_REQUEST:
    case CREATE_BANK_REQUEST:
    case UPDATE_BANK_REQUEST:
    case DELETE_BANK_REQUEST:
      return {
        ...state,
        payload,
        response: {},
        error: {},
        successMessage: '',
        errorMessage: '',
      };

    case FETCH_BANK_OPTION_SUCCESS:
      return {
        ...state,
        data: { ...state.data, options: response.data.data },
        response,
        successMessage,
      };

    case FETCH_BANK_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          items: response.data.data.items,
          pages: response.data.data.pageCount,
        },
        response,
        successMessage,
      };
    case GET_BANK_DETAIL_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          item: response.data.data,
        },
        response,
        successMessage,
      };

    case CREATE_BANK_SUCCESS:
    case UPDATE_BANK_SUCCESS:
    case DELETE_BANK_SUCCESS:
      return {
        ...state,
        response,
        successMessage,
      };

    case FETCH_BANK_OPTION_ERROR:
    case FETCH_BANK_ERROR:
    case GET_BANK_DETAIL_ERROR:
    case CREATE_BANK_ERROR:
    case UPDATE_BANK_ERROR:
    case DELETE_BANK_ERROR:
      return {
        ...state,
        payload,
        error,
        errorMessage,
      };
    default:
      return state;
  }
}
