import sidebarBgImage from 'assets/img/sidebar/network-2.jpg';
import SourceLink from 'views/components/SourceLink';
import React, { Fragment } from 'react';
import { MdKeyboardArrowDown, MdRecentActors, MdSettings, MdShoppingCart, MdGroupAdd } from 'react-icons/md';
import { NavLink } from 'react-router-dom';
import { Collapse, Nav, Navbar, NavItem, NavLink as BSNavLink } from 'reactstrap';
import bn from 'utils/bemnames';
import { FaUserAlt } from 'react-icons/fa';
import {
  navProductSetupItems,
  navAgentItems,
  navARItems,
  navCustomerItems,
  navAppointmentItems,
  navAppointmentSetupItems,
  navProductItems,
} from '../../../configs/navigationItems';
import { getStoredCompanyInfo, getStoredObject } from '../../../helpers/storageHelper';
import { StorageKeys } from '../../../constants/storageKeys';
import { connect } from 'react-redux';
import cx from 'classnames';
import { PATH_SETUP } from 'configs/navigationUrl';
import classnames from 'classnames';
import { hasPermission } from 'services/permissionService';
import { CustomCompanies } from 'constants/customCompanies';

const sidebarBackground = {
  backgroundImage: `url("${sidebarBgImage}")`,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
};

const bem = bn.create('sidebar');

class SetupSidebar extends React.Component {
  state = {
    userRoles: [],
    isOpenAgent: false,
    isOpenMasterSetup: false,
    isOpenProduct: false,
    isOpenProductSetup: false,
    isOpenAR: false,
    isOpenCustomer: false,
    isOpenAppointment: false,
    isOpenAppointmentSetup: false,
    currentLink: '',
    customCompanyCode: '',
    filteredNavARItems: [],
    filteredNavProductSetupItems: []
  };

  componentDidMount() {
    const path = window.location.hash.slice('#/setup'.length);
    const roleNames = this.getRoleNames();
    this.setState({ currentLink: path, userRoles: roleNames });

    if (navARItems.find(i => i.to == path || i.childrenRoutes?.find(r => path.startsWith(r)))) {
      this.setState({ isOpenAR: true });
    } else if (navCustomerItems.find(i => i.to == path || i.childrenRoutes?.find(r => path.startsWith(r)))) {
      this.setState({ isOpenCustomer: true });
    } else if (navAppointmentItems.find(i => i.to == path || i.childrenRoutes?.find(r => path.startsWith(r)))) {
      this.setState({ isOpenAppointment: true });
    } else if (navAppointmentSetupItems.find(i => i.to == path || i.childrenRoutes?.find(r => path.startsWith(r)))) {
      this.setState({ isOpenAppointment: true, isOpenAppointmentSetup: true });
    } else if (navProductItems.find(i => i.to == path || i.childrenRoutes?.find(r => path.startsWith(r)))) {
      this.setState({ isOpenProduct: true });
    } else if (navProductSetupItems.find(i => i.to == path || i.childrenRoutes?.find(r => path.startsWith(r)))) {
      this.setState({ isOpenProduct: true, isOpenProductSetup: true });
    } else if (navAgentItems.find(i => i.to == path || i.childrenRoutes?.find(r => path.startsWith(r)))) {
      this.setState({ isOpenAgent: true });
    }

    const company = getStoredCompanyInfo();
    this.setState({
      customCompanyCode: company?.customCode
    });

    this.setState({
      filteredNavARItems: navARItems,
      filteredNavProductSetupItems: navProductSetupItems
    });
    if (company?.customCode != CustomCompanies.Frewie)
    {
      this.setState({
        filteredNavARItems: navARItems.filter(function(value, index, arr){ 
          return !value.to.includes('/frewie');
        }),
        filteredNavProductSetupItems: navProductSetupItems.filter(function(value, index, arr){ 
          return !value.to.includes('/frewie');
        })
      });
    }
  }

  handleClick = name => () => {
    this.setState(prevState => {
      const isOpen = prevState[`isOpen${name}`];

      return {
        [`isOpen${name}`]: !isOpen,
      };
    });
  };

  handleClickMenuItem = to => {
    this.setState({ currentLink: to });
  };

  showGroup = (navGroupItems, userRoles) => {
    return navGroupItems.some(item => this.showNavItem(item.roles, userRoles, item.resource, item.action));
  };

  showNavItem = (rolesAllow, userRoles, resource, action) => {
    if (!rolesAllow) {
      const { profile } = this.props;

      if (profile && profile.isDmsAdmin) return true;

      return hasPermission(resource, action, profile.permissions);
    }
    if (rolesAllow.length === 0) {
      return false;
    }
    return userRoles.some(r => rolesAllow.includes(r?.roleName));
    // return userRoles.some(r => rolesAllow.includes(r));
  };

  getRoleNames = () => {
    let { profile } = this.props;
    if (!profile || !profile.userRoleEntities) {
      profile = getStoredObject(StorageKeys.Profile);
    }
    if (profile && profile.userRoleEntities) {
      return profile.userRoleEntities;
    } else {
      return [];
    }
  };

  renderMenuItem = ({ to, name, exact, Icon, roles, childrenRoutes, resource, action, alterName, alterNameOnCompany },
    index, className) => {

    const { userRoles, currentLink, customCompanyCode } = this.state;
    const path = window.location.hash.substring('#/setup'.length);
    let isCurrentLink = path === to;

    if (!isCurrentLink) {
      if (currentLink && currentLink === to) {
        isCurrentLink = true;
      } else if (childrenRoutes && childrenRoutes.find(r => path.startsWith(r))) {
        isCurrentLink = true;
      }
    }

    let menuItemName = name;
    if (customCompanyCode && customCompanyCode == alterNameOnCompany) {
      menuItemName = alterName;
    }

    return (
      this.showNavItem(roles, userRoles, resource, action) && (
        <NavItem key={index} className={cx({ 'active-item-tab': isCurrentLink }, className)}>
          <BSNavLink
            id={`navItem-${name}-${index}`}
            tag={NavLink}
            to={`${PATH_SETUP}${to}`}
            className={isCurrentLink ? 'active' : ''}
            exact={exact}
            onClick={() => this.setState({ currentLink: to })}
          >
            <Icon className={bem.e('nav-item-icon')} />
            <span className="">{menuItemName}</span>
          </BSNavLink>
        </NavItem>
      )
    );
  };

  render() {
    const { userRoles } = this.state;

    return (
      <aside className={bem.b()} data-image={sidebarBgImage}>
        <div className={bem.e('background')} style={sidebarBackground} />
        <div className={bem.e('content')}>
          <Navbar>
            <SourceLink className="navbar-brand d-flex">
              <span className="text-white">DMS</span>
            </SourceLink>
          </Navbar>
          <Nav vertical>
            {/* Begin AR */}
            {this.showGroup(navARItems, userRoles) && (
              <Fragment>
                <NavItem className={bem.e('nav-item')} onClick={this.handleClick('AR')}>
                  <BSNavLink className={bem.e('nav-item-collapse')}>
                    <div className="d-flex">
                      <MdShoppingCart className={bem.e('nav-item-icon')} />
                      <span className=" align-self-start">AR</span>
                    </div>
                    <MdKeyboardArrowDown
                      className={bem.e('nav-item-icon')}
                      style={{
                        padding: 0,
                        transform: this.state.isOpenAR ? 'rotate(0deg)' : 'rotate(-90deg)',
                        transitionDuration: '0.3s',
                        transitionProperty: 'transform',
                      }}
                    />
                  </BSNavLink>
                </NavItem>
                <Collapse isOpen={this.state.isOpenAR}>
                  {this.state.filteredNavARItems.map((item, index) => this.renderMenuItem(item, index, 'pl-2'))}
                </Collapse>
              </Fragment>
            )}
            {/* End AR */}

            {/* Begin Customer */}
            {this.showGroup(navCustomerItems, userRoles) && (
              <Fragment>
                <NavItem className={bem.e('nav-item')} onClick={this.handleClick('Customer')}>
                  <BSNavLink className={bem.e('nav-item-collapse')}>
                    <div className="d-flex">
                      <FaUserAlt className={bem.e('nav-item-icon')} />
                      <span className=" align-self-start">Customer</span>
                    </div>
                    <MdKeyboardArrowDown
                      className={bem.e('nav-item-icon')}
                      style={{
                        padding: 0,
                        transform: this.state.isOpenCustomer ? 'rotate(0deg)' : 'rotate(-90deg)',
                        transitionDuration: '0.3s',
                        transitionProperty: 'transform',
                      }}
                    />
                  </BSNavLink>
                </NavItem>
                <Collapse isOpen={this.state.isOpenCustomer}>
                  {navCustomerItems.map((item, index) => this.renderMenuItem(item, index, 'pl-2'))}
                </Collapse>
              </Fragment>
            )}
            {/* End Customer */}

            {/* Begin Appointment */}
            {(this.showGroup(navAppointmentItems, userRoles) ||
              this.showGroup(navAppointmentSetupItems, userRoles)) && (
              <Fragment>
                <NavItem className={bem.e('nav-item')} onClick={this.handleClick('Appointment')}>
                  <BSNavLink className={bem.e('nav-item-collapse')}>
                    <div className="d-flex">
                      <MdSettings className={bem.e('nav-item-icon')} />
                      <span className=" align-self-start">Appointment</span>
                    </div>
                    <MdKeyboardArrowDown
                      className={bem.e('nav-item-icon')}
                      style={{
                        padding: 0,
                        transform: this.state.isOpenAppointment ? 'rotate(0deg)' : 'rotate(-90deg)',
                        transitionDuration: '0.3s',
                        transitionProperty: 'transform',
                      }}
                    />
                  </BSNavLink>
                </NavItem>
                <Collapse isOpen={this.state.isOpenAppointment}>
                  {navAppointmentItems.map((item, index) => this.renderMenuItem(item, index, 'pl-2'))}

                  {/* Begin Appoinment Setup */}
                  {this.showGroup(navAppointmentSetupItems, userRoles) && (
                    <Fragment>
                      <NavItem
                        className={classnames(bem.e('nav-item'), 'pl-2')}
                        onClick={this.handleClick('AppointmentSetup')}
                      >
                        <BSNavLink className={bem.e('nav-item-collapse')}>
                          <div className="d-flex">
                            <MdSettings className={bem.e('nav-item-icon')} />
                            <span className=" align-self-start">Setup</span>
                          </div>
                          <MdKeyboardArrowDown
                            className={bem.e('nav-item-icon')}
                            style={{
                              padding: 0,
                              transform: this.state.isOpenAppointmentSetup ? 'rotate(0deg)' : 'rotate(-90deg)',
                              transitionDuration: '0.3s',
                              transitionProperty: 'transform',
                            }}
                          />
                        </BSNavLink>
                      </NavItem>
                      <Collapse isOpen={this.state.isOpenAppointmentSetup}>
                        {navAppointmentSetupItems.map((item, index) => this.renderMenuItem(item, index, 'pl-3'))}
                      </Collapse>
                    </Fragment>
                  )}
                  {/* End Appoinment Setup */}
                </Collapse>
              </Fragment>
            )}
            {/* End Appointment */}

            {/* Begin Product */}
            {(this.showGroup(navProductItems, userRoles) || this.showGroup(navProductSetupItems, userRoles)) && (
              <Fragment>
                <NavItem className={bem.e('nav-item')} onClick={this.handleClick('Product')}>
                  <BSNavLink className={bem.e('nav-item-collapse')}>
                    <div className="d-flex">
                      <MdGroupAdd className={bem.e('nav-item-icon')} />
                      <span className=" align-self-start">Product</span>
                    </div>
                    <MdKeyboardArrowDown
                      className={bem.e('nav-item-icon')}
                      style={{
                        padding: 0,
                        transform: this.state.isOpenProduct ? 'rotate(0deg)' : 'rotate(-90deg)',
                        transitionDuration: '0.3s',
                        transitionProperty: 'transform',
                      }}
                    />
                  </BSNavLink>
                </NavItem>
                <Collapse isOpen={this.state.isOpenProduct}>
                  {navProductItems.map((item, index) => this.renderMenuItem(item, index, 'pl-2'))}

                  {/* Begin Product Setup */}
                  {this.showGroup(navProductSetupItems, userRoles) && (
                    <Fragment>
                      <NavItem
                        className={classnames(bem.e('nav-item'), 'pl-2')}
                        onClick={this.handleClick('ProductSetup')}
                      >
                        <BSNavLink className={bem.e('nav-item-collapse')}>
                          <div className="d-flex">
                            <MdSettings className={bem.e('nav-item-icon')} />
                            <span className=" align-self-start">Setup</span>
                          </div>
                          <MdKeyboardArrowDown
                            className={bem.e('nav-item-icon')}
                            style={{
                              padding: 0,
                              transform: this.state.isOpenProductSetup ? 'rotate(0deg)' : 'rotate(-90deg)',
                              transitionDuration: '0.3s',
                              transitionProperty: 'transform',
                            }}
                          />
                        </BSNavLink>
                      </NavItem>
                      <Collapse isOpen={this.state.isOpenProductSetup}>
                        {this.state.filteredNavProductSetupItems.map((item, index) => this.renderMenuItem(item, index, 'pl-3'))}
                      </Collapse>
                    </Fragment>
                  )}
                  {/* End Product Setup */}
                </Collapse>
              </Fragment>
            )}
            {/* End Product */}

            {/* Begin Agent Group */}
            {this.showGroup(navAgentItems, userRoles) && (
              <Fragment>
                <NavItem className={bem.e('nav-item')} onClick={this.handleClick('Agent')}>
                  <BSNavLink className={bem.e('nav-item-collapse')}>
                    <div className="d-flex">
                      <MdRecentActors className={bem.e('nav-item-icon')} />
                      <span className=" align-self-start">Agent</span>
                    </div>
                    <MdKeyboardArrowDown
                      className={bem.e('nav-item-icon')}
                      style={{
                        padding: 0,
                        transform: this.state.isOpenAgent ? 'rotate(0deg)' : 'rotate(-90deg)',
                        transitionDuration: '0.3s',
                        transitionProperty: 'transform',
                      }}
                    />
                  </BSNavLink>
                </NavItem>
                <Collapse isOpen={this.state.isOpenAgent}>
                  {navAgentItems.map((item, index) => this.renderMenuItem(item, index, 'pl-2'))}
                </Collapse>
              </Fragment>
            )}
            {/* End Agent Group */}
          </Nav>
        </div>
      </aside>
    );
  }
}

const mapStateToProps = ({ account }) => ({
  profile: account.profile,
});

export default connect(mapStateToProps, null)(SetupSidebar);
