import ApiClient from '../../../helpers/apiClient';
import { startSubmit } from 'redux-form';

export const FETCH_AGENTS_REQUEST = 'FETCH_AGENTS_REQUEST';
export const FETCH_AGENTS_SUCCESS = 'FETCH_AGENTS_SUCCESS';
export const FETCH_AGENTS_ERROR = 'FETCH_AGENTS_ERROR';

export const GET_AGENT_HIERARCHY_DATA_REQUEST =
  'GET_AGENT_HIERARCHY_DATA_REQUEST';
export const GET_AGENT_HIERARCHY_DATA_SUCCESS =
  'GET_AGENT_HIERARCHY_DATA_SUCCESS';
export const GET_AGENT_HIERARCHY_DATA_ERROR = 'GET_AGENT_HIERARCHY_DATA_ERROR';

export const INVITE_AGENT_REQUEST = 'INVITE_AGENT_REQUEST';
export const INVITE_AGENT_SUCCESS = 'INVITE_AGENT_SUCCESS';
export const INVITE_AGENT_ERROR = 'INVITE_AGENT_ERROR';

export const GET_AGENT_TOKEN_REQUEST = 'GET_AGENT_TOKEN_REQUEST';
export const GET_AGENT_TOKEN_SUCCESS = 'GET_AGENT_TOKEN_SUCCESS';
export const GET_AGENT_TOKEN_ERROR = 'GET_AGENT_TOKEN_ERROR';

export const GET_AGENT_DETAIL_REQUEST = 'GET_AGENT_DETAIL_REQUEST';
export const GET_AGENT_DETAIL_SUCCESS = 'GET_AGENT_DETAIL_SUCCESS';
export const GET_AGENT_DETAIL_ERROR = 'GET_AGENT_DETAIL_ERROR';

export const UPDATE_AGENT_REQUEST = 'UPDATE_AGENT_REQUEST';
export const UPDATE_AGENT_SUCCESS = 'UPDATE_AGENT_SUCCESS';
export const UPDATE_AGENT_ERROR = 'UPDATE_AGENT_ERROR';

export const DELETE_AGENT_REQUEST = 'DELETE_AGENT_REQUEST';
export const DELETE_AGENT_SUCCESS = 'DELETE_AGENT_SUCCESS';
export const DELETE_AGENT_ERROR = 'DELETE_AGENT_ERROR';

export const fetchAgents = data => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [FETCH_AGENTS_REQUEST, FETCH_AGENTS_SUCCESS, FETCH_AGENTS_ERROR],
      callAPI: () => ApiClient.post('agent/Agents/Search', data),
      payload: { data },
    }),
  );
};

export const getAgentHierarchyData = () => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [
        GET_AGENT_HIERARCHY_DATA_REQUEST,
        GET_AGENT_HIERARCHY_DATA_SUCCESS,
        GET_AGENT_HIERARCHY_DATA_ERROR,
      ],
      callAPI: () => ApiClient.get('agent/Agents/GetHierarchyData'),
      payload: {},
    }),
  );
};

export const inviteAgent = (data, formId) => dispatch => {
  return Promise.resolve(dispatch(startSubmit(formId))).then(() =>
    dispatch({
      types: [INVITE_AGENT_REQUEST, INVITE_AGENT_SUCCESS, INVITE_AGENT_ERROR],
      callAPI: () => ApiClient.post('agent/Agents/Invite', data),
      payload: { data, formId },
    }),
  );
};

export const getAgentToken = () => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [
        GET_AGENT_TOKEN_REQUEST,
        GET_AGENT_TOKEN_SUCCESS,
        GET_AGENT_TOKEN_ERROR,
      ],
      callAPI: () => ApiClient.get('agent/Agents/GetToken'),
      payload: {},
    }),
  );
};

export const getAgentDetail = id => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [
        GET_AGENT_DETAIL_REQUEST,
        GET_AGENT_DETAIL_SUCCESS,
        GET_AGENT_DETAIL_ERROR,
      ],
      callAPI: () => ApiClient.get(`agent/Agents/GetDetail/${id}`),
      payload: { data: { id } },
    }),
  );
};

export const updateAgent = (id, data, formId) => dispatch => {
  return Promise.resolve(dispatch(startSubmit(formId))).then(() =>
    dispatch({
      types: [UPDATE_AGENT_REQUEST, UPDATE_AGENT_SUCCESS, UPDATE_AGENT_ERROR],
      callAPI: () => ApiClient.put(`agent/Agents/${id}`, data),
      payload: { data: { id, ...data }, formId },
    }),
  );
};

export const deleteAgent = id => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [DELETE_AGENT_REQUEST, DELETE_AGENT_SUCCESS, DELETE_AGENT_ERROR],
      callAPI: () => ApiClient.delete(`agent/Agents/${id}`),
      payload: { data: { id } },
    }),
  );
};

const initialState = {
  payload: {},
  data: {},
  response: {},
  error: {},
  errorMessage: '',
  successMessage: '',
};

export default function reducer(state = initialState, action) {
  const { payload, response, error, successMessage, errorMessage } = action;
  switch (action.type) {
    case FETCH_AGENTS_REQUEST:
    case GET_AGENT_HIERARCHY_DATA_REQUEST:
    case GET_AGENT_TOKEN_REQUEST:
    case GET_AGENT_DETAIL_REQUEST:
    case UPDATE_AGENT_REQUEST:
    case DELETE_AGENT_REQUEST:
      return {
        ...state,
        payload,
        response: {},
        error: {},
        successMessage: '',
        errorMessage: '',
      };

    case FETCH_AGENTS_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          items: response.data.data.items,
          pages: response.data.data.pageCount,
        },
        response,
        successMessage,
      };
    case GET_AGENT_DETAIL_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          item: response.data.data,
        },
        response,
        successMessage,
      };
    case GET_AGENT_HIERARCHY_DATA_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          hierarchyData: response.data.data,
        },
        response,
        successMessage,
      };
    case GET_AGENT_TOKEN_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          token: response.data.data,
        },
        response,
        successMessage,
      };

    case UPDATE_AGENT_SUCCESS:
    case DELETE_AGENT_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
        },
        response,
        successMessage,
      };

    case FETCH_AGENTS_ERROR:
    case GET_AGENT_HIERARCHY_DATA_ERROR:
    case GET_AGENT_TOKEN_ERROR:
    case GET_AGENT_DETAIL_ERROR:
    case UPDATE_AGENT_ERROR:
    case DELETE_AGENT_ERROR:
      return {
        ...state,
        payload,
        error,
        errorMessage,
      };
    default:
      return state;
  }
}
