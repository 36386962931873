import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import { required, email, createValidator, match, password, maxLength } from '../../../helpers/validator';
import TextField from '../Fields/TextField';
import SelectField from '../Fields/SelectField';
import PropTypes from 'prop-types';
import Button from '../Controls/Button';
import { Link } from 'react-router-dom';
import CheckField from '../Fields/CheckField';
import { countryCodeOptions } from '../../../constants/countryCodes';

const validator = createValidator({
  email: [required, email],
  password: [required, password],
  confirmedPassword: [required, match('password', 'Password')],
  countryCode: [required],
  contactNumber: [required, maxLength(13)],
  agentName: [required, maxLength(256)],
  agentCode: [required, maxLength(256)],
  identityCardNumber: [maxLength(32)],
  plan: [required, maxLength(256)],
  tncAgree: [required],
});

export const agentRegisterFormId = 'agent-register';

class AgentRegisterForm extends Component {
  render() {
    const { error, handleSubmit, pristine, reset, submitting } = this.props;

    return (
      <form onSubmit={handleSubmit} className="ml-2 mr-2">
        <Field
          name="email"
          component={TextField}
          type="text"
          label="Email"
          showLabel={true}
          required={true}
          disabled={submitting}
        />
        <Field
          name="password"
          component={TextField}
          type="password"
          label="Password"
          showLabel={true}
          required={true}
          disabled={submitting}
        />
        <Field
          name="confirmedPassword"
          component={TextField}
          type="password"
          label="Confirm Password"
          showLabel={true}
          required={true}
          disabled={submitting}
        />
        <div className="row">
          <div className="col-4">
            <Field
              name="countryCode"
              component={SelectField}
              options={countryCodeOptions}
              label="Code"
              showLabel={true}
              required={true}
              disabled={submitting}
            />
          </div>
          <div className="col-8">
            <Field
              name="contactNumber"
              component={TextField}
              type="text"
              label="Contact Number"
              showLabel={true}
              required={true}
              disabled={submitting}
            />
          </div>
        </div>

        <Field
          name="agentName"
          component={TextField}
          type="text"
          label="Agent Name"
          showLabel={true}
          required={true}
          disabled={submitting}
        />
        <Field
          name="agentCode"
          component={TextField}
          type="text"
          label="Agent ID"
          showLabel={true}
          required={true}
          disabled={submitting}
        />
        <Field
          name="plan"
          component={TextField}
          rows="text"
          label="Plan"
          showLabel={true}
          required={true}
          disabled={submitting}
        />
        <Field
          name="identityCardNumber"
          component={TextField}
          type="text"
          label="Identity Card Number"
          showLabel={true}
          disabled={submitting}
        />
        <Field
          name="tncAgree"
          displayName="TNC Agree"
          component={CheckField}
          label="Agree the terms and policy"
          showLabel={true}
          required={true}
          disabled={submitting}
        />
        {error && (
          <div className="my-2 text-left">
            <span className="form-error">{error}</span>
          </div>
        )}
        <hr />
        <Button
          type="submit"
          size="lg"
          className="btn btn-secondary btn-lg btn-block bg-gradient-theme-left border-0"
          disabled={pristine}
          loading={submitting}
        >
          Register
        </Button>
        <div className="text-center pt-3">
          <h6>
            <Link to="/sign-in">Back to Sign In</Link>
          </h6>
        </div>
      </form>
    );
  }
}

AgentRegisterForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  invalid: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({});
const mapDispatchToProps = dispatch => ({});

AgentRegisterForm = reduxForm({
  form: agentRegisterFormId,
  validate: validator,
  enableReinitialize: true,
  destroyOnUnmount: false,
})(AgentRegisterForm);

export default connect(mapStateToProps, mapDispatchToProps)(AgentRegisterForm);
