import ApiClient from '../../../helpers/apiClient';
import { startSubmit } from 'redux-form';
import axios from 'axios';

export const FETCH_FREWIE_PLATFORM_REQUEST = 'FETCH_FREWIE_PLATFORM_REQUEST';
export const FETCH_FREWIE_PLATFORM_SUCCESS = 'FETCH_FREWIE_PLATFORM_SUCCESS';
export const FETCH_FREWIE_PLATFORM_ERROR = 'FETCH_FREWIE_PLATFORM_ERROR';

export const FETCH_STRIPE_REQUEST = 'FETCH_STRIPE_REQUEST';
export const FETCH_STRIPE_SUCCESS = 'FETCH_STRIPE_SUCCESS';
export const FETCH_STRIPE_ERROR = 'FETCH_STRIPE_ERROR';

export const FETCH_FREWIE_PLATFORM_OPTIONS_REQUEST =
    'FETCH_FREWIE_PLATFORM_OPTIONS_REQUEST';
export const FETCH_FREWIE_PLATFORM_OPTIONS_SUCCESS =
    'FETCH_FREWIE_PLATFORM_OPTIONS_SUCCESS';
export const FETCH_FREWIE_PLATFORM_OPTIONS_ERROR =
    'FETCH_FREWIE_PLATFORM_OPTIONS_ERROR';

export const GET_FREWIE_PLATFORM_DETAIL_REQUEST =
    'GET_FREWIE_PLATFORM_DETAIL_REQUEST';
export const GET_FREWIE_PLATFORM_DETAIL_SUCCESS =
    'GET_FREWIE_PLATFORM_DETAIL_SUCCESS';
export const GET_FREWIE_PLATFORM_DETAIL_ERROR = 'GET_FREWIE_PLATFORM_DETAIL_ERROR';

export const CREATE_FREWIE_PLATFORM_REQUEST = 'CREATE_FREWIE_PLATFORM_REQUEST';
export const CREATE_FREWIE_PLATFORM_SUCCESS = 'CREATE_FREWIE_PLATFORM_SUCCESS';
export const CREATE_FREWIE_PLATFORM_ERROR = 'CREATE_FREWIE_PLATFORM_ERROR';

export const UPDATE_FREWIE_PLATFORM_REQUEST = 'UPDATE_FREWIE_PLATFORM_REQUEST';
export const UPDATE_FREWIE_PLATFORM_SUCCESS = 'UPDATE_FREWIE_PLATFORM_SUCCESS';
export const UPDATE_FREWIE_PLATFORM_ERROR = 'UPDATE_FREWIE_PLATFORM_ERROR';

export const DELETE_FREWIE_PLATFORM_REQUEST = 'DELETE_FREWIE_PLATFORM_REQUEST';
export const DELETE_FREWIE_PLATFORM_SUCCESS = 'DELETE_FREWIE_PLATFORM_SUCCESS';
export const DELETE_FREWIE_PLATFORM_ERROR = 'DELETE_FREWIE_PLATFORM_ERROR';

export const GENERATE_PO_PDF_REQUEST = 'GENERATE_PO_PDF_REQUEST';
export const GENERATE_PO_PDF_SUCCESS = 'GENERATE_PO_PDF_SUCCESS';
export const GENERATE_PO_PDF_ERROR = 'GENERATE_PO_PDF_ERROR';

export const fetchFrewiePlatform = data => dispatch => {
    return Promise.resolve().then(() =>
        dispatch({
            types: [
                FETCH_FREWIE_PLATFORM_REQUEST,
                FETCH_FREWIE_PLATFORM_SUCCESS,
                FETCH_FREWIE_PLATFORM_ERROR,
            ],
            callAPI: () => ApiClient.post('payment/FrewiePlatforms/Search', data),
            payload: { data },
        }),
    );
};

export const fetchFrewiePlatformOptions = () => dispatch => {
    return Promise.resolve().then(() =>
        dispatch({
            types: [
                FETCH_FREWIE_PLATFORM_OPTIONS_REQUEST,
                FETCH_FREWIE_PLATFORM_OPTIONS_SUCCESS,
                FETCH_FREWIE_PLATFORM_OPTIONS_ERROR,
            ],
            callAPI: () => ApiClient.get('payment/FrewiePlatforms/GetSelectList'),
            payload: {},
        }),
    );
};

export const getFrewiePlatformDetail = id => dispatch => {
    return Promise.resolve().then(() =>
        dispatch({
            types: [
                GET_FREWIE_PLATFORM_DETAIL_REQUEST,
                GET_FREWIE_PLATFORM_DETAIL_SUCCESS,
                GET_FREWIE_PLATFORM_DETAIL_ERROR,
            ],
            callAPI: () => ApiClient.get(`payment/FrewiePlatforms/${id}`),
            payload: { data: { id } },
        }),
    );
};

export const createFrewiePlatform = (data, formId) => dispatch => {
    return Promise.resolve(dispatch(startSubmit(formId))).then(() =>
        dispatch({
            types: [
                CREATE_FREWIE_PLATFORM_REQUEST,
                CREATE_FREWIE_PLATFORM_SUCCESS,
                CREATE_FREWIE_PLATFORM_ERROR,
            ],
            callAPI: () => ApiClient.post('payment/FrewiePlatforms', data),
            payload: { data, formId },
        }),
    );
};


export const createStripePayment = ({ data, poHeaderId }) => dispatch => {
    return Promise.resolve().then(() =>
      dispatch({
        types: [FETCH_STRIPE_REQUEST, FETCH_STRIPE_SUCCESS, FETCH_STRIPE_ERROR],
        callAPI: () => ApiClient.post('payment/Payment/GetStripePlzUrl', { dataPlanModel: data, poHeaderId }),
        payload: { data, poHeaderId },
      }),
    );
  };

  export const createFrewieCheckout = ({ data, poHeaderId }) => dispatch => {
    return Promise.resolve().then(() =>
      dispatch({
        types: [FETCH_STRIPE_REQUEST, FETCH_STRIPE_SUCCESS, FETCH_STRIPE_ERROR],
        callAPI: () => ApiClient.post('payment/Payment/FrewieCheckout', { dataPlanModel: data, poHeaderId }),
        payload: { data, poHeaderId },
      }),
    );
  };
  
  export const downloadPOPdf = ({ id }) => dispatch => {
    return Promise.resolve().then(() =>
      dispatch({
        types: [GENERATE_PO_PDF_REQUEST, GENERATE_PO_PDF_SUCCESS, GENERATE_PO_PDF_ERROR],
        callAPI: () => ApiClient.post(`network/Freewie/CreateBulkPDF/${id}`),
        payload: { data: { id } },
      }),
    );
  };


export const updateFrewiePlatform = (id, data, formId) => dispatch => {
    return Promise.resolve(dispatch(startSubmit(formId))).then(() =>
        dispatch({
            types: [
                UPDATE_FREWIE_PLATFORM_REQUEST,
                UPDATE_FREWIE_PLATFORM_SUCCESS,
                UPDATE_FREWIE_PLATFORM_ERROR,
            ],
            callAPI: () => ApiClient.put(`payment/FrewiePlatforms/${id}`, data),
            payload: { data: { ...data, id }, formId },
        }),
    );
};

export const deleteFrewiePlatform = id => dispatch => {
    return Promise.resolve().then(() =>
        dispatch({
            types: [
                DELETE_FREWIE_PLATFORM_REQUEST,
                DELETE_FREWIE_PLATFORM_SUCCESS,
                DELETE_FREWIE_PLATFORM_ERROR,
            ],
            callAPI: () => ApiClient.delete(`payment/FrewiePlatforms/${id}`),
            payload: { data: { id } },
        }),
    );
};

const initialState = {
    payload: {},
    data: {},
    response: {},
    error: {},
    errorMessage: '',
    successMessage: '',
};

export default function reducer(state = initialState, action) {
    const { payload, response, error, successMessage, errorMessage } = action;
    switch (action.type) {
        case FETCH_FREWIE_PLATFORM_REQUEST:
        case FETCH_FREWIE_PLATFORM_OPTIONS_REQUEST:
        case GET_FREWIE_PLATFORM_DETAIL_REQUEST:
        case CREATE_FREWIE_PLATFORM_REQUEST:
        case GENERATE_PO_PDF_REQUEST:
        case UPDATE_FREWIE_PLATFORM_REQUEST:
        case DELETE_FREWIE_PLATFORM_REQUEST:
            return {
                ...state,
                payload,
                response: {},
                error: {},
                successMessage: '',
                errorMessage: '',
            };

        case FETCH_FREWIE_PLATFORM_OPTIONS_SUCCESS:
            return {
                ...state,
                data: { ...state.data, options: response.data.data },
                response,
                successMessage,
            };

        case FETCH_FREWIE_PLATFORM_SUCCESS:
            return {
                ...state,
                data: {
                    ...state.data,
                    items: response.data.data.items,
                    pages: response.data.data.pageCount,
                },
                response,
                successMessage,
            };

        case GET_FREWIE_PLATFORM_DETAIL_SUCCESS:
            return {
                ...state,
                data: {
                    ...state.data,
                    item: response.data.data,
                },
                response,
                successMessage,
            };

        case CREATE_FREWIE_PLATFORM_SUCCESS:
        case UPDATE_FREWIE_PLATFORM_SUCCESS:
        case DELETE_FREWIE_PLATFORM_SUCCESS:
            return {
                ...state,
                response,
                successMessage,
            };

        case FETCH_FREWIE_PLATFORM_ERROR:
        case FETCH_FREWIE_PLATFORM_OPTIONS_ERROR:
        case GET_FREWIE_PLATFORM_DETAIL_ERROR:
        case CREATE_FREWIE_PLATFORM_ERROR:
        case UPDATE_FREWIE_PLATFORM_ERROR:
        case GENERATE_PO_PDF_ERROR:
        case DELETE_FREWIE_PLATFORM_ERROR:
            return {
                ...state,
                payload,
                error,
                errorMessage,
            };
        default:
            return state;
    }
}
