import ApiClient from '../../../helpers/apiClient';
import { startSubmit } from 'redux-form';

export const FETCH_PAYMENT_TERMS_REQUEST = 'FETCH_PAYMENT_TERMS_REQUEST';
export const FETCH_PAYMENT_TERMS_SUCCESS = 'FETCH_PAYMENT_TERMS_SUCCESS';
export const FETCH_PAYMENT_TERMS_ERROR = 'FETCH_PAYMENT_TERMS_ERROR';

export const FETCH_PAYMENT_TERM_OPTIONS_REQUEST =
  'FETCH_PAYMENT_TERM_OPTIONS_REQUEST';
export const FETCH_PAYMENT_TERM_OPTIONS_SUCCESS =
  'FETCH_PAYMENT_TERM_OPTIONS_SUCCESS';
export const FETCH_PAYMENT_TERM_OPTIONS_ERROR =
  'FETCH_PAYMENT_TERM_OPTIONS_ERROR';

export const FETCH_ENTITY_PAYMENT_TERM_OPTIONS_REQUEST =
  'FETCH_ENTITY_PAYMENT_TERM_OPTIONS_REQUEST';
export const FETCH_ENTITY_PAYMENT_TERM_OPTIONS_SUCCESS =
  'FETCH_ENTITY_PAYMENT_TERM_OPTIONS_SUCCESS';
export const FETCH_ENTITY_PAYMENT_TERM_OPTIONS_ERROR =
  'FETCH_ENTITY_PAYMENT_TERM_OPTIONS_ERROR';

export const GET_PAYMENT_TERM_DETAIL_REQUEST =
  'GET_PAYMENT_TERM_DETAIL_REQUEST';
export const GET_PAYMENT_TERM_DETAIL_SUCCESS =
  'GET_PAYMENT_TERM_DETAIL_SUCCESS';
export const GET_PAYMENT_TERM_DETAIL_ERROR = 'GET_PAYMENT_TERM_DETAIL_ERROR';

export const CREATE_PAYMENT_TERM_REQUEST = 'CREATE_PAYMENT_TERM_REQUEST';
export const CREATE_PAYMENT_TERM_SUCCESS = 'CREATE_PAYMENT_TERM_SUCCESS';
export const CREATE_PAYMENT_TERM_ERROR = 'CREATE_PAYMENT_TERM_ERROR';

export const UPDATE_PAYMENT_TERM_REQUEST = 'UPDATE_PAYMENT_TERM_REQUEST';
export const UPDATE_PAYMENT_TERM_SUCCESS = 'UPDATE_PAYMENT_TERM_SUCCESS';
export const UPDATE_PAYMENT_TERM_ERROR = 'UPDATE_PAYMENT_TERM_ERROR';

export const DELETE_PAYMENT_TERM_REQUEST = 'DELETE_PAYMENT_TERM_REQUEST';
export const DELETE_PAYMENT_TERM_SUCCESS = 'DELETE_PAYMENT_TERM_SUCCESS';
export const DELETE_PAYMENT_TERM_ERROR = 'DELETE_PAYMENT_TERM_ERROR';

export const fetchPaymentTerms = data => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [
        FETCH_PAYMENT_TERMS_REQUEST,
        FETCH_PAYMENT_TERMS_SUCCESS,
        FETCH_PAYMENT_TERMS_ERROR,
      ],
      callAPI: () => ApiClient.post('payment/PaymentTerms/Search', data),
      payload: { data },
    }),
  );
};

export const fetchPaymentTermOptions = () => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [
        FETCH_PAYMENT_TERM_OPTIONS_REQUEST,
        FETCH_PAYMENT_TERM_OPTIONS_SUCCESS,
        FETCH_PAYMENT_TERM_OPTIONS_ERROR,
      ],
      callAPI: () => ApiClient.get('payment/PaymentTerms/GetSelectList'),
      payload: {},
    }),
  );
};

export const fetchEntityPaymentTermOptions = entity => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [
        FETCH_ENTITY_PAYMENT_TERM_OPTIONS_REQUEST,
        FETCH_ENTITY_PAYMENT_TERM_OPTIONS_SUCCESS,
        FETCH_ENTITY_PAYMENT_TERM_OPTIONS_ERROR,
      ],
      callAPI: () =>
        ApiClient.get(`payment/PaymentTerms/GetOptionsByEntity/${entity}`),
      payload: { data: { entity } },
    }),
  );
};

export const getPaymentTermDetail = id => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [
        GET_PAYMENT_TERM_DETAIL_REQUEST,
        GET_PAYMENT_TERM_DETAIL_SUCCESS,
        GET_PAYMENT_TERM_DETAIL_ERROR,
      ],
      callAPI: () => ApiClient.get(`payment/PaymentTerms/GetDetail/${id}`),
      payload: { data: { id } },
    }),
  );
};

export const createPaymentTerm = (data, formId) => dispatch => {
  return Promise.resolve(dispatch(startSubmit(formId))).then(() =>
    dispatch({
      types: [
        CREATE_PAYMENT_TERM_REQUEST,
        CREATE_PAYMENT_TERM_SUCCESS,
        CREATE_PAYMENT_TERM_ERROR,
      ],
      callAPI: () => ApiClient.post('payment/PaymentTerms', data),
      payload: { data, formId },
    }),
  );
};

export const updatePaymentTerm = (id, data, formId) => dispatch => {
  return Promise.resolve(dispatch(startSubmit(formId))).then(() =>
    dispatch({
      types: [
        UPDATE_PAYMENT_TERM_REQUEST,
        UPDATE_PAYMENT_TERM_SUCCESS,
        UPDATE_PAYMENT_TERM_ERROR,
      ],
      callAPI: () => ApiClient.put(`payment/PaymentTerms/${id}`, data),
      payload: { data: { ...data, id }, formId },
    }),
  );
};

export const deletePaymentTerm = id => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [
        DELETE_PAYMENT_TERM_REQUEST,
        DELETE_PAYMENT_TERM_SUCCESS,
        DELETE_PAYMENT_TERM_ERROR,
      ],
      callAPI: () => ApiClient.delete(`payment/PaymentTerms/${id}`),
      payload: { data: { id } },
    }),
  );
};

const initialState = {
  payload: {},
  data: {},
  response: {},
  error: {},
  errorMessage: '',
  successMessage: '',
};

export default function reducer(state = initialState, action) {
  const { payload, response, error, successMessage, errorMessage } = action;
  switch (action.type) {
    case FETCH_PAYMENT_TERMS_REQUEST:
    case FETCH_PAYMENT_TERM_OPTIONS_REQUEST:
    case FETCH_ENTITY_PAYMENT_TERM_OPTIONS_REQUEST:
    case GET_PAYMENT_TERM_DETAIL_REQUEST:
    case CREATE_PAYMENT_TERM_REQUEST:
    case UPDATE_PAYMENT_TERM_REQUEST:
    case DELETE_PAYMENT_TERM_REQUEST:
      return {
        ...state,
        payload,
        response: {},
        error: {},
        successMessage: '',
        errorMessage: '',
      };

    case FETCH_PAYMENT_TERMS_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          items: response.data.data.items,
          pages: response.data.data.pageCount,
        },
        response,
        successMessage,
      };
    case FETCH_PAYMENT_TERM_OPTIONS_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          options: response.data.data,
        },
        response,
        successMessage,
      };

    case FETCH_ENTITY_PAYMENT_TERM_OPTIONS_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          entityPaymentTermOptions: {
            ...state.data.entityPaymentTermOptions,
            [payload.data.entity + 'PaymentTermOptions']: response.data.data,
          },
        },
        response,
        successMessage,
      };

    case GET_PAYMENT_TERM_DETAIL_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          item: response.data.data,
        },
        response,
        successMessage,
      };

    case CREATE_PAYMENT_TERM_SUCCESS:
    case UPDATE_PAYMENT_TERM_SUCCESS:
    case DELETE_PAYMENT_TERM_SUCCESS:
      return {
        ...state,
        response,
        successMessage,
      };

    case FETCH_PAYMENT_TERMS_ERROR:
    case FETCH_PAYMENT_TERM_OPTIONS_ERROR:
    case FETCH_ENTITY_PAYMENT_TERM_OPTIONS_ERROR:
    case GET_PAYMENT_TERM_DETAIL_ERROR:
    case CREATE_PAYMENT_TERM_ERROR:
    case UPDATE_PAYMENT_TERM_ERROR:
    case DELETE_PAYMENT_TERM_ERROR:
      return {
        ...state,
        payload,
        error,
        errorMessage,
      };
    default:
      return state;
  }
}
